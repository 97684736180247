/* tslint:disable */
/* eslint-disable */
/**
 * Nirvana Claims API
 * Nirvana Claims APIs
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from './base';

/**
 *
 * @export
 * @interface Address
 */
export interface Address {
  /**
   *
   * @type {AddressType}
   * @memberof Address
   */
  addressType?: AddressType;
  /**
   * Address line 1
   * @type {string}
   * @memberof Address
   */
  line1?: string;
  /**
   * Address line 2
   * @type {string}
   * @memberof Address
   */
  line2?: string;
  /**
   * City
   * @type {string}
   * @memberof Address
   */
  city?: string;
  /**
   * Country
   * @type {string}
   * @memberof Address
   */
  country?: string;
  /**
   *
   * @type {USState}
   * @memberof Address
   */
  state?: USState;
  /**
   * ZIP code
   * @type {string}
   * @memberof Address
   */
  zip?: string;
  /**
   *
   * @type {boolean}
   * @memberof Address
   */
  isMailingAddress?: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum AddressType {
  AddressTypeBusiness = 'AddressTypeBusiness',
  AddressTypeHome = 'AddressTypeHome',
  AddressTypeOther = 'AddressTypeOther',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum AutoPartyType {
  AutoPartyTypeDriver = 'AutoPartyTypeDriver',
  AutoPartyTypeOwner = 'AutoPartyTypeOwner',
  AutoPartyTypeInsurance = 'AutoPartyTypeInsurance',
  AutoPartyTypeAttorney = 'AutoPartyTypeAttorney',
  AutoPartyTypePassenger = 'AutoPartyTypePassenger',
  AutoPartyTypeWitness = 'AutoPartyTypeWitness',
  AutoPartyTypePedestrian = 'AutoPartyTypePedestrian',
  AutoPartyTypeEmployer = 'AutoPartyTypeEmployer',
  AutoPartyTypeProperty = 'AutoPartyTypeProperty',
  AutoPartyTypeOther = 'AutoPartyTypeOther',
  AutoPartyTypeLienholder = 'AutoPartyTypeLienholder',
  AutoPartyTypeOwnerDriver = 'AutoPartyTypeOwnerDriver',
  AutoPartyTypeOwnerPassenger = 'AutoPartyTypeOwnerPassenger',
}

/**
 *
 * @export
 * @interface ClaimRecord
 */
export interface ClaimRecord {
  /**
   *
   * @type {string}
   * @memberof ClaimRecord
   */
  claimNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ClaimRecord
   */
  policyNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ClaimRecord
   */
  fnolID?: string;
  /**
   *
   * @type {string}
   * @memberof ClaimRecord
   */
  state?: string;
  /**
   *
   * @type {string}
   * @memberof ClaimRecord
   */
  insuredName?: string;
  /**
   *
   * @type {string}
   * @memberof ClaimRecord
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof ClaimRecord
   */
  createdAt?: string;
}
/**
 *
 * @export
 * @interface ClaimRecords
 */
export interface ClaimRecords {
  /**
   *
   * @type {Array<ClaimRecord>}
   * @memberof ClaimRecords
   */
  ClaimRecordsInternal?: Array<ClaimRecord>;
  /**
   *
   * @type {Array<ClaimRecord>}
   * @memberof ClaimRecords
   */
  ClaimRecordsExternal?: Array<ClaimRecord>;
}
/**
 *
 * @export
 * @interface CreateFNOLForm
 */
export interface CreateFNOLForm {
  /**
   *
   * @type {string}
   * @memberof CreateFNOLForm
   */
  policyNumber?: string;
  /**
   *
   * @type {LineOfBusiness}
   * @memberof CreateFNOLForm
   */
  clientLOB?: LineOfBusiness;
  /**
   *
   * @type {Location}
   * @memberof CreateFNOLForm
   */
  location?: Location;
  /**
   *
   * @type {ReportedPerson}
   * @memberof CreateFNOLForm
   */
  reportedPerson?: ReportedPerson;
  /**
   *
   * @type {Loss}
   * @memberof CreateFNOLForm
   */
  loss?: Loss;
  /**
   *
   * @type {Police}
   * @memberof CreateFNOLForm
   */
  police?: Police;
  /**
   *
   * @type {Miscellaneous}
   * @memberof CreateFNOLForm
   */
  miscellaneous?: Miscellaneous;
  /**
   *
   * @type {Array<Property>}
   * @memberof CreateFNOLForm
   */
  properties?: Array<Property>;
  /**
   *
   * @type {Array<PartyDetails>}
   * @memberof CreateFNOLForm
   */
  parties?: Array<PartyDetails>;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateFNOLForm
   */
  files?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof CreateFNOLForm
   */
  total_attachments_size: number;
}
/**
 *
 * @export
 * @interface CreateFNOLFormResponse
 */
export interface CreateFNOLFormResponse {
  /**
   * The number of the Claim created on successful FNOL filing
   * @type {string}
   * @memberof CreateFNOLFormResponse
   */
  clientClaimNumber?: string;
}
/**
 *
 * @export
 * @interface EmailAttachment
 */
export interface EmailAttachment {
  /**
   *
   * @type {string}
   * @memberof EmailAttachment
   */
  filename: string;
  /**
   *
   * @type {string}
   * @memberof EmailAttachment
   */
  contentType: string;
  /**
   *
   * @type {string}
   * @memberof EmailAttachment
   */
  payload: string;
}
/**
 *
 * @export
 * @interface EmailContact
 */
export interface EmailContact {
  /**
   *
   * @type {string}
   * @memberof EmailContact
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof EmailContact
   */
  email: string;
}
/**
 *
 * @export
 * @interface EmailIntakeRequest
 */
export interface EmailIntakeRequest {
  /**
   * The message ID of the email being replied to
   * @type {string}
   * @memberof EmailIntakeRequest
   */
  in_reply_to: string;
  /**
   * All the message IDs of the emails being replied to
   * @type {Array<string>}
   * @memberof EmailIntakeRequest
   */
  references: Array<string>;
  /**
   *
   * @type {string}
   * @memberof EmailIntakeRequest
   */
  message_id: string;
  /**
   *
   * @type {string}
   * @memberof EmailIntakeRequest
   */
  subject: string;
  /**
   *
   * @type {Array<EmailContact>}
   * @memberof EmailIntakeRequest
   */
  from: Array<EmailContact>;
  /**
   *
   * @type {Array<EmailContact>}
   * @memberof EmailIntakeRequest
   */
  to: Array<EmailContact>;
  /**
   *
   * @type {Array<EmailContact>}
   * @memberof EmailIntakeRequest
   */
  cc?: Array<EmailContact>;
  /**
   *
   * @type {Array<EmailContact>}
   * @memberof EmailIntakeRequest
   */
  bcc?: Array<EmailContact>;
  /**
   *
   * @type {string}
   * @memberof EmailIntakeRequest
   */
  date: string;
  /**
   *
   * @type {Array<string>}
   * @memberof EmailIntakeRequest
   */
  body: Array<string>;
  /**
   *
   * @type {Array<EmailAttachment>}
   * @memberof EmailIntakeRequest
   */
  attachments?: Array<EmailAttachment>;
}
/**
 *
 * @export
 * @interface EmailIntakeResponse
 */
export interface EmailIntakeResponse {
  /**
   * The ID of the draft FNOL created from the email
   * @type {string}
   * @memberof EmailIntakeResponse
   */
  draft_fnol_id: string;
  /**
   * The type of notice that was created from the email (eg. Claim or Report)
   * @type {string}
   * @memberof EmailIntakeResponse
   */
  noticeType: EmailIntakeResponseNoticeTypeEnum;
  /**
   *
   * @type {string}
   * @memberof EmailIntakeResponse
   */
  policyNumber: string;
  /**
   *
   * @type {string}
   * @memberof EmailIntakeResponse
   */
  lineOfBusiness: string;
  /**
   *
   * @type {FnolIntakeVehicleDetails}
   * @memberof EmailIntakeResponse
   */
  ownVehicleDetails?: FnolIntakeVehicleDetails;
  /**
   *
   * @type {FnolIntakeVehicleDetails}
   * @memberof EmailIntakeResponse
   */
  otherVehicleDetails?: FnolIntakeVehicleDetails;
  /**
   *
   * @type {FnolIntakeReporter}
   * @memberof EmailIntakeResponse
   */
  reporterDetails?: FnolIntakeReporter;
  /**
   *
   * @type {FnolIntakeIncidentDetails}
   * @memberof EmailIntakeResponse
   */
  incidentDetails: FnolIntakeIncidentDetails;
}

/**
 * @export
 * @enum {string}
 */
export enum EmailIntakeResponseNoticeTypeEnum {
  Claim = 'Claim',
  Report = 'Report',
}

/**
 *
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
  /**
   *
   * @type {string}
   * @memberof ErrorMessage
   */
  message: string;
  /**
   *
   * @type {number}
   * @memberof ErrorMessage
   */
  code: number;
}
/**
 *
 * @export
 * @interface FileHandle
 */
export interface FileHandle {
  /**
   *
   * @type {string}
   * @memberof FileHandle
   */
  handle: string;
}
/**
 *
 * @export
 * @interface FnolIntakeIncidentDetails
 */
export interface FnolIntakeIncidentDetails {
  /**
   *
   * @type {string}
   * @memberof FnolIntakeIncidentDetails
   */
  dateOfLoss: string;
  /**
   *
   * @type {string}
   * @memberof FnolIntakeIncidentDetails
   */
  state: string;
  /**
   *
   * @type {string}
   * @memberof FnolIntakeIncidentDetails
   */
  lossLocation: string;
  /**
   *
   * @type {string}
   * @memberof FnolIntakeIncidentDetails
   */
  incidentDescription: string;
}
/**
 *
 * @export
 * @interface FnolIntakeReporter
 */
export interface FnolIntakeReporter {
  /**
   *
   * @type {string}
   * @memberof FnolIntakeReporter
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof FnolIntakeReporter
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof FnolIntakeReporter
   */
  phone: string;
}
/**
 *
 * @export
 * @interface FnolIntakeVehicleDetails
 */
export interface FnolIntakeVehicleDetails {
  /**
   *
   * @type {string}
   * @memberof FnolIntakeVehicleDetails
   */
  vin: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum LineOfBusiness {
  LineOfBusinessAuto = 'LineOfBusinessAuto',
  LineOfBusinessPropertyOrLiability = 'LineOfBusinessPropertyOrLiability',
}

/**
 *
 * @export
 * @interface Location
 */
export interface Location {
  /**
   *
   * @type {string}
   * @memberof Location
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  city?: string;
  /**
   *
   * @type {USState}
   * @memberof Location
   */
  state?: USState;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  zip?: string;
}
/**
 *
 * @export
 * @interface Loss
 */
export interface Loss {
  /**
   *
   * @type {string}
   * @memberof Loss
   */
  date?: string;
  /**
   *
   * @type {string}
   * @memberof Loss
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof Loss
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof Loss
   */
  county?: string;
  /**
   *
   * @type {USState}
   * @memberof Loss
   */
  state?: USState;
  /**
   *
   * @type {string}
   * @memberof Loss
   */
  zip?: string;
  /**
   *
   * @type {string}
   * @memberof Loss
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof Loss
   */
  roadCondition?: string;
  /**
   *
   * @type {string}
   * @memberof Loss
   */
  roadType?: string;
  /**
   *
   * @type {string}
   * @memberof Loss
   */
  weather?: string;
  /**
   *
   * @type {USState}
   * @memberof Loss
   */
  jurisdiction?: USState;
  /**
   *
   * @type {string}
   * @memberof Loss
   */
  natureOfInjury?: string;
  /**
   *
   * @type {string}
   * @memberof Loss
   */
  partOfBody?: string;
  /**
   *
   * @type {string}
   * @memberof Loss
   */
  sourceOfInjury?: string;
}
/**
 *
 * @export
 * @interface Miscellaneous
 */
export interface Miscellaneous {
  /**
   *
   * @type {string}
   * @memberof Miscellaneous
   */
  memo?: string;
  /**
   *
   * @type {ReportedVia}
   * @memberof Miscellaneous
   */
  howReportedValue?: ReportedVia;
  /**
   *
   * @type {ReportedBy}
   * @memberof Miscellaneous
   */
  whoReportedValue?: ReportedBy;
  /**
   *
   * @type {string}
   * @memberof Miscellaneous
   */
  customFieldValue?: string;
}
/**
 *
 * @export
 * @interface PartyDetails
 */
export interface PartyDetails {
  /**
   *
   * @type {AutoPartyType}
   * @memberof PartyDetails
   */
  partyTypeAuto?: AutoPartyType;
  /**
   *
   * @type {PropertyOrLiabilityPartyType}
   * @memberof PartyDetails
   */
  partyTypePropertyOrLiability?: PropertyOrLiabilityPartyType;
  /**
   *
   * @type {boolean}
   * @memberof PartyDetails
   */
  isCompany?: boolean;
  /**
   * Company name (if applicable)
   * @type {string}
   * @memberof PartyDetails
   */
  companyName?: string;
  /**
   * First name
   * @type {string}
   * @memberof PartyDetails
   */
  firstName?: string;
  /**
   * Last name
   * @type {string}
   * @memberof PartyDetails
   */
  lastName?: string;
  /**
   * Email address
   * @type {string}
   * @memberof PartyDetails
   */
  email?: string;
  /**
   * Date of birth
   * @type {string}
   * @memberof PartyDetails
   */
  dateOfBirth?: string;
  /**
   * Gender
   * @type {string}
   * @memberof PartyDetails
   */
  gender?: string;
  /**
   * Relationship (e.g., spouse, child)
   * @type {string}
   * @memberof PartyDetails
   */
  relationship?: string;
  /**
   * Employment status (e.g., Regular/Full-Time)
   * @type {string}
   * @memberof PartyDetails
   */
  employmentStatus?: string;
  /**
   * Job position
   * @type {string}
   * @memberof PartyDetails
   */
  position?: string;
  /**
   * Citation information
   * @type {string}
   * @memberof PartyDetails
   */
  citation?: string;
  /**
   * Description of injury
   * @type {string}
   * @memberof PartyDetails
   */
  injuryDescription?: string;
  /**
   *
   * @type {boolean}
   * @memberof PartyDetails
   */
  isAdmittedToHospital?: boolean;
  /**
   * Hospital information
   * @type {string}
   * @memberof PartyDetails
   */
  hospitalInfo?: string;
  /**
   *
   * @type {boolean}
   * @memberof PartyDetails
   */
  isTakenByAmbulance?: boolean;
  /**
   * Description of damage
   * @type {string}
   * @memberof PartyDetails
   */
  damageDescription?: string;
  /**
   *
   * @type {Array<Address>}
   * @memberof PartyDetails
   */
  addresses?: Array<Address>;
  /**
   *
   * @type {Array<Phone>}
   * @memberof PartyDetails
   */
  phones?: Array<Phone>;
}
/**
 *
 * @export
 * @interface Phone
 */
export interface Phone {
  /**
   *
   * @type {PhoneType}
   * @memberof Phone
   */
  phoneType?: PhoneType;
  /**
   * Phone number
   * @type {string}
   * @memberof Phone
   */
  phoneNumber?: string;
  /**
   * Memo or description
   * @type {string}
   * @memberof Phone
   */
  memo?: string;
  /**
   *
   * @type {boolean}
   * @memberof Phone
   */
  isPreferredContact?: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum PhoneType {
  PhoneTypeHome = 'PhoneTypeHome',
  PhoneTypeFax = 'PhoneTypeFax',
  PhoneTypeTollFree = 'PhoneTypeTollFree',
  PhoneTypeOther = 'PhoneTypeOther',
  PhoneTypeMobile = 'PhoneTypeMobile',
  PhoneTypeBusiness = 'PhoneTypeBusiness',
}

/**
 *
 * @export
 * @interface Police
 */
export interface Police {
  /**
   *
   * @type {boolean}
   * @memberof Police
   */
  onTheScene?: boolean;
  /**
   *
   * @type {string}
   * @memberof Police
   */
  agencyName?: string;
  /**
   *
   * @type {string}
   * @memberof Police
   */
  reportNumber?: string;
}
/**
 *
 * @export
 * @interface Property
 */
export interface Property {
  /**
   *
   * @type {PropertyType}
   * @memberof Property
   */
  propertyType?: PropertyType;
  /**
   *
   * @type {string}
   * @memberof Property
   */
  propertyName?: string;
  /**
   *
   * @type {string}
   * @memberof Property
   */
  propertyDescription?: string;
  /**
   *
   * @type {string}
   * @memberof Property
   */
  propertyDamageDescription?: string;
  /**
   *
   * @type {string}
   * @memberof Property
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof Property
   */
  vehicleYear?: string;
  /**
   *
   * @type {string}
   * @memberof Property
   */
  vehicleMake?: string;
  /**
   *
   * @type {string}
   * @memberof Property
   */
  vehicleModel?: string;
  /**
   *
   * @type {string}
   * @memberof Property
   */
  vehicleTrim?: string;
  /**
   *
   * @type {string}
   * @memberof Property
   */
  vehicleType?: string;
  /**
   *
   * @type {string}
   * @memberof Property
   */
  vehicleVin?: string;
  /**
   *
   * @type {string}
   * @memberof Property
   */
  vehiclePlateNumber?: string;
  /**
   *
   * @type {USState}
   * @memberof Property
   */
  vehiclePlateState?: USState;
  /**
   *
   * @type {string}
   * @memberof Property
   */
  vehicleColor?: string;
  /**
   *
   * @type {string}
   * @memberof Property
   */
  vehicleLocation?: string;
  /**
   *
   * @type {boolean}
   * @memberof Property
   */
  vehicleIsDriveable?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Property
   */
  vehicleIsTowed?: boolean;
  /**
   *
   * @type {string}
   * @memberof Property
   */
  vehicleTowedInfo?: string;
  /**
   *
   * @type {boolean}
   * @memberof Property
   */
  vehicleIsDamaged?: boolean;
  /**
   *
   * @type {string}
   * @memberof Property
   */
  vehicleDamagedInfo?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum PropertyOrLiabilityPartyType {
  PropertyOrLiabilityPartyTypeInsured = 'PropertyOrLiabilityPartyTypeInsured',
  PropertyOrLiabilityPartyTypeClaimant = 'PropertyOrLiabilityPartyTypeClaimant',
  PropertyOrLiabilityPartyTypeAttorney = 'PropertyOrLiabilityPartyTypeAttorney',
  PropertyOrLiabilityPartyTypeInsurance = 'PropertyOrLiabilityPartyTypeInsurance',
  PropertyOrLiabilityPartyTypeLienholder = 'PropertyOrLiabilityPartyTypeLienholder',
  PropertyOrLiabilityPartyTypeWitness = 'PropertyOrLiabilityPartyTypeWitness',
  PropertyOrLiabilityPartyTypeOther = 'PropertyOrLiabilityPartyTypeOther',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum PropertyType {
  PropertyTypeCommercialProperty = 'PropertyTypeCommercialProperty',
  PropertyTypeResidentialProperty = 'PropertyTypeResidentialProperty',
  PropertyTypePersonalBusinessProperty = 'PropertyTypePersonalBusinessProperty',
  PropertyTypeVehicle = 'PropertyTypeVehicle',
  PropertyTypeLossOfUse = 'PropertyTypeLossOfUse',
  PropertyTypeOther = 'PropertyTypeOther',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum ReportedBy {
  ReportedByAgent = 'ReportedByAgent',
  ReportedByAttorney = 'ReportedByAttorney',
  ReportedByOther = 'ReportedByOther',
  ReportedByClaimantCarrier = 'ReportedByClaimantCarrier',
  ReportedByPolicyHolder = 'ReportedByPolicyHolder',
  ReportedByClaimant = 'ReportedByClaimant',
  ReportedByPublicAdjuster = 'ReportedByPublicAdjuster',
}

/**
 *
 * @export
 * @interface ReportedPerson
 */
export interface ReportedPerson {
  /**
   *
   * @type {string}
   * @memberof ReportedPerson
   */
  date?: string;
  /**
   *
   * @type {string}
   * @memberof ReportedPerson
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ReportedPerson
   */
  middleName?: string;
  /**
   *
   * @type {string}
   * @memberof ReportedPerson
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof ReportedPerson
   */
  contactPhone?: string;
  /**
   *
   * @type {string}
   * @memberof ReportedPerson
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ReportedPerson
   */
  dateReportedEmployer?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum ReportedVia {
  ReportedViaEmail = 'ReportedViaEmail',
  ReportedViaFax = 'ReportedViaFax',
  ReportedViaMail = 'ReportedViaMail',
  ReportedViaPhone = 'ReportedViaPhone',
}

/**
 * Two character short code for the US state the driver is licensed in.
 * @export
 * @enum {string}
 */

export enum USState {
  Al = 'AL',
  Ak = 'AK',
  Az = 'AZ',
  Ar = 'AR',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  De = 'DE',
  Dc = 'DC',
  Fl = 'FL',
  Ga = 'GA',
  Hi = 'HI',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ia = 'IA',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Me = 'ME',
  Md = 'MD',
  Ma = 'MA',
  Mi = 'MI',
  Mn = 'MN',
  Ms = 'MS',
  Mo = 'MO',
  Mt = 'MT',
  Ne = 'NE',
  Nv = 'NV',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Ny = 'NY',
  Nc = 'NC',
  Nd = 'ND',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Vt = 'VT',
  Va = 'VA',
  Wa = 'WA',
  Wv = 'WV',
  Wi = 'WI',
  Wy = 'WY',
}

/**
 *
 * @export
 * @interface UploadFileRequest
 */
export interface UploadFileRequest {
  /**
   *
   * @type {any}
   * @memberof UploadFileRequest
   */
  file: any;
  /**
   *
   * @type {string}
   * @memberof UploadFileRequest
   */
  fileType: string;
  /**
   *
   * @type {string}
   * @memberof UploadFileRequest
   */
  fileDestinationGroup: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get list of all the claims created in the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    claimListGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/claim/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Endpoint to create a draft FNOL from an email
     * @param {EmailIntakeRequest} emailIntakeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    draftFnolEmailIntakePost: async (
      emailIntakeRequest: EmailIntakeRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'emailIntakeRequest' is not null or undefined
      assertParamExists(
        'draftFnolEmailIntakePost',
        'emailIntakeRequest',
        emailIntakeRequest,
      );
      const localVarPath = `/draft-fnol/email-intake`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        emailIntakeRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a FNOL and submit it to NARS
     * @param {CreateFNOLForm} createFNOLForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fnolPost: async (
      createFNOLForm: CreateFNOLForm,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createFNOLForm' is not null or undefined
      assertParamExists('fnolPost', 'createFNOLForm', createFNOLForm);
      const localVarPath = `/fnol`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createFNOLForm,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Upload one file for the claim
     * @param {any} file
     * @param {string} fileType
     * @param {string} fileDestinationGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFNOLFile: async (
      file: any,
      fileType: string,
      fileDestinationGroup: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists('uploadFNOLFile', 'file', file);
      // verify required parameter 'fileType' is not null or undefined
      assertParamExists('uploadFNOLFile', 'fileType', fileType);
      // verify required parameter 'fileDestinationGroup' is not null or undefined
      assertParamExists(
        'uploadFNOLFile',
        'fileDestinationGroup',
        fileDestinationGroup,
      );
      const localVarPath = `/fnol/file`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      if (fileType !== undefined) {
        localVarFormParams.append('fileType', fileType as any);
      }

      if (fileDestinationGroup !== undefined) {
        localVarFormParams.append(
          'fileDestinationGroup',
          fileDestinationGroup as any,
        );
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get list of all the claims created in the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async claimListGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClaimRecords>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.claimListGet(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Endpoint to create a draft FNOL from an email
     * @param {EmailIntakeRequest} emailIntakeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async draftFnolEmailIntakePost(
      emailIntakeRequest: EmailIntakeRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<EmailIntakeResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.draftFnolEmailIntakePost(
          emailIntakeRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create a FNOL and submit it to NARS
     * @param {CreateFNOLForm} createFNOLForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fnolPost(
      createFNOLForm: CreateFNOLForm,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateFNOLFormResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fnolPost(
        createFNOLForm,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Upload one file for the claim
     * @param {any} file
     * @param {string} fileType
     * @param {string} fileDestinationGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadFNOLFile(
      file: any,
      fileType: string,
      fileDestinationGroup: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileHandle>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFNOLFile(
        file,
        fileType,
        fileDestinationGroup,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @summary Get list of all the claims created in the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    claimListGet(options?: any): AxiosPromise<ClaimRecords> {
      return localVarFp
        .claimListGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Endpoint to create a draft FNOL from an email
     * @param {EmailIntakeRequest} emailIntakeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    draftFnolEmailIntakePost(
      emailIntakeRequest: EmailIntakeRequest,
      options?: any,
    ): AxiosPromise<EmailIntakeResponse> {
      return localVarFp
        .draftFnolEmailIntakePost(emailIntakeRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create a FNOL and submit it to NARS
     * @param {CreateFNOLForm} createFNOLForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fnolPost(
      createFNOLForm: CreateFNOLForm,
      options?: any,
    ): AxiosPromise<CreateFNOLFormResponse> {
      return localVarFp
        .fnolPost(createFNOLForm, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Upload one file for the claim
     * @param {any} file
     * @param {string} fileType
     * @param {string} fileDestinationGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFNOLFile(
      file: any,
      fileType: string,
      fileDestinationGroup: string,
      options?: any,
    ): AxiosPromise<FileHandle> {
      return localVarFp
        .uploadFNOLFile(file, fileType, fileDestinationGroup, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary Get list of all the claims created in the system
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public claimListGet(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .claimListGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Endpoint to create a draft FNOL from an email
   * @param {EmailIntakeRequest} emailIntakeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public draftFnolEmailIntakePost(
    emailIntakeRequest: EmailIntakeRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .draftFnolEmailIntakePost(emailIntakeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a FNOL and submit it to NARS
   * @param {CreateFNOLForm} createFNOLForm
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public fnolPost(
    createFNOLForm: CreateFNOLForm,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .fnolPost(createFNOLForm, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Upload one file for the claim
   * @param {any} file
   * @param {string} fileType
   * @param {string} fileDestinationGroup
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public uploadFNOLFile(
    file: any,
    fileType: string,
    fileDestinationGroup: string,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .uploadFNOLFile(file, fileType, fileDestinationGroup, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
