import {
  FormControl,
  FormHelperText,
  Link,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { Checkbox, InputWithLabel, InputZip, constants } from '@nirvana/ui-kit';
import { useMutation } from '@tanstack/react-query';
import { Controller, useFormContext } from 'react-hook-form-v7';
import TrashIcon from 'src/assets/icons/trash-dark.svg';
import AddressSearch from 'src/components/address-search';
import { decodeZipInfo } from 'src/features/admitted/queries/application';

const AdditionalInsuredForm = ({
  index,
  onRemove,
  hasBlanketWaiverOfSubrogation,
  disabled = false,
}: {
  index: number;
  onRemove: (index: number) => void;
  hasBlanketWaiverOfSubrogation: boolean;
  disabled?: boolean;
}) => {
  const { mutateAsync } = useMutation(decodeZipInfo);
  const baseKey = `additionalInsureds.[${index}]`;

  const {
    control,
    formState: { errors },
    register,
    setValue,
  } = useFormContext();

  return (
    <>
      <div className="grid grid-cols-6 gap-4">
        <FormControl className="col-span-4">
          <InputWithLabel
            noMargin
            label="Name"
            placeholder=""
            disabled={disabled}
            formControlProps={{
              fullWidth: true,
            }}
            error={!!errors?.name?.message}
            {...register(`${baseKey}.additionalInsured.name`, {
              required: 'Please enter name',
            })}
          />
        </FormControl>

        <FormControl className="col-span-2 pt-5">
          {!disabled && (
            <Link
              className="flex leading-10 cursor-pointer text-text-primary "
              underline="none"
              onClick={() => onRemove(index)}
            >
              <img src={TrashIcon} className="w-3 mr-2" />
              Remove Add. Insured
            </Link>
          )}
        </FormControl>

        <Controller
          control={control}
          defaultValue=""
          name={`${baseKey}.additionalInsured.address.street`}
          rules={{
            required: 'Please enter street name',
          }}
          render={({ field: { value, onChange } }) => {
            return (
              <FormControl className="col-span-6">
                <FormHelperText className="mt-0 text-primary-main">
                  Address
                </FormHelperText>
                <AddressSearch
                  value={value}
                  onValueChange={onChange}
                  onPlaceSelect={(placeDetails) => {
                    onChange(placeDetails?.street);
                    setValue(
                      `${baseKey}.additionalInsured.address.city`,
                      placeDetails?.city,
                    );
                    setValue(
                      `${baseKey}.additionalInsured.address.state`,
                      placeDetails?.state,
                    );
                    setValue(
                      `${baseKey}.additionalInsured.address.zip`,
                      placeDetails?.zip,
                    );
                  }}
                  disabled={disabled}
                />

                {!!(errors?.operationsForm as any)?.businessOwner?.address
                  ?.street && (
                  <FormHelperText error>
                    {
                      (errors?.operationsForm as any)?.businessOwner?.address
                        ?.street?.message
                    }
                  </FormHelperText>
                )}
              </FormControl>
            );
          }}
        />

        <FormControl className="col-span-2">
          <InputWithLabel
            noMargin
            label="City"
            placeholder=""
            disabled={disabled}
            formControlProps={{
              fullWidth: true,
            }}
            error={!!errors?.city?.message}
            {...register(`${baseKey}.additionalInsured.address.city`, {
              required: 'Please enter city',
            })}
          />
        </FormControl>
        <FormControl className="col-span-2" size="small">
          <FormHelperText className="mt-0 text-primary-main">
            State
          </FormHelperText>
          <Controller
            control={control}
            defaultValue=""
            name={`${baseKey}.additionalInsured.address.state`}
            rules={{ required: 'Please select state' }}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  size="small"
                  displayEmpty
                  variant="outlined"
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  error={!!errors?.state?.message}
                  disabled={disabled}
                >
                  <MenuItem value="">
                    <Typography color="text.hint">State</Typography>
                  </MenuItem>
                  {constants.usStates.map((record) => (
                    <MenuItem value={record.name} key={record.code}>
                      {record.name}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
        </FormControl>
        <FormControl className="col-span-2">
          <FormHelperText className="mt-0 text-primary-main">
            Zip
          </FormHelperText>
          <Controller
            control={control}
            defaultValue=""
            name={`${baseKey}.additionalInsured.address.zip`}
            rules={{
              required: 'Enter zip',
              validate: {
                isExtendedZip: (value) => {
                  return (
                    value.length <= 5 || 'Please remove the zip code extension'
                  );
                },
                isValidZip: async (value) => {
                  try {
                    await mutateAsync(value);

                    return true;
                  } catch (ex) {
                    return 'Please enter a valid ZIP code';
                  }
                },
              },
            }}
            render={({ field: { value, onChange } }) => (
              <InputZip
                placeholder="eg. 12345"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                error={!!errors?.zip?.message}
                disabled={disabled}
              />
            )}
          />
        </FormControl>
      </div>
      <FormControl>
        <Controller
          control={control}
          defaultValue={true}
          name={`${baseKey}.hasWoS`}
          render={({ field: { value, onChange } }) => {
            return (
              <Checkbox
                checked={hasBlanketWaiverOfSubrogation || value}
                name="hasWoS"
                onChange={(e) => {
                  onChange(e);
                }}
                disabled={disabled}
              >
                Waiver of Subrogation
              </Checkbox>
            );
          }}
        />
      </FormControl>
    </>
  );
};

export default AdditionalInsuredForm;
