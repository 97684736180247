import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  FormControl,
  InputLabel,
  Grid,
  Box,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio,
  List,
  ListItem,
  makeStyles,
} from '@material-ui/core';
import { ITheme, Show } from '@nirvana/ui-kit';
import { Controller, useFormContext } from 'react-hook-form-v7';
import {
  FileDestinationGroup,
  FileMetadata,
  FileType,
} from '@nirvana/api/quoting';

import { useDispatch } from 'src/redux';
import {
  removeApplicationFileUpload,
  uploadApplicationFiles,
  cancelApplicationFilesUpload,
  retryApplicationFilesUpload,
  downloadApplicationFile,
  resetApplicationFileUpload,
} from 'src/features/application/actions';
import { FileUpload } from 'src/components/button';
import { useApplicationDetailsContext } from 'src/features/nonFleet/hooks/useApplicationDetails';
import {
  FileUploadProgress,
  applicationSelector,
} from 'src/features/application/slices';

const useStyles = makeStyles((theme: ITheme) => ({
  classItem: {
    ...theme.typography.caption,
    display: 'list-item',
    listStyle: 'inside',
    padding: theme.spacing(0.4, 0),
    color: theme.palette.text.hint,
  },
}));

const Losses = () => {
  const classes = useStyles();
  const application = useSelector(applicationSelector);
  const { uploadedFiles } = application;
  const [isUploading, setUploading] = useState(false);
  const dispatch = useDispatch();
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();
  const { applicationDetails } = useApplicationDetailsContext();
  const lossRunFiles = watch('operationsForm.lossInfo.lossRunFiles');
  const lossesOver20k = watch('operationsForm.lossInfo.hasLossesOver20k');
  const hasLosses = watch('operationsForm.lossInfo.hasLosses');
  const lowPowerUnits =
    applicationDetails?.nrb?.operationsForm?.companyInfo?.numberOfPowerUnits &&
    applicationDetails?.nrb?.operationsForm?.companyInfo?.numberOfPowerUnits <
      3;

  const handleLossRunFileUpload = (files: any) => {
    setUploading(true);

    files.forEach((file: any) => {
      dispatch(
        uploadApplicationFiles(file, {
          type: FileType.FileTypeLossRun,
          destinationGroup: FileDestinationGroup.FileDestinationGroupQuoting,
        }),
      );
    });
  };

  const handleLossRunFileUploadCancel = (progress: FileUploadProgress) => {
    dispatch(cancelApplicationFilesUpload(progress));
  };

  const handleLossRunFileUploadRetry = (progress: FileUploadProgress) => {
    dispatch(retryApplicationFilesUpload(progress));
  };

  const handleFileDownload = (handleId?: string) => {
    if (!handleId) {
      return;
    }

    dispatch(downloadApplicationFile(handleId));
  };

  useEffect(() => {
    if (!isUploading) {
      return;
    }

    const uploadedFilesValues = Object.values(uploadedFiles);
    const newFiles: any = [];
    uploadedFilesValues.forEach((record) => {
      if (record.status === 'succeeded') {
        newFiles.push(record);
        dispatch(removeApplicationFileUpload(record));
      }
    });
    setValue('operationsForm.lossInfo.lossRunFiles', [
      ...newFiles,
      ...lossRunFiles,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isUploading, uploadedFiles, setValue]);

  return (
    <>
      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={5}>
          <Box>
            <InputLabel htmlFor="company-name">Recent Bankruptcies</InputLabel>
            <FormHelperText style={{ width: 288 }}>
              Has the company or its principal had a bankruptcy in the last 5
              years?
            </FormHelperText>
          </Box>
        </Grid>
        <Grid item container flexDirection="column" xs={7} spacing={2}>
          <Grid item>
            <FormControl>
              <Controller
                control={control}
                rules={{
                  validate: (value) =>
                    value !== undefined || 'Please select an option!',
                }}
                name="operationsForm.financialInfo.recentBankruptcies"
                render={({ field: { value, onChange } }) => (
                  <RadioGroup
                    row
                    value={`${value}`}
                    aria-label="coverage"
                    onChange={(e) => onChange(e.target.value === 'true')}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio color="primary" size="small" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio color="primary" size="small" />}
                      label="No"
                    />
                  </RadioGroup>
                )}
              />
              <Show
                when={
                  (errors.operationsForm as any)?.financialInfo
                    ?.recentBankruptcies?.message
                }
              >
                {(message) => <FormHelperText error>{message}</FormHelperText>}
              </Show>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Show
        when={
          applicationDetails?.nrb?.operationsForm?.companyInfo
            ?.numberOfPowerUnits &&
          applicationDetails?.nrb?.operationsForm?.companyInfo
            ?.numberOfPowerUnits >= 3
        }
      >
        <Grid
          item
          container
          spacing={3}
          wrap="nowrap"
          direction="row"
          alignItems="flex-start"
        >
          <Grid item xs={5}>
            <Box>
              <InputLabel htmlFor="company-name">Losses over $20K</InputLabel>
              <FormHelperText style={{ width: 288 }}>
                Has the company incurred any single loss over $20,000 in the
                last 3 years?
              </FormHelperText>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <FormControl>
              <Controller
                control={control}
                rules={{
                  validate: (value) =>
                    value !== undefined || 'Please select an option!',
                }}
                name="operationsForm.lossInfo.hasLossesOver20k"
                render={({ field: { value, onChange } }) => (
                  <RadioGroup
                    row
                    value={`${value}`}
                    aria-label="coverage"
                    onChange={(e) => onChange(e.target.value === 'true')}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio color="primary" size="small" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio color="primary" size="small" />}
                      label="No"
                    />
                  </RadioGroup>
                )}
              />
              <Show
                when={
                  (errors.operationsForm as any)?.lossInfo?.hasLossesOver20k
                    ?.message
                }
              >
                {(message) => <FormHelperText error>{message}</FormHelperText>}
              </Show>
            </FormControl>
          </Grid>
        </Grid>
      </Show>

      <Show when={lowPowerUnits}>
        <Grid
          item
          container
          direction="row"
          wrap="nowrap"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={5}>
            <Box>
              <InputLabel htmlFor="company-name">Any losses?</InputLabel>
              <FormHelperText style={{ width: 288 }}>
                Has the company incurred any losses in the last 2 years?
              </FormHelperText>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <FormControl>
              <Controller
                control={control}
                name="operationsForm.lossInfo.hasLosses"
                rules={{
                  validate: (value) =>
                    value !== undefined || 'Please select an option!',
                }}
                render={({ field: { value, onChange } }) => (
                  <RadioGroup
                    row
                    value={`${value}`}
                    aria-label="coverage"
                    onChange={(e) => onChange(e.target.value === 'true')}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio color="primary" size="small" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio color="primary" size="small" />}
                      label="No"
                    />
                  </RadioGroup>
                )}
              />
              <Show
                when={
                  (errors.operationsForm as any)?.lossInfo?.hasLosses?.message
                }
              >
                {(message) => <FormHelperText error>{message}</FormHelperText>}
              </Show>
            </FormControl>
          </Grid>
        </Grid>
      </Show>

      <Show when={lossesOver20k || lowPowerUnits}>
        <Grid
          item
          container
          direction="row"
          wrap="nowrap"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={5}>
            <Box>
              <InputLabel htmlFor="company-name">
                Loss Runs{' '}
                {!applicationDetails?.fmcsaDataPresent && '(Optional)'}
              </InputLabel>
              <FormHelperText style={{ width: 288 }}>
                Upload loss runs for up to the past 3 years
              </FormHelperText>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Controller
              name="operationsForm.lossInfo.lossRunFiles"
              defaultValue={[]}
              control={control}
              rules={{
                validate: {
                  required: (value) => {
                    return (
                      !applicationDetails?.fmcsaDataPresent ||
                      !hasLosses ||
                      !!value.length ||
                      'Please upload at least one file'
                    );
                  },
                },
              }}
              render={({ field: { value, onChange } }) => {
                return (
                  <>
                    <FileUpload
                      key="loss-run-file-upload"
                      value={Object.values(uploadedFiles).concat(value)}
                      onChange={handleLossRunFileUpload}
                      onRemove={(file) => {
                        if (file && file.handle && uploadedFiles[file.handle]) {
                          // Remove by filename
                          dispatch(removeApplicationFileUpload(file));
                        } else if (file && file.handle) {
                          const newValue = value.filter(
                            (record: FileMetadata) =>
                              record.handle !== file.handle,
                          );
                          onChange(newValue);
                        } else if (!file) {
                          // Remove all
                          dispatch(resetApplicationFileUpload());
                          onChange([]);
                        } else {
                          // Invalid case - do nothing
                        }
                      }}
                      onCancel={handleLossRunFileUploadCancel}
                      onRetry={handleLossRunFileUploadRetry}
                      onDownload={(file) => handleFileDownload(file.handle)}
                    />
                    {!!(errors?.operationsForm as any)?.lossInfo
                      ?.lossRunFiles && (
                      <FormHelperText error>
                        {
                          (errors?.operationsForm as any)?.lossInfo
                            ?.lossRunFiles?.message
                        }
                      </FormHelperText>
                    )}
                  </>
                );
              }}
            />
          </Grid>
        </Grid>
      </Show>

      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={5}>
          <Box>
            <InputLabel htmlFor="company-name">
              Serious Driver Violations
            </InputLabel>
            <FormHelperText style={{ width: 288 }}>
              Have any of the drivers on the policy been convicted in any of the
              following in the past 5 years?
            </FormHelperText>
          </Box>
        </Grid>
        <Grid item container flexDirection="column" xs={7} spacing={2}>
          <Grid item>
            <FormControl>
              <Controller
                control={control}
                name="operationsForm.seriousDriverViolations"
                rules={{
                  validate: (value) =>
                    value !== undefined || 'Please select an option!',
                }}
                render={({ field: { value, onChange } }) => (
                  <RadioGroup
                    row
                    value={`${value}`}
                    aria-label="coverage"
                    onChange={(e) => onChange(e.target.value === 'true')}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio color="primary" size="small" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio color="primary" size="small" />}
                      label="No"
                    />
                  </RadioGroup>
                )}
              />
              <Show
                when={
                  (errors.operationsForm as any)?.seriousDriverViolations
                    ?.message
                }
              >
                {(message) => <FormHelperText error>{message}</FormHelperText>}
              </Show>
            </FormControl>
          </Grid>

          <Grid item container spacing={8}>
            <Grid item>
              <List>
                {[
                  'Hit and run',
                  'Eluding police',
                  'Felony with a motor vehicle',
                  'Negligent homicide',
                  'Manslaughter',
                  'Felony auto theft',
                  'Filing a false report of theft',
                  'DUI or DWI',
                ].map((c) => (
                  <ListItem className={classes.classItem} key={c}>
                    {c}
                  </ListItem>
                ))}
              </List>
            </Grid>

            <Grid item>
              <List>
                {[
                  'Falling asleep at the wheel',
                  "Operating a motor vehicle without the owner's consent",
                  'Driving under suspension / revocation / cancelled license',
                  'Leaving the scene of an accident',
                  'Following too close',
                ].map((c) => (
                  <ListItem className={classes.classItem} key={c}>
                    {c}
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Losses;
