import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { Controller, useFormContext } from 'react-hook-form-v7';
import {
  CoverageOption,
  CoverageOptionMetadata,
  CoverageType,
} from '@nirvana/api/non-fleet';
import { Checkbox, InputNumeric, Show } from '@nirvana/ui-kit';
import NumberFormat from 'react-number-format';

import { getCoverageOption, isMandatoryCoverage } from './utils';

const AncillaryCoverages = ({
  coverages,
  metadata,
  onSubmit,
}: {
  coverages: CoverageOption[];
  metadata?: CoverageOptionMetadata;
  onSubmit: () => void;
}) => {
  const {
    control,
    register,
    formState: { errors },
    watch,
  } = useFormContext();
  const values = watch('coveragesForm.ancillaryCovs') ?? {};
  const apdCoverage = getCoverageOption(
    coverages,
    CoverageType.CoverageAutoPhysicalDamage,
  );
  const trailerInterchangeCoverage = getCoverageOption(
    coverages,
    CoverageType.CoverageTrailerInterchange,
  );
  const uiiaCoverage = getCoverageOption(coverages, CoverageType.CoverageUiia);
  const nonOwnedTrailerCoverage = getCoverageOption(
    coverages,
    CoverageType.CoverageNonOwnedTrailer,
  );

  return (
    <>
      <Grid container spacing={4}>
        <Grid
          item
          container
          direction="row"
          wrap="nowrap"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={5}>
            <Box paddingTop="10px">
              <InputLabel htmlFor="ancillary">Ancillary Coverages</InputLabel>
              <FormHelperText style={{ width: 288 }}>
                Additional coverages requested
              </FormHelperText>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <input
              {...register(
                `coveragesForm.ancillaryCovs.${CoverageType.CoverageTrailerInterchange}.coverageType`,
              )}
              type="hidden"
              value={CoverageType.CoverageTrailerInterchange}
            />
            <Controller
              control={control}
              defaultValue={false}
              name={`coveragesForm.ancillaryCovs.${CoverageType.CoverageTrailerInterchange}.isRequired`}
              render={({ field: { onChange, value } }) => {
                return (
                  <Checkbox
                    checked={value}
                    onChange={(e) => {
                      onChange(e.target.checked);
                      onSubmit();
                    }}
                    disabled={isMandatoryCoverage(
                      CoverageType.CoverageTrailerInterchange,
                      metadata,
                    )}
                  >
                    Trailer Interchange
                  </Checkbox>
                );
              }}
            />
          </Grid>

          <Grid item xs={3} container spacing={0.5} direction="column">
            <Grid item>
              <FormHelperText>
                Limit&nbsp;
                <Tooltip title="This limit should be set in line with insured’s contractually required limit">
                  <InfoOutlined className="text-info-main" fontSize="small" />
                </Tooltip>
              </FormHelperText>
            </Grid>

            <Grid item>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name={`coveragesForm.ancillaryCovs.${CoverageType.CoverageTrailerInterchange}.limit`}
                  defaultValue={trailerInterchangeCoverage?.defaultLimit}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        variant="outlined"
                        value={value}
                        onChange={(e) => {
                          onChange(+e.target.value);

                          onSubmit();
                        }}
                        disabled={
                          !values[CoverageType.CoverageTrailerInterchange]
                            ?.isRequired
                        }
                      >
                        {trailerInterchangeCoverage?.limitOptions?.map(
                          (record) => (
                            <MenuItem value={record} key={`ti-limit-${record}`}>
                              <NumberFormat
                                value={record}
                                displayType="text"
                                thousandSeparator={true}
                                prefix="$"
                              />
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    );
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction="row"
          wrap="nowrap"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={5} />

          <Grid item xs={4}>
            <input
              {...register(
                `coveragesForm.ancillaryCovs.${CoverageType.CoverageUiia}.coverageType`,
              )}
              type="hidden"
              value={CoverageType.CoverageUiia}
            />
            <Controller
              control={control}
              defaultValue={false}
              name={`coveragesForm.ancillaryCovs.${CoverageType.CoverageUiia}.isRequired`}
              render={({ field: { onChange, value } }) => {
                return (
                  <Checkbox
                    checked={value}
                    onChange={(e) => {
                      onChange(e.target.checked);
                      onSubmit();
                    }}
                    disabled={isMandatoryCoverage(
                      CoverageType.CoverageUiia,
                      metadata,
                    )}
                  >
                    UIIA
                  </Checkbox>
                );
              }}
            />
          </Grid>

          <Grid item xs={3} container spacing={0.5} direction="column">
            <Grid item>
              <FormHelperText>
                Limit&nbsp;
                <Tooltip title="This limit should be set in line with insured’s contractually required limit">
                  <InfoOutlined className="text-info-main" fontSize="small" />
                </Tooltip>
              </FormHelperText>
            </Grid>

            <Grid item>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name={`coveragesForm.ancillaryCovs.${CoverageType.CoverageUiia}.limit`}
                  defaultValue={uiiaCoverage?.defaultLimit}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        variant="outlined"
                        value={value}
                        onChange={(e) => {
                          onChange(+e.target.value);

                          onSubmit();
                        }}
                        disabled={
                          !values[CoverageType.CoverageUiia]?.isRequired
                        }
                      >
                        {uiiaCoverage?.limitOptions?.map((record) => (
                          <MenuItem value={record} key={`uiia-limit-${record}`}>
                            <NumberFormat
                              value={record}
                              displayType="text"
                              thousandSeparator={true}
                              prefix="$"
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    );
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Show when={!apdCoverage && nonOwnedTrailerCoverage}>
          <Grid
            item
            container
            direction="row"
            wrap="nowrap"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={5} />

            <Grid item xs={4}>
              <input
                {...register(
                  `coveragesForm.ancillaryCovs.${CoverageType.CoverageNonOwnedTrailer}.coverageType`,
                )}
                type="hidden"
                value={CoverageType.CoverageNonOwnedTrailer}
              />
              <Controller
                control={control}
                defaultValue={false}
                name={`coveragesForm.ancillaryCovs.${CoverageType.CoverageNonOwnedTrailer}.isRequired`}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Checkbox
                      checked={value}
                      onChange={(e) => {
                        onChange(e.target.checked);
                        onSubmit();
                      }}
                      disabled={isMandatoryCoverage(
                        CoverageType.CoverageNonOwnedTrailer,
                        metadata,
                      )}
                    >
                      Non-owned trailer
                    </Checkbox>
                  );
                }}
              />
            </Grid>

            <Grid item xs={3} container spacing={0.5} direction="column">
              <Grid item>
                <FormHelperText>
                  Limit&nbsp;
                  <Tooltip title="This is the total insured value for each non-owned trailer">
                    <InfoOutlined className="text-info-main" fontSize="small" />
                  </Tooltip>
                </FormHelperText>
              </Grid>

              <Grid item>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    defaultValue={0}
                    name={`coveragesForm.ancillaryCovs.${CoverageType.CoverageNonOwnedTrailer}.limit`}
                    rules={{
                      validate: (v) => {
                        if (v && v > 150000) {
                          return 'Must be $150K or lower';
                        }

                        return true;
                      },
                    }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <InputNumeric
                          prefix="$"
                          placeholder="e.g. $90,000"
                          value={value}
                          onChange={(e) => {
                            onChange(+e.target.value);
                            onSubmit();
                          }}
                          disabled={
                            !values[CoverageType.CoverageNonOwnedTrailer]
                              ?.isRequired
                          }
                          error={
                            !!(errors?.coveragesForm as any)?.ancillaryCovs?.[
                              CoverageType.CoverageNonOwnedTrailer
                            ]?.limit?.message
                          }
                        />
                      );
                    }}
                  />
                  {!!(errors?.coveragesForm as any)?.ancillaryCovs?.[
                    CoverageType.CoverageNonOwnedTrailer
                  ]?.limit?.message && (
                    <FormHelperText error>
                      {
                        (errors?.coveragesForm as any)?.ancillaryCovs?.[
                          CoverageType.CoverageNonOwnedTrailer
                        ]?.limit?.message
                      }
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Show>
      </Grid>

      <Divider sx={{ mt: 4 }} />
    </>
  );
};

export default AncillaryCoverages;
