import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  makeStyles,
  styled,
  useMediaQuery,
} from '@material-ui/core';
import { TelematicsConsentInfo } from '@nirvana/api/quoting';

import ClaimsIcon from 'src/assets/icons/claims-small.svg';
import GraphicBg from 'src/assets/icons/graphic-triangle.svg';
import InsuranceIcon from 'src/assets/icons/insurance-small.svg';
import SafetyIcon from 'src/assets/icons/safety.png';
import { ITheme, useLightTheme } from 'src/assets/themes';
import { HorizontalStepper } from 'src/components/stepper';
import { hooks as Hooks } from 'src/helpers';
import { useAnalytics } from 'src/helpers/analytics';
import useNavigateParams from 'src/hooks/useNavigateParams';
import { RequestStatus } from '../slices';

const useStyles = makeStyles((theme: ITheme) => ({
  underline: {
    width: theme.typography.pxToRem(55),
    height: theme.typography.pxToRem(5),
    background: theme.palette.gold.main,
    marginTop: theme.spacing(1),
  },
}));

const CARD_WIDTH = 405;

const WhiteBox = styled(Box)(() => ({
  width: CARD_WIDTH,
  minHeight: 100,
  background: 'rgba(255, 255, 255, 0.43)',
  border: '1.5px solid #FFFFFF',
  boxShadow: ' 0px 17px 28px rgba(128, 145, 196, 0.04)',
  backdropFilter: ' blur(24px)',
  borderRadius: 8,
}));

const BackgroundGradient = styled('img')(() => ({
  position: 'fixed',
  bottom: 0,
  right: 0,
  zIndex: 1,
}));

interface ITelematicsWelcome {
  consentInfo?: TelematicsConsentInfo;
  status?: RequestStatus;
}

const TelematicsWelcome = ({
  consentInfo: applicationInfo,
  status,
}: ITelematicsWelcome) => {
  const classes = useStyles();
  const { capture } = useAnalytics();
  const navigate = useNavigateParams();
  const query = Hooks.useQuery();
  const applicationId = query.get('token');
  const theme = useLightTheme();
  const desktopMatches = useMediaQuery(theme.breakpoints.up('xl'));

  const handleConnectTelematics = () => {
    capture('telematics_welcome_connect_click', {
      applicationId,
      agencyName: applicationInfo?.agencyName,
      producerName: applicationInfo?.producerName,
      token: applicationId,
    });
    navigate('/telematics/providers');
  };

  if (['expired', 'revoked'].includes(status || '')) {
    navigate('/telematics/previously-connected');
  }

  return (
    <Box
      sx={{
        position: 'relative',
        background: '#F8FAFE',
        zIndex: 10,
        flex: 1,
      }}
      py={4}
      pt={desktopMatches ? 16 : 11}
    >
      <BackgroundGradient src={GraphicBg} style={{ width: '100%' }} />
      <Container>
        <Grid container zIndex={10} position="relative">
          <Grid item container direction="column" spacing={3} xs={7}>
            <Grid item>
              <Box width={480} marginLeft="-50px">
                <HorizontalStepper
                  steps={[
                    { key: 1, label: '' },
                    { key: 2, label: '' },
                    { key: 3, label: '' },
                    { key: 4, label: '' },
                  ]}
                  activeStep={2}
                  onChange={() => null}
                />
              </Box>
            </Grid>
            <Grid item>
              <Typography
                variant="h4"
                color="textPrimary"
                fontWeight="fontWeightBold"
                mb={desktopMatches ? 3 : 2}
                maxWidth={650}
                sx={{ fontSize: desktopMatches ? '2rem' : '1.75rem' }}
              >
                Your insurance application with {applicationInfo?.agencyName} is
                almost complete!
              </Typography>
              <Typography
                variant="h6"
                color="textPrimary"
                fontWeight="fontWeightRegular"
                maxWidth={500}
              >
                There’s one thing left to do: connect your ELD/telematics data.
                You could earn up to a{' '}
                <Typography
                  display="inline-block"
                  color="primary.main"
                  fontWeight="fontWeightBold"
                >
                  20% discount
                </Typography>{' '}
                on your premiums – AND earn access to Nirvana Safety!
              </Typography>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              spacing={1}
              mt={desktopMatches ? 2 : 0}
              mb={desktopMatches ? 4 : 2}
            >
              <Grid item>
                <Box width={10} bgcolor="#E6E7EF" height={3} />
              </Grid>
              <Grid item>
                <Typography
                  variant={desktopMatches ? 'body1' : 'caption'}
                  color="textSecondary"
                  fontWeight="fontWeightSemiBold"
                >
                  {applicationInfo?.producerName}
                </Typography>
                <Typography
                  component="p"
                  variant={desktopMatches ? 'body1' : 'caption'}
                  color="text.hint"
                  fontWeight="fontWeightRegular"
                >
                  Producer @ {applicationInfo?.agencyName}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleConnectTelematics}
                >
                  Connect Now
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            xs={5}
            px={4}
            pt={16}
            container
            direction="column"
            spacing={1}
          >
            <Grid item alignSelf="flex-end">
              <Box width={CARD_WIDTH} mb={1}>
                <Typography
                  component="p"
                  variant="body1"
                  color="textPrimary"
                  fontWeight="fontWeightSemiBold"
                >
                  Nirvana&apos;s unique program brings modern insurance and risk
                  management, backed by A-rated insurance carriers. Besides
                  competitive rates & discounts tailored for YOU, we offer
                </Typography>
                <Box className={classes.underline} />
              </Box>
            </Grid>
            <Grid item alignSelf="flex-end">
              <WhiteBox p={2}>
                <Grid container spacing={2} wrap="nowrap">
                  <Grid item>
                    <img src={SafetyIcon} alt="Safety" height={30} />
                  </Grid>
                  <Grid item>
                    <Box>
                      <Typography
                        component="p"
                        variant="body1"
                        color="textPrimary"
                      >
                        Nirvana Safety Platform included at no additional cost
                      </Typography>
                      <Typography
                        component="p"
                        variant="caption"
                        color="text.hint"
                      >
                        Insights & recommendations from FMCSA & telematics can
                        improve safety & further reduce premiums
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </WhiteBox>
            </Grid>
            <Grid item alignSelf="flex-start">
              <WhiteBox p={2}>
                <Grid container spacing={2} wrap="nowrap">
                  <Grid item>
                    <img src={InsuranceIcon} alt="Safety" />
                  </Grid>
                  <Grid item>
                    <Box>
                      <Typography
                        component="p"
                        variant="body1"
                        color="textPrimary"
                      >
                        Usage-based insurance – for YOU
                      </Typography>
                      <Typography
                        component="p"
                        variant="caption"
                        color="text.hint"
                      >
                        Using actual mileage & driving data to calculate
                        premiums, you’ll only pay for how much you drive
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </WhiteBox>
            </Grid>
            <Grid item alignSelf="flex-end">
              <WhiteBox p={2}>
                <Grid container spacing={2} wrap="nowrap">
                  <Grid item>
                    <img src={ClaimsIcon} alt="Safety" />
                  </Grid>
                  <Grid item>
                    <Box>
                      <Typography
                        component="p"
                        variant="body1"
                        color="textPrimary"
                      >
                        Modern, expedited claims
                      </Typography>
                      <Typography
                        component="p"
                        variant="caption"
                        color="text.hint"
                      >
                        Managed seamlessly. Telematics data helps prevent fraud
                        and quickly resolve claims
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </WhiteBox>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default TelematicsWelcome;
