import * as React from 'react';
import {
  Box,
  Card,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';

import { getFileTypeByFilename } from '../../utils/files';

import { ITheme } from '../../assets/themes';

import IconPDFSuccess from '../../assets/icons/pdf-success.svg';
import IconPDFError from '../../assets/icons/pdf-error.svg';
import IconCSVSuccess from '../../assets/icons/csv-success.svg';
import IconCSVError from '../../assets/icons/csv-error.svg';
import IconXLSSuccess from '../../assets/icons/xls-success.svg';
import IconXLSError from '../../assets/icons/xls-error.svg';
import IconTrash from '../../assets/icons/trash.svg';
import IconMultiplePDF from '../../assets/icons/multiple-pdf.svg';
import IconMultipleCSV from '../../assets/icons/multiple-csv.svg';
import IconMultipleXLS from '../../assets/icons/multiple-xls.svg';

const useStyles = makeStyles((theme: ITheme) => ({
  card: (props: { showWarning: boolean }) => ({
    alignItems: 'center',
    border: `1px solid ${
      props.showWarning ? theme.palette.warning.dark : '#E6E7Ef'
    }`,
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0px 2px 4px rgba(128, 145, 196, 0.12)',
    cursor: 'pointer',
    display: 'flex',
    padding: theme.spacing(2, 1),
    position: 'relative',
    width: theme.typography.pxToRem(380),
    zIndex: 1,
  }),
  filename: {
    margin: theme.spacing(0, 2),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: 1,
  },
  countContainer: {
    backgroundColor: theme.palette.primary.extraLight,
    height: '100%',
    padding: theme.spacing(1.25),
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
  },
}));

const getIconByTypeStatus = (type: string, status: 'success' | 'error') => {
  switch (type) {
    case 'pdf':
      return status === 'success' ? IconPDFSuccess : IconPDFError;

    case 'xls':
    case 'xlsx':
      return status === 'success' ? IconXLSSuccess : IconXLSError;

    case 'csv':
    default:
      return status === 'success' ? IconCSVSuccess : IconCSVError;
  }
};

const getMultipleIcon = (files: any) => {
  let hasPDF = false;
  let hasCSV = false;
  let hasXLS = false;

  files.forEach((file: any) => {
    const fileType = getFileTypeByFilename(file.filename);

    if (fileType === 'pdf') {
      hasPDF = true;
    } else if (fileType === 'csv') {
      hasCSV = true;
    } else if (fileType === 'xls' || fileType === 'xlsx') {
      hasXLS = true;
    }
  });

  if (hasPDF) {
    return IconMultiplePDF;
  } else if (hasCSV) {
    return IconMultipleCSV;
  } else if (hasXLS) {
    return IconMultipleXLS;
  }

  return IconMultiplePDF;
};

export interface IAttachment {
  filename: string | undefined;
  size?: number;
}
export interface IAttachmentViewProps {
  files: Array<IAttachment>;
  onClick?: () => void;
  onRemove?: (index: number, data: IAttachment) => void;
  onDownload?: (data: IAttachment, index: number) => void;
  showWarning?: boolean;
}

/**
 * Display one or more file attachments.
 * @component
 *
 * @param {Object} props
 * @param {Object[]} props.files - List of files to be displayed.
 * @param {string} files[].filename - Name of file.
 * @param {number} [files[].size] - Optional. Size of file in kb.
 * @param {Function} props.onRemove - Callback triggered on clicking the delete button.
 * @param {boolean} [props.showWarning] - Optional. Show warning message.
 *
 * @example
 * const files = [{
 *  filename: 'some-filename.csv',
 *  size: 20,
 * }]
 * return <AttachmentView files={files} onRemove={() => null} />
 */
const AttachmentView = ({
  files,
  onClick = () => null,
  onRemove,
  onDownload = () => null,
  showWarning = false,
}: IAttachmentViewProps) => {
  const classes = useStyles({
    showWarning,
  });
  const filesCount = files.length;

  if (!filesCount) {
    return null;
  }

  const displayFile: IAttachment = files[0];

  return (
    <>
      {filesCount > 1 ? (
        <Card elevation={0} className={classes.card} onClick={onClick}>
          <img src={getMultipleIcon(files)} alt="Files" />
          <Typography variant="body1" className={classes.filename}>
            {`${filesCount} uploaded files`}
          </Typography>
        </Card>
      ) : (
        <Box
          component="a"
          download
          onClick={() => onDownload(displayFile, 0)}
          sx={{
            textDecoration: 'none',
            cursor: 'pointer',
          }}
        >
          <Card elevation={0} className={classes.card}>
            <img
              src={getIconByTypeStatus(
                getFileTypeByFilename(displayFile.filename),
                'success',
              )}
              alt="File"
              width="24"
            />
            <Typography variant="body1" className={classes.filename}>
              {displayFile.filename}
            </Typography>
            {!!onRemove && (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();

                  onRemove(0, displayFile);
                }}
              >
                <img src={IconTrash} alt="Delete" />
              </IconButton>
            )}
          </Card>
        </Box>
      )}
    </>
  );
};

export default AttachmentView;
