import {
  Alert,
  AppBar,
  Box,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import {
  AdditionalInformationForm,
  ApplicationDetail,
  ClassesAndCommoditiesForm,
  CoverageType,
  IndicationForm,
  IndicationOption,
  LossRunSummaryPerCoverage,
  OperatingClassDistributionRecord,
  PotentialClearanceStatus,
  WeightedCommodityRecord,
} from '@nirvana/api/quoting';
import { ApplicationHeader, Show, debounce } from '@nirvana/ui-kit';
import _ from 'lodash';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  useBeforeUnload,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import IconDiscount from 'src/assets/icons/discount-success.svg';
import { ITheme } from 'src/assets/themes';
import Button from 'src/components/button';
import ServerError from 'src/components/serverError';
import { VerticalStepper } from 'src/components/stepper';
import { Feature, FleetType, useFeatureFlag } from 'src/helpers/featureFlags';
import { useDispatch } from 'src/redux';

import {
  CLASSES_AND_COMMODITIES_PAGE_PROCEED,
  INDICATION_PAGE_PROCEED,
  LOSS_SUMMARY_PAGE_PROCEED,
  OPERATIONS_PAGE_PROCEED,
} from 'src/features/telematics/events';
import { useAnalytics } from 'src/helpers/analytics';

import ApplicationHeaderClearedApplicationExists from 'src/components/header/applicationHeaderClearedApplicationExists';
import ApplicationHeaderClearedApplicationExistsDeclined from 'src/components/header/applicationHeaderClearedApplicationExistsDeclined';
import {
  fetchApplicationById,
  resetIndicationOptions,
  resetStatus,
  selectIndicationOption,
  submitIndication,
  updateAdditionalInformation,
  updateApplication,
} from '../../actions';
import {
  applicationSelector,
  resetActiveApplication,
  submitApplication,
} from '../../slices';
import { operationSelector } from '../../slices/classesAndCommodities';
import { indicationOptionsSelector } from '../../slices/indicationOptions';
import Complete from './complete';
import { getSteps } from './constants/steps';
import ConnectTelematics from './globalTelematics/connectTelematics';

const useStyles = makeStyles((theme: ITheme) => ({
  footerToolbar: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px -2px 4px rgba(128, 145, 196, 0.13)',
  },
  appBar: {
    boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.06)',
    padding: theme.spacing(1, 0),
    position: 'relative',
    top: 1,
    zIndex: 4,
  },
  toolbar: {
    minHeight: theme.spacing(3),
  },
  divider: {
    borderColor: theme.palette.common.black,
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stepperWrapper: {
    backgroundColor: theme.palette.primary.extraLight,
    borderRadius: 10,
    padding: theme.spacing(4, 2),
    paddingLeft: theme.typography.pxToRem(21),
  },
  infoIcon: {
    color: theme.palette.info.main,
  },
  colorLight: {
    color: theme.palette.grey[400],
  },
}));

type RouteParams = {
  applicationId?: string;
};

type RouteLocation = {
  state: any;
};

/**
 * Application create page that orchestrates the sub-pages required by the creation flow.
 * Corresponds to Operations Landing (https://www.figma.com/proto/OwouvIq33I1CCIjUXIlrcn/NIrvana_Dev-Handoff?node-id=98%3A11276&scaling=min-zoom&page-id=98%3A10867)
 * @component
 */
const ApplicationCreate = () => {
  const { capture } = useAnalytics();
  const classes = useStyles();
  const navigate = useNavigate();
  const params: RouteParams = useParams();
  const dispatch = useDispatch();
  const { update: intercomUpdate } = useIntercom();
  const location: RouteLocation = useLocation();
  const application = useSelector(applicationSelector);
  const indicationOptions = useSelector(indicationOptionsSelector);
  const { list: indicationOptionsList, isLoadingIndication } =
    indicationOptions;
  const [isFetching, setFetching] = React.useState<boolean>(true);
  const [isSaving, setSaving] = React.useState<boolean>(false);
  const [isSubmittingIndication, setSubmittingIndication] =
    React.useState<boolean>(false);
  const [hasError, setError] = React.useState<boolean>(false);
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [isComplete, setComplete] = React.useState<boolean>(false); // application complete
  const [isApplicationPanic, setApplicationPanic] =
    React.useState<boolean>(false);
  const childFormRef = React.useRef<any>();
  const getFeatureValue = useFeatureFlag();
  const fleetType: FleetType = getFeatureValue(Feature.FLEET_TYPE, '');
  const isPricelessIndication = getFeatureValue(
    Feature.PRICELESS_INDICATION,
    false,
  );
  const anytimeTelematics = getFeatureValue(Feature.ANYTIME_TELEMATICS, false);
  const isApplicationPersistenceEnabled = getFeatureValue(
    Feature.APPLICATION_PERSISTANCE,
    false,
  );

  const methods = useForm({
    defaultValues: {},
  });
  const { formState, handleSubmit, reset, getValues } = methods;
  const { activeApplication: details, status } = application;
  const { applicationId = '' } = params;
  const { state } = location;
  const activeStepKey: string = state?.activeStep;

  const { isFetchingCategories } = useSelector(operationSelector);

  const handleApplicationPanic = React.useCallback(() => {
    setApplicationPanic(true);
  }, []);

  // Review screen
  const handleSectionEdit = (key: string) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const matchedStepIndex = steps.findIndex(
      (record: any) => record.key === key,
    );

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    handleGotoStep(matchedStepIndex);
  };

  const steps = React.useMemo(
    () => {
      return getSteps({
        application: details,
        childFormRef: childFormRef,
        coveragesRequired:
          details?.indicationForm?.operationsForm?.coveragesRequired,
        fleetType,
        handleSectionEdit: handleSectionEdit,
        onPanic: handleApplicationPanic,
        isPricelessIndication,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [applicationId, details, fleetType],
  );
  const currentStep = steps[activeStep];

  const onSubmit = (data: any) => {
    dispatch(resetStatus());
    setSaving(true);

    let sanitizedData = data;
    if (currentStep.key === 'operationsForm') {
      capture(OPERATIONS_PAGE_PROCEED, {
        applicationId: applicationId,
      });
      sanitizedData = {
        ...data,
        numberOfPowerUnits: +data.numberOfPowerUnits,
        projectedMileage: +data.projectedMileage,
        radiusOfOperation: data.radiusOfOperation,
      };
    } else if (currentStep.key === 'classesAndCommoditiesForm') {
      capture(CLASSES_AND_COMMODITIES_PAGE_PROCEED, {
        applicationId: applicationId,
      });
      sanitizedData = {
        ...data,
      };
      if (sanitizedData.commodityDistribution) {
        sanitizedData.commodityDistribution = {
          ...sanitizedData.commodityDistribution,
          commodities: sanitizedData.commodityDistribution.commodities.filter(
            (record: WeightedCommodityRecord) =>
              !!record && !!Object.keys(record).length,
          ),
        };
      }

      if (
        !sanitizedData.primaryOperatingClass &&
        sanitizedData.operatingClassDistribution
      ) {
        const sortedClasses = [
          ...(sanitizedData?.operatingClassDistribution || []),
        ];

        sortedClasses.sort((a: any, b: any) => {
          if (a.percentageOfFleet === b.percentageOfFleet) {
            return a.operatingClass < b.operatingClass ? -1 : 1;
          } else {
            return a.percentageOfFleet < b.percentageOfFleet ? -1 : 1;
          }
        });

        if (sortedClasses[0]) {
          sanitizedData.primaryOperatingClass =
            sortedClasses[0]?.operatingClass;
        }
      }
    } else if (currentStep.key === 'lossRunSummaryForm') {
      capture(LOSS_SUMMARY_PAGE_PROCEED, {
        applicationId,
      });
      sanitizedData = data.lossRunSummaryForm.map((record: any) => {
        return {
          ...record,
          lossRunSummary: record.lossRunSummary.map((summary: any) => {
            return {
              ...summary,
              lossIncurred: summary.lossIncurred || 0,
              numberOfClaims: summary.numberOfClaims || 0,
              numberOfPowerUnits: summary.numberOfPowerUnits || 0,
            };
          }),
        };
      });

      // Reset indication options
      dispatch(resetIndicationOptions());
    } else if (currentStep.key === 'selectedIndication') {
      sanitizedData =
        indicationOptionsList.find(
          (record) => record.id === sanitizedData.id,
        ) || sanitizedData;
    }

    if (currentStep.key === 'selectedIndication') {
      // CAPTURE INDICATION FINAL EVENT (INDICATION_VIEW_PROCEED)
      capture(INDICATION_PAGE_PROCEED, {
        applicationId: applicationId,
        indicationOptionId: sanitizedData.id,
      });

      dispatch(selectIndicationOption(applicationId, sanitizedData));
    } else if (currentStep.key === 'additionalInfoForm') {
      dispatch(updateAdditionalInformation(applicationId, sanitizedData)).then(
        () => {
          localStorage.removeItem(`unsavedApplication|${applicationId}`);
        },
      );

      if (fleetType !== 'fleet') {
        dispatch(submitIndication(applicationId));
      }
    } else if (currentStep.key === 'applicationSummary') {
      dispatch(submitApplication({ applicationId }))
        .then(() => {
          localStorage.removeItem(`unsavedApplication|${applicationId}`);
          // Show completion screen on success
          setComplete(true);
        })
        .catch(() => {
          // Show appliction panic error on failure
          handleApplicationPanic();
        });
    } else {
      const payload = {
        ...details?.indicationForm,
        [currentStep.key]: sanitizedData,
      };

      if (currentStep.key === 'operationsForm') {
        // Reorder coverages on saving operations form
        if (payload.lossRunSummaryForm && payload.lossRunSummaryForm.length) {
          const newLossHistory: LossRunSummaryPerCoverage[] = [];
          const lossSummaryByCoverage: {
            [key: string]: LossRunSummaryPerCoverage;
          } = {};
          payload.lossRunSummaryForm?.forEach((record) => {
            lossSummaryByCoverage[record.coverageType] = record;
          });

          const filteredCoverages =
            payload.operationsForm?.coveragesRequired?.filter(
              (record) =>
                record.coverageType !== CoverageType.CoverageGeneralLiability,
            ) || [];

          filteredCoverages?.forEach((record) => {
            if (lossSummaryByCoverage[record.coverageType]) {
              newLossHistory.push(lossSummaryByCoverage[record.coverageType]);
            }
          });

          payload.lossRunSummaryForm = newLossHistory;
        }

        const hasMTC = payload.operationsForm?.coveragesRequired?.some(
          (record) =>
            record.coverageType === CoverageType.CoverageMotorTruckCargo,
        );

        if (
          hasMTC &&
          payload.classesAndCommoditiesForm &&
          (!payload.classesAndCommoditiesForm.commodityDistribution ||
            !payload.classesAndCommoditiesForm?.commodityDistribution
              ?.commodities)
        ) {
          payload.classesAndCommoditiesForm = {
            ...payload.classesAndCommoditiesForm,
            commodityDistribution: {
              commodities: [],
              additionalCommodities: { commodities: '', percentageOfHauls: 0 },
            },
          };
        }
      }

      // All other cases
      dispatch(
        updateApplication({
          applicationID: applicationId,
          payload,
        }),
      ).then(() => {
        setSaving(false);

        localStorage.removeItem(`unsavedApplication|${applicationId}`);

        if (currentStep.key === 'classesAndCommoditiesForm') {
          dispatch(fetchApplicationById(applicationId));
        }
      });
    }
  };

  const preSubmit = () => {
    if (childFormRef.current) {
      childFormRef.current.submitForm(
        () => {
          handleSubmit(onSubmit)();
        },
        () => {},
      );
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const handleGotoStep = React.useCallback(
    (step: number) => {
      if (step >= steps.length) {
        step = steps.length - 1;
      }

      setActiveStep(step);

      let formData:
        | IndicationForm
        | AdditionalInformationForm
        | ClassesAndCommoditiesForm
        | ApplicationDetail
        | IndicationOption
        | undefined
        | any;

      const matchedStep = steps[step];
      if (
        matchedStep &&
        matchedStep.key === 'additionalInfoForm' &&
        details &&
        details[matchedStep.key]
      ) {
        formData = details[matchedStep.key];
      } else if (
        details?.indicationForm &&
        details.indicationForm[matchedStep.key as keyof IndicationForm]
      ) {
        if (matchedStep.key === 'lossRunSummaryForm') {
          formData = {
            lossRunSummaryForm: details.indicationForm[matchedStep.key],
          };
        } else if (matchedStep.key === 'classesAndCommoditiesForm') {
          formData = {
            ...details.indicationForm.classesAndCommoditiesForm,
          };
          if (formData?.operatingClassDistribution) {
            formData.operatingClassDistribution =
              formData.operatingClassDistribution.map(
                (record: OperatingClassDistributionRecord) => ({
                  ...record,
                  selected: !!record.percentageOfFleet,
                }),
              );
          }
        } else {
          formData =
            details.indicationForm[matchedStep.key as keyof IndicationForm];
        }
      } else if (
        matchedStep.key === 'selectedIndication' &&
        details?.selectedIndication
      ) {
        formData = details[matchedStep.key];
      }

      let savedApplication;
      try {
        if (isApplicationPersistenceEnabled) {
          savedApplication = JSON.parse(
            localStorage.getItem(`unsavedApplication|${applicationId}`) || '{}',
          );
        }
      } catch (e) {
        savedApplication = { data: {} };
      }

      if (savedApplication?.currentStep === matchedStep.key) {
        formData = {
          ...formData,
          ...savedApplication?.data,
        };
      }

      reset(formData);

      window.scrollTo(0, 0);
    },
    [details, reset, steps],
  );

  const getLastApplicationStep = React.useCallback(() => {
    if (!details) {
      return { step: steps[0], index: -1 };
    }

    for (let i = steps.length - 1; i >= 0; i -= 1) {
      if (
        details[steps[i].key as keyof ApplicationDetail] ||
        (steps[i].key !== 'operationsForm' &&
          details.indicationForm?.[steps[i].key as keyof IndicationForm]) ||
        (steps[i].key === 'operationsForm' &&
          details.indicationForm?.[steps[i].key as keyof IndicationForm] &&
          (
            details.indicationForm?.[
              steps[i].key as keyof IndicationForm
            ] as any
          )?.equipmentList?.info?.length)
      ) {
        if (i === steps.length - 2) {
          // Check if selected indication is not empty
          //  TODO: SID/JIGAR refactor this to update backend state and not override
          if (!details.selectedIndication && i + 1 > 2) {
            return {
              step: steps[2],
              index: 2,
            };
          }
          return {
            step: steps[i + 1],
            index: i + 1,
          };
        }
        return {
          step: steps[i],
          index: i,
        };
      }
    }

    return { step: steps[0], index: -1 };
  }, [details, steps]);

  const handleStepChange = (stepIndex: number) => {
    const lastStep = getLastApplicationStep();
    if (
      stepIndex > lastStep.index ||
      (stepIndex > activeStep && formState.isDirty)
    ) {
      return;
    }

    // forward steps not allowed
    if (stepIndex > activeStep) {
      return;
    }

    handleGotoStep(stepIndex);
  };

  const handlePreviousStep = () => {
    handleGotoStep(activeStep - 1);
  };

  const handleNextStep = React.useCallback(() => {
    handleGotoStep(activeStep + 1);
  }, [activeStep, handleGotoStep]);

  React.useEffect(() => {
    if (details?.summary.state === 'ApplicationStatePanic') {
      handleApplicationPanic();
    }
  }, [details?.summary.state, handleApplicationPanic]);

  React.useEffect(() => {
    intercomUpdate({
      verticalPadding: 85,
    });

    return () => {
      dispatch(resetActiveApplication());

      intercomUpdate({
        verticalPadding: 0,
      });
    };
  }, [dispatch, intercomUpdate]);

  React.useEffect(() => {
    if (fleetType) {
      // Fetch application information
      dispatch(fetchApplicationById(applicationId));
    }
  }, [dispatch, applicationId, fleetType]);

  React.useEffect(() => {
    if (!isFetching || status === 'idle' || status === 'loading') {
      return;
    }

    if (status === 'succeeded' && details) {
      // Set active step based on application state
      const lastStep = getLastApplicationStep();
      handleGotoStep(lastStep.index + 1);

      dispatch(resetStatus());
      setFetching(false);
    } else if (status === 'failed') {
      setError(true);
      dispatch(resetStatus());
      setFetching(false);
    }
  }, [
    dispatch,
    getLastApplicationStep,
    handleGotoStep,
    isFetching,
    status,
    details,
  ]);

  React.useEffect(() => {
    if (!isSubmittingIndication || status === 'idle' || status === 'loading') {
      return;
    }

    if (status === 'succeeded') {
      handleNextStep();
      setSubmittingIndication(false);
      dispatch(resetStatus());
    } else if (status === 'failed') {
      setSubmittingIndication(false);
      setSaving(false);
      setError(true);
      dispatch(resetStatus());
    }
  }, [isSubmittingIndication, status, dispatch, handleNextStep]);

  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState.isSubmitSuccessful, reset]);

  React.useEffect(() => {
    if (!isSaving) {
      return;
    }

    if (status === 'succeeded') {
      dispatch(resetStatus());
      setSaving(false);
      setError(false);
      if (currentStep.key === 'lossRunSummaryForm' && fleetType === 'fleet') {
        setSubmittingIndication(true);
        dispatch(submitIndication(applicationId));
      } else {
        // Move to next step
        handleNextStep();
      }
    } else if (status === 'failed') {
      setError(true);
      dispatch(resetStatus());
      setSaving(false);
    }
  }, [
    applicationId,
    currentStep.key,
    dispatch,
    handleNextStep,
    fleetType,
    isSaving,
    status,
  ]);

  React.useEffect(() => {
    if (activeStepKey) {
      handleSectionEdit(activeStepKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, activeStepKey]);

  const beforeUnloadEventHandler = React.useCallback(() => {
    if (isApplicationPersistenceEnabled && !_.isEmpty(getValues())) {
      localStorage.setItem(
        `unsavedApplication|${applicationId}`,
        JSON.stringify({
          currentStep: currentStep.key,
          timeStamp: Date.now(),
          data: getValues(),
        }),
      );
    }
  }, [applicationId, currentStep.key, isApplicationPersistenceEnabled]);

  useBeforeUnload(() => {
    beforeUnloadEventHandler();
  });

  React.useEffect(() => {
    return () => {
      beforeUnloadEventHandler();
    };
  }, []);

  if (isFetching) {
    return (
      <Box flex={1} className={classes.center}>
        <CircularProgress />
      </Box>
    );
  }

  if (isComplete) {
    return (
      <Box flex={1} className={classes.center}>
        <Complete />
      </Box>
    );
  }

  if (isApplicationPanic) {
    return (
      <Grid item container direction="column" flexGrow={1}>
        <Grid item>
          <ApplicationHeader
            details={details || ({} as ApplicationDetail)}
            onBack={() => {
              navigate('/applications/list', { replace: true });
            }}
          />
        </Grid>
        <ServerError />
      </Grid>
    );
  }

  return (
    <>
      <ApplicationHeader
        details={details || ({} as ApplicationDetail)}
        onBack={() => {
          navigate('/applications/list', { replace: true });
        }}
        showBDInHeader={true}
      />

      <Show
        when={
          details?.summary?.clearanceStatus ===
          PotentialClearanceStatus.ClearedApplicationExists
        }
      >
        <ApplicationHeaderClearedApplicationExists isSameAgency={false} />
      </Show>

      <Show
        when={
          details?.summary?.clearanceStatus ===
          PotentialClearanceStatus.ClearedApplicationExistsSameAgency
        }
      >
        <ApplicationHeaderClearedApplicationExists isSameAgency />
      </Show>

      <Show
        when={
          details?.summary?.clearanceStatus ===
          PotentialClearanceStatus.DeclinedClearedApplicationExists
        }
      >
        <ApplicationHeaderClearedApplicationExistsDeclined />
      </Show>

      <Paper elevation={0} square sx={{ position: 'relative', flex: 1 }}>
        <Container>
          <Box py={5} px={3}>
            {hasError && (
              <Box paddingBottom={3}>
                <Alert severity="error">
                  Unable to save application information
                </Alert>
              </Box>
            )}
            <Grid container spacing={4}>
              <Grid item xs={3}>
                <Box className={classes.stepperWrapper}>
                  <VerticalStepper
                    steps={steps.map((record) => record.label)}
                    activeStep={activeStep}
                    onChange={handleStepChange}
                  />
                </Box>

                <Show when={anytimeTelematics}>
                  <ConnectTelematics
                    applicationId={applicationId}
                    details={details}
                    step={currentStep.key}
                  />
                </Show>
              </Grid>
              <Grid item xs={9}>
                <FormProvider {...methods}>
                  <form
                    onSubmit={preSubmit}
                    style={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {steps[activeStep].component}
                  </form>
                </FormProvider>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Paper>

      {/* Spacer to compensate for height of fixed bottom bar */}
      <Box py="32px" />

      <AppBar
        classes={{ root: classes.footerToolbar }}
        color="inherit"
        elevation={0}
        position="fixed"
        sx={{ top: 'auto', bottom: 0 }}
      >
        <Container>
          <Toolbar>
            <Grid container>
              <Grid
                item
                style={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {currentStep.key === 'selectedIndication' &&
                fleetType === 'fleet' ? (
                  <>
                    <img src={IconDiscount} />
                    &nbsp;
                    <Typography
                      variant="caption"
                      fontWeight="fontWeightRegular"
                    >
                      This application may be eligible for a{' '}
                      <strong>discount up to 20%</strong> by sharing
                      ELD/Telematics data in the next step
                    </Typography>
                  </>
                ) : null}
              </Grid>
              <Grid item>
                <Grid container alignItems="center" justifyContent="flex-end">
                  <Grid item container>
                    <Box display="flex" alignItems="center">
                      {activeStep >= 1 ? (
                        <Box mx={1}>
                          <Button
                            startIcon={<ArrowBackIosRoundedIcon />}
                            variant="outlined"
                            onClick={handlePreviousStep}
                            disabled={isLoadingIndication}
                          >
                            Previous
                          </Button>
                        </Box>
                      ) : null}

                      <Button
                        endIcon={<ArrowForwardIosRoundedIcon />}
                        variant="contained"
                        disabled={
                          isSaving ||
                          isSubmittingIndication ||
                          (activeStepKey === 'classesAndCommoditiesForm' &&
                            isFetchingCategories) ||
                          (currentStep.key === 'selectedIndication' &&
                            isLoadingIndication)
                        }
                        onClick={debounce(preSubmit, 800)}
                      >
                        {currentStep.key !== 'applicationSummary'
                          ? 'Proceed'
                          : 'Submit'}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default ApplicationCreate;
