import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { ApplicationHeader, HorizontalStepper, ITheme } from '@nirvana/ui-kit';
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import IconApplicationStatus from 'src/assets/icons/application-status.svg';
import IconStepper from 'src/assets/icons/stepper.svg';

import { useSelector } from 'react-redux';
import {
  fetchApplicationById,
  fetchAvailableProducers,
} from 'src/features/application/actions';

import { format, parseISO } from 'date-fns';
import { useDispatch } from 'src/redux';
import { applicationSelector, fetchApplicationStatus } from '../../../slices';
import { isStateNotProgress } from '../utils';
import Review from './review';

const useStyles = makeStyles((theme: ITheme) => ({
  card: {
    border: '1px solid #E6E7EF',
    boxShadow: '0px 4px 6px rgba(128, 145, 196, 0.16)',
  },
  cardHeader: {
    borderBottom: '1px solid #E6E7EF',
    backgroundColor: '#FBFCFE',
    padding: theme.spacing(2, 4),
  },
  cardHeaderContent: {
    display: 'flex',
    alignItems: 'center',
  },
}));

type RouteParams = {
  applicationId?: string;
};

const RenewalSubmittedView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [activeStep] = useState(1);
  const params: RouteParams = useParams();
  const applicationData = useSelector(applicationSelector);
  const dispatch = useDispatch();

  const {
    activeApplication: application,
    applicationStatus,
    availableProducers,
  } = applicationData;
  const { applicationId = '' } = params;

  React.useEffect(() => {
    // Fetch application information
    dispatch(fetchApplicationById(applicationId));
    dispatch(
      fetchApplicationStatus({
        applicationId: applicationId,
      }),
    );
    dispatch(fetchAvailableProducers());
  }, [applicationId, dispatch]);

  const steps = useMemo(
    () => [
      {
        key: 'statusSubmitted',
        label: 'Application submitted',
        extra: () => {
          if (applicationStatus?.statusSubmitted?.finalizedDate) {
            return format(
              parseISO(applicationStatus?.statusSubmitted?.finalizedDate),
              'MM/dd/yyyy',
            );
          } else {
            return null;
          }
        },
      },
      { key: 'statusUnderwriterReview', label: 'Underwriter review' },
      { key: 'statusQuoteFinalized', label: 'Quote finalized' },
    ],
    [applicationStatus?.statusSubmitted?.finalizedDate],
  );

  if (!application) {
    return null;
  }

  if (application?.summary && isStateNotProgress(application?.summary)) {
    return (
      <>
        <ApplicationHeader
          details={application}
          onBack={() => navigate('/applications/list', { replace: true })}
        />
        <Container maxWidth="md">
          <Box py={3}>
            <Grid container direction="column" spacing={5}>
              <Grid item>
                <Card>
                  <CardHeader
                    disableTypography
                    title={
                      <>
                        <img
                          src={IconApplicationStatus}
                          alt="Application Status Icon"
                        />
                        <Box px={3} flexGrow={1}>
                          <Typography variant="h5" color="textSecondary">
                            Renewal Application Status
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <img src={IconStepper} alt="Active Step Icon" />
                          <Typography variant="body1" color="text.hint" ml={1}>
                            Underwriter Review
                          </Typography>
                        </Box>
                      </>
                    }
                    classes={{
                      root: classes.cardHeader,
                      content: classes.cardHeaderContent,
                    }}
                  />
                  <CardContent>
                    <Box p={2} pt={10}>
                      <HorizontalStepper
                        steps={steps}
                        onChange={() => null}
                        activeStep={activeStep}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              {application && (
                <Review
                  application={application}
                  availableProducers={availableProducers}
                />
              )}
            </Grid>
          </Box>
        </Container>
      </>
    );
  } else {
    navigate(`/applications/${applicationId}/renew`, { replace: true });
    return null;
  }
};

export default RenewalSubmittedView;
