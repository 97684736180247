import ApplicationContainer from '../applicationContainer';
import Panic from './panic';

const PanicWrapper = () => {
  return (
    <ApplicationContainer>
      <Panic />
    </ApplicationContainer>
  );
};

export default PanicWrapper;
