import * as React from 'react';
import clsx from 'clsx';

import {
  Card,
  Grid,
  IconButton,
  Typography,
  makeStyles,
  Box,
} from '@material-ui/core';

import { getFileTypeByFilename } from '../../utils/files';

import { ITheme } from '../../assets/themes';
import IconTrash from '../../assets/icons/trash.svg';
import IconPDFSuccess from '../../assets/icons/pdf-success.svg';
import IconPDFError from '../../assets/icons/pdf-error.svg';
import IconCSVSuccess from '../../assets/icons/csv-success.svg';
import IconCSVError from '../../assets/icons/csv-error.svg';
import IconXLSSuccess from '../../assets/icons/xls-success.svg';
import IconXLSError from '../../assets/icons/xls-error.svg';
import IconRetry from '../../assets/icons/upload-retry.svg';
import IconSuccess from '../../assets/icons/upload-success.svg';
import Progress from './progress';

const useStyles = makeStyles((theme: ITheme) => ({
  card: {
    alignItems: 'center',
    border: '1px solid #E6E7EF',
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0px 2px 4px rgba(128, 145, 196, 0.12)',
    padding: theme.spacing(2, 1),
    display: 'flex',
  },
  cardItem: {
    marginBottom: theme.spacing(1),
  },
  filename: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 230,
  },
  cardListForm: {
    alignItems: 'center',
    border: 0,
    borderBottom: '1px solid #E6E7EF',
    padding: theme.spacing(2, 1),
    boxShadow: 'none',
    borderRadius: 0,
    display: 'flex',
    backgroundColor: 'transparent',

    '&:last-child': {
      border: 0,
    },
  },
}));

const getIconByTypeStatus = (
  type: string,
  status?: 'loading' | 'succeeded' | 'failed' | 'cancelled',
) => {
  switch (type) {
    case 'pdf':
      return status === 'loading' ||
        status === 'failed' ||
        status === 'cancelled'
        ? IconPDFError
        : IconPDFSuccess;

    case 'xls':
    case 'xlsx':
      return status === 'loading' ||
        status === 'failed' ||
        status === 'cancelled'
        ? IconXLSError
        : IconXLSSuccess;

    case 'csv':
    default:
      return status === 'loading' ||
        status === 'failed' ||
        status === 'cancelled'
        ? IconCSVError
        : IconCSVSuccess;
  }
};

export interface IAttachment {
  filename: string | undefined;
  size?: number;
  progress?: number;
  status?: 'succeeded' | 'failed' | 'cancelled';
}
export interface IAttachmentListProps<T> {
  files: Array<T>;
  onRemove?: (data: T, index: number) => void;
  onCancel?: (data: T, index: number) => void;
  onRetry?: (data: T, index: number) => void;
  onDownload?: (data: T, index: number) => void;
  mode?: 'list' | 'card';
}

/**
 * Display one or more file attachments.
 * @component
 *
 * @param {Object} props
 * @param {Object[]} props.files - List of files to be displayed.
 * @param {string} files[].filename - Name of file.
 * @param {number} [files[].size] - Optional. Size of file in kb.
 * @param {number} [files[].progress] - Optional. Upload progress percentage.
 * @param {number} [files[].status] - Optional. State of file upload.
 * @param {Function} props.onRemove - Callback triggered on clicking the delete button.
 * @param {Function} props.onCancel - Callback triggered on clicking the upload cancel button.
 * @param {Function} props.onRetry - Callback triggered on clicking the retry button.
 * @param {Function} props.onDownload - Callback triggered on clicking the download button.
 * @param {string} [props.mode] - Layout of the list. [list | card]
 *
 * @example
 * const files = [{
 *  filename: 'some-filename.csv',
 *  size: 20,
 * }]
 * return <AttachmentList files={files} onRemove={() => null} />
 */
const AttachmentList = ({
  files,
  onRemove,
  onCancel,
  onRetry,
  onDownload,
  mode = 'card',
}: IAttachmentListProps<any>) => {
  const classes = useStyles();
  const filesCount = files.length;

  if (!filesCount) {
    return null;
  }

  return (
    <div
      className={clsx('flex-col', {
        'gap-8': mode !== 'list',
      })}
    >
      {files.map((file, index) => (
        <Box
          key={`attachment-${file.filename}-${index}`}
          component={Card}
          elevation={0}
          className={clsx(classes.card, {
            [classes.cardListForm]: mode === 'list',
            [classes.cardItem]: mode === 'card' && index < files.length - 1,
          })}
        >
          <Grid container alignItems="center" flexWrap="nowrap" spacing={2}>
            <Grid
              item
              container
              alignItems={mode === 'list' ? 'center' : 'flex-start'}
              spacing={mode === 'list' ? 2 : 1}
            >
              <Grid
                item
                sx={{
                  display: 'flex',
                }}
              >
                <Box
                  component="a"
                  download
                  onClick={
                    onDownload ? () => onDownload(file, index) : () => null
                  }
                  sx={{
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                >
                  <Grid
                    container
                    alignItems={mode === 'list' ? 'center' : 'flex-start'}
                    spacing={mode === 'list' ? 2 : 1}
                  >
                    <Grid
                      item
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <img
                        src={getIconByTypeStatus(
                          getFileTypeByFilename(file.filename),
                          file.status,
                        )}
                        alt="File"
                        height={mode === 'list' ? 28 : 14}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        className={classes.filename}
                        color="secondary"
                      >
                        {file.filename}
                      </Typography>
                      {file.status === 'failed' && (
                        <Typography
                          variant="caption"
                          color="error.main"
                          fontWeight="fontWeightRegular"
                        >
                          Upload Failed
                        </Typography>
                      )}
                      {file.status === 'cancelled' && (
                        <Typography
                          variant="caption"
                          color="error.main"
                          fontWeight="fontWeightRegular"
                        >
                          Upload Cancelled
                        </Typography>
                      )}
                      {/* Temporarily hide file size until it is available from backend */}
                      {file.status === 'succeeded' && false && (
                        <Typography
                          variant="caption"
                          color="text.hint"
                          fontWeight="fontWeightRegular"
                        >
                          17kb
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>

            <Grid item>
              {(file.status === 'failed' || file.status === 'cancelled') &&
              onRetry ? (
                <IconButton size="small" onClick={() => onRetry(file, index)}>
                  <img src={IconRetry} alt="Delete" />
                </IconButton>
              ) : null}

              {file.status === 'succeeded' ? (
                <img src={IconSuccess} alt="Success" style={{ marginTop: 5 }} />
              ) : null}
            </Grid>

            <Grid item>
              {file.progress && file.progress <= 100 && onCancel ? (
                <Progress
                  value={file.progress}
                  onClick={() => onCancel(file, index)}
                />
              ) : (
                <>
                  {file.status !== 'failed' && onRemove && (
                    <IconButton
                      size="small"
                      onClick={() => onRemove(file, index)}
                    >
                      <img src={IconTrash} height="20" alt="Delete" />
                    </IconButton>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      ))}
    </div>
  );
};

export default AttachmentList;
