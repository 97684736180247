import NumberFormat from 'react-number-format';
import { CoverageType } from '@nirvana/api/non-fleet';
import { FormHelperText, Typography } from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';

import Section from 'src/features/application/components/create/review/section';
import { TableView } from 'src/components/table';
import { useApplicationDetailsContext } from '../../hooks/useApplicationDetails';
import { getReeferDeductible } from '../indication/utils';
import { fetchIndication } from '../../queries/application';

const QuoteView = () => {
  const { applicationDetails, applicationId } = useApplicationDetailsContext();
  const coveragesForm = applicationDetails?.nrb?.coveragesForm;

  const { data: quoteDetails } = useQuery({
    queryKey: ['indication', applicationId],
    queryFn: () => fetchIndication(applicationId),
  });

  return (
    <Section
      title="Quote"
      onEdit={() => {}}
      applicationData={{} as any}
      data={[
        {
          key: 'totalPremium',
          label: 'Total Premium',
          renderValue: () => (
            <TableView
              headers={[
                {
                  key: 'totalPremium-header',
                  content: '',
                  width: '50%',
                },
              ]}
              rows={[
                {
                  columns: [
                    {
                      key: 'totalPremium-value',
                      content: quoteDetails?.quote?.totalPremium ? (
                        <NumberFormat
                          value={quoteDetails?.quote?.totalPremium}
                          displayType="text"
                          thousandSeparator={true}
                          prefix="$"
                        />
                      ) : (
                        'N/A'
                      ),
                      width: '50%',
                    },
                  ],
                },
              ]}
            />
          ),
        },
        {
          key: 'apd',
          label: 'Auto Physical Damage',
          renderValue: () => (
            <TableView
              headers={[
                {
                  key: `${CoverageType.CoverageAutoPhysicalDamage}-header-deductible`,
                  content: 'Deductible',
                  width: '50%',
                },
                {
                  key: `${CoverageType.CoverageAutoPhysicalDamage}-header-limit`,
                  content: '',
                  width: '50%',
                },
              ]}
              rows={[
                {
                  columns: [
                    {
                      key: `${CoverageType.CoverageAutoPhysicalDamage}-deductible`,
                      content: coveragesForm?.primaryCovs?.[
                        CoverageType.CoverageAutoPhysicalDamage
                      ]?.isRequired ? (
                        <NumberFormat
                          value={
                            coveragesForm?.primaryCovs?.[
                              CoverageType.CoverageAutoPhysicalDamage
                            ].deductible
                          }
                          displayType="text"
                          thousandSeparator={true}
                          prefix="$"
                        />
                      ) : (
                        'N/A'
                      ),
                      width: '50%',
                    },
                    {
                      key: `${CoverageType.CoverageAutoPhysicalDamage}-limit`,
                      content: '',
                      width: '50%',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      key: `${CoverageType.CoverageTowingLaborAndStorage}-label`,
                      content: (
                        <Typography fontWeight="fontWeightMedium" mt={2}>
                          Towing, labor & storage coverage
                        </Typography>
                      ),
                      width: '50%',
                    },
                    {
                      key: `${CoverageType.CoverageTowingLaborAndStorage}-limit`,
                      content: (
                        <>
                          <FormHelperText>Limit</FormHelperText>
                          {coveragesForm?.ancillaryCovs?.[
                            CoverageType.CoverageTowingLaborAndStorage
                          ]?.isRequired ? (
                            <NumberFormat
                              value={
                                coveragesForm?.ancillaryCovs?.[
                                  CoverageType.CoverageTowingLaborAndStorage
                                ].limit
                              }
                              displayType="text"
                              thousandSeparator={true}
                              prefix="$"
                            />
                          ) : (
                            'N/A'
                          )}
                        </>
                      ),
                      width: '50%',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      key: `${CoverageType.CoverageNonOwnedTrailer}-label`,
                      content: (
                        <Typography fontWeight="fontWeightMedium" mt={2}>
                          Non-owned trailer
                        </Typography>
                      ),
                      width: '50%',
                    },
                    {
                      key: `${CoverageType.CoverageNonOwnedTrailer}-limit`,
                      content: (
                        <>
                          <FormHelperText>Limit</FormHelperText>
                          {coveragesForm?.ancillaryCovs?.[
                            CoverageType.CoverageNonOwnedTrailer
                          ]?.isRequired ? (
                            <NumberFormat
                              value={
                                coveragesForm?.ancillaryCovs?.[
                                  CoverageType.CoverageNonOwnedTrailer
                                ].limit
                              }
                              displayType="text"
                              thousandSeparator={true}
                              prefix="$"
                            />
                          ) : (
                            'N/A'
                          )}
                        </>
                      ),
                      width: '50%',
                    },
                  ],
                },
              ]}
            />
          ),
        },
        {
          key: 'mtc',
          label: 'Motor Truck Cargo',
          renderValue: () => (
            <TableView
              headers={[
                {
                  key: `${CoverageType.CoverageMotorTruckCargo}-header-deductible`,
                  content: 'Deductible',
                  width: '50%',
                },
                {
                  key: `${CoverageType.CoverageMotorTruckCargo}-header-limit`,
                  content: 'Limit',
                  width: '50%',
                },
              ]}
              rows={[
                {
                  columns: [
                    {
                      key: `${CoverageType.CoverageMotorTruckCargo}-deductible`,
                      content: coveragesForm?.primaryCovs?.[
                        CoverageType.CoverageMotorTruckCargo
                      ]?.isRequired ? (
                        <NumberFormat
                          value={
                            coveragesForm?.primaryCovs?.[
                              CoverageType.CoverageMotorTruckCargo
                            ].deductible
                          }
                          displayType="text"
                          thousandSeparator={true}
                          prefix="$"
                        />
                      ) : (
                        'N/A'
                      ),
                      width: '50%',
                    },
                    {
                      key: `${CoverageType.CoverageMotorTruckCargo}-limit`,
                      content: coveragesForm?.primaryCovs?.[
                        CoverageType.CoverageMotorTruckCargo
                      ]?.isRequired ? (
                        <NumberFormat
                          value={
                            coveragesForm?.primaryCovs?.[
                              CoverageType.CoverageMotorTruckCargo
                            ].limit
                          }
                          displayType="text"
                          thousandSeparator={true}
                          prefix="$"
                        />
                      ) : (
                        'N/A'
                      ),
                      width: '50%',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      key: `${CoverageType.CoverageReefer}-label`,
                      content: (
                        <Typography fontWeight="fontWeightMedium" mt={2}>
                          Reefer Breakdown
                        </Typography>
                      ),
                      width: '50%',
                    },
                    {
                      key: `${CoverageType.CoverageReefer}-limit`,
                      content: (
                        <>
                          <FormHelperText>Deductible</FormHelperText>
                          {coveragesForm?.ancillaryCovs?.[
                            CoverageType.CoverageReefer
                          ]?.isRequired &&
                          coveragesForm?.primaryCovs?.[
                            CoverageType.CoverageMotorTruckCargo
                          ]?.deductible ? (
                            <NumberFormat
                              value={getReeferDeductible(
                                coveragesForm?.primaryCovs?.[
                                  CoverageType.CoverageMotorTruckCargo
                                ]?.deductible,
                              )}
                              displayType="text"
                              thousandSeparator={true}
                              prefix="$"
                            />
                          ) : (
                            'N/A'
                          )}
                        </>
                      ),
                      width: '50%',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      key: `${CoverageType.CoverageDebrisRemoval}-label`,
                      content: (
                        <Typography fontWeight="fontWeightMedium" mt={2}>
                          Debris Removal
                        </Typography>
                      ),
                      width: '50%',
                    },
                    {
                      key: `${CoverageType.CoverageDebrisRemoval}-limit`,
                      content: (
                        <>
                          <FormHelperText>Limit</FormHelperText>
                          {coveragesForm?.ancillaryCovs?.[
                            CoverageType.CoverageDebrisRemoval
                          ]?.isRequired ? (
                            <NumberFormat
                              value={
                                coveragesForm?.ancillaryCovs?.[
                                  CoverageType.CoverageDebrisRemoval
                                ].limit
                              }
                              displayType="text"
                              thousandSeparator={true}
                              prefix="$"
                            />
                          ) : (
                            'N/A'
                          )}
                        </>
                      ),
                      width: '50%',
                    },
                  ],
                },
              ]}
            />
          ),
        },
        {
          key: 'ancillaryCoverages',
          label: 'Ancillary Coverages',
          renderValue: () => (
            <TableView
              headers={[
                {
                  key: `${CoverageType.CoverageTrailerInterchange}-row`,
                  content: '',
                  width: '50%',
                },
                {
                  key: `${CoverageType.CoverageTrailerInterchange}-header`,
                  content: '',
                  width: '50%',
                },
              ]}
              rows={[
                {
                  columns: [
                    {
                      key: `${CoverageType.CoverageTrailerInterchange}-label`,
                      content: (
                        <Typography fontWeight="fontWeightMedium" mt={2}>
                          Trailer Interchange
                        </Typography>
                      ),
                      width: '50%',
                    },
                    {
                      key: `${CoverageType.CoverageMotorTruckCargo}-limit`,
                      content: (
                        <>
                          <FormHelperText>Limit</FormHelperText>
                          {coveragesForm?.ancillaryCovs?.[
                            CoverageType.CoverageTrailerInterchange
                          ]?.isRequired ? (
                            <NumberFormat
                              value={
                                coveragesForm?.ancillaryCovs?.[
                                  CoverageType.CoverageTrailerInterchange
                                ].limit
                              }
                              displayType="text"
                              thousandSeparator={true}
                              prefix="$"
                            />
                          ) : (
                            'N/A'
                          )}
                        </>
                      ),
                      width: '50%',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      key: `${CoverageType.CoverageUiia}-label`,
                      content: (
                        <Typography fontWeight="fontWeightMedium" mt={2}>
                          UIIA
                        </Typography>
                      ),
                      width: '50%',
                    },
                    {
                      key: `${CoverageType.CoverageUiia}-limit`,
                      content: (
                        <>
                          <FormHelperText>Limit</FormHelperText>
                          {coveragesForm?.ancillaryCovs?.[
                            CoverageType.CoverageUiia
                          ]?.isRequired ? (
                            <NumberFormat
                              value={
                                coveragesForm?.ancillaryCovs?.[
                                  CoverageType.CoverageUiia
                                ].limit
                              }
                              displayType="text"
                              thousandSeparator={true}
                              prefix="$"
                            />
                          ) : (
                            'N/A'
                          )}
                        </>
                      ),
                      width: '50%',
                    },
                  ],
                },
              ]}
            />
          ),
        },
      ]}
    />
  );
};

export default QuoteView;
