import * as React from 'react';
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  Link,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { ITheme } from '@nirvana/ui-kit';

import { Feature, useFeatureFlag } from 'src/helpers/featureFlags';
import ImageLink from 'src/assets/icons/link.svg';

// This file has been copied from resources/components/index.tsx and only slightly changed.
// If there are more pages created that use this same style, we should factor to reduce duplication.

const LinkBlock = styled(Paper)(({ theme }: { theme: ITheme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  borderRadius: 4,
  border: 0,
  boxShadow: 'none',
  [theme.breakpoints.up('xl')]: {
    padding: theme.spacing(3),
  } as any,
  '&:hover': {
    backgroundColor: theme.palette.grey['50'],
  },
}));

type SafetyContent = {
  name: string;
  url: string;
};

const Safety = () => {
  const getFeatureValue = useFeatureFlag();
  const theme = useTheme();
  const isXL = useMediaQuery(theme.breakpoints.up('xl'));

  const content: SafetyContent[] = React.useMemo(() => {
    return getFeatureValue(Feature.SAFETY_CONTENT, []);
  }, [getFeatureValue]);

  return (
    <Box p={4}>
      <Container maxWidth="lg">
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography
              variant="h4"
              color="textSecondary"
              fontWeight="fontWeightBold"
            >
              Safety
            </Typography>
          </Grid>

          <Grid item container direction="column" spacing={isXL ? 2 : 1.5}>
            {content.map((record) => (
              <Grid item key={record.url}>
                <LinkBlock elevation={0}>
                  <Link href={record.url} target="_blank">
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ fontSize: '1rem' }}
                    >
                      {record.name}{' '}
                      <Box ml={2}>
                        <img src={ImageLink} />
                      </Box>
                    </Box>
                  </Link>
                </LinkBlock>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Safety;
