import { makeStyles } from '@material-ui/core';
import { ITheme } from '@nirvana/ui-kit';

export const useStyles = makeStyles((theme: ITheme) => ({
  inputLabel: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    whiteSpace: 'normal',
  },
  coverageInputLabel: {
    fontSize: 12,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
  },
  cardHeaderBar: {
    backgroundColor: theme.palette.gold.main,
    height: 4,
    width: 35,
    marginTop: 10,
    marginBottom: 8,
  },
  currencySymbol: {
    marginRight: theme.spacing(0.5),
  },
  cardHeader: {
    padding: theme.typography.pxToRem(30),
    paddingBottom: theme.typography.pxToRem(14),
  },
  overlay: {
    position: 'absolute',
    top: theme.spacing(6),
    left: theme.spacing(6),
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(255, 255, 255, 0.8)',
    filter: 'blur(22px)',
    zIndex: 10,
  },
  loaderContainer: {
    position: 'absolute',
    top: theme.spacing(6),
    left: theme.spacing(6),
    bottom: 0,
    right: 0,
    zIndex: 11,
    paddingTop: theme.spacing(12),
  },
  title: {
    color: theme.palette.primary.light,
  },
  selectedTitle: {
    color: theme.palette.primary.main,
  },
  cardHeaderContent: {
    textAlign: 'center',
  },
  card: {
    flex: 1,
    position: 'relative',
    overflow: 'visible',
    borderWidth: 3,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 0px 9px rgba(128, 145, 196, 0.2)',
    borderRadius: theme.typography.pxToRem(10),
    borderColor: theme.palette.grey[50],
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    '&:hover': {
      backgroundColor: theme.palette.primary.tint1,
      border: `3px solid ${theme.palette.primary.main}`,
      boxShadow: '0px 5px 12px rgba(0, 36, 138, 0.24)',
    },
  },
  selected: {
    backgroundColor: theme.palette.primary.tint1,
    border: `3px solid ${theme.palette.primary.main}`,
    boxShadow: '0px 5px 12px rgba(0, 36, 138, 0.24)',
  },
  recommended: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: theme.spacing(2),
  },
  error: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
  cardChip: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.typography.pxToRem(-5),
    backgroundColor: theme.palette.success.dark,
    padding: theme.spacing(0.125, 2, 0.125, 3),
    borderRadius: 0,
    boxShadow: '3px 2px 3px rgba(128, 255, 179, 0.4)',
    color: theme.palette.common.white,

    '&::before': {
      content: '" "',
      position: 'absolute',
      display: 'block',
      width: 0,
      height: 0,
      borderTop: `5px solid ${theme.palette.success.dark}`,
      borderLeft: '5px solid transparent',
      bottom: -5,
      zIndex: 1,
      left: 0,
    },

    '&::after': {
      content: '" "',
      position: 'absolute',
      display: 'block',
      right: -7,
      width: 0,
      height: 0,
      borderBottom: `23.5px solid ${theme.palette.success.dark}`,
      borderRight: '7px solid transparent',
      top: 0,
      bottom: 0,
      zIndex: 1,
    },
  },
  telematicsConnectedCard: {
    cursor: 'pointer',
    padding: theme.spacing(2),
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    flex: 1,
  },
}));
