import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  OutlinedInput,
  Skeleton,
} from '@material-ui/core';
import { ApplicationDetail, CoverageType } from '@nirvana/api/quoting';
import { Checkbox, InputNumeric } from '@nirvana/ui-kit';
import clsx from 'clsx';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOperations } from 'src/features/application/actions/classesAndCommodities';
import { getHelperText } from 'src/features/application/components/create/additionalInformation';
import PrimaryCategory from 'src/features/application/components/create/commodities/primaryCategory';
import { useStyles } from 'src/features/application/components/create/commodities/styles';
import { getOperatingClassErrorMessage } from 'src/features/application/components/create/commodities/utils';
import { operationSelector } from 'src/features/application/slices/classesAndCommodities';
import CommoditiesForm from './commoditiesForm';
import CommodityLabelFormView from './commodityLabelFormView';

interface ClassesAndCommoditiesFormViewProps {
  applicationData: ApplicationDetail;
}

interface IOperationClass {
  label: string;
  value: string;
  iconInactive: any;
  iconActive: any;
  commodities: Array<ICommodity>;
  mtcCommodities?: Array<ICommodity>;
}

export interface ICommodity {
  label: string;
  value: string;
}

const useOutlinedInputStyles = makeStyles(() => ({
  root: {
    '& $notchedOutline': {
      border: '1px solid #D7D8DB ',
    },
  },
  disabled: {
    '& $notchedOutline': {
      border: '1px solid #D7D8DB ',
    },
  },
}));

const ClassesAndCommoditiesFormView = ({
  applicationData,
}: ClassesAndCommoditiesFormViewProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();
  const { control, watch, setValue, errors } = useFormContext();

  const watchableValues = watch([
    'commodities',
    'commoditiesComment',
    'lossRunFiles',
  ]);
  const hasSelectedCommodities = !!(
    watchableValues.commodities &&
    watchableValues.commodities.filter((record: string) => !!record).length
  );

  const operation = useSelector(operationSelector);
  const applicationId = applicationData?.summary.applicationID;

  const values = watch(['operatingClassDistribution']);

  const totalFleetPercentage: number =
    values &&
    values.operatingClassDistribution &&
    values.operatingClassDistribution.reduce(
      (acc: number, record: { percentageOfFleet: number }) =>
        acc + (+record?.percentageOfFleet || 0),
      0,
    );

  // Fetch list of supported operations on mount
  React.useEffect(() => {
    // Fetch list of operations
    dispatch(fetchOperations({ applicationId }));
  }, [dispatch, applicationId]);

  const hasMTC =
    applicationData?.indicationForm?.operationsForm?.coveragesRequired
      ?.map((item) => item.coverageType)
      .includes(CoverageType.CoverageMotorTruckCargo) || false;

  const { list } = operation;

  React.useEffect(() => {
    const classesAndCommoditiesData =
      applicationData.indicationForm?.classesAndCommoditiesForm
        ?.operatingClassDistribution;

    classesAndCommoditiesData?.forEach((item, index) => {
      if (item.percentageOfFleet > 0) {
        setValue(`operatingClassDistribution[${index}]`, {
          operatingClass: item.operatingClass,
          percentageOfFleet: item.percentageOfFleet,
          selected: true,
        });
      }
    });
  }, [applicationData, setValue, list]);

  return (
    <>
      <Grid item xs={4}>
        <Box mb={3}>
          <InputLabel className={classes.inputLabel}>
            Operating class distribution
          </InputLabel>
        </Box>
      </Grid>
      {list?.length > 0 ? (
        <Grid container spacing={3} direction="row" mb={2}>
          <Grid item xs={12} container spacing={2} direction="row" px={4}>
            <Grid
              item
              xs={12}
              container
              spacing={2}
              direction="row"
              sx={{
                paddingLeft: '36px !important',
              }}
            >
              {list.map((item: IOperationClass, index: number) => (
                <Controller
                  key={`operatingClassDistribution[${index}]`}
                  name={`operatingClassDistribution[${index}]`}
                  defaultValue={{
                    operatingClass: item.value,
                    percentageOfFleet: undefined,
                    selected: false,
                  }}
                  control={control}
                  rules={{
                    validate: {
                      required: (record) =>
                        !record ||
                        !record.selected ||
                        (record.selected && record.percentageOfFleet) ||
                        'Field cannot be empty',
                      number: (record) =>
                        !record ||
                        !record.percentageOfFleet ||
                        (record && /^[0-9]+$/.test(record.percentageOfFleet)) ||
                        'Please enter a valid number',
                      minOperatingClass: () => {
                        if (totalFleetPercentage === 0) {
                          return 'Please select at least 1 operating class';
                        }

                        return true;
                      },
                      total: () =>
                        !totalFleetPercentage ||
                        totalFleetPercentage === 100 ||
                        'Total value of operating class distribution should be equal to 100',
                    },
                  }}
                  render={({ value, onChange }) => {
                    return (
                      <Grid key={item.value} item xs={4} justifyContent="start">
                        <FormGroup>
                          <Grid container justifyContent="start">
                            <FormControlLabel
                              className="w-32"
                              classes={{
                                label: 'text-base',
                              }}
                              control={
                                <Checkbox
                                  onChange={(event) => {
                                    if (!event.target.checked) {
                                      onChange({
                                        ...value,
                                        percentageOfFleet: 0,
                                        selected: event.target.checked,
                                      });
                                    } else {
                                      onChange({
                                        ...value,
                                        selected: event.target.checked,
                                      });
                                    }
                                  }}
                                  checked={value.selected}
                                />
                              }
                              label={item.label}
                            />

                            <InputNumeric
                              placeholder="0%"
                              decimalScale={0}
                              disabled={!value.selected}
                              classes={outlinedInputClasses}
                              sx={{
                                maxWidth: '73px',
                                '& .MuiInputBase-root.Mui-disabled': {
                                  '& > fieldset': {
                                    borderColor: 'red !important',
                                  },
                                },
                              }}
                              value={value?.percentageOfFleet}
                              onChange={(event) => {
                                if (event.target.value) {
                                  const newValue = +event.target.value;
                                  if (newValue > totalFleetPercentage) {
                                    setValue('primaryCommodity', undefined);
                                    setValue(
                                      'primaryOperatingClass',
                                      undefined,
                                    );
                                  }
                                  onChange({
                                    ...value,
                                    percentageOfFleet: newValue,
                                  });
                                } else {
                                  onChange({
                                    ...value,
                                    percentageOfFleet: undefined,
                                  });
                                }
                              }}
                              error={!!errors.operatingClassDistribution}
                              suffix="%"
                            />
                          </Grid>
                        </FormGroup>
                      </Grid>
                    );
                  }}
                />
              ))}
            </Grid>

            <Grid item container direction="row" wrap="nowrap" spacing={0}>
              <Grid item>
                <Box className={clsx(classes.totalItem, classes.totalLabel)}>
                  Total
                </Box>
              </Grid>
              <Grid item flexGrow={1}>
                <Box className={clsx(classes.totalItem, classes.totalValue)}>
                  {totalFleetPercentage}%
                </Box>
              </Grid>
            </Grid>

            <Grid item container direction="row" wrap="nowrap" spacing={0}>
              <Grid item>
                {!!errors?.operatingClassDistribution && (
                  <FormHelperText error>
                    {getOperatingClassErrorMessage(errors)}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Box mb={4}>
          <Skeleton animation="pulse" className="w-full h-30" />
          <Skeleton animation="pulse" className="w-6/12 h-30" />
          <Skeleton animation="pulse" className="w-4/12 h-30" />
        </Box>
      )}
      <Divider
        sx={{
          my: 3,
        }}
      />

      {!hasMTC && (
        <>
          <div className="my-10">
            <PrimaryCategory
              list={operation.categories}
              layout="row"
              defaultValue={
                applicationData.indicationForm?.classesAndCommoditiesForm
                  ?.primaryCategory
              }
            />
          </div>
          <Divider
            sx={{
              my: 3,
            }}
          />
        </>
      )}
      {hasMTC && (
        <>
          <CommoditiesForm
            application={applicationData}
            list={operation.categories}
          />
          <Divider
            sx={{
              my: 3,
            }}
          />
        </>
      )}
      <CommodityLabelFormView application={applicationData} />

      {hasSelectedCommodities && (
        <div className="mt-4 ">
          <Grid
            item
            container
            direction="row"
            wrap="nowrap"
            alignItems="flex-start"
            spacing={3}
            mb={2}
          >
            <Grid item xs={12}>
              <Box paddingTop="10px">
                <InputLabel
                  htmlFor="commoditiesComment-input"
                  className={classes.inputLabel}
                >
                  Please share additional information
                </InputLabel>
              </Box>
            </Grid>
          </Grid>

          <Controller
            name="commoditiesComment"
            defaultValue={
              applicationData.additionalInfoForm?.commoditiesComment
            }
            control={control}
            rules={{
              required: false,
            }}
            render={(props) => (
              <FormControl fullWidth>
                <OutlinedInput
                  id="commoditiesComment-input"
                  rows={3}
                  multiline
                  placeholder="Write your additional information here..."
                  value={props.value}
                  onChange={props.onChange}
                  error={!!errors.commoditiesComment}
                />
                {!!errors.commoditiesComment && (
                  <FormHelperText error>
                    {getHelperText('commoditiesComment', errors)}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </div>
      )}
    </>
  );
};

export default ClassesAndCommoditiesFormView;
