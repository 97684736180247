import { useMemo } from 'react';
import clsx from 'clsx';
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { Show } from '@nirvana/ui-kit';
import NumberFormat from 'react-number-format';
import {
  CoverageDetails,
  CoverageType,
  QuoteOption,
} from '@nirvana/api/non-fleet';

import Button from 'src/components/button';
import { utils as UtilsHelper } from 'src/helpers';
import IconSync from 'src/assets/icons/sync-primary.svg';

import { useStyles } from './styles';

export const getFormattedCoverageInfo = (
  details: CoverageDetails,
  data?: QuoteOption,
) => {
  switch (details.coverageType) {
    case CoverageType.CoverageAutoPhysicalDamage:
      return {
        name: 'Physical Damage',
        primary: (
          <>
            {`${UtilsHelper.roundOffNumber(data?.TIVPercentage || 0)}% per`}
            &nbsp;TIV
          </>
        ),
        secondary: (
          <NumberFormat
            value={details.premium}
            displayType="text"
            thousandSeparator={true}
            prefix="Premium - $"
          />
        ),
      };

    case CoverageType.CoverageMotorTruckCargo:
      return {
        name: 'Motor Truck Cargo',
        primary: (
          <NumberFormat
            value={details.premiumPerUnit}
            displayType="text"
            thousandSeparator={true}
            prefix="$"
            suffix=" per Unit"
          />
        ),
        secondary: (
          <NumberFormat
            value={details.premium}
            displayType="text"
            thousandSeparator={true}
            prefix="Premium - $"
          />
        ),
      };

    default:
      return null;
  }
};

const IndicationSummary = ({
  data,
  recalculate = false,
  recalculateInProgress = false,
  onRecalculate,
}: {
  data?: QuoteOption;
  recalculate?: boolean;
  recalculateInProgress?: boolean;
  onRecalculate?: () => void;
}) => {
  const classes = useStyles();

  const coverages = useMemo(() => {
    const sortedData = data?.coverages.sort((a: CoverageDetails) => {
      if (a.coverageType === CoverageType.CoverageAutoPhysicalDamage) {
        return -1;
      }

      if (a.coverageType === CoverageType.CoverageMotorTruckCargo) {
        return 1;
      }

      return 0;
    });

    return sortedData || [];
  }, [data?.coverages]);

  return (
    <>
      <Grid
        container
        spacing={2}
        className={clsx(classes.quoteContainer, {
          [classes.blur]: recalculate,
        })}
        direction="row"
      >
        <Grid item>
          <Grid container direction="column" alignItems="flex-start">
            <Grid item>
              <Typography className={classes.quote}>Total Premium</Typography>
            </Grid>

            <Grid item container alignItems="end" justifyContent="center">
              <Typography variant="h2">
                <Typography variant="caption" fontSize={16} fontWeight={400}>
                  $
                </Typography>{' '}
                <NumberFormat
                  value={data?.totalPremium}
                  displayType="text"
                  thousandSeparator={true}
                  prefix=""
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item flexGrow={1} display="flex" justifyContent="center">
          <Divider orientation="vertical" />
        </Grid>

        {coverages?.map((record) => {
          const details = getFormattedCoverageInfo(record, data);

          if (!details) {
            return null;
          }

          return (
            <Grid item key={record.coverageType}>
              <Typography
                color="primary"
                component="p"
                fontWeight="fontWeightRegular"
                mb={1}
                textAlign="center"
                variant="caption"
              >
                {details?.name}
              </Typography>
              <Typography
                color="textPrimary"
                component="p"
                fontWeight="fontWeightBold"
                mb={0.4}
                textAlign="center"
                variant="h6"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {details?.primary}
                &nbsp;&nbsp;
                <InfoOutlined className={classes.infoIcon} fontSize="small" />
              </Typography>
              <Typography
                color="textSecondary"
                fontWeight="fontWeightRegular"
                textAlign="center"
              >
                {details?.secondary}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
      <Show when={recalculate}>
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            variant="outlined"
            startIcon={
              recalculateInProgress ? (
                <CircularProgress size="16px" />
              ) : (
                <img src={IconSync} />
              )
            }
            onClick={onRecalculate}
          >
            {recalculateInProgress ? 'Recalculating...' : 'Recalculate'}
          </Button>
        </Box>
      </Show>
    </>
  );
};

export default IndicationSummary;
