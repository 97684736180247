import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  GridDirection,
} from '@material-ui/core';
import { CommodityCategory } from '@nirvana/api/quoting';

import { useStyles } from './styles';

export interface IPrimaryCategory {
  layout: GridDirection;
  list: CommodityCategory[];
  defaultValue?: CommodityCategory;
}

const PrimaryCategory = ({ layout, list, defaultValue }: IPrimaryCategory) => {
  const classes = useStyles();
  const { control, errors } = useFormContext();

  return (
    <Grid
      item
      container
      direction={layout}
      spacing={layout === 'row' ? 3 : 1}
      alignItems={layout === 'row' ? 'center' : 'flex-start'}
    >
      <Grid item xs={layout === 'row' ? 4 : 12}>
        <InputLabel className={classes.inputLabel}>
          Primary commodity
        </InputLabel>
      </Grid>
      <Grid item container spacing={3} xs={layout === 'row' ? 8 : 12}>
        <Grid item flexGrow={1} xs={layout === 'row' ? 8 : 4}>
          <FormControl fullWidth>
            <Controller
              name="primaryCategory"
              defaultValue={defaultValue}
              control={control}
              rules={{
                required: 'Please select primary commodity',
              }}
              render={(props) => {
                return (
                  <>
                    <Select
                      displayEmpty
                      variant="outlined"
                      value={props.value?.type || ''}
                      onChange={(event) => {
                        if (event.target.value) {
                          props.onChange({
                            type: event.target.value,
                            label: '',
                          });
                        } else {
                          props.onChange(undefined);
                        }
                      }}
                      error={!!errors.primaryCategory}
                    >
                      <MenuItem value="">Select</MenuItem>
                      {list.map((record) => (
                        <MenuItem key={record.type} value={record.type}>
                          {record.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {!!errors?.primaryCategory && (
                      <FormHelperText error>
                        {errors?.primaryCategory?.message}
                      </FormHelperText>
                    )}
                  </>
                );
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PrimaryCategory;
