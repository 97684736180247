/* tslint:disable */
/* eslint-disable */
/**
 * Nirvana Non Fleet Application API
 * Nirvana Non Fleet Application APIs
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from './base';

/**
 *
 * @export
 * @interface Address
 */
export interface Address {
  /**
   *
   * @type {string}
   * @memberof Address
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  state: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  zip: string;
}
/**
 *
 * @export
 * @interface AdmittedApp
 */
export interface AdmittedApp {
  /**
   *
   * @type {AdmittedAppOperationsForm}
   * @memberof AdmittedApp
   */
  operationsForm?: AdmittedAppOperationsForm;
  /**
   *
   * @type {AdmittedAppEquipmentsForm}
   * @memberof AdmittedApp
   */
  equipmentsForm?: AdmittedAppEquipmentsForm;
  /**
   *
   * @type {AdmittedAppDriversForm}
   * @memberof AdmittedApp
   */
  driversForm?: AdmittedAppDriversForm;
  /**
   *
   * @type {AdmittedAppIndicationForm}
   * @memberof AdmittedApp
   */
  indicationForm?: AdmittedAppIndicationForm;
}
/**
 *
 * @export
 * @interface AdmittedAppBusinessOwner
 */
export interface AdmittedAppBusinessOwner {
  /**
   *
   * @type {string}
   * @memberof AdmittedAppBusinessOwner
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof AdmittedAppBusinessOwner
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof AdmittedAppBusinessOwner
   */
  dateOfBirth: string;
  /**
   *
   * @type {Address}
   * @memberof AdmittedAppBusinessOwner
   */
  address: Address;
  /**
   *
   * @type {boolean}
   * @memberof AdmittedAppBusinessOwner
   */
  driverOnPolicy?: boolean;
}
/**
 *
 * @export
 * @interface AdmittedAppCoverageForm
 */
export interface AdmittedAppCoverageForm {
  /**
   *
   * @type {CoverageDetails}
   * @memberof AdmittedAppCoverageForm
   */
  CoverageAutoLiability?: CoverageDetails;
  /**
   *
   * @type {CoverageDetails}
   * @memberof AdmittedAppCoverageForm
   */
  CoverageAutoPhysicalDamage?: CoverageDetails;
  /**
   *
   * @type {CoverageDetails}
   * @memberof AdmittedAppCoverageForm
   */
  CoverageGeneralLiability?: CoverageDetails;
  /**
   *
   * @type {CoverageDetails}
   * @memberof AdmittedAppCoverageForm
   */
  CoverageMotorTruckCargo?: CoverageDetails;
  /**
   *
   * @type {boolean}
   * @memberof AdmittedAppCoverageForm
   */
  isAPDMTCDeductibleCombined?: boolean;
}
/**
 *
 * @export
 * @interface AdmittedAppDriverDetails
 */
export interface AdmittedAppDriverDetails {
  /**
   *
   * @type {string}
   * @memberof AdmittedAppDriverDetails
   */
  licenseNumber: string;
  /**
   *
   * @type {string}
   * @memberof AdmittedAppDriverDetails
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof AdmittedAppDriverDetails
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof AdmittedAppDriverDetails
   */
  licenseState: string;
  /**
   *
   * @type {number}
   * @memberof AdmittedAppDriverDetails
   */
  yearsOfExp: number;
  /**
   *
   * @type {string}
   * @memberof AdmittedAppDriverDetails
   */
  dateOfBirth: string;
  /**
   *
   * @type {string}
   * @memberof AdmittedAppDriverDetails
   */
  dateOfHire: string;
  /**
   *
   * @type {boolean}
   * @memberof AdmittedAppDriverDetails
   */
  isIncluded: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AdmittedAppDriverDetails
   */
  isOutOfState: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AdmittedAppDriverDetails
   */
  violationInLastThreeYears: boolean;
}
/**
 *
 * @export
 * @interface AdmittedAppDriversForm
 */
export interface AdmittedAppDriversForm {
  /**
   *
   * @type {Array<AdmittedAppDriverDetails>}
   * @memberof AdmittedAppDriversForm
   */
  drivers?: Array<AdmittedAppDriverDetails>;
}
/**
 *
 * @export
 * @interface AdmittedAppEquipmentsForm
 */
export interface AdmittedAppEquipmentsForm {
  /**
   *
   * @type {Array<AdmittedAppVehicleDetails>}
   * @memberof AdmittedAppEquipmentsForm
   */
  vehicles?: Array<AdmittedAppVehicleDetails>;
}
/**
 *
 * @export
 * @interface AdmittedAppIndicationForm
 */
export interface AdmittedAppIndicationForm {
  /**
   *
   * @type {AdmittedAppSelectedIndication}
   * @memberof AdmittedAppIndicationForm
   */
  selectedIndication?: AdmittedAppSelectedIndication;
  /**
   *
   * @type {TelematicsInfo}
   * @memberof AdmittedAppIndicationForm
   */
  telematicsInfo?: TelematicsInfo;
  /**
   *
   * @type {AdmittedAppCoverageForm}
   * @memberof AdmittedAppIndicationForm
   */
  coverages?: AdmittedAppCoverageForm;
}
/**
 *
 * @export
 * @interface AdmittedAppOperationsForm
 */
export interface AdmittedAppOperationsForm {
  /**
   *
   * @type {CompanyInfo}
   * @memberof AdmittedAppOperationsForm
   */
  companyInfo?: CompanyInfo;
  /**
   *
   * @type {string}
   * @memberof AdmittedAppOperationsForm
   */
  effectiveDate?: string;
  /**
   *
   * @type {Array<CoverageDetails>}
   * @memberof AdmittedAppOperationsForm
   */
  coverages?: Array<CoverageDetails>;
  /**
   *
   * @type {string}
   * @memberof AdmittedAppOperationsForm
   */
  producerID?: string;
  /**
   *
   * @type {string}
   * @memberof AdmittedAppOperationsForm
   */
  producerName?: string;
  /**
   *
   * @type {AdmittedAppBusinessOwner}
   * @memberof AdmittedAppOperationsForm
   */
  businessOwner?: AdmittedAppBusinessOwner;
  /**
   *
   * @type {MaxRadiusOfOperation}
   * @memberof AdmittedAppOperationsForm
   */
  maxRadiusOfOperation?: MaxRadiusOfOperation;
  /**
   *
   * @type {number}
   * @memberof AdmittedAppOperationsForm
   */
  atFaultAccidents?: number;
  /**
   *
   * @type {Array<FileMetadata>}
   * @memberof AdmittedAppOperationsForm
   */
  lossRunFiles?: Array<FileMetadata>;
  /**
   *
   * @type {AdmittedCommodity}
   * @memberof AdmittedAppOperationsForm
   */
  primaryCommodity?: AdmittedCommodity;
  /**
   *
   * @type {string}
   * @memberof AdmittedAppOperationsForm
   */
  primaryCommodityLabel?: string;
  /**
   *
   * @type {string}
   * @memberof AdmittedAppOperationsForm
   */
  primaryCategory?: string;
  /**
   *
   * @type {Array<AdmittedCommodityRecord>}
   * @memberof AdmittedAppOperationsForm
   */
  commodityDistribution?: Array<AdmittedCommodityRecord>;
  /**
   *
   * @type {AdmittedAppTerminalLocation}
   * @memberof AdmittedAppOperationsForm
   */
  terminalLocation?: AdmittedAppTerminalLocation;
  /**
   *
   * @type {boolean}
   * @memberof AdmittedAppOperationsForm
   */
  hasHiredAuto?: boolean;
}
/**
 *
 * @export
 * @interface AdmittedAppPackages
 */
export interface AdmittedAppPackages {
  /**
   *
   * @type {string}
   * @memberof AdmittedAppPackages
   */
  id?: string;
  /**
   *
   * @type {IndicationOptionTag}
   * @memberof AdmittedAppPackages
   */
  packageName?: IndicationOptionTag;
  /**
   *
   * @type {number}
   * @memberof AdmittedAppPackages
   */
  totalPremium?: number;
  /**
   *
   * @type {number}
   * @memberof AdmittedAppPackages
   */
  tiv?: number;
  /**
   *
   * @type {number}
   * @memberof AdmittedAppPackages
   */
  tivPercentage?: number;
  /**
   *
   * @type {boolean}
   * @memberof AdmittedAppPackages
   */
  isRecommended?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AdmittedAppPackages
   */
  isDisabled?: boolean;
  /**
   *
   * @type {number}
   * @memberof AdmittedAppPackages
   */
  maxSafetyDiscount?: number;
  /**
   *
   * @type {Array<CoverageDetails>}
   * @memberof AdmittedAppPackages
   */
  primaryCovs?: Array<CoverageDetails>;
  /**
   *
   * @type {Array<CoverageDetails>}
   * @memberof AdmittedAppPackages
   */
  statutoryCovs?: Array<CoverageDetails>;
  /**
   *
   * @type {Array<CoverageDetails>}
   * @memberof AdmittedAppPackages
   */
  additionalCovs?: Array<CoverageDetails>;
  /**
   *
   * @type {Array<CoverageDetails>}
   * @memberof AdmittedAppPackages
   */
  ancillaryCovsToHighlight?: Array<CoverageDetails>;
  /**
   *
   * @type {number}
   * @memberof AdmittedAppPackages
   */
  tivPercentageNew?: number;
  /**
   *
   * @type {Array<CoverageDetails>}
   * @memberof AdmittedAppPackages
   */
  additionalCovsNew?: Array<CoverageDetails>;
}
/**
 *
 * @export
 * @interface AdmittedAppSelectedIndication
 */
export interface AdmittedAppSelectedIndication {
  /**
   *
   * @type {string}
   * @memberof AdmittedAppSelectedIndication
   */
  id?: string;
  /**
   *
   * @type {IndicationOptionTag}
   * @memberof AdmittedAppSelectedIndication
   */
  packageName?: IndicationOptionTag;
  /**
   *
   * @type {number}
   * @memberof AdmittedAppSelectedIndication
   */
  totalPremium?: number;
}
/**
 *
 * @export
 * @interface AdmittedAppTerminalLocation
 */
export interface AdmittedAppTerminalLocation {
  /**
   *
   * @type {boolean}
   * @memberof AdmittedAppTerminalLocation
   */
  sameAsPhysicalAddress: boolean;
  /**
   *
   * @type {Address}
   * @memberof AdmittedAppTerminalLocation
   */
  address: Address;
}
/**
 *
 * @export
 * @interface AdmittedAppUpdateForm
 */
export interface AdmittedAppUpdateForm {
  /**
   *
   * @type {AdmittedAppOperationsForm}
   * @memberof AdmittedAppUpdateForm
   */
  operationsForm?: AdmittedAppOperationsForm;
  /**
   *
   * @type {AdmittedAppEquipmentsForm}
   * @memberof AdmittedAppUpdateForm
   */
  equipmentsForm?: AdmittedAppEquipmentsForm;
  /**
   *
   * @type {AdmittedAppDriversForm}
   * @memberof AdmittedAppUpdateForm
   */
  driversForm?: AdmittedAppDriversForm;
  /**
   *
   * @type {AdmittedAppIndicationForm}
   * @memberof AdmittedAppUpdateForm
   */
  indicationForm?: AdmittedAppIndicationForm;
}
/**
 *
 * @export
 * @interface AdmittedAppVehicleDetails
 */
export interface AdmittedAppVehicleDetails {
  /**
   *
   * @type {string}
   * @memberof AdmittedAppVehicleDetails
   */
  vin: string;
  /**
   *
   * @type {number}
   * @memberof AdmittedAppVehicleDetails
   */
  year: number;
  /**
   *
   * @type {string}
   * @memberof AdmittedAppVehicleDetails
   */
  make: string;
  /**
   *
   * @type {string}
   * @memberof AdmittedAppVehicleDetails
   */
  model: string;
  /**
   *
   * @type {number}
   * @memberof AdmittedAppVehicleDetails
   */
  statedValue?: number;
  /**
   *
   * @type {VehicleType}
   * @memberof AdmittedAppVehicleDetails
   */
  vehicleType: VehicleType;
  /**
   *
   * @type {AdmittedVehicleClass}
   * @memberof AdmittedAppVehicleDetails
   */
  vehicleClass: AdmittedVehicleClass;
  /**
   *
   * @type {string}
   * @memberof AdmittedAppVehicleDetails
   */
  weightClass?: string;
  /**
   *
   * @type {InsuredData}
   * @memberof AdmittedAppVehicleDetails
   */
  lossPayee?: InsuredData;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum AdmittedCommodity {
  AdmittedCommodityBakedGoods = 'AdmittedCommodityBakedGoods',
  AdmittedCommodityBeerWineNoLiquor = 'AdmittedCommodityBeerWineNoLiquor',
  AdmittedCommodityBeverages = 'AdmittedCommodityBeverages',
  AdmittedCommodityCannedGoods = 'AdmittedCommodityCannedGoods',
  AdmittedCommodityDairy = 'AdmittedCommodityDairy',
  AdmittedCommodityEggs = 'AdmittedCommodityEggs',
  AdmittedCommodityFlour = 'AdmittedCommodityFlour',
  AdmittedCommodityFoodFrozenNotSeafood = 'AdmittedCommodityFoodFrozenNotSeafood',
  AdmittedCommodityFruits = 'AdmittedCommodityFruits',
  AdmittedCommodityMeatsDressedPoultry = 'AdmittedCommodityMeatsDressedPoultry',
  AdmittedCommodityOilsEdible = 'AdmittedCommodityOilsEdible',
  AdmittedCommoditySalt = 'AdmittedCommoditySalt',
  AdmittedCommodityFreshSeafood = 'AdmittedCommodityFreshSeafood',
  AdmittedCommodityFrozenSeafood = 'AdmittedCommodityFrozenSeafood',
  AdmittedCommodityTeaCoffeeSpices = 'AdmittedCommodityTeaCoffeeSpices',
  AdmittedCommodityVegetables = 'AdmittedCommodityVegetables',
  AdmittedCommodityOtherFoodAndBeverages = 'AdmittedCommodityOtherFoodAndBeverages',
  AdmittedCommodityCottonNonGinned = 'AdmittedCommodityCottonNonGinned',
  AdmittedCommodityTextilesSkinsFurs = 'AdmittedCommodityTextilesSkinsFurs',
  AdmittedCommodityFeedFertilizer = 'AdmittedCommodityFeedFertilizer',
  AdmittedCommodityGrainHay = 'AdmittedCommodityGrainHay',
  AdmittedCommodityMulchTopSoilAndFill = 'AdmittedCommodityMulchTopSoilAndFill',
  AdmittedCommodityPlantsShrubsTreesNotTempControlled = 'AdmittedCommodityPlantsShrubsTreesNotTempControlled',
  AdmittedCommodityPlantsShrubsTreesTempControlled = 'AdmittedCommodityPlantsShrubsTreesTempControlled',
  AdmittedCommoditySeeds = 'AdmittedCommoditySeeds',
  AdmittedCommodityOtherFarmingAgriculture = 'AdmittedCommodityOtherFarmingAgriculture',
  AdmittedCommodityLivestock = 'AdmittedCommodityLivestock',
  AdmittedCommodityTobaccoLeafRaw = 'AdmittedCommodityTobaccoLeafRaw',
  AdmittedCommodityChemicalsNonHazardous = 'AdmittedCommodityChemicalsNonHazardous',
  AdmittedCommodityCleaningSuppliesAndCompounds = 'AdmittedCommodityCleaningSuppliesAndCompounds',
  AdmittedCommodityDyesInkAndPaintsNonHazardous = 'AdmittedCommodityDyesInkAndPaintsNonHazardous',
  AdmittedCommodityLiquidsNonChemicalOrNonPetroleum = 'AdmittedCommodityLiquidsNonChemicalOrNonPetroleum',
  AdmittedCommodityOtherChemicals = 'AdmittedCommodityOtherChemicals',
  AdmittedCommodityAluminum = 'AdmittedCommodityAluminum',
  AdmittedCommodityCoal = 'AdmittedCommodityCoal',
  AdmittedCommodityIronAndOre = 'AdmittedCommodityIronAndOre',
  AdmittedCommodityMetalProducts = 'AdmittedCommodityMetalProducts',
  AdmittedCommoditySteel = 'AdmittedCommoditySteel',
  AdmittedCommodityZinc = 'AdmittedCommodityZinc',
  AdmittedCommodityOtherMetalsMineralsCoal = 'AdmittedCommodityOtherMetalsMineralsCoal',
  AdmittedCommodityCoiledSteel = 'AdmittedCommodityCoiledSteel',
  AdmittedCommodityScrapMetal = 'AdmittedCommodityScrapMetal',
  AdmittedCommodityAsphaltCement = 'AdmittedCommodityAsphaltCement',
  AdmittedCommodityAggregatesGravelRockSandStone = 'AdmittedCommodityAggregatesGravelRockSandStone',
  AdmittedCommodityMarbleGraniteOtherStoneSlabs = 'AdmittedCommodityMarbleGraniteOtherStoneSlabs',
  AdmittedCommodityOtherConstructionMaterials = 'AdmittedCommodityOtherConstructionMaterials',
  AdmittedCommodityLogs = 'AdmittedCommodityLogs',
  AdmittedCommodityBottlesPlastic = 'AdmittedCommodityBottlesPlastic',
  AdmittedCommodityContainerizedFreight = 'AdmittedCommodityContainerizedFreight',
  AdmittedCommodityGlassProducts = 'AdmittedCommodityGlassProducts',
  AdmittedCommodityPackingMaterialsAndSupplies = 'AdmittedCommodityPackingMaterialsAndSupplies',
  AdmittedCommodityPaperAndPaperProducts = 'AdmittedCommodityPaperAndPaperProducts',
  AdmittedCommodityPlasticProducts = 'AdmittedCommodityPlasticProducts',
  AdmittedCommodityPrintedMaterial = 'AdmittedCommodityPrintedMaterial',
  AdmittedCommodityRubberProductsNotTires = 'AdmittedCommodityRubberProductsNotTires',
  AdmittedCommodityOtherPaperPlasticGlass = 'AdmittedCommodityOtherPaperPlasticGlass',
  AdmittedCommodityRecyclingMaterials = 'AdmittedCommodityRecyclingMaterials',
  AdmittedCommodityAppliances = 'AdmittedCommodityAppliances',
  AdmittedCommodityCarpetNotOriental = 'AdmittedCommodityCarpetNotOriental',
  AdmittedCommodityCaskets = 'AdmittedCommodityCaskets',
  AdmittedCommodityCdsdvdVideoGamesTapes = 'AdmittedCommodityCDSDVDVideoGamesTapes',
  AdmittedCommodityClothingAndShoesNonDesigner = 'AdmittedCommodityClothingAndShoesNonDesigner',
  AdmittedCommodityCosmeticsPerfume = 'AdmittedCommodityCosmeticsPerfume',
  AdmittedCommodityFurnitureNew = 'AdmittedCommodityFurnitureNew',
  AdmittedCommodityMusicalInstruments = 'AdmittedCommodityMusicalInstruments',
  AdmittedCommodityOfficeEquipment = 'AdmittedCommodityOfficeEquipment',
  AdmittedCommodityPharmaceuticalsOverTheCounter = 'AdmittedCommodityPharmaceuticalsOverTheCounter',
  AdmittedCommoditySpasHotTubs = 'AdmittedCommoditySpasHotTubs',
  AdmittedCommoditySportingGoods = 'AdmittedCommoditySportingGoods',
  AdmittedCommodityToys = 'AdmittedCommodityToys',
  AdmittedCommodityOtherConsumerGoods = 'AdmittedCommodityOtherConsumerGoods',
  AdmittedCommodityHouseholdGoodsMover = 'AdmittedCommodityHouseholdGoodsMover',
  AdmittedCommodityAircraftEngines = 'AdmittedCommodityAircraftEngines',
  AdmittedCommodityAircraftPartsNotEngines = 'AdmittedCommodityAircraftPartsNotEngines',
  AdmittedCommodityAutoAccessoriesPartsNotTires = 'AdmittedCommodityAutoAccessoriesPartsNotTires',
  AdmittedCommodityAutomobiles = 'AdmittedCommodityAutomobiles',
  AdmittedCommodityBoatsGolfCartsRvTravelTrailers = 'AdmittedCommodityBoatsGolfCartsRVTravelTrailers',
  AdmittedCommodityTires = 'AdmittedCommodityTires',
  AdmittedCommodityOtherAutosAircrafts = 'AdmittedCommodityOtherAutosAircrafts',
  AdmittedCommodityConstructionEquipment = 'AdmittedCommodityConstructionEquipment',
  AdmittedCommodityElectricalCommunicationsEquipment = 'AdmittedCommodityElectricalCommunicationsEquipment',
  AdmittedCommodityMachinery = 'AdmittedCommodityMachinery',
  AdmittedCommodityMedicalAndScientificEquipment = 'AdmittedCommodityMedicalAndScientificEquipment',
  AdmittedCommodityOilfieldEquipment = 'AdmittedCommodityOilfieldEquipment',
  AdmittedCommodityOtherMachineryEquipment = 'AdmittedCommodityOtherMachineryEquipment',
  AdmittedCommodityElectricalSupplies = 'AdmittedCommodityElectricalSupplies',
  AdmittedCommodityLumber = 'AdmittedCommodityLumber',
  AdmittedCommodityPipe = 'AdmittedCommodityPipe',
  AdmittedCommodityPlumbingSupplies = 'AdmittedCommodityPlumbingSupplies',
  AdmittedCommodityTools = 'AdmittedCommodityTools',
  AdmittedCommodityWire = 'AdmittedCommodityWire',
  AdmittedCommodityWoodProductsNotFurnitureAndCaskets = 'AdmittedCommodityWoodProductsNotFurnitureAndCaskets',
  AdmittedCommodityOtherBuildingSupplies = 'AdmittedCommodityOtherBuildingSupplies',
  AdmittedCommodityMobileModularHomes = 'AdmittedCommodityMobileModularHomes',
  AdmittedCommodityAnimalByProducts = 'AdmittedCommodityAnimalByProducts',
  AdmittedCommodityResins = 'AdmittedCommodityResins',
  AdmittedCommodityOtherMiscellaneous = 'AdmittedCommodityOtherMiscellaneous',
  AdmittedCommodityGarbage = 'AdmittedCommodityGarbage',
  AdmittedCommodityBusinessDocumentsNonNegotiableSecurities = 'AdmittedCommodityBusinessDocumentsNonNegotiableSecurities',
}

/**
 *
 * @export
 * @interface AdmittedCommodityConstant
 */
export interface AdmittedCommodityConstant {
  /**
   *
   * @type {AdmittedCommodity}
   * @memberof AdmittedCommodityConstant
   */
  value: AdmittedCommodity;
  /**
   *
   * @type {string}
   * @memberof AdmittedCommodityConstant
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof AdmittedCommodityConstant
   */
  category: string;
}
/**
 *
 * @export
 * @interface AdmittedCommodityRecord
 */
export interface AdmittedCommodityRecord {
  /**
   *
   * @type {string}
   * @memberof AdmittedCommodityRecord
   */
  name: string;
  /**
   *
   * @type {AdmittedCommodity}
   * @memberof AdmittedCommodityRecord
   */
  category: AdmittedCommodity;
  /**
   *
   * @type {string}
   * @memberof AdmittedCommodityRecord
   */
  categoryLabel: string;
  /**
   *
   * @type {number}
   * @memberof AdmittedCommodityRecord
   */
  percentage: number;
}
/**
 *
 * @export
 * @interface AdmittedCommodityWithLabel
 */
export interface AdmittedCommodityWithLabel {
  /**
   *
   * @type {AdmittedCommodity}
   * @memberof AdmittedCommodityWithLabel
   */
  value: AdmittedCommodity;
  /**
   *
   * @type {string}
   * @memberof AdmittedCommodityWithLabel
   */
  label: string;
}
/**
 *
 * @export
 * @interface AdmittedConstants
 */
export interface AdmittedConstants {
  /**
   *
   * @type {Array<AdmittedVehicleTypeWithLabel>}
   * @memberof AdmittedConstants
   */
  vehicleTypes: Array<AdmittedVehicleTypeWithLabel>;
  /**
   *
   * @type {{ [key: string]: Array<AdmittedVehicleClassWithLabel>; }}
   * @memberof AdmittedConstants
   */
  vehicleClassByType: { [key: string]: Array<AdmittedVehicleClassWithLabel> };
  /**
   *
   * @type {Array<AdmittedCommodityConstant>}
   * @memberof AdmittedConstants
   */
  commodities: Array<AdmittedCommodityConstant>;
  /**
   *
   * @type {{ [key: string]: Array<AdmittedCommodityWithLabel>; }}
   * @memberof AdmittedConstants
   */
  commoditiesByCategory: { [key: string]: Array<AdmittedCommodityWithLabel> };
  /**
   *
   * @type {Array<string>}
   * @memberof AdmittedConstants
   */
  categories?: Array<string>;
  /**
   *
   * @type {Array<PaymentPlanWithLabel>}
   * @memberof AdmittedConstants
   */
  paymentPlans: Array<PaymentPlanWithLabel>;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum AdmittedVehicleClass {
  VehicleClassAgricultural = 'VehicleClassAgricultural',
  VehicleClassAutoHauler = 'VehicleClassAutoHauler',
  VehicleClassBulkCommodity = 'VehicleClassBulkCommodity',
  VehicleClassDryFreight = 'VehicleClassDryFreight',
  VehicleClassDump = 'VehicleClassDump',
  VehicleClassFlatbed = 'VehicleClassFlatbed',
  VehicleClassFrontLoader = 'VehicleClassFrontLoader',
  VehicleClassGarbage = 'VehicleClassGarbage',
  VehicleClassLivestock = 'VehicleClassLivestock',
  VehicleClassLogging = 'VehicleClassLogging',
  VehicleClassLowboy = 'VehicleClassLowboy',
  VehicleClassPickupTruck = 'VehicleClassPickupTruck',
  VehicleClassPole = 'VehicleClassPole',
  VehicleClassRagTop = 'VehicleClassRagTop',
  VehicleClassReefer = 'VehicleClassReefer',
  VehicleClassStake = 'VehicleClassStake',
  VehicleClassStraight = 'VehicleClassStraight',
  VehicleClassTank = 'VehicleClassTank',
  VehicleClassTilt = 'VehicleClassTilt',
  VehicleClassTruckTractor = 'VehicleClassTruckTractor',
  VehicleClassUtility = 'VehicleClassUtility',
  VehicleClassUnidentified = 'VehicleClassUnidentified',
  VehicleClassOther = 'VehicleClassOther',
}

/**
 *
 * @export
 * @interface AdmittedVehicleClassWithLabel
 */
export interface AdmittedVehicleClassWithLabel {
  /**
   *
   * @type {AdmittedVehicleClass}
   * @memberof AdmittedVehicleClassWithLabel
   */
  value: AdmittedVehicleClass;
  /**
   *
   * @type {string}
   * @memberof AdmittedVehicleClassWithLabel
   */
  label: string;
}
/**
 *
 * @export
 * @interface AdmittedVehicleTypeWithLabel
 */
export interface AdmittedVehicleTypeWithLabel {
  /**
   *
   * @type {VehicleType}
   * @memberof AdmittedVehicleTypeWithLabel
   */
  value: VehicleType;
  /**
   *
   * @type {string}
   * @memberof AdmittedVehicleTypeWithLabel
   */
  label: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum AppetiteCheckRule {
  AppetiteCheckRuleYearsInOperation = 'AppetiteCheckRuleYearsInOperation',
  AppetiteCheckRuleLapsesInOperation = 'AppetiteCheckRuleLapsesInOperation',
  AppetiteCheckRuleCarrierLoyalty = 'AppetiteCheckRuleCarrierLoyalty',
  AppetiteCheckRuleIsValidOrActive = 'AppetiteCheckRuleIsValidOrActive',
  AppetiteCheckRuleUnitMeasure = 'AppetiteCheckRuleUnitMeasure',
  AppetiteCheckRuleCompanyAddress = 'AppetiteCheckRuleCompanyAddress',
  AppetiteCheckRuleDotRating = 'AppetiteCheckRuleDotRating',
  AppetiteCheckRuleHasUndesiredOperations = 'AppetiteCheckRuleHasUndesiredOperations',
}

/**
 *
 * @export
 * @interface AppetiteCheckRuleResponse
 */
export interface AppetiteCheckRuleResponse {
  /**
   *
   * @type {AppetiteCheckRule}
   * @memberof AppetiteCheckRuleResponse
   */
  rule: AppetiteCheckRule;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof AppetiteCheckRuleResponse
   */
  details: { [key: string]: any };
}
/**
 *
 * @export
 * @interface ApplicationCreationForm
 */
export interface ApplicationCreationForm {
  /**
   *
   * @type {number}
   * @memberof ApplicationCreationForm
   */
  dotNumber: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationCreationForm
   */
  numberOfPowerUnits: number;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationCreationForm
   */
  hasUndesiredOperations: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationCreationForm
   */
  effectiveDate: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationCreationForm
   */
  companyName: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationCreationForm
   */
  isAdmitted: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationCreationForm
   */
  producerID: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationCreationForm
   */
  producerName: string;
}
/**
 *
 * @export
 * @interface ApplicationDetails
 */
export interface ApplicationDetails {
  /**
   *
   * @type {string}
   * @memberof ApplicationDetails
   */
  applicationID?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationDetails
   */
  shortID?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationDetails
   */
  pageState?: string;
  /**
   *
   * @type {ProgramType}
   * @memberof ApplicationDetails
   */
  programType?: ProgramType;
  /**
   *
   * @type {string}
   * @memberof ApplicationDetails
   */
  appStatus?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationDetails
   */
  declineReason?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationDetails
   */
  fmcsaDataPresent?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationDetails
   */
  underwriterName?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationDetails
   */
  underwriterEmail?: string;
  /**
   *
   * @type {NRBApp}
   * @memberof ApplicationDetails
   */
  nrb?: NRBApp;
  /**
   *
   * @type {AdmittedApp}
   * @memberof ApplicationDetails
   */
  admitted?: AdmittedApp;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationDetails
   */
  isOnNewCreditScore?: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum ApplicationTab {
  ApplicationTabAll = 'ApplicationTabAll',
  ApplicationTabInProgress = 'ApplicationTabInProgress',
  ApplicationTabUnderUwReview = 'ApplicationTabUnderUWReview',
  ApplicationTabReadyToBind = 'ApplicationTabReadyToBind',
  ApplicationTabDeclined = 'ApplicationTabDeclined',
  ApplicationTabBound = 'ApplicationTabBound',
  ApplicationTabClosed = 'ApplicationTabClosed',
  ApplicationTabReadyToQuote = 'ApplicationTabReadyToQuote',
}

/**
 *
 * @export
 * @interface ApplicationUpdateForm
 */
export interface ApplicationUpdateForm {
  /**
   *
   * @type {PageState}
   * @memberof ApplicationUpdateForm
   */
  pageState?: PageState;
  /**
   *
   * @type {NRBAppUpdateForm}
   * @memberof ApplicationUpdateForm
   */
  nrb?: NRBAppUpdateForm;
  /**
   *
   * @type {AdmittedAppUpdateForm}
   * @memberof ApplicationUpdateForm
   */
  admitted?: AdmittedAppUpdateForm;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum BusinessStructure {
  BusinessStructureSoleProprietor = 'BusinessStructureSoleProprietor',
  BusinessStructurePartnership = 'BusinessStructurePartnership',
  BusinessStructureCorporation = 'BusinessStructureCorporation',
  BusinessStructureLlc = 'BusinessStructureLLC',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum Category {
  CategoryAppliances = 'CategoryAppliances',
  CategoryAutoParts = 'CategoryAutoParts',
  CategoryBuildingMaterials = 'CategoryBuildingMaterials',
  CategoryChemicals = 'CategoryChemicals',
  CategoryComputers = 'CategoryComputers',
  CategoryCosmetics = 'CategoryCosmetics',
  CategoryDryFreight = 'CategoryDryFreight',
  CategoryDvdCd = 'CategoryDVD_CD',
  CategoryFrozenFoods = 'CategoryFrozenFoods',
  CategoryFurnitureNew = 'CategoryFurnitureNew',
  CategoryLumberFinished = 'CategoryLumberFinished',
  CategoryMachineryLightHeavy = 'CategoryMachineryLightHeavy',
  CategoryMeat = 'CategoryMeat',
  CategoryMilkDairyProducts = 'CategoryMilkDairyProducts',
  CategoryPoultryDressed = 'CategoryPoultryDressed',
  CategoryProduceNonRefrigerated = 'CategoryProduceNonRefrigerated',
  CategorySportingGoods = 'CategorySportingGoods',
  CategoryTires = 'CategoryTires',
  CategoryTools = 'CategoryTools',
  CategoryToys = 'CategoryToys',
  CategoryAlcoholBeerWine = 'CategoryAlcoholBeerWine',
  CategoryElectronics = 'CategoryElectronics',
  CategoryFurs = 'CategoryFurs',
  CategoryGarments = 'CategoryGarments',
  CategoryNonFerrousMetals = 'CategoryNonFerrousMetals',
  CategorySolarPanels = 'CategorySolarPanels',
  CategoryBulkBaggedNuts = 'CategoryBulkBaggedNuts',
  CategoryPharmaceuticals = 'CategoryPharmaceuticals',
  CategorySeafoodUnlessCanned = 'CategorySeafoodUnlessCanned',
  CategoryTobacco = 'CategoryTobacco',
}

/**
 *
 * @export
 * @interface CategoryWithLabel
 */
export interface CategoryWithLabel {
  /**
   *
   * @type {Category}
   * @memberof CategoryWithLabel
   */
  name: Category;
  /**
   *
   * @type {string}
   * @memberof CategoryWithLabel
   */
  label: string;
}
/**
 *
 * @export
 * @interface ClassInfo
 */
export interface ClassInfo {
  /**
   *
   * @type {boolean}
   * @memberof ClassInfo
   */
  ownsRestrictedClasses?: boolean;
  /**
   *
   * @type {OperatingClass}
   * @memberof ClassInfo
   */
  primaryOperatingClass?: OperatingClass;
}
/**
 *
 * @export
 * @interface ClassWithLabel
 */
export interface ClassWithLabel {
  /**
   *
   * @type {OperatingClass}
   * @memberof ClassWithLabel
   */
  name: OperatingClass;
  /**
   *
   * @type {string}
   * @memberof ClassWithLabel
   */
  label: string;
}
/**
 *
 * @export
 * @interface ClassesAndCommodities
 */
export interface ClassesAndCommodities {
  /**
   *
   * @type {Array<ClassWithLabel>}
   * @memberof ClassesAndCommodities
   */
  classes: Array<ClassWithLabel>;
  /**
   *
   * @type {Array<CategoryWithLabel>}
   * @memberof ClassesAndCommodities
   */
  commodities: Array<CategoryWithLabel>;
}
/**
 *
 * @export
 * @interface ClassesAndCommoditiesForm
 */
export interface ClassesAndCommoditiesForm {
  /**
   *
   * @type {ClassInfo}
   * @memberof ClassesAndCommoditiesForm
   */
  classInfo?: ClassInfo;
  /**
   *
   * @type {CommodityInfo}
   * @memberof ClassesAndCommoditiesForm
   */
  commodityInfo?: CommodityInfo;
}
/**
 *
 * @export
 * @interface CommodityInfo
 */
export interface CommodityInfo {
  /**
   *
   * @type {boolean}
   * @memberof CommodityInfo
   */
  haulsRestrictedCommodities?: boolean;
  /**
   *
   * @type {Array<CommodityRecord>}
   * @memberof CommodityInfo
   */
  commodityDistribution?: Array<CommodityRecord>;
}
/**
 *
 * @export
 * @interface CommodityRecord
 */
export interface CommodityRecord {
  /**
   *
   * @type {string}
   * @memberof CommodityRecord
   */
  name: string;
  /**
   *
   * @type {Category}
   * @memberof CommodityRecord
   */
  category: Category;
  /**
   *
   * @type {string}
   * @memberof CommodityRecord
   */
  categoryLabel: string;
  /**
   *
   * @type {number}
   * @memberof CommodityRecord
   */
  percentage: number;
}
/**
 *
 * @export
 * @interface CompanyInfo
 */
export interface CompanyInfo {
  /**
   *
   * @type {string}
   * @memberof CompanyInfo
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof CompanyInfo
   */
  dotNumber?: number;
  /**
   *
   * @type {string}
   * @memberof CompanyInfo
   */
  usState?: string;
  /**
   *
   * @type {number}
   * @memberof CompanyInfo
   */
  numberOfPowerUnits?: number;
  /**
   *
   * @type {number}
   * @memberof CompanyInfo
   */
  annualCostOfHire?: number;
  /**
   *
   * @type {Address}
   * @memberof CompanyInfo
   */
  address?: Address;
  /**
   *
   * @type {Address}
   * @memberof CompanyInfo
   */
  mailingAddress?: Address;
  /**
   *
   * @type {BusinessStructure}
   * @memberof CompanyInfo
   */
  businessStructure?: BusinessStructure;
}
/**
 *
 * @export
 * @interface CoverageDetails
 */
export interface CoverageDetails {
  /**
   *
   * @type {CoverageType}
   * @memberof CoverageDetails
   */
  coverageType: CoverageType;
  /**
   *
   * @type {string}
   * @memberof CoverageDetails
   */
  label: string;
  /**
   *
   * @type {number}
   * @memberof CoverageDetails
   */
  limit?: number;
  /**
   *
   * @type {number}
   * @memberof CoverageDetails
   */
  deductible?: number;
  /**
   *
   * @type {number}
   * @memberof CoverageDetails
   */
  premium?: number;
  /**
   *
   * @type {number}
   * @memberof CoverageDetails
   */
  premiumPerUnit?: number;
  /**
   *
   * @type {boolean}
   * @memberof CoverageDetails
   */
  isRequired: boolean;
  /**
   *
   * @type {number}
   * @memberof CoverageDetails
   */
  premiumNew?: number;
}
/**
 *
 * @export
 * @interface CoverageInfo
 */
export interface CoverageInfo {
  /**
   *
   * @type {Array<CoverageDetails>}
   * @memberof CoverageInfo
   */
  primaryCovs?: Array<CoverageDetails>;
  /**
   *
   * @type {Array<CoverageDetails>}
   * @memberof CoverageInfo
   */
  ancillaryCovs?: Array<CoverageDetails>;
}
/**
 *
 * @export
 * @interface CoverageOption
 */
export interface CoverageOption {
  /**
   *
   * @type {CoverageType}
   * @memberof CoverageOption
   */
  coverage: CoverageType;
  /**
   *
   * @type {boolean}
   * @memberof CoverageOption
   */
  isRequired: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof CoverageOption
   */
  limitOptions?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CoverageOption
   */
  deductibleOptions?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof CoverageOption
   */
  defaultLimit?: number;
  /**
   *
   * @type {number}
   * @memberof CoverageOption
   */
  limit?: number;
  /**
   *
   * @type {number}
   * @memberof CoverageOption
   */
  defaultDeductible?: number;
  /**
   *
   * @type {number}
   * @memberof CoverageOption
   */
  deductible?: number;
}
/**
 *
 * @export
 * @interface CoverageOptionMetadata
 */
export interface CoverageOptionMetadata {
  /**
   *
   * @type {Array<CoverageType>}
   * @memberof CoverageOptionMetadata
   */
  mandatoryCoverages: Array<CoverageType>;
  /**
   *
   * @type {boolean}
   * @memberof CoverageOptionMetadata
   */
  hasReefer: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof CoverageOptionMetadata
   */
  targetCommodities?: Array<string>;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum CoverageType {
  CoverageAutoLiability = 'CoverageAutoLiability',
  CoverageAutoPhysicalDamage = 'CoverageAutoPhysicalDamage',
  CoverageGeneralLiability = 'CoverageGeneralLiability',
  CoverageMotorTruckCargo = 'CoverageMotorTruckCargo',
  CoverageTrailerInterchange = 'CoverageTrailerInterchange',
  CoverageUninsuredMotoristBodilyInjury = 'CoverageUninsuredMotoristBodilyInjury',
  CoverageUnderinsuredMotoristBodilyInjury = 'CoverageUnderinsuredMotoristBodilyInjury',
  CoverageUninsuredMotoristPropertyDamage = 'CoverageUninsuredMotoristPropertyDamage',
  CoverageUnderinsuredMotoristPropertyDamage = 'CoverageUnderinsuredMotoristPropertyDamage',
  CoveragePersonalInjuryProtection = 'CoveragePersonalInjuryProtection',
  CoverageMedicalPayments = 'CoverageMedicalPayments',
  CoverageUmuimBodilyInjury = 'CoverageUMUIMBodilyInjury',
  CoverageUmuimPropertyDamage = 'CoverageUMUIMPropertyDamage',
  CoveragePropertyProtectionInsurance = 'CoveragePropertyProtectionInsurance',
  CoveragePersonalInjuryProtectionBasic = 'CoveragePersonalInjuryProtectionBasic',
  CoveragePersonalInjuryProtectionIncreased = 'CoveragePersonalInjuryProtectionIncreased',
  CoveragePipExcessAttendantCare = 'CoveragePIPExcessAttendantCare',
  CoverageReefer = 'CoverageReefer',
  CoverageReeferWithHumanError = 'CoverageReeferWithHumanError',
  CoverageEnhancedPackageTowingLimit = 'CoverageEnhancedPackageTowingLimit',
  CoverageGuestPersonalInjuryProtection = 'CoverageGuestPersonalInjuryProtection',
  CoverageReeferWithoutHumanError = 'CoverageReeferWithoutHumanError',
  CoverageStopGap = 'CoverageStopGap',
  CoverageBroadenedPollution = 'CoverageBroadenedPollution',
  CoverageBlanketAdditional = 'CoverageBlanketAdditional',
  CoverageUiia = 'CoverageUIIA',
  CoverageBlanketWaiverOfSubrogation = 'CoverageBlanketWaiverOfSubrogation',
  CoverageGlBlanketWaiverOfSubrogation = 'CoverageGLBlanketWaiverOfSubrogation',
  CoverageGlBlanketAdditional = 'CoverageGLBlanketAdditional',
  CoverageMtcBlanketWaiverOfSubrogation = 'CoverageMTCBlanketWaiverOfSubrogation',
  CoverageMtcBlanketAdditional = 'CoverageMTCBlanketAdditional',
  CoverageUmuim = 'CoverageUMUIM',
  CoverageUmbiuimbi = 'CoverageUMBIUIMBI',
  CoverageUm = 'CoverageUM',
  CoverageUim = 'CoverageUIM',
  CoverageTerrorism = 'CoverageTerrorism',
  CoverageDebrisRemoval = 'CoverageDebrisRemoval',
  CoverageNonOwnedTrailer = 'CoverageNonOwnedTrailer',
  CoverageApduiia = 'CoverageAPDUIIA',
  CoverageMtcuiia = 'CoverageMTCUIIA',
  CoverageApdTrailerInterchange = 'CoverageAPDTrailerInterchange',
  CoverageMtcTrailerInterchange = 'CoverageMTCTrailerInterchange',
  CoverageApdNonOwnedTrailer = 'CoverageAPDNonOwnedTrailer',
  CoverageMtcNonOwnedTrailer = 'CoverageMTCNonOwnedTrailer',
  CoverageUnattendedTruck = 'CoverageUnattendedTruck',
  CoverageEarnedFreight = 'CoverageEarnedFreight',
  CoverageRentalReimbursement = 'CoverageRentalReimbursement',
  CoverageTowingLaborAndStorage = 'CoverageTowingLaborAndStorage',
  CoverageHiredAuto = 'CoverageHiredAuto',
}

/**
 *
 * @export
 * @interface CoveragesForm
 */
export interface CoveragesForm {
  /**
   *
   * @type {{ [key: string]: CoverageDetails; }}
   * @memberof CoveragesForm
   */
  primaryCovs?: { [key: string]: CoverageDetails };
  /**
   *
   * @type {{ [key: string]: CoverageDetails; }}
   * @memberof CoveragesForm
   */
  ancillaryCovs?: { [key: string]: CoverageDetails };
}
/**
 *
 * @export
 * @interface CreatedApplicationResponse
 */
export interface CreatedApplicationResponse {
  /**
   *
   * @type {string}
   * @memberof CreatedApplicationResponse
   */
  applicationID: string;
  /**
   *
   * @type {Flags}
   * @memberof CreatedApplicationResponse
   */
  flags?: Flags;
}
/**
 *
 * @export
 * @interface DecodeVinResponse
 */
export interface DecodeVinResponse {
  /**
   *
   * @type {string}
   * @memberof DecodeVinResponse
   */
  vin: string;
  /**
   *
   * @type {boolean}
   * @memberof DecodeVinResponse
   */
  hasDecodingError: boolean;
}
/**
 *
 * @export
 * @interface DriverDetails
 */
export interface DriverDetails {
  /**
   *
   * @type {string}
   * @memberof DriverDetails
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof DriverDetails
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof DriverDetails
   */
  licenseNumber: string;
  /**
   *
   * @type {string}
   * @memberof DriverDetails
   */
  licenseState: string;
  /**
   *
   * @type {number}
   * @memberof DriverDetails
   */
  licenseIssueYear: number;
  /**
   *
   * @type {string}
   * @memberof DriverDetails
   */
  dateOfBirth: string;
  /**
   *
   * @type {boolean}
   * @memberof DriverDetails
   */
  ownerOperator: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DriverDetails
   */
  isIncluded: boolean;
  /**
   *
   * @type {number}
   * @memberof DriverDetails
   */
  cdlYearsOfExperience?: number;
}
/**
 *
 * @export
 * @interface DriverInfo
 */
export interface DriverInfo {
  /**
   *
   * @type {Array<DriverDetails>}
   * @memberof DriverInfo
   */
  drivers?: Array<DriverDetails>;
}
/**
 *
 * @export
 * @interface DuplicateApplicationDetails
 */
export interface DuplicateApplicationDetails {
  /**
   *
   * @type {string}
   * @memberof DuplicateApplicationDetails
   */
  existingApplicationId: string;
  /**
   *
   * @type {string}
   * @memberof DuplicateApplicationDetails
   */
  existingApplicationStatus: string;
  /**
   *
   * @type {boolean}
   * @memberof DuplicateApplicationDetails
   */
  isRenewal: boolean;
}
/**
 *
 * @export
 * @interface Email
 */
export interface Email {
  /**
   *
   * @type {string}
   * @memberof Email
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Email
   */
  email: string;
}
/**
 *
 * @export
 * @interface EquipmentInfo
 */
export interface EquipmentInfo {
  /**
   *
   * @type {Array<VehicleDetails>}
   * @memberof EquipmentInfo
   */
  vehicles?: Array<VehicleDetails>;
}
/**
 *
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
  /**
   *
   * @type {string}
   * @memberof ErrorMessage
   */
  message: string;
  /**
   *
   * @type {number}
   * @memberof ErrorMessage
   */
  code: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum FileDestinationGroup {
  FileDestinationGroupQuoting = 'FileDestinationGroupQuoting',
  FileDestinationGroupUnderwriting = 'FileDestinationGroupUnderwriting',
  FileDestinationGroupForms = 'FileDestinationGroupForms',
  FileDestinationGroupClaims = 'FileDestinationGroupClaims',
}

/**
 *
 * @export
 * @interface FileMetadata
 */
export interface FileMetadata {
  /**
   *
   * @type {string}
   * @memberof FileMetadata
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof FileMetadata
   */
  handle?: string;
  /**
   *
   * @type {FileType}
   * @memberof FileMetadata
   */
  FileType?: FileType;
  /**
   *
   * @type {FileDestinationGroup}
   * @memberof FileMetadata
   */
  FileDestinationGroup?: FileDestinationGroup;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum FileType {
  FileTypeEquipmentList = 'FileTypeEquipmentList',
  FileTypeDriversList = 'FileTypeDriversList',
  FileTypeLossRun = 'FileTypeLossRun',
  FileTypeUwDocument = 'FileTypeUwDocument',
  FileTypePdfForms = 'FileTypePDFForms',
  FileTypeIftaFile = 'FileTypeIFTAFile',
  FileTypeClaimDocument = 'FileTypeClaimDocument',
  FileTypeAgentAdditionalFiles = 'FileTypeAgentAdditionalFiles',
}

/**
 *
 * @export
 * @interface FinancialInfo
 */
export interface FinancialInfo {
  /**
   *
   * @type {boolean}
   * @memberof FinancialInfo
   */
  recentBankruptcies?: boolean;
}
/**
 *
 * @export
 * @interface Flags
 */
export interface Flags {
  /**
   *
   * @type {boolean}
   * @memberof Flags
   */
  clearanceConflict: boolean;
}
/**
 *
 * @export
 * @interface FormRecord
 */
export interface FormRecord {
  /**
   *
   * @type {string}
   * @memberof FormRecord
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof FormRecord
   */
  name: string;
  /**
   * template url
   * @type {string}
   * @memberof FormRecord
   */
  url: string;
  /**
   * filled PDF file handle
   * @type {string}
   * @memberof FormRecord
   */
  handle?: string;
  /**
   *
   * @type {OrderCategory}
   * @memberof FormRecord
   */
  orderCategory: OrderCategory;
  /**
   * frozen PDF file handle
   * @type {string}
   * @memberof FormRecord
   */
  frozenHandleId?: string;
  /**
   *
   * @type {boolean}
   * @memberof FormRecord
   */
  isDynamic: boolean;
  /**
   *
   * @type {CoverageType}
   * @memberof FormRecord
   */
  coverage: CoverageType;
  /**
   *
   * @type {string}
   * @memberof FormRecord
   */
  UserVisibleCode: string;
}
/**
 *
 * @export
 * @interface GetAdmittedAppIndicationResponse
 */
export interface GetAdmittedAppIndicationResponse {
  /**
   *
   * @type {string}
   * @memberof GetAdmittedAppIndicationResponse
   */
  appStatus?: string;
  /**
   *
   * @type {JobRunStatus}
   * @memberof GetAdmittedAppIndicationResponse
   */
  status: JobRunStatus;
  /**
   *
   * @type {Array<CoverageOption>}
   * @memberof GetAdmittedAppIndicationResponse
   */
  options?: Array<CoverageOption>;
  /**
   *
   * @type {Array<AdmittedAppPackages>}
   * @memberof GetAdmittedAppIndicationResponse
   */
  packages?: Array<AdmittedAppPackages>;
  /**
   *
   * @type {boolean}
   * @memberof GetAdmittedAppIndicationResponse
   */
  isGLRemoved: boolean;
}
/**
 *
 * @export
 * @interface GetApplicationListResponse
 */
export interface GetApplicationListResponse {
  /**
   *
   * @type {Array<ApplicationDetails>}
   * @memberof GetApplicationListResponse
   */
  data: Array<ApplicationDetails>;
}
/**
 *
 * @export
 * @interface GetApplicationsResponse
 */
export interface GetApplicationsResponse {
  /**
   *
   * @type {Array<ApplicationDetails>}
   * @memberof GetApplicationsResponse
   */
  data: Array<ApplicationDetails>;
  /**
   *
   * @type {string}
   * @memberof GetApplicationsResponse
   */
  cursor?: string;
  /**
   *
   * @type {number}
   * @memberof GetApplicationsResponse
   */
  totalCount: number;
}
/**
 *
 * @export
 * @interface GetBindableQuoteResponse
 */
export interface GetBindableQuoteResponse {
  /**
   *
   * @type {QuoteOption}
   * @memberof GetBindableQuoteResponse
   */
  quote: QuoteOption;
  /**
   *
   * @type {ApplicationDetails}
   * @memberof GetBindableQuoteResponse
   */
  appDetails: ApplicationDetails;
  /**
   *
   * @type {string}
   * @memberof GetBindableQuoteResponse
   */
  quotePDF: string;
  /**
   *
   * @type {string}
   * @memberof GetBindableQuoteResponse
   */
  signaturePacket: string;
  /**
   *
   * @type {string}
   * @memberof GetBindableQuoteResponse
   */
  signaturePacketZip: string;
  /**
   *
   * @type {string}
   * @memberof GetBindableQuoteResponse
   */
  lastUpdatedAt?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof GetBindableQuoteResponse
   */
  excludedDrivers?: Array<string>;
  /**
   *
   * @type {Array<FormRecord>}
   * @memberof GetBindableQuoteResponse
   */
  formDetails: Array<FormRecord>;
}
/**
 *
 * @export
 * @interface GetQuoteResponse
 */
export interface GetQuoteResponse {
  /**
   *
   * @type {string}
   * @memberof GetQuoteResponse
   */
  appStatus?: string;
  /**
   *
   * @type {JobRunStatus}
   * @memberof GetQuoteResponse
   */
  status: JobRunStatus;
  /**
   *
   * @type {QuoteOption}
   * @memberof GetQuoteResponse
   */
  quote?: QuoteOption;
  /**
   *
   * @type {Array<CoverageOption>}
   * @memberof GetQuoteResponse
   */
  options?: Array<CoverageOption>;
  /**
   *
   * @type {CoverageOptionMetadata}
   * @memberof GetQuoteResponse
   */
  metadata?: CoverageOptionMetadata;
  /**
   *
   * @type {PageState}
   * @memberof GetQuoteResponse
   */
  pageState?: PageState;
}
/**
 *
 * @export
 * @interface GetVehicleInfoResponse
 */
export interface GetVehicleInfoResponse {
  /**
   *
   * @type {Array<VehicleInfo>}
   * @memberof GetVehicleInfoResponse
   */
  data: Array<VehicleInfo>;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum IndicationOptionTag {
  IndicationOptionTagBasic = 'IndicationOptionTagBasic',
  IndicationOptionTagStandard = 'IndicationOptionTagStandard',
  IndicationOptionTagComplete = 'IndicationOptionTagComplete',
  IndicationOptionTagCustom = 'IndicationOptionTagCustom',
}

/**
 *
 * @export
 * @interface InsuredData
 */
export interface InsuredData {
  /**
   *
   * @type {string}
   * @memberof InsuredData
   */
  insuredName: string;
  /**
   *
   * @type {string}
   * @memberof InsuredData
   */
  streetAddress: string;
  /**
   *
   * @type {string}
   * @memberof InsuredData
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof InsuredData
   */
  state: string;
  /**
   *
   * @type {string}
   * @memberof InsuredData
   */
  zipCode: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum JobRunStatus {
  Running = 'running',
  Failure = 'failure',
  Success = 'success',
  Unknown = 'unknown',
}

/**
 *
 * @export
 * @interface LossInfo
 */
export interface LossInfo {
  /**
   *
   * @type {boolean}
   * @memberof LossInfo
   */
  hasLosses?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LossInfo
   */
  hasLossesOver20k?: boolean;
  /**
   *
   * @type {Array<FileMetadata>}
   * @memberof LossInfo
   */
  lossRunFiles?: Array<FileMetadata>;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum MaxRadiusOfOperation {
  MaxRadiusOfOperation50 = 'MaxRadiusOfOperation50',
  MaxRadiusOfOperation100 = 'MaxRadiusOfOperation100',
  MaxRadiusOfOperation200 = 'MaxRadiusOfOperation200',
  MaxRadiusOfOperation300 = 'MaxRadiusOfOperation300',
  MaxRadiusOfOperation500 = 'MaxRadiusOfOperation500',
  MaxRadiusOfOperation999plus = 'MaxRadiusOfOperation999plus',
}

/**
 *
 * @export
 * @interface NRBApp
 */
export interface NRBApp {
  /**
   *
   * @type {OperationsForm}
   * @memberof NRBApp
   */
  operationsForm?: OperationsForm;
  /**
   *
   * @type {ClassesAndCommoditiesForm}
   * @memberof NRBApp
   */
  classesAndCommoditiesForm?: ClassesAndCommoditiesForm;
  /**
   *
   * @type {EquipmentInfo}
   * @memberof NRBApp
   */
  equipmentsForm?: EquipmentInfo;
  /**
   *
   * @type {DriverInfo}
   * @memberof NRBApp
   */
  driversForm?: DriverInfo;
  /**
   *
   * @type {CoveragesForm}
   * @memberof NRBApp
   */
  coveragesForm?: CoveragesForm;
}
/**
 *
 * @export
 * @interface NRBAppUpdateForm
 */
export interface NRBAppUpdateForm {
  /**
   *
   * @type {OperationsForm}
   * @memberof NRBAppUpdateForm
   */
  operationsForm?: OperationsForm;
  /**
   *
   * @type {ClassesAndCommoditiesForm}
   * @memberof NRBAppUpdateForm
   */
  classesAndCommoditiesForm?: ClassesAndCommoditiesForm;
  /**
   *
   * @type {EquipmentInfo}
   * @memberof NRBAppUpdateForm
   */
  equipmentsForm?: EquipmentInfo;
  /**
   *
   * @type {DriverInfo}
   * @memberof NRBAppUpdateForm
   */
  driversForm?: DriverInfo;
  /**
   *
   * @type {CoveragesForm}
   * @memberof NRBAppUpdateForm
   */
  coveragesForm?: CoveragesForm;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum NRBVehicleType {
  Trailer = 'Trailer',
  Truck = 'Truck',
  PickupTruck = 'Pickup Truck',
  Unknown = 'Unknown',
  Tractor = 'Tractor',
  SemiTrailer = 'Semi_Trailer',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum OperatingClass {
  OperatingClassDump = 'OperatingClassDump',
  OperatingClassDryVan = 'OperatingClassDryVan',
  OperatingClassIntermodal = 'OperatingClassIntermodal',
  OperatingClassTanker = 'OperatingClassTanker',
  OperatingClassRefrigerated = 'OperatingClassRefrigerated',
  OperatingClassHeavyHaul = 'OperatingClassHeavyHaul',
  OperatingClassFlatbed = 'OperatingClassFlatbed',
  OperatingClassHazmat = 'OperatingClassHazmat',
}

/**
 *
 * @export
 * @interface OperationsForm
 */
export interface OperationsForm {
  /**
   *
   * @type {string}
   * @memberof OperationsForm
   */
  producerId?: string;
  /**
   *
   * @type {string}
   * @memberof OperationsForm
   */
  producerName?: string;
  /**
   *
   * @type {string}
   * @memberof OperationsForm
   */
  effectiveDate?: string;
  /**
   *
   * @type {CoverageInfo}
   * @memberof OperationsForm
   */
  coverageInfo?: CoverageInfo;
  /**
   *
   * @type {CompanyInfo}
   * @memberof OperationsForm
   */
  companyInfo?: CompanyInfo;
  /**
   *
   * @type {FinancialInfo}
   * @memberof OperationsForm
   */
  financialInfo?: FinancialInfo;
  /**
   *
   * @type {LossInfo}
   * @memberof OperationsForm
   */
  lossInfo?: LossInfo;
  /**
   *
   * @type {boolean}
   * @memberof OperationsForm
   */
  seriousDriverViolations?: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum OrderCategory {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  O = 'O',
  P = 'P',
  Q = 'Q',
  R = 'R',
  S = 'S',
  T = 'T',
  U = 'U',
  V = 'V',
  W = 'W',
  X = 'X',
  Y = 'Y',
  Z = 'Z',
  Za = 'ZA',
  Zb = 'ZB',
  Zc = 'ZC',
  Zd = 'ZD',
  Ze = 'ZE',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum PageState {
  PageStateUnsubmitted = 'PageStateUnsubmitted',
  PageStateOperations = 'PageStateOperations',
  PageStateClassesAndCommodities = 'PageStateClassesAndCommodities',
  PageStateEquipments = 'PageStateEquipments',
  PageStateDrivers = 'PageStateDrivers',
  PageStateSubmitted = 'PageStateSubmitted',
  PageStateRecalculateQuote = 'PageStateRecalculateQuote',
  PageStateDecline = 'PageStateDecline',
  PageStateReferral = 'PageStateReferral',
  PageStateReview = 'PageStateReview',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum PaymentPlan {
  PaymentPlanInstallmentWithEft = 'PaymentPlanInstallmentWithEFT',
  PaymentPlanPaidInFull = 'PaymentPlanPaidInFull',
}

/**
 *
 * @export
 * @interface PaymentPlanWithLabel
 */
export interface PaymentPlanWithLabel {
  /**
   *
   * @type {PaymentPlan}
   * @memberof PaymentPlanWithLabel
   */
  value: PaymentPlan;
  /**
   *
   * @type {string}
   * @memberof PaymentPlanWithLabel
   */
  label: string;
}
/**
 *
 * @export
 * @interface PotentialClearanceDetails
 */
export interface PotentialClearanceDetails {
  /**
   *
   * @type {PotentialClearanceStatus}
   * @memberof PotentialClearanceDetails
   */
  status: PotentialClearanceStatus;
  /**
   *
   * @type {boolean}
   * @memberof PotentialClearanceDetails
   */
  isSameAgency: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum PotentialClearanceStatus {
  ClearedApplicationExists = 'ClearedApplicationExists',
  ClearedApplicationExistsSameAgency = 'ClearedApplicationExistsSameAgency',
  DeclinedClearedApplicationExists = 'DeclinedClearedApplicationExists',
}

/**
 *
 * @export
 * @interface PotentialRenewalDetails
 */
export interface PotentialRenewalDetails {
  /**
   *
   * @type {string}
   * @memberof PotentialRenewalDetails
   */
  applicationExpiryDate: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum PreApplicationCheckRule {
  PreApplicationRuleDuplicateApplication = 'PreApplicationRuleDuplicateApplication',
  PreApplicationRulePotentialClearance = 'PreApplicationRulePotentialClearance',
  PreApplicationRulePossibleRenewal = 'PreApplicationRulePossibleRenewal',
  PreApplicationRulePossibleMidTermMove = 'PreApplicationRulePossibleMidTermMove',
  PreApplicationRuleSameAgencyRenewal = 'PreApplicationRuleSameAgencyRenewal',
}

/**
 *
 * @export
 * @interface PreApplicationChecksRuleResponse
 */
export interface PreApplicationChecksRuleResponse {
  /**
   *
   * @type {PreApplicationCheckRule}
   * @memberof PreApplicationChecksRuleResponse
   */
  rule: PreApplicationCheckRule;
  /**
   *
   * @type {DuplicateApplicationDetails | PotentialClearanceDetails | PotentialRenewalDetails}
   * @memberof PreApplicationChecksRuleResponse
   */
  details:
    | DuplicateApplicationDetails
    | PotentialClearanceDetails
    | PotentialRenewalDetails;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum ProgramType {
  ProgramTypeFleet = 'ProgramTypeFleet',
  ProgramTypeNonFleetCanopiusNrb = 'ProgramTypeNonFleetCanopiusNRB',
  ProgramTypeNonFleetAdmitted = 'ProgramTypeNonFleetAdmitted',
  ProgramTypeInvalid = 'ProgramTypeInvalid',
}

/**
 *
 * @export
 * @interface QuoteOption
 */
export interface QuoteOption {
  /**
   *
   * @type {string}
   * @memberof QuoteOption
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof QuoteOption
   */
  totalPremium: number;
  /**
   *
   * @type {Array<CoverageDetails>}
   * @memberof QuoteOption
   */
  coverages: Array<CoverageDetails>;
  /**
   *
   * @type {number}
   * @memberof QuoteOption
   */
  totalPowerUnits: number;
  /**
   *
   * @type {number}
   * @memberof QuoteOption
   */
  TIV: number;
  /**
   *
   * @type {number}
   * @memberof QuoteOption
   */
  TIVPercentage: number;
  /**
   *
   * @type {number}
   * @memberof QuoteOption
   */
  subTotalPremium: number;
  /**
   *
   * @type {number}
   * @memberof QuoteOption
   */
  flatCharges: number;
  /**
   *
   * @type {number}
   * @memberof QuoteOption
   */
  stateSurcharge: number;
  /**
   *
   * @type {number}
   * @memberof QuoteOption
   */
  safetyDiscountAmount: number;
}
/**
 *
 * @export
 * @interface RejectedApplicationResponse
 */
export interface RejectedApplicationResponse {
  /**
   *
   * @type {Array<AppetiteCheckRuleResponse>}
   * @memberof RejectedApplicationResponse
   */
  declinedRules: Array<AppetiteCheckRuleResponse>;
}
/**
 *
 * @export
 * @interface StatusTracker
 */
export interface StatusTracker {
  /**
   *
   * @type {StatusTrackerRecord}
   * @memberof StatusTracker
   */
  statusSubmitted: StatusTrackerRecord;
  /**
   *
   * @type {StatusTrackerRecord}
   * @memberof StatusTracker
   */
  statusWaitingELDTelematicsConsent: StatusTrackerRecord;
  /**
   *
   * @type {StatusTrackerRecord}
   * @memberof StatusTracker
   */
  statusWaitingELDTelematicsData: StatusTrackerRecord;
  /**
   *
   * @type {StatusTrackerRecord}
   * @memberof StatusTracker
   */
  statusUnderReviewForQuote: StatusTrackerRecord;
}
/**
 *
 * @export
 * @interface StatusTrackerRecord
 */
export interface StatusTrackerRecord {
  /**
   *
   * @type {string}
   * @memberof StatusTrackerRecord
   */
  finalizedDate?: string;
  /**
   *
   * @type {number}
   * @memberof StatusTrackerRecord
   */
  minEstimatedDays?: number;
  /**
   *
   * @type {number}
   * @memberof StatusTrackerRecord
   */
  maxEstimatedDays?: number;
}
/**
 *
 * @export
 * @interface TelematicsConsentRequestEmail
 */
export interface TelematicsConsentRequestEmail {
  /**
   * List of email addresses to send the consent link email to.
   * @type {Array<Email>}
   * @memberof TelematicsConsentRequestEmail
   */
  to: Array<Email>;
  /**
   * List of email addresses to CC the consent link email to.
   * @type {Array<Email>}
   * @memberof TelematicsConsentRequestEmail
   */
  cc?: Array<Email>;
}
/**
 * Mark the consent request email task as completed, and optionally send a request email to the insured.
 * @export
 * @interface TelematicsConsentRequestEmailData
 */
export interface TelematicsConsentRequestEmailData {
  /**
   *
   * @type {boolean}
   * @memberof TelematicsConsentRequestEmailData
   */
  completed: boolean;
  /**
   *
   * @type {TelematicsConsentRequestEmail}
   * @memberof TelematicsConsentRequestEmailData
   */
  email?: TelematicsConsentRequestEmail;
}
/**
 *
 * @export
 * @interface TelematicsConsentRequestEmailResponse
 */
export interface TelematicsConsentRequestEmailResponse {
  /**
   *
   * @type {boolean}
   * @memberof TelematicsConsentRequestEmailResponse
   */
  completed: boolean;
  /**
   *
   * @type {string}
   * @memberof TelematicsConsentRequestEmailResponse
   */
  completedAt?: string;
}
/**
 *
 * @export
 * @interface TelematicsInfo
 */
export interface TelematicsInfo {
  /**
   *
   * @type {string}
   * @memberof TelematicsInfo
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TelematicsInfo
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof TelematicsInfo
   */
  linkEmailedAt?: string;
  /**
   *
   * @type {string}
   * @memberof TelematicsInfo
   */
  link: string;
}
/**
 *
 * @export
 * @interface VehicleDetails
 */
export interface VehicleDetails {
  /**
   *
   * @type {string}
   * @memberof VehicleDetails
   */
  vin: string;
  /**
   *
   * @type {string}
   * @memberof VehicleDetails
   */
  vehicleType: string;
  /**
   *
   * @type {string}
   * @memberof VehicleDetails
   */
  weightClass?: string;
  /**
   *
   * @type {number}
   * @memberof VehicleDetails
   */
  year: number;
  /**
   *
   * @type {string}
   * @memberof VehicleDetails
   */
  make: string;
  /**
   *
   * @type {string}
   * @memberof VehicleDetails
   */
  model: string;
  /**
   *
   * @type {string}
   * @memberof VehicleDetails
   */
  parkingLocationZIP: string;
  /**
   *
   * @type {number}
   * @memberof VehicleDetails
   */
  statedValue: number;
  /**
   *
   * @type {string}
   * @memberof VehicleDetails
   */
  ownershipType: string;
  /**
   *
   * @type {string}
   * @memberof VehicleDetails
   */
  class?: string;
}
/**
 *
 * @export
 * @interface VehicleInfo
 */
export interface VehicleInfo {
  /**
   *
   * @type {string}
   * @memberof VehicleInfo
   */
  make: string;
  /**
   *
   * @type {string}
   * @memberof VehicleInfo
   */
  model: string;
  /**
   *
   * @type {string}
   * @memberof VehicleInfo
   */
  modelYear: string;
  /**
   *
   * @type {string}
   * @memberof VehicleInfo
   */
  weightClass?: string;
  /**
   *
   * @type {NRBVehicleType}
   * @memberof VehicleInfo
   */
  type: NRBVehicleType;
  /**
   *
   * @type {VehicleType}
   * @memberof VehicleInfo
   */
  typeAdmitted: VehicleType;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum VehicleType {
  VehicleTypeTruck = 'VehicleTypeTruck',
  VehicleTypeTractor = 'VehicleTypeTractor',
  VehicleTypeTrailer = 'VehicleTypeTrailer',
  VehicleTypeSemiTrailer = 'VehicleTypeSemiTrailer',
  VehicleTypeSpareSemiTrailer = 'VehicleTypeSpareSemiTrailer',
  VehicleTypeNonOwnedSemiTrailer = 'VehicleTypeNonOwnedSemiTrailer',
  VehicleTypePickup = 'VehicleTypePickup',
  VehicleTypeUnknown = 'VehicleTypeUnknown',
}

/**
 * ApplicationApi - axios parameter creator
 * @export
 */
export const ApplicationApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Bind the application with given quote
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bindQuote: async (
      applicationID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists('bindQuote', 'applicationID', applicationID);
      const localVarPath = `/nonfleet/application/{applicationID}/bind`.replace(
        `{${'applicationID'}}`,
        encodeURIComponent(String(applicationID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of vins decoded results
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    decodeVins: async (
      requestBody?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/utils/decode_vins`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get Quote Option for Admitted App
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdmittedAppIndication: async (
      applicationID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'getAdmittedAppIndication',
        'applicationID',
        applicationID,
      );
      const localVarPath =
        `/nonfleet/application/{applicationID}/indication`.replace(
          `{${'applicationID'}}`,
          encodeURIComponent(String(applicationID)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return non-fleet admitted constants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdmittedConstants: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/nonfleet/application/admitted/constants`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return non-fleet application\'s information
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplication: async (
      applicationID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists('getApplication', 'applicationID', applicationID);
      const localVarPath = `/nonfleet/application/{applicationID}`.replace(
        `{${'applicationID'}}`,
        encodeURIComponent(String(applicationID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return non-fleet application list
     * @param {string} [q]
     * @param {boolean} [admitted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplicationList: async (
      q?: string,
      admitted?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/nonfleet/application/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      if (q !== undefined) {
        localVarQueryParameter['q'] = q;
      }

      if (admitted !== undefined) {
        localVarQueryParameter['admitted'] = admitted;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return non-fleet applications with pagination
     * @param {number} [size]
     * @param {string} [cursor]
     * @param {string} [q]
     * @param {ApplicationTab} [tab]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplicationListV2: async (
      size?: number,
      cursor?: string,
      q?: string,
      tab?: ApplicationTab,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/nonfleet/application/v2/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (cursor !== undefined) {
        localVarQueryParameter['cursor'] = cursor;
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q;
      }

      if (tab !== undefined) {
        localVarQueryParameter['tab'] = tab;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Trigger RateML Job to generate Quote
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBindableQuote: async (
      applicationID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists('getBindableQuote', 'applicationID', applicationID);
      const localVarPath =
        `/nonfleet/application/{applicationID}/bindable/quote`.replace(
          `{${'applicationID'}}`,
          encodeURIComponent(String(applicationID)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return non-fleet classes & commodities list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClassesAndCommoditiesList: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/nonfleet/application/classes_and_commodities/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get Quote Option for NRBApplication
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuoteIndication: async (
      applicationID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists('getQuoteIndication', 'applicationID', applicationID);
      const localVarPath =
        `/nonfleet/application/{applicationID}/quote`.replace(
          `{${'applicationID'}}`,
          encodeURIComponent(String(applicationID)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get information about a vehicle given a VIN
     * @param {string} [vins] Comma-seperated list of VINs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVehicleInfo: async (
      vins?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/utils/vehicle_info`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      if (vins !== undefined) {
        localVarQueryParameter['vins'] = vins;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Runs all pre application checks (existing application, potential clearance)
     * @param {number} dotNumber
     * @param {string} producerID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nonFleetApplicationPreCreateValidatePost: async (
      dotNumber: number,
      producerID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dotNumber' is not null or undefined
      assertParamExists(
        'nonFleetApplicationPreCreateValidatePost',
        'dotNumber',
        dotNumber,
      );
      // verify required parameter 'producerID' is not null or undefined
      assertParamExists(
        'nonFleetApplicationPreCreateValidatePost',
        'producerID',
        producerID,
      );
      const localVarPath = `/non_fleet/application/pre_create_validate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      if (dotNumber !== undefined) {
        localVarQueryParameter['dotNumber'] = dotNumber;
      }

      if (producerID !== undefined) {
        localVarQueryParameter['producerID'] = producerID;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns the status tracker
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nonfleetApplicationApplicationIDStatusTrackerGet: async (
      applicationID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'nonfleetApplicationApplicationIDStatusTrackerGet',
        'applicationID',
        applicationID,
      );
      const localVarPath =
        `/nonfleet/application/{applicationID}/status_tracker`.replace(
          `{${'applicationID'}}`,
          encodeURIComponent(String(applicationID)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * After the agent generates a new telematics connection link, we require  the agent to send that link to the insured either directly or indirectly. The agent may choose to have Nirvana send the consent request email  to the insured, by populating the \'email\' dictionary in the request.  Alternatively, the agent may send the consent request email themselves, in  which case this endpoint will be called with no \'email\' dictionary.
     * @param {string} applicationID
     * @param {TelematicsConsentRequestEmailData} [telematicsConsentRequestEmailData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nonfleetApplicationApplicationIDTelematicsConsentRequestEmailPost: async (
      applicationID: string,
      telematicsConsentRequestEmailData?: TelematicsConsentRequestEmailData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'nonfleetApplicationApplicationIDTelematicsConsentRequestEmailPost',
        'applicationID',
        applicationID,
      );
      const localVarPath =
        `/nonfleet/application/{applicationID}/telematics_consent_request_email`.replace(
          `{${'applicationID'}}`,
          encodeURIComponent(String(applicationID)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        telematicsConsentRequestEmailData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update non-fleet application\'s information
     * @param {string} applicationID
     * @param {ApplicationUpdateForm} applicationUpdateForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchApplication: async (
      applicationID: string,
      applicationUpdateForm: ApplicationUpdateForm,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists('patchApplication', 'applicationID', applicationID);
      // verify required parameter 'applicationUpdateForm' is not null or undefined
      assertParamExists(
        'patchApplication',
        'applicationUpdateForm',
        applicationUpdateForm,
      );
      const localVarPath = `/nonfleet/application/{applicationID}`.replace(
        `{${'applicationID'}}`,
        encodeURIComponent(String(applicationID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        applicationUpdateForm,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a new non-fleet application
     * @param {ApplicationCreationForm} applicationCreationForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postApplication: async (
      applicationCreationForm: ApplicationCreationForm,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationCreationForm' is not null or undefined
      assertParamExists(
        'postApplication',
        'applicationCreationForm',
        applicationCreationForm,
      );
      const localVarPath = `/nonfleet/application`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        applicationCreationForm,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Trigger RateML Job to generate Quote
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postApplicationSubmit: async (
      applicationID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'postApplicationSubmit',
        'applicationID',
        applicationID,
      );
      const localVarPath =
        `/nonfleet/application/{applicationID}/submit`.replace(
          `{${'applicationID'}}`,
          encodeURIComponent(String(applicationID)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Trigger RateML Job to generate Quote
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postQuoteSubmit: async (
      applicationID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists('postQuoteSubmit', 'applicationID', applicationID);
      const localVarPath =
        `/nonfleet/application/{applicationID}/quote/submit`.replace(
          `{${'applicationID'}}`,
          encodeURIComponent(String(applicationID)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ApplicationApi - functional programming interface
 * @export
 */
export const ApplicationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ApplicationApiAxiosParamCreator(configuration);
  return {
    /**
     * Bind the application with given quote
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bindQuote(
      applicationID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bindQuote(
        applicationID,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Returns list of vins decoded results
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async decodeVins(
      requestBody?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<DecodeVinResponse>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.decodeVins(
        requestBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get Quote Option for Admitted App
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAdmittedAppIndication(
      applicationID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAdmittedAppIndicationResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAdmittedAppIndication(
          applicationID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Return non-fleet admitted constants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAdmittedConstants(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdmittedConstants>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAdmittedConstants(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Return non-fleet application\'s information
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getApplication(
      applicationID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApplicationDetails>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getApplication(
        applicationID,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Return non-fleet application list
     * @param {string} [q]
     * @param {boolean} [admitted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getApplicationList(
      q?: string,
      admitted?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetApplicationListResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getApplicationList(
          q,
          admitted,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Return non-fleet applications with pagination
     * @param {number} [size]
     * @param {string} [cursor]
     * @param {string} [q]
     * @param {ApplicationTab} [tab]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getApplicationListV2(
      size?: number,
      cursor?: string,
      q?: string,
      tab?: ApplicationTab,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetApplicationsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getApplicationListV2(
          size,
          cursor,
          q,
          tab,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Trigger RateML Job to generate Quote
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBindableQuote(
      applicationID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetBindableQuoteResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBindableQuote(
          applicationID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Return non-fleet classes & commodities list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClassesAndCommoditiesList(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ClassesAndCommodities>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClassesAndCommoditiesList(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get Quote Option for NRBApplication
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getQuoteIndication(
      applicationID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetQuoteResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getQuoteIndication(
          applicationID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get information about a vehicle given a VIN
     * @param {string} [vins] Comma-seperated list of VINs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVehicleInfo(
      vins?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetVehicleInfoResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVehicleInfo(
        vins,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Runs all pre application checks (existing application, potential clearance)
     * @param {number} dotNumber
     * @param {string} producerID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async nonFleetApplicationPreCreateValidatePost(
      dotNumber: number,
      producerID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.nonFleetApplicationPreCreateValidatePost(
          dotNumber,
          producerID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Returns the status tracker
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async nonfleetApplicationApplicationIDStatusTrackerGet(
      applicationID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusTracker>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.nonfleetApplicationApplicationIDStatusTrackerGet(
          applicationID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * After the agent generates a new telematics connection link, we require  the agent to send that link to the insured either directly or indirectly. The agent may choose to have Nirvana send the consent request email  to the insured, by populating the \'email\' dictionary in the request.  Alternatively, the agent may send the consent request email themselves, in  which case this endpoint will be called with no \'email\' dictionary.
     * @param {string} applicationID
     * @param {TelematicsConsentRequestEmailData} [telematicsConsentRequestEmailData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async nonfleetApplicationApplicationIDTelematicsConsentRequestEmailPost(
      applicationID: string,
      telematicsConsentRequestEmailData?: TelematicsConsentRequestEmailData,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TelematicsConsentRequestEmailResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.nonfleetApplicationApplicationIDTelematicsConsentRequestEmailPost(
          applicationID,
          telematicsConsentRequestEmailData,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Update non-fleet application\'s information
     * @param {string} applicationID
     * @param {ApplicationUpdateForm} applicationUpdateForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchApplication(
      applicationID: string,
      applicationUpdateForm: ApplicationUpdateForm,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApplicationDetails>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchApplication(
          applicationID,
          applicationUpdateForm,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Create a new non-fleet application
     * @param {ApplicationCreationForm} applicationCreationForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postApplication(
      applicationCreationForm: ApplicationCreationForm,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreatedApplicationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postApplication(
        applicationCreationForm,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Trigger RateML Job to generate Quote
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postApplicationSubmit(
      applicationID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApplicationDetails>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postApplicationSubmit(
          applicationID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Trigger RateML Job to generate Quote
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postQuoteSubmit(
      applicationID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApplicationDetails>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postQuoteSubmit(
        applicationID,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ApplicationApi - factory interface
 * @export
 */
export const ApplicationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ApplicationApiFp(configuration);
  return {
    /**
     * Bind the application with given quote
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bindQuote(applicationID: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .bindQuote(applicationID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of vins decoded results
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    decodeVins(
      requestBody?: Array<string>,
      options?: any,
    ): AxiosPromise<Array<DecodeVinResponse>> {
      return localVarFp
        .decodeVins(requestBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get Quote Option for Admitted App
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdmittedAppIndication(
      applicationID: string,
      options?: any,
    ): AxiosPromise<GetAdmittedAppIndicationResponse> {
      return localVarFp
        .getAdmittedAppIndication(applicationID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return non-fleet admitted constants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdmittedConstants(options?: any): AxiosPromise<AdmittedConstants> {
      return localVarFp
        .getAdmittedConstants(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return non-fleet application\'s information
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplication(
      applicationID: string,
      options?: any,
    ): AxiosPromise<ApplicationDetails> {
      return localVarFp
        .getApplication(applicationID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return non-fleet application list
     * @param {string} [q]
     * @param {boolean} [admitted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplicationList(
      q?: string,
      admitted?: boolean,
      options?: any,
    ): AxiosPromise<GetApplicationListResponse> {
      return localVarFp
        .getApplicationList(q, admitted, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return non-fleet applications with pagination
     * @param {number} [size]
     * @param {string} [cursor]
     * @param {string} [q]
     * @param {ApplicationTab} [tab]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplicationListV2(
      size?: number,
      cursor?: string,
      q?: string,
      tab?: ApplicationTab,
      options?: any,
    ): AxiosPromise<GetApplicationsResponse> {
      return localVarFp
        .getApplicationListV2(size, cursor, q, tab, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Trigger RateML Job to generate Quote
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBindableQuote(
      applicationID: string,
      options?: any,
    ): AxiosPromise<GetBindableQuoteResponse> {
      return localVarFp
        .getBindableQuote(applicationID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return non-fleet classes & commodities list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClassesAndCommoditiesList(
      options?: any,
    ): AxiosPromise<ClassesAndCommodities> {
      return localVarFp
        .getClassesAndCommoditiesList(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get Quote Option for NRBApplication
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuoteIndication(
      applicationID: string,
      options?: any,
    ): AxiosPromise<GetQuoteResponse> {
      return localVarFp
        .getQuoteIndication(applicationID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get information about a vehicle given a VIN
     * @param {string} [vins] Comma-seperated list of VINs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVehicleInfo(
      vins?: string,
      options?: any,
    ): AxiosPromise<GetVehicleInfoResponse> {
      return localVarFp
        .getVehicleInfo(vins, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Runs all pre application checks (existing application, potential clearance)
     * @param {number} dotNumber
     * @param {string} producerID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nonFleetApplicationPreCreateValidatePost(
      dotNumber: number,
      producerID: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .nonFleetApplicationPreCreateValidatePost(
          dotNumber,
          producerID,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns the status tracker
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nonfleetApplicationApplicationIDStatusTrackerGet(
      applicationID: string,
      options?: any,
    ): AxiosPromise<StatusTracker> {
      return localVarFp
        .nonfleetApplicationApplicationIDStatusTrackerGet(
          applicationID,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * After the agent generates a new telematics connection link, we require  the agent to send that link to the insured either directly or indirectly. The agent may choose to have Nirvana send the consent request email  to the insured, by populating the \'email\' dictionary in the request.  Alternatively, the agent may send the consent request email themselves, in  which case this endpoint will be called with no \'email\' dictionary.
     * @param {string} applicationID
     * @param {TelematicsConsentRequestEmailData} [telematicsConsentRequestEmailData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nonfleetApplicationApplicationIDTelematicsConsentRequestEmailPost(
      applicationID: string,
      telematicsConsentRequestEmailData?: TelematicsConsentRequestEmailData,
      options?: any,
    ): AxiosPromise<TelematicsConsentRequestEmailResponse> {
      return localVarFp
        .nonfleetApplicationApplicationIDTelematicsConsentRequestEmailPost(
          applicationID,
          telematicsConsentRequestEmailData,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Update non-fleet application\'s information
     * @param {string} applicationID
     * @param {ApplicationUpdateForm} applicationUpdateForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchApplication(
      applicationID: string,
      applicationUpdateForm: ApplicationUpdateForm,
      options?: any,
    ): AxiosPromise<ApplicationDetails> {
      return localVarFp
        .patchApplication(applicationID, applicationUpdateForm, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a new non-fleet application
     * @param {ApplicationCreationForm} applicationCreationForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postApplication(
      applicationCreationForm: ApplicationCreationForm,
      options?: any,
    ): AxiosPromise<CreatedApplicationResponse> {
      return localVarFp
        .postApplication(applicationCreationForm, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Trigger RateML Job to generate Quote
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postApplicationSubmit(
      applicationID: string,
      options?: any,
    ): AxiosPromise<ApplicationDetails> {
      return localVarFp
        .postApplicationSubmit(applicationID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Trigger RateML Job to generate Quote
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postQuoteSubmit(
      applicationID: string,
      options?: any,
    ): AxiosPromise<ApplicationDetails> {
      return localVarFp
        .postQuoteSubmit(applicationID, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ApplicationApi - object-oriented interface
 * @export
 * @class ApplicationApi
 * @extends {BaseAPI}
 */
export class ApplicationApi extends BaseAPI {
  /**
   * Bind the application with given quote
   * @param {string} applicationID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public bindQuote(applicationID: string, options?: AxiosRequestConfig) {
    return ApplicationApiFp(this.configuration)
      .bindQuote(applicationID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of vins decoded results
   * @param {Array<string>} [requestBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public decodeVins(requestBody?: Array<string>, options?: AxiosRequestConfig) {
    return ApplicationApiFp(this.configuration)
      .decodeVins(requestBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get Quote Option for Admitted App
   * @param {string} applicationID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public getAdmittedAppIndication(
    applicationID: string,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .getAdmittedAppIndication(applicationID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return non-fleet admitted constants
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public getAdmittedConstants(options?: AxiosRequestConfig) {
    return ApplicationApiFp(this.configuration)
      .getAdmittedConstants(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return non-fleet application\'s information
   * @param {string} applicationID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public getApplication(applicationID: string, options?: AxiosRequestConfig) {
    return ApplicationApiFp(this.configuration)
      .getApplication(applicationID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return non-fleet application list
   * @param {string} [q]
   * @param {boolean} [admitted]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public getApplicationList(
    q?: string,
    admitted?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .getApplicationList(q, admitted, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return non-fleet applications with pagination
   * @param {number} [size]
   * @param {string} [cursor]
   * @param {string} [q]
   * @param {ApplicationTab} [tab]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public getApplicationListV2(
    size?: number,
    cursor?: string,
    q?: string,
    tab?: ApplicationTab,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .getApplicationListV2(size, cursor, q, tab, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Trigger RateML Job to generate Quote
   * @param {string} applicationID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public getBindableQuote(applicationID: string, options?: AxiosRequestConfig) {
    return ApplicationApiFp(this.configuration)
      .getBindableQuote(applicationID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return non-fleet classes & commodities list
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public getClassesAndCommoditiesList(options?: AxiosRequestConfig) {
    return ApplicationApiFp(this.configuration)
      .getClassesAndCommoditiesList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get Quote Option for NRBApplication
   * @param {string} applicationID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public getQuoteIndication(
    applicationID: string,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .getQuoteIndication(applicationID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get information about a vehicle given a VIN
   * @param {string} [vins] Comma-seperated list of VINs
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public getVehicleInfo(vins?: string, options?: AxiosRequestConfig) {
    return ApplicationApiFp(this.configuration)
      .getVehicleInfo(vins, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Runs all pre application checks (existing application, potential clearance)
   * @param {number} dotNumber
   * @param {string} producerID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public nonFleetApplicationPreCreateValidatePost(
    dotNumber: number,
    producerID: string,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .nonFleetApplicationPreCreateValidatePost(dotNumber, producerID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns the status tracker
   * @param {string} applicationID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public nonfleetApplicationApplicationIDStatusTrackerGet(
    applicationID: string,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .nonfleetApplicationApplicationIDStatusTrackerGet(applicationID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * After the agent generates a new telematics connection link, we require  the agent to send that link to the insured either directly or indirectly. The agent may choose to have Nirvana send the consent request email  to the insured, by populating the \'email\' dictionary in the request.  Alternatively, the agent may send the consent request email themselves, in  which case this endpoint will be called with no \'email\' dictionary.
   * @param {string} applicationID
   * @param {TelematicsConsentRequestEmailData} [telematicsConsentRequestEmailData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public nonfleetApplicationApplicationIDTelematicsConsentRequestEmailPost(
    applicationID: string,
    telematicsConsentRequestEmailData?: TelematicsConsentRequestEmailData,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .nonfleetApplicationApplicationIDTelematicsConsentRequestEmailPost(
        applicationID,
        telematicsConsentRequestEmailData,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update non-fleet application\'s information
   * @param {string} applicationID
   * @param {ApplicationUpdateForm} applicationUpdateForm
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public patchApplication(
    applicationID: string,
    applicationUpdateForm: ApplicationUpdateForm,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .patchApplication(applicationID, applicationUpdateForm, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new non-fleet application
   * @param {ApplicationCreationForm} applicationCreationForm
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public postApplication(
    applicationCreationForm: ApplicationCreationForm,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .postApplication(applicationCreationForm, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Trigger RateML Job to generate Quote
   * @param {string} applicationID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public postApplicationSubmit(
    applicationID: string,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .postApplicationSubmit(applicationID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Trigger RateML Job to generate Quote
   * @param {string} applicationID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public postQuoteSubmit(applicationID: string, options?: AxiosRequestConfig) {
    return ApplicationApiFp(this.configuration)
      .postQuoteSubmit(applicationID, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TelematicsApi - axios parameter creator
 * @export
 */
export const TelematicsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * After the agent generates a new telematics connection link, we require  the agent to send that link to the insured either directly or indirectly. The agent may choose to have Nirvana send the consent request email  to the insured, by populating the \'email\' dictionary in the request.  Alternatively, the agent may send the consent request email themselves, in  which case this endpoint will be called with no \'email\' dictionary.
     * @param {string} applicationID
     * @param {TelematicsConsentRequestEmailData} [telematicsConsentRequestEmailData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nonfleetApplicationApplicationIDTelematicsConsentRequestEmailPost: async (
      applicationID: string,
      telematicsConsentRequestEmailData?: TelematicsConsentRequestEmailData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'nonfleetApplicationApplicationIDTelematicsConsentRequestEmailPost',
        'applicationID',
        applicationID,
      );
      const localVarPath =
        `/nonfleet/application/{applicationID}/telematics_consent_request_email`.replace(
          `{${'applicationID'}}`,
          encodeURIComponent(String(applicationID)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        telematicsConsentRequestEmailData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TelematicsApi - functional programming interface
 * @export
 */
export const TelematicsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    TelematicsApiAxiosParamCreator(configuration);
  return {
    /**
     * After the agent generates a new telematics connection link, we require  the agent to send that link to the insured either directly or indirectly. The agent may choose to have Nirvana send the consent request email  to the insured, by populating the \'email\' dictionary in the request.  Alternatively, the agent may send the consent request email themselves, in  which case this endpoint will be called with no \'email\' dictionary.
     * @param {string} applicationID
     * @param {TelematicsConsentRequestEmailData} [telematicsConsentRequestEmailData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async nonfleetApplicationApplicationIDTelematicsConsentRequestEmailPost(
      applicationID: string,
      telematicsConsentRequestEmailData?: TelematicsConsentRequestEmailData,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TelematicsConsentRequestEmailResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.nonfleetApplicationApplicationIDTelematicsConsentRequestEmailPost(
          applicationID,
          telematicsConsentRequestEmailData,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * TelematicsApi - factory interface
 * @export
 */
export const TelematicsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TelematicsApiFp(configuration);
  return {
    /**
     * After the agent generates a new telematics connection link, we require  the agent to send that link to the insured either directly or indirectly. The agent may choose to have Nirvana send the consent request email  to the insured, by populating the \'email\' dictionary in the request.  Alternatively, the agent may send the consent request email themselves, in  which case this endpoint will be called with no \'email\' dictionary.
     * @param {string} applicationID
     * @param {TelematicsConsentRequestEmailData} [telematicsConsentRequestEmailData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nonfleetApplicationApplicationIDTelematicsConsentRequestEmailPost(
      applicationID: string,
      telematicsConsentRequestEmailData?: TelematicsConsentRequestEmailData,
      options?: any,
    ): AxiosPromise<TelematicsConsentRequestEmailResponse> {
      return localVarFp
        .nonfleetApplicationApplicationIDTelematicsConsentRequestEmailPost(
          applicationID,
          telematicsConsentRequestEmailData,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TelematicsApi - object-oriented interface
 * @export
 * @class TelematicsApi
 * @extends {BaseAPI}
 */
export class TelematicsApi extends BaseAPI {
  /**
   * After the agent generates a new telematics connection link, we require  the agent to send that link to the insured either directly or indirectly. The agent may choose to have Nirvana send the consent request email  to the insured, by populating the \'email\' dictionary in the request.  Alternatively, the agent may send the consent request email themselves, in  which case this endpoint will be called with no \'email\' dictionary.
   * @param {string} applicationID
   * @param {TelematicsConsentRequestEmailData} [telematicsConsentRequestEmailData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TelematicsApi
   */
  public nonfleetApplicationApplicationIDTelematicsConsentRequestEmailPost(
    applicationID: string,
    telematicsConsentRequestEmailData?: TelematicsConsentRequestEmailData,
    options?: AxiosRequestConfig,
  ) {
    return TelematicsApiFp(this.configuration)
      .nonfleetApplicationApplicationIDTelematicsConsentRequestEmailPost(
        applicationID,
        telematicsConsentRequestEmailData,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
