import * as React from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  makeStyles,
  Grid,
  Typography,
} from '@material-ui/core';

import BackgroundGeneric from '../../assets/images/background.jpg';
import BackgroundSafety from '../../assets/images/background-safety.jpg';

import Branding, { BrandingTypes } from '../branding';
import { ITheme } from '../../assets/themes';

const useStyles = makeStyles((theme: ITheme) => ({
  cardContainer: {
    position: 'relative',
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
  },
  cardContent: {
    padding: theme.spacing(5, 7),
    display: 'flex',
    flexDirection: 'column',
    minHeight: 400,
  },
  bgWrapper: {
    flex: 1,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: theme.spacing(12),
    height: '100vh',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.overlay,
    opacity: 0.49,
  },
  text: {
    color: theme.palette.text.disabled,
  },
  logoOutline: {
    position: 'absolute',
    backgroundColor: theme.palette.gold.main,
    height: 2,
    width: theme.typography.pxToRem(90),
    left: theme.spacing(1.5),
    bottom: -15,
  },
  card: {
    width: theme.typography.pxToRem(495),
    maxWidth: theme.typography.pxToRem(495),
    border: '1px solid #E6E7EF',
    boxShadow: '0px 17px 18px rgba(128, 145, 196, 0.13)',
  },
}));

const getBackgroundImage = (brandingType: BrandingTypes) => {
  const bgStyles = {
    backgroundImage: `url(${BackgroundGeneric})`,
  };
  if (
    brandingType === BrandingTypes.Safety ||
    brandingType === BrandingTypes.SafetyInverted
  ) {
    bgStyles.backgroundImage = `url(${BackgroundSafety})`;
  }

  return bgStyles;
};

export interface IAuthLayoutProps {
  title: React.ReactNode;
  brandingType: BrandingTypes;
}

/**
 * HOC for unauthenticated pages. Includes basic skeleton including branding and content.
 * @component
 *
 * @param {Object} props
 * @param {Object} props.children - Text or any valid ReactNode.
 *
 * @example
 * return <AuthLayout>Login component goes here</AuthLayout>
 */
const AuthLayout: React.FC<IAuthLayoutProps> = ({
  children,
  title = '',
  brandingType = BrandingTypes.Default,
}) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.bgWrapper}
      style={{ ...getBackgroundImage(brandingType) }}
    >
      <Box className={classes.overlay} />
      <Container className={classes.cardContainer}>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item flexBasis="50%">
            <Grid container direction="column" spacing={7}>
              <Grid item>
                <Box display="flex" position="relative">
                  <Branding brandingType={brandingType} />
                  <Box className={classes.logoOutline} />
                </Box>
              </Grid>
              <Grid item>
                <Typography variant="h3" pl={1} className={classes.text}>
                  {title}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item flexBasis="50%" container justifyContent="flex-end">
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                {children}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AuthLayout;
