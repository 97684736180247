import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@material-ui/core';
import { ClassWithLabel } from '@nirvana/api/non-fleet';
import { ITheme, Show } from '@nirvana/ui-kit';
import { Controller, useFormContext } from 'react-hook-form-v7';
import { classItems } from '../constants/restrictedItems';

const useStyles = makeStyles((theme: ITheme) => ({
  classItem: {
    ...theme.typography.caption,
    display: 'list-item',
    listStyle: 'inside',
    padding: theme.spacing(0.4, 0),
    color: theme.palette.text.hint,
  },
}));

const RestrictedClasses = ({
  operatingClasses,
}: {
  operatingClasses?: ClassWithLabel[];
}) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={5}>
          <Box paddingTop="10px">
            <InputLabel htmlFor="restricted-classes">
              Restricted Classes
            </InputLabel>
            <FormHelperText style={{ width: 288 }}>
              Does the insured have any of the following operating classes?
            </FormHelperText>
          </Box>
        </Grid>

        <Grid item xs={7} container flexDirection="column" spacing={2}>
          <Grid item>
            <FormControl>
              <Controller
                control={control}
                rules={{
                  validate: (value) =>
                    value !== undefined || 'Please select an option!',
                }}
                name="classesAndCommoditiesForm.classInfo.ownsRestrictedClasses"
                render={({ field: { value, onChange } }) => (
                  <RadioGroup
                    row
                    value={`${value}`}
                    aria-label="restricted-classes"
                    onChange={(e) => onChange(e.target.value === 'true')}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio color="primary" size="small" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio color="primary" size="small" />}
                      label="No"
                    />
                  </RadioGroup>
                )}
              />
              <Show
                when={
                  (errors?.classesAndCommoditiesForm as any)?.classInfo
                    ?.ownsRestrictedClasses?.message
                }
              >
                {(message) => <FormHelperText error>{message}</FormHelperText>}
              </Show>
            </FormControl>
          </Grid>

          <Grid item container spacing={10}>
            {classItems.map((list, index) => (
              <Grid item key={index}>
                <List>
                  {list.map((item) => (
                    <ListItem className={classes.classItem} key={item}>
                      {item}
                    </ListItem>
                  ))}
                </List>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={5}>
          <Box paddingTop="10px">
            <InputLabel htmlFor="restricted-classes">
              Primary Operating Class
            </InputLabel>
            <FormHelperText style={{ width: 288 }}>
              Specify the main class of operations for the insured
            </FormHelperText>
          </Box>
        </Grid>

        <Grid item xs={7} container flexDirection="column" spacing={2}>
          <Grid item>
            <FormControl>
              <Controller
                control={control}
                name="classesAndCommoditiesForm.classInfo.primaryOperatingClass"
                rules={{ required: 'Please select a primary commodity' }}
                render={({ field: { value, onChange } }) => (
                  <Select
                    displayEmpty
                    value={value}
                    className="w-64"
                    variant="outlined"
                    onChange={onChange}
                  >
                    <MenuItem value="">
                      <Typography color="text.hint">Select</Typography>
                    </MenuItem>
                    {operatingClasses?.map((record) => (
                      <MenuItem key={record.name} value={record.name}>
                        {record.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {(errors?.classesAndCommoditiesForm as any)?.classInfo
                ?.primaryOperatingClass?.message && (
                <FormHelperText error>
                  {
                    (errors?.classesAndCommoditiesForm as any)?.classInfo
                      ?.primaryOperatingClass?.message
                  }
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RestrictedClasses;
