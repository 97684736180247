import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Grid,
  Box,
  FormHelperText,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form-v7';
import {
  FileDestinationGroup,
  FileMetadata,
  FileType,
} from '@nirvana/api/non-fleet';

import { useDispatch } from 'src/redux';
import {
  removeApplicationFileUpload,
  uploadApplicationFiles,
  cancelApplicationFilesUpload,
  retryApplicationFilesUpload,
  downloadApplicationFile,
  resetApplicationFileUpload,
} from 'src/features/application/actions';
import { FileUpload } from 'src/components/button';
import {
  FileUploadProgress,
  applicationSelector,
} from 'src/features/application/slices';

const LossRuns = () => {
  const dispatch = useDispatch();
  const application = useSelector(applicationSelector);
  const { uploadedFiles } = application;
  const [isUploading, setUploading] = useState(false);
  const {
    control,
    formState: { errors },
    register,
    setValue,
    watch,
  } = useFormContext();
  const lossRunFiles = watch('operationsForm.lossRunFiles');

  const handleLossRunFileUpload = (files: any) => {
    setUploading(true);

    files.forEach((file: any) => {
      dispatch(
        uploadApplicationFiles(file, {
          type: FileType.FileTypeLossRun,
          destinationGroup: FileDestinationGroup.FileDestinationGroupQuoting,
        }),
      );
    });
  };

  const handleLossRunFileUploadCancel = (progress: FileUploadProgress) => {
    dispatch(cancelApplicationFilesUpload(progress));
  };

  const handleLossRunFileUploadRetry = (progress: FileUploadProgress) => {
    dispatch(retryApplicationFilesUpload(progress));
  };

  const handleFileDownload = (handleId?: string) => {
    if (!handleId) {
      return;
    }

    dispatch(downloadApplicationFile(handleId));
  };

  useEffect(() => {
    if (!isUploading) {
      return;
    }

    const uploadedFilesValues = Object.values(uploadedFiles);
    const newFiles: any = [];
    uploadedFilesValues.forEach((record) => {
      if (record.status === 'succeeded') {
        newFiles.push(record);
        dispatch(removeApplicationFileUpload(record));
      }
    });
    setValue('operationsForm.lossRunFiles', [...newFiles, ...lossRunFiles]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isUploading, uploadedFiles, setValue]);

  return (
    <>
      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={5}>
          <Box paddingTop="10px">
            <InputLabel htmlFor="producer">Auto Liability Claims</InputLabel>
            <FormHelperText style={{ width: 288 }}>
              Please enter the total number of liability claims with a non-zero
              payout in the last 3-years
            </FormHelperText>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <OutlinedInput
              placeholder="eg. '1'"
              error={!!(errors.operationsForm as any)?.atFaultAccidents}
              {...register('operationsForm.atFaultAccidents', {
                valueAsNumber: true,
                max: {
                  value: 100,
                  message: 'Auto Liability Claims must be less than 100',
                },
              })}
            />
            {(errors.operationsForm as any)?.atFaultAccidents?.message && (
              <FormHelperText error>
                {(errors.operationsForm as any)?.atFaultAccidents?.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={5}>
          <Box paddingTop="10px">
            <InputLabel htmlFor="producer">
              Loss Runs <span className="text-text-hint">(optional)</span>
            </InputLabel>
            <FormHelperText style={{ width: 288 }}>
              Please upload loss runs for all lines for the past 3 years. AL
              loss runs will be required to bind.
            </FormHelperText>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="operationsForm.lossRunFiles"
            defaultValue={[]}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <>
                  <FileUpload
                    key="loss-run-file-upload"
                    value={Object.values(uploadedFiles).concat(value)}
                    onChange={handleLossRunFileUpload}
                    onRemove={(file) => {
                      if (file && file.handle && uploadedFiles[file.handle]) {
                        // Remove by filename
                        dispatch(removeApplicationFileUpload(file));
                      } else if (file && file.handle) {
                        const newValue = value.filter(
                          (record: FileMetadata) =>
                            record.handle !== file.handle,
                        );
                        onChange(newValue);
                      } else if (!file) {
                        // Remove all
                        dispatch(resetApplicationFileUpload());
                        onChange([]);
                      } else {
                        // Invalid case - do nothing
                      }
                    }}
                    onCancel={handleLossRunFileUploadCancel}
                    onRetry={handleLossRunFileUploadRetry}
                    onDownload={(file) => handleFileDownload(file.handle)}
                  />
                  {!!(errors?.operationsForm as any)?.lossInfo
                    ?.lossRunFiles && (
                    <FormHelperText error>
                      {
                        (errors?.operationsForm as any)?.lossInfo?.lossRunFiles
                          ?.message
                      }
                    </FormHelperText>
                  )}
                </>
              );
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default LossRuns;
