import { useEffect } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
} from '@material-ui/core';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form-v7';
import { InputNumeric, ITheme } from '@nirvana/ui-kit';
import { CategoryWithLabel, CommodityRecord } from '@nirvana/api/non-fleet';

import AddIcon from 'src/assets/icons/plus-primary.svg';
import { InputWithoutLabel as OutlinedInput } from 'src/components/input';

import CommodityCombobox from './commodity-combobox';

const useStyles = makeStyles((theme: ITheme) => ({
  totalItem: {
    alignItems: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    fontWeight: theme.typography.fontWeightRegular,
    height: '100%',
    fontSize: theme.typography.caption.fontSize,
    padding: theme.spacing(1, 2),
  },
  totalLabel: {
    backgroundColor: theme.palette.primary.extraLight,
    borderRadius: '5px 0 0 5px',
  },
  totalValue: {
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.primary.extraLight,
    borderRadius: '0 5px 5px 0',
  },
}));

const defaultValues = {
  name: '',
  categoryLabel: '',
  category: '',
  percentage: '',
};

const getDefaultRows = (existingData: CommodityRecord[] = []) => {
  // Existing data can have any number of rows, but we need to ensure a minimum of 5 rows
  const rows = existingData.length > 5 ? existingData : [...existingData];

  while (rows.length < 5) {
    rows.push(defaultValues as any);
  }

  return rows;
};

interface CommiditiesHauledProps {
  categories?: CategoryWithLabel[];
}

const CommoditiesHalued = ({ categories }: CommiditiesHauledProps) => {
  const classes = useStyles();
  const {
    clearErrors,
    control,
    getValues,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const { fields, append } = useFieldArray({
    control,
    name: 'classesAndCommoditiesForm.commodityInfo.commodityDistribution',
    shouldUnregister: true,
  });

  const commodityDistribution = watch(
    'classesAndCommoditiesForm.commodityInfo.commodityDistribution',
  );

  const totalPercentage = commodityDistribution?.reduce(
    (acc: number, item: CommodityRecord) => acc + +item.percentage,
    0,
  );

  useEffect(() => {
    setTimeout(() => {
      setValue(
        'classesAndCommoditiesForm.commodityInfo.commodityDistribution',
        getDefaultRows(
          getValues(
            'classesAndCommoditiesForm.commodityInfo.commodityDistribution',
          ),
        ),
      );
    }, 300);
  }, [getValues, setValue]);

  return (
    <Grid
      item
      container
      direction="row"
      wrap="nowrap"
      alignItems="flex-start"
      spacing={3}
    >
      <Grid item xs={5}>
        <Box paddingTop="10px">
          <InputLabel htmlFor="restricted-classes">
            Commodities Hauled
          </InputLabel>
          <FormHelperText style={{ width: 288 }}>
            Please specify commodities hauled, with category, and percentage of
            hauls
          </FormHelperText>
        </Box>
      </Grid>

      <Grid item xs={7} container flexDirection="column" spacing={2}>
        <Grid item container alignItems="center" spacing={2} flexWrap="nowrap">
          <Grid item xs={5}>
            <FormHelperText>Commodity</FormHelperText>
          </Grid>
          <Grid item xs={5}>
            <FormHelperText>Category</FormHelperText>
          </Grid>
          <Grid item xs={2}>
            <FormHelperText>% of hauls</FormHelperText>
          </Grid>
        </Grid>

        <Grid item container flexDirection="column" spacing={1}>
          {fields.map((item, index) => (
            <Grid
              item
              container
              key={item.id}
              spacing={2}
              flexWrap="nowrap"
              alignItems="flex-start"
            >
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <OutlinedInput
                    placeholder="Commodity Name"
                    helperText={
                      (errors.classesAndCommoditiesForm as any)?.commodityInfo
                        ?.commodityDistribution?.[index]?.name?.message
                    }
                    error={
                      !!(errors.classesAndCommoditiesForm as any)?.commodityInfo
                        ?.commodityDistribution?.[index]?.name
                    }
                    {...register(
                      `classesAndCommoditiesForm.commodityInfo.commodityDistribution.${index}.name`,
                      {
                        validate: (value) => {
                          if (
                            !value &&
                            (commodityDistribution[index].categoryLabel ||
                              commodityDistribution[index].percentage)
                          ) {
                            return 'Commodity name is required';
                          }
                        },
                      },
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={5}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name={`classesAndCommoditiesForm.commodityInfo.commodityDistribution.${index}.categoryLabel`}
                    rules={{
                      validate: (value) => {
                        if (
                          !value &&
                          (commodityDistribution[index].name ||
                            commodityDistribution[index].percentage)
                        ) {
                          return 'Category is required';
                        }
                      },
                    }}
                    render={({ field: { value, onChange } }) => (
                      <CommodityCombobox
                        value={value}
                        list={categories ?? []}
                        InputProps={{
                          endAdornment: undefined,
                          error: !!(errors.classesAndCommoditiesForm as any)
                            ?.commodityInfo?.commodityDistribution?.[index]
                            ?.categoryLabel,
                        }}
                        onRemove={() => {
                          setValue(
                            `classesAndCommoditiesForm.commodityInfo.commodityDistribution.${index}.category`,
                            undefined,
                          );
                          onChange('');
                        }}
                        onChange={(_, value: any) => {
                          setValue(
                            `classesAndCommoditiesForm.commodityInfo.commodityDistribution.${index}.category`,
                            value?.name,
                          );
                          setValue(
                            `classesAndCommoditiesForm.commodityInfo.commodityDistribution.${index}.categoryLabel`,
                            value?.label,
                          );
                        }}
                      />
                    )}
                  />

                  {!!(errors.classesAndCommoditiesForm as any)?.commodityInfo
                    ?.commodityDistribution?.[index]?.categoryLabel
                    ?.message && (
                    <FormHelperText error>
                      {
                        (errors.classesAndCommoditiesForm as any)?.commodityInfo
                          ?.commodityDistribution?.[index]?.categoryLabel
                          ?.message
                      }
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={2}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name={`classesAndCommoditiesForm.commodityInfo.commodityDistribution.${index}.percentage`}
                    rules={{
                      validate: (value) => {
                        if (
                          !value &&
                          (commodityDistribution[index].name ||
                            commodityDistribution[index].categoryLabel)
                        ) {
                          return 'Required';
                        }
                      },
                    }}
                    render={({ field: { value, onChange } }) => (
                      <InputNumeric
                        value={value}
                        placeholder="0%"
                        suffix="%"
                        onChange={(e) => {
                          clearErrors(
                            'classesAndCommoditiesForm.commodityInfo.commodityDistribution',
                          );
                          onChange(+e.target.value);
                        }}
                        error={
                          !!(errors.classesAndCommoditiesForm as any)
                            ?.commodityInfo?.commodityDistribution?.[index]
                            ?.percentage
                        }
                      />
                    )}
                  />
                  {!!(errors.classesAndCommoditiesForm as any)?.commodityInfo
                    ?.commodityDistribution?.[index]?.percentage?.message && (
                    <FormHelperText error>
                      {
                        (errors.classesAndCommoditiesForm as any)?.commodityInfo
                          ?.commodityDistribution?.[index]?.percentage?.message
                      }
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          ))}
        </Grid>

        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => append(defaultValues)}
            startIcon={<img src={AddIcon} width="14" alt="+" />}
          >
            Add New Row
          </Button>
        </Grid>

        <Grid item container direction="row" wrap="nowrap" spacing={0}>
          <Grid item>
            <Box className={clsx(classes.totalItem, classes.totalLabel)}>
              Total
            </Box>
          </Grid>
          <Grid item flexGrow={1}>
            <Box className={clsx(classes.totalItem, classes.totalValue)}>
              {totalPercentage}%
            </Box>
          </Grid>
        </Grid>

        {!!(errors.classesAndCommoditiesForm as any)?.commodityInfo
          ?.commodityDistribution?.message && (
          <Grid item>
            <FormHelperText error>
              {
                (errors.classesAndCommoditiesForm as any)?.commodityInfo
                  ?.commodityDistribution?.message
              }
            </FormHelperText>
          </Grid>
        )}

        <Grid item>
          <FormHelperText>
            Following commodities have pre-determined theft limits and
            deductibles, and they will be excluded from the policy entirely if
            not stated above: alcohol, beer, wine, electronics, furs, garments
            (not high fashion), non-ferrous metal, solar panels, bulk & bagged
            nuts, pharmaceuticals, seafood (unless canned), tobacco.
          </FormHelperText>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CommoditiesHalued;
