import { Button } from '@material-ui/core';
import { Show } from '@nirvana/ui-kit';
import { useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form-v7';
import { NavLink, useParams } from 'react-router-dom';
import {
  APPETITE_LITE_CREATE_APPLICATION_CLICK,
  APPETITE_LITE_SCORE_FETCHED,
} from 'src/features/telematics/events';
import { useAnalytics } from 'src/helpers/analytics';

import InsuredDetails from 'src/features/application/components/create/insuredDetails';
import { prefillApplication } from 'src/features/nonFleet/queries/application';
import {
  InsuredDetailsFormValues,
  RecommendationCardProps,
} from '../constants/types';
import {
  getColorSettings,
  getFormattedScoreType,
  getRecommendationText,
  getScoreTypeIcon,
} from '../utils';

export default function RecommendationCard({ data }: RecommendationCardProps) {
  const { dotNumber = '' } = useParams();
  const { capture } = useAnalytics();

  React.useEffect(() => {
    capture(APPETITE_LITE_SCORE_FETCHED, {
      dotNumber: dotNumber,
      score: data?.score,
    });
  }, [dotNumber, data?.score]);

  const methods = useForm<InsuredDetailsFormValues>({
    defaultValues: {
      dotNumber,
      companyName: '',
      effectiveDate: '',
      numberOfPowerUnits: '',
      hasUndesiredOperations: '',
      producerId: '',
    },
  });
  const { border, bg } = getColorSettings(data?.score);

  const [isInsuredDetailsDialogVisible, setInsuredDetailsDialogVisibility] =
    useState(false);

  const { setValue, reset } = methods;

  const { mutate: prefillDotNumber } = useMutation(prefillApplication, {
    onSuccess: (data) => {
      setValue('companyName', data.name, { shouldValidate: true });
    },
  });

  const handleApplicateCreate = () => {
    capture(APPETITE_LITE_CREATE_APPLICATION_CLICK, {
      dotNumber: dotNumber,
      score: data?.score,
    });
    prefillDotNumber({ dotNumber: +dotNumber });
    reset({
      dotNumber,
      companyName: '',
      effectiveDate: '',
      numberOfPowerUnits: '',
      hasUndesiredOperations: '',
      producerId: '',
    });
    setInsuredDetailsDialogVisibility(true);
  };

  return (
    <div
      className={clsx(
        'flex flex-col bg-white h-full rounded-lg border-2 shadow-md',
        border,
      )}
    >
      <div className={clsx('p-6 border-b-2 rounded-t-lg', border, bg)}>
        <div className="flex items-start text-base font-bold text-text-secondary">
          DOT {dotNumber}
        </div>
      </div>
      <div className="flex-grow p-6">
        <div className="flex items-start mb-8">
          {getScoreTypeIcon(data?.score)}
          <div className="ml-4">
            <p className="mt-1 text-3xl font-bold text-text-primary">
              {getFormattedScoreType(data?.score)}
            </p>
          </div>
        </div>
        <div className="flex items-start">
          <p className="text-base">{getRecommendationText(data?.score)}</p>
        </div>
      </div>
      <div className="flex justify-end p-6">
        <Show
          when={data?.score !== 'OutOfAppetite'}
          fallback={
            <NavLink to="/applications/list">
              <Button
                variant="contained"
                color="primary"
                size="large"
                className="normal-case"
              >
                Back to Home
              </Button>
            </NavLink>
          }
        >
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={handleApplicateCreate}
            className="w-44"
          >
            Create Application
          </Button>
        </Show>
      </div>
      <FormProvider {...methods}>
        <InsuredDetails
          open={isInsuredDetailsDialogVisible}
          onClose={() => setInsuredDetailsDialogVisibility(false)}
          openDialog={setInsuredDetailsDialogVisibility}
          accessType="fleet"
          isAppetiteLiteMode={true}
        />
      </FormProvider>
    </div>
  );
}
