import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import NumberFormat from 'react-number-format';
import { Controller, useFormContext } from 'react-hook-form-v7';
import {
  CoverageOption,
  CoverageOptionMetadata,
  CoverageType,
} from '@nirvana/api/non-fleet';
import { Checkbox, Show } from '@nirvana/ui-kit';

import {
  getCoverageOption,
  getReeferDeductible,
  isMandatoryCoverage,
} from './utils';

const MotorTruckCargo = ({
  coverages,
  metadata,
  onSubmit,
}: {
  coverages: CoverageOption[];
  metadata?: CoverageOptionMetadata;
  onSubmit: () => void;
}) => {
  const { control, setValue, register, watch } = useFormContext();
  const values = watch('coveragesForm.ancillaryCovs') ?? {};
  const mtcDeductible = watch(
    `coveragesForm.primaryCovs.${CoverageType.CoverageMotorTruckCargo}.deductible`,
  );

  const mtcCoverage = getCoverageOption(
    coverages,
    CoverageType.CoverageMotorTruckCargo,
  );
  const reeferCoverage = getCoverageOption(
    coverages,
    CoverageType.CoverageReefer,
  );
  const debrisRemovalCoverage = getCoverageOption(
    coverages,
    CoverageType.CoverageDebrisRemoval,
  );
  const enableReefer = metadata?.hasReefer ?? false;
  const reeferTooltip = enableReefer
    ? 'This coverage is not available based on insured’s operations'
    : '';

  if (!mtcCoverage) {
    return null;
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid
          item
          container
          direction="row"
          wrap="nowrap"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={5}>
            <Box paddingTop="10px">
              <InputLabel htmlFor="mtc">Motor Truck Cargo</InputLabel>
              <FormHelperText style={{ width: 288 }}>
                Coverages that apply to the MTC policy
              </FormHelperText>
            </Box>
          </Grid>

          <Grid item xs={7} container spacing={0.5}>
            <Grid item container spacing={4}>
              <Grid item xs={5}>
                <FormHelperText>Deductible</FormHelperText>
              </Grid>

              <Grid item xs={2} />

              <Grid item xs={5}>
                <FormHelperText>Limit</FormHelperText>
              </Grid>
            </Grid>

            <Grid
              item
              container
              alignItems="center"
              spacing={4}
              flexWrap="nowrap"
            >
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <input
                    {...register(
                      `coveragesForm.primaryCovs.${CoverageType.CoverageMotorTruckCargo}.coverageType`,
                    )}
                    type="hidden"
                    value={CoverageType.CoverageMotorTruckCargo}
                  />
                  <input
                    {...register(
                      `coveragesForm.primaryCovs.${CoverageType.CoverageMotorTruckCargo}.isRequired`,
                      { setValueAs: (v) => Boolean(v) },
                    )}
                    type="hidden"
                    value={1}
                  />
                  <Controller
                    control={control}
                    defaultValue={mtcCoverage?.defaultDeductible}
                    name={`coveragesForm.primaryCovs.${CoverageType.CoverageMotorTruckCargo}.deductible`}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <Select
                          variant="outlined"
                          value={value}
                          onChange={(e) => {
                            onChange(+e.target.value);
                            setValue(
                              `coveragesForm.ancillaryCovs.${CoverageType.CoverageReefer}.deductible`,
                              getReeferDeductible(+e.target.value),
                            );
                            onSubmit();
                          }}
                        >
                          {mtcCoverage?.deductibleOptions?.map((record) => (
                            <MenuItem
                              value={record}
                              key={`mtc-deductible-${record}`}
                            >
                              <NumberFormat
                                value={record}
                                displayType="text"
                                thousandSeparator={true}
                                prefix="$"
                                suffix=""
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      );
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={2} />

              <Grid item xs={5}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    defaultValue={mtcCoverage?.defaultLimit}
                    name={`coveragesForm.primaryCovs.${CoverageType.CoverageMotorTruckCargo}.limit`}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <Select
                          value={value}
                          variant="outlined"
                          onChange={(e) => {
                            onChange(+e.target.value);
                            onSubmit();
                          }}
                        >
                          {mtcCoverage?.limitOptions?.map((record) => (
                            <MenuItem
                              value={record}
                              key={`mtc-limit-${record}`}
                            >
                              <NumberFormat
                                value={record}
                                displayType="text"
                                thousandSeparator={true}
                                prefix="$"
                                suffix=""
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      );
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Show when={!!metadata?.targetCommodities?.length}>
              <Grid item>
                <FormHelperText>
                  A lower $25,000 sub-limit will apply to any loss resulting
                  from theft with respect to{' '}
                  {metadata?.targetCommodities?.join(', ')}.
                </FormHelperText>
              </Grid>
            </Show>
          </Grid>
        </Grid>

        <Show when={reeferCoverage}>
          <Grid item container direction="row" alignItems="center" spacing={3}>
            <Grid item xs={5} />

            <Grid item xs={4}>
              <input
                {...register(
                  `coveragesForm.ancillaryCovs.${CoverageType.CoverageReefer}.coverageType`,
                )}
                type="hidden"
                value={CoverageType.CoverageReefer}
              />
              <Tooltip title={reeferTooltip}>
                <span>
                  <Controller
                    control={control}
                    name={`coveragesForm.ancillaryCovs.${CoverageType.CoverageReefer}.isRequired`}
                    defaultValue={false}
                    render={() => (
                      <Checkbox
                        checked={enableReefer}
                        disabled={isMandatoryCoverage(
                          CoverageType.CoverageReefer,
                          metadata,
                        )}
                      >
                        Reefer Breakdown
                      </Checkbox>
                    )}
                  />
                </span>
              </Tooltip>
            </Grid>

            <Grid item xs={3} container spacing={0.5} direction="column">
              <Grid item>
                <FormHelperText>
                  Deductible&nbsp;
                  <Tooltip title="Assigned based on your MTC deductible selection">
                    <InfoOutlined className="text-info-main" fontSize="small" />
                  </Tooltip>
                </FormHelperText>
              </Grid>

              <Grid item>
                <Show when={enableReefer} fallback="-">
                  <Controller
                    control={control}
                    name={`coveragesForm.ancillaryCovs.${CoverageType.CoverageReefer}.deductible`}
                    defaultValue={getReeferDeductible(mtcDeductible)}
                    render={({ field: { value } }) => (
                      <NumberFormat
                        value={value}
                        displayType="text"
                        thousandSeparator={true}
                        prefix="$"
                        suffix=""
                      />
                    )}
                  />
                </Show>
              </Grid>
            </Grid>
          </Grid>
        </Show>

        <Show when={debrisRemovalCoverage}>
          <Grid item container direction="row" alignItems="center" spacing={3}>
            <Grid item xs={5} />

            <Grid item xs={4}>
              <input
                {...register(
                  `coveragesForm.ancillaryCovs.${CoverageType.CoverageDebrisRemoval}.coverageType`,
                )}
                type="hidden"
                value={CoverageType.CoverageDebrisRemoval}
              />
              <Controller
                control={control}
                name={`coveragesForm.ancillaryCovs.${CoverageType.CoverageDebrisRemoval}.isRequired`}
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    checked={value}
                    onChange={(e) => {
                      onChange(e.target.checked);
                      onSubmit();
                    }}
                    disabled={isMandatoryCoverage(
                      CoverageType.CoverageDebrisRemoval,
                      metadata,
                    )}
                  >
                    Debris Removal
                  </Checkbox>
                )}
              />
            </Grid>

            <Grid item xs={3} container spacing={0.5} direction="column">
              <Grid item>
                <FormHelperText>Limit</FormHelperText>
              </Grid>

              <Grid item>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    defaultValue={debrisRemovalCoverage?.defaultLimit}
                    name={`coveragesForm.ancillaryCovs.${CoverageType.CoverageDebrisRemoval}.limit`}
                    render={({ field: { value, onChange } }) => (
                      <Select
                        value={value}
                        variant="outlined"
                        onChange={(e) => {
                          onChange(+e.target.value);
                          onSubmit();
                        }}
                        disabled={
                          !values[CoverageType.CoverageDebrisRemoval]
                            ?.isRequired
                        }
                      >
                        {debrisRemovalCoverage?.limitOptions?.map((record) => (
                          <MenuItem
                            value={record}
                            key={`towing-limit-${record}`}
                          >
                            <NumberFormat
                              value={record}
                              displayType="text"
                              thousandSeparator={true}
                              prefix="$"
                              suffix=""
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Show>
      </Grid>

      <Divider sx={{ mt: 4 }} />
    </>
  );
};

export default MotorTruckCargo;
