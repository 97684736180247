import {
  Box,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { InputNumeric } from '@nirvana/ui-kit';
import clsx from 'clsx';
import { format } from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';

import { utils as UtilsHelper } from 'src/helpers';

import { useStyles } from './styles';
import { DateRange, getFirstErrorMessage } from './utils';

export interface LossHistoryBaseFields {
  coverageIndex: number;
  dateRanges: DateRange[];
  readOnly?: boolean;
  disabledPredicate?: (rangeIndex: number) => boolean;
}

const BaseFields = ({
  coverageIndex,
  dateRanges,
  readOnly,
  disabledPredicate,
}: LossHistoryBaseFields) => {
  const classes = useStyles();
  const { control, errors, getValues } = useFormContext();

  return (
    <Table key={`baseFields-${coverageIndex}`}>
      <TableHead>
        <TableRow>
          <TableCell
            className={clsx(classes.tableCell, classes.tableHeaderLabel)}
            align="left"
          >
            Policy period
          </TableCell>
          <TableCell
            className={clsx(classes.tableCell, classes.tableHeaderLabel)}
            align="left"
          >
            # of power units
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {dateRanges.map((record: DateRange, rangeIndex: number) => (
          <TableRow key={`lossRunSummaryForm.lossRunSummary[${rangeIndex}]`}>
            <TableCell className={classes.tableCell}>
              <Box className={classes.periodLabelContainer}>
                <Typography
                  color="textSecondary"
                  fontWeight="fontWeightRegular"
                  lineHeight="24px"
                  whiteSpace="nowrap"
                >
                  {format(record.from, 'M/d/yy')}
                  &nbsp;-&nbsp;
                  {format(record.to, 'M/d/yy')}
                </Typography>
              </Box>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <FormControl>
                <Controller
                  name={`lossRunSummaryForm[${coverageIndex}].lossRunSummary[${rangeIndex}].numberOfPowerUnits`}
                  control={control}
                  rules={{
                    validate: {
                      numberOfPowerUnits: (value) => {
                        if (
                          disabledPredicate &&
                          disabledPredicate(rangeIndex)
                        ) {
                          return true;
                        }

                        const values = getValues();
                        const record =
                          values.lossRunSummaryForm[coverageIndex]
                            .lossRunSummary[rangeIndex];

                        if ((rangeIndex === 0 || rangeIndex === 1) && !value) {
                          return 'Please enter missing values';
                        } else if (rangeIndex > 1) {
                          // Check for partial data in rows
                          if (
                            !(value || value === 0) &&
                            (record.lossIncurred ||
                              record.lossIncurred === 0 ||
                              record.numberOfClaims ||
                              record.numberOfClaims === 0)
                          ) {
                            return 'Please enter missing values';
                          }
                        }

                        if (value && !UtilsHelper.isValidInt32(value)) {
                          return 'Please enter a valid number';
                        }

                        return true;
                      },
                    },
                  }}
                  render={(props) => {
                    if (disabledPredicate?.(rangeIndex)) {
                      return (
                        <OutlinedInput
                          size="small"
                          readOnly={readOnly}
                          disabled={disabledPredicate?.(rangeIndex)}
                          value="-"
                          onChange={(event) => {
                            props.onChange(
                              event.target.value
                                ? +event.target.value
                                : undefined,
                            );
                          }}
                          error={
                            !!errors?.lossRunSummaryForm?.[coverageIndex]
                              ?.lossRunSummary?.[rangeIndex]?.numberOfPowerUnits
                          }
                        />
                      );
                    }

                    return (
                      <InputNumeric
                        size="small"
                        placeholder="0"
                        decimalScale={0}
                        readOnly={readOnly}
                        disabled={disabledPredicate?.(rangeIndex)}
                        value={props?.value}
                        onChange={(event) => {
                          props.onChange(
                            event.target.value
                              ? +event.target.value
                              : undefined,
                          );
                        }}
                        error={
                          !!errors?.lossRunSummaryForm?.[coverageIndex]
                            ?.lossRunSummary?.[rangeIndex]?.numberOfPowerUnits
                        }
                      />
                    );
                  }}
                />
              </FormControl>
            </TableCell>
          </TableRow>
        ))}

        <TableRow>
          <TableCell className={classes.tableCell} colSpan={4}>
            <FormHelperText
              error
              sx={{
                marginTop: '-3px',
                lineHeight: 1,
              }}
            >
              {getFirstErrorMessage(
                errors,
                coverageIndex,
                'numberOfPowerUnits',
              ) || <>&nbsp;</>}
            </FormHelperText>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default BaseFields;
