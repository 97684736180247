import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { ArrowForwardIosRounded, Close } from '@material-ui/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import {
  addMonths,
  formatISO,
  isAfter,
  isBefore,
  isPast,
  isToday,
  isValid,
  startOfToday,
  sub,
} from 'date-fns';
import React, { FocusEvent, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form-v7';

import {
  PotentialClearanceStatus,
  PreApplicationCheckRule,
  Producer,
} from '@nirvana/api/quoting';
import {
  DatePicker,
  Dialog,
  InputNumeric,
  InputWithLabel,
  Show,
} from '@nirvana/ui-kit';
import { fetchAvailableProducers } from 'src/features/admitted/queries/application';
import {
  fetchNonFleetFleetExistingApplicationMetadata,
  prefillApplication,
  runPreCreateValidationChecks,
} from 'src/features/nonFleet/queries/application';
import {
  APPETITE_CHECK_MODAL_VIEW,
  INSURED_DETAILS_CONTINUE_CLICK,
} from 'src/features/telematics/events';
import { useAnalytics } from 'src/helpers/analytics';
import ClearanceConflictDialog from './ClearanceConflictDialog';
import ClearanceConflictDialogDeclined from './ClearanceConflictDialogDeclined';
import ConflictErrorDialog from './ConflictErrorDialog';
import MidTermPolicyRenewal from './MidTermPolicyRenewal';
import NFPowerUnitsModal from './nfPowerUnitsModal';
import PossibleRenewalDialog from './PossibleRenewal';
import UndesiredOperations from './undesiredOperations';
import { isNonFleet } from './utils';

const useStyles = makeStyles((theme) => ({
  formLabel: {
    ...theme.typography.caption,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.primary.main,
  },
  companyDisabled: {
    WebkitTextFillColor: `${theme.palette.text.primary} !important`,
    cursor: 'not-allowed',
  },
}));

interface InsuredDetailsFormValues {
  dotNumber: string;
  companyName: string;
  effectiveDate: string;
  numberOfPowerUnits: string;
  hasUndesiredOperations: string;
  producerId: string;
}

const defaultValues: InsuredDetailsFormValues = {
  dotNumber: '',
  companyName: '',
  effectiveDate: '',
  numberOfPowerUnits: '',
  hasUndesiredOperations: '',
  producerId: '',
};

interface InsuredDetailsProps {
  open: boolean;
  onClose: () => void;
  accessType: string;
  openDialog: React.Dispatch<React.SetStateAction<boolean>>;
  isAppetiteLiteMode?: boolean;
}

const InsuredDetails = ({
  open,
  onClose,
  accessType,
  openDialog,
  isAppetiteLiteMode = false,
}: InsuredDetailsProps) => {
  const classes = useStyles();
  const [hasUndesiredOperations, setHasUndesiredOperations] = useState(false);
  const [showNFPowerUnitsModal, setShowNFPowerUnitsModal] = useState(false);
  const [existingAppMetadata, setExistingAppMetadata] = useState<any>();
  const [potentialClearanceDetected, setPotentialClearanceDetected] =
    useState(false);
  const [isSameAgency, setIsSameAgency] = useState(false);
  const [
    potentialDeclinedClearanceDetected,
    setPotentialDeclinedClearanceDetected,
  ] = useState(false);
  const [possibleRenewalDetected, setPossibleRenewalDetected] = useState(false);
  const [midtermPossibleRenewal, setMidtermPossibleRenewal] = useState(false);
  const [sameAgencyRenewal, setSameAgenyRenewal] = useState(false);
  const [manualMode, setManualMode] = useState(true);
  const [numPowerUnits, setNumOfPowerUnits] = useState(0);
  const [policyExpirationDate, setPolicyExpiration] = useState('');
  const { capture } = useAnalytics();

  useEffect(() => {
    if (open) {
      capture(APPETITE_CHECK_MODAL_VIEW);
    }
  }, [open]);

  const {
    control,
    register,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
    watch,
  } = useFormContext<InsuredDetailsFormValues>();

  const dotNumberValue = watch('dotNumber');
  const producerId = watch('producerId');
  const effectiveDate = watch('effectiveDate');
  const numberOfPowerUnits = watch('numberOfPowerUnits');
  const company = watch('companyName');

  const { mutate: prefillDotNumber } = useMutation(prefillApplication, {
    onSuccess: (data) => {
      setValue('companyName', data.name, { shouldValidate: true });
      capture('company_name_prefilled');
      setManualMode(false);
    },
    onError: () => {
      setValue('numberOfPowerUnits', '');
      setManualMode(true);
    },
  });

  function handleDOTBlur(event: FocusEvent<HTMLInputElement>) {
    if (event.target.value) {
      prefillDotNumber({ dotNumber: +event.target.value });
    }
  }

  function handleContinue() {
    if (accessType === 'non-fleet' && +numberOfPowerUnits >= 10) {
      capture(INSURED_DETAILS_CONTINUE_CLICK);
      setShowNFPowerUnitsModal(true);
    } else {
      capture(INSURED_DETAILS_CONTINUE_CLICK);
      setHasUndesiredOperations(true);
      onClose();
    }
  }

  const {
    mutate: runFleetPreCreateChecks,
    isLoading: isFleetExistingAppLoading,
  } = useMutation(
    ['existingAppMetadata', +dotNumberValue, producerId],
    () =>
      runPreCreateValidationChecks({
        dotNumber: +dotNumberValue,
        producerId,
        effectiveDate: formatISO(new Date(effectiveDate), {
          representation: 'date',
        }),
      }),
    {
      onSuccess: () => {
        handleContinue();
      },
      onError: (ex: any) => {
        if (
          ex.response.data.rule ===
          PreApplicationCheckRule.PreApplicationRuleDuplicateApplication
        ) {
          setExistingAppMetadata(ex.response.data.details);
          onClose();
          reset(defaultValues);
        }

        if (
          ex.response.data.rule ===
            PreApplicationCheckRule.PreApplicationRulePotentialClearance &&
          ex.response.data.details.status ===
            PotentialClearanceStatus.ClearedApplicationExistsSameAgency
        ) {
          setPotentialClearanceDetected(true);
          setIsSameAgency(true);
          onClose();
        }

        if (
          ex.response.data.rule ===
            PreApplicationCheckRule.PreApplicationRulePotentialClearance &&
          ex.response.data.details.status ===
            PotentialClearanceStatus.ClearedApplicationExists
        ) {
          setPotentialClearanceDetected(true);
          setIsSameAgency(false);
          onClose();
        }

        if (
          ex.response.data.rule ===
            PreApplicationCheckRule.PreApplicationRulePotentialClearance &&
          ex.response.data.details.status ===
            PotentialClearanceStatus.DeclinedClearedApplicationExists
        ) {
          setPotentialDeclinedClearanceDetected(true);
          onClose();
        }

        if (
          ex.response.data.rule ===
          PreApplicationCheckRule.PreApplicationRulePossibleRenewal
        ) {
          setPossibleRenewalDetected(true);
          onClose();
        }

        if (
          ex.response.data.rule ===
          PreApplicationCheckRule.PreApplicationRuleSameAgencyRenewal
        ) {
          setSameAgenyRenewal(true);
          setPolicyExpiration(ex.response.data.details.applicationExpiryDate);
          onClose();
        }

        if (
          ex.response.data.rule ===
          PreApplicationCheckRule.PreApplicationRulePossibleMidTermMove
        ) {
          setMidtermPossibleRenewal(true);
          setPolicyExpiration(ex.response.data.details.applicationExpiryDate);
          onClose();
        }
      },
    },
  );

  const {
    isLoading: isNonFleetExistingAppLoading,
    mutate: fetchNonFleetExistingAppMetadata,
  } = useMutation(
    ['existingAppMetadata', +dotNumberValue, producerId],
    () =>
      fetchNonFleetFleetExistingApplicationMetadata({
        dotNumber: +dotNumberValue,
        producerId,
      }),
    {
      onSuccess: () => {
        handleContinue();
      },
      onError: (ex: any) => {
        if (
          ex.response.data.rule ===
          PreApplicationCheckRule.PreApplicationRuleDuplicateApplication
        ) {
          setExistingAppMetadata(ex.response.data.details);
          onClose();
          reset(defaultValues);
        }
      },
    },
  );

  const { data: producers } = useQuery(['producers'], fetchAvailableProducers);

  function handleClose() {
    reset(defaultValues);
    onClose();
  }

  function onContinue(values: InsuredDetailsFormValues) {
    setNumOfPowerUnits(+values.numberOfPowerUnits);
    if (!isNonFleet(accessType, +values.numberOfPowerUnits)) {
      runFleetPreCreateChecks();
    } else {
      fetchNonFleetExistingAppMetadata();
    }
  }

  return (
    <>
      <Dialog
        open={open}
        maxWidth="md"
        onClose={handleClose}
        primaryAction={
          <Button
            variant="contained"
            onClick={handleSubmit(onContinue)}
            endIcon={<ArrowForwardIosRounded />}
            data-attr="posthog-create-application-continue"
            disabled={isFleetExistingAppLoading || isNonFleetExistingAppLoading}
          >
            Continue
          </Button>
        }
      >
        <Box pt={4} px={4} position="relative">
          <IconButton
            edge="end"
            size="small"
            onClick={handleClose}
            className="absolute top-0 right-0"
          >
            <Close />
          </IconButton>

          <Grid container direction="column">
            <Grid item>
              <Typography
                variant="h5"
                color="textPrimary"
                textAlign="center"
                mb={3}
              >
                Please tell us more about the insured
              </Typography>
            </Grid>

            <Grid item>
              <Grid container direction="row" wrap="nowrap" spacing={6}>
                <Grid item xs={12} container direction="column">
                  <Grid item>
                    <FormControl fullWidth>
                      <FormLabel
                        color="primary"
                        component="label"
                        className={classes.formLabel}
                      >
                        DOT number
                      </FormLabel>
                      <InputNumeric
                        placeholder="eg. 123456"
                        readOnly={isAppetiteLiteMode}
                        value={dotNumberValue}
                        decimalScale={0}
                        thousandSeparator={false}
                        inputProps={{
                          className: clsx({
                            [classes.companyDisabled]: isAppetiteLiteMode,
                          }),
                        }}
                        error={!!errors.dotNumber}
                        {...register('dotNumber', {
                          required: 'Please enter a valid DOT number',
                        })}
                        onBlur={handleDOTBlur}
                      />
                      <FormHelperText error>
                        {errors?.dotNumber?.message ?? ' '}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item>
                    <FormControl fullWidth>
                      <InputWithLabel
                        noMargin
                        label="Company name"
                        error={!!errors.companyName}
                        placeholder="eg. ABC company"
                        readOnly={isAppetiteLiteMode || !manualMode}
                        inputProps={{
                          className: clsx({
                            [classes.companyDisabled]:
                              isAppetiteLiteMode || !manualMode,
                          }),
                          'data-attr': 'posthog-company-name',
                        }}
                        {...register('companyName', {
                          required: 'Please enter a valid company name',
                        })}
                        helperText={
                          (errors?.companyName?.message as string) ?? ' '
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item xs={12} container direction="column">
                  <Grid item>
                    <FormControl fullWidth>
                      <FormLabel
                        color="primary"
                        component="label"
                        className={classes.formLabel}
                      >
                        Effective date of coverage
                      </FormLabel>
                      <Controller
                        name="effectiveDate"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: 'Please select effective date',
                          validate: {
                            validateDate: (v) => {
                              const date = new Date(v);
                              if (!isValid(date)) {
                                return 'Please enter a valid date (mm/dd/yyyy)';
                              }

                              if (isAfter(date, addMonths(startOfToday(), 4))) {
                                return 'Effective date cannot be more than four months into the future';
                              }

                              if (isBefore(date, startOfToday())) {
                                return 'Effective date cannot be in the past';
                              }

                              return (
                                /^(2\d{3})-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])$/.test(
                                  formatISO(date, {
                                    representation: 'date',
                                  }),
                                ) || 'Please enter a valid date (mm/dd/yyyy)'
                              );
                            },
                          },
                        }}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <DatePicker
                              inputFormat="MM/dd/yyyy"
                              shouldDisableDate={(date: unknown) =>
                                date instanceof Date
                                  ? (isPast(date) && !isToday(date)) ||
                                    isAfter(
                                      date,
                                      sub(addMonths(startOfToday(), 4), {
                                        days: 1,
                                      }),
                                    )
                                  : false
                              }
                              value={value ? new Date(value) : null}
                              onChange={(val) => {
                                capture('effective_date_selected');
                                onChange(val);
                              }}
                              PopperProps={{
                                placement: 'bottom',
                                modifiers: [
                                  {
                                    name: 'flip',
                                    options: {
                                      fallbackPlacements: [
                                        'bottom',
                                        'right',
                                        'top',
                                      ],
                                    },
                                  },
                                ],
                              }}
                              InputProps={{
                                placeholder: 'eg. 12/31/2021',
                                fullWidth: true,
                                error: !!errors.effectiveDate,
                              }}
                            />
                          );
                        }}
                      />

                      <FormHelperText error>
                        {errors?.effectiveDate?.message ?? ' '}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item>
                    <FormLabel
                      color="primary"
                      component="label"
                      className={classes.formLabel}
                    >
                      # of power units
                    </FormLabel>
                    <FormControl fullWidth>
                      <Controller
                        control={control}
                        name="numberOfPowerUnits"
                        rules={{ required: 'Please enter a valid number' }}
                        render={({ field }) => (
                          <InputNumeric
                            value={field.value}
                            placeholder="eg. 10"
                            onChange={field.onChange}
                            data-attr="posthog-power-units"
                            inputProps={{
                              'data-attr': 'posthog-power-units',
                            }}
                            decimalScale={0}
                          />
                        )}
                      />

                      <FormHelperText error>
                        {errors?.numberOfPowerUnits?.message ?? ' '}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <FormLabel
                color="primary"
                component="label"
                className={classes.formLabel}
              >
                Select Producer
              </FormLabel>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="producerId"
                  rules={{ required: 'Please select a producer' }}
                  render={({ field }) => (
                    <FormControl className={classes.formLabel}>
                      <Select
                        id="producer-select"
                        displayEmpty
                        variant="outlined"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!errors.producerId}
                        fullWidth
                      >
                        <MenuItem value="">Select</MenuItem>
                        {producers?.map((producer: Producer) => {
                          return (
                            <MenuItem
                              data-attr="posthog-producer-id"
                              value={producer.id}
                              key={producer.id}
                            >
                              {producer.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error>
                        {errors?.producerId?.message ?? ' '}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      <Show when={existingAppMetadata}>
        <ConflictErrorDialog
          open={!!existingAppMetadata}
          data={existingAppMetadata}
          isNonFleet={isNonFleet(accessType, +numPowerUnits)}
          onClose={() => setExistingAppMetadata(null)}
          openDialog={openDialog}
        />
      </Show>
      <Show when={potentialClearanceDetected}>
        <ClearanceConflictDialog
          open={potentialClearanceDetected}
          onClose={() => setPotentialClearanceDetected(false)}
          handleCreateApplication={() => {
            setPotentialClearanceDetected(false);
            handleContinue();
          }}
          isSameAgency={isSameAgency}
        />
      </Show>

      <Show when={potentialDeclinedClearanceDetected}>
        <ClearanceConflictDialogDeclined
          open={potentialDeclinedClearanceDetected}
          onClose={() => setPotentialDeclinedClearanceDetected(false)}
          handleCreateApplication={() => {
            setPotentialDeclinedClearanceDetected(false);
            handleContinue();
          }}
          accountName={company}
        />
      </Show>

      <Show when={possibleRenewalDetected}>
        <PossibleRenewalDialog
          open={possibleRenewalDetected}
          onClose={() => setPossibleRenewalDetected(false)}
          handleCreateApplication={() => {
            setPossibleRenewalDetected(false);
            handleContinue();
          }}
          renewalData={{
            dotNumber: dotNumberValue,
            accountName: company,
          }}
        />
      </Show>

      <Show when={midtermPossibleRenewal}>
        <MidTermPolicyRenewal
          open={midtermPossibleRenewal}
          renewalData={{
            effectiveDate: policyExpirationDate,
          }}
          onClose={() => setMidtermPossibleRenewal(false)}
        />
      </Show>

      <Show when={sameAgencyRenewal}>
        <MidTermPolicyRenewal
          open={sameAgencyRenewal}
          onClose={() => setSameAgenyRenewal(false)}
          renewalData={{
            effectiveDate: policyExpirationDate,
          }}
          isSameAgency={true}
        />
      </Show>
      <Show when={showNFPowerUnitsModal}>
        <NFPowerUnitsModal
          open={showNFPowerUnitsModal}
          onClose={() => setShowNFPowerUnitsModal(false)}
        />
      </Show>
      <UndesiredOperations
        open={hasUndesiredOperations}
        isNonFleet={isNonFleet(accessType, +numberOfPowerUnits)}
        onClose={() => setHasUndesiredOperations(false)}
        openDialog={openDialog}
      />
    </>
  );
};

export default InsuredDetails;
