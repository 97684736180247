import { Divider, Grid } from '@material-ui/core';
import { CoverageDetails, CoverageType } from '@nirvana/api/non-fleet';
import { useFormContext } from 'react-hook-form-v7';

import { useMemo } from 'react';
import { Feature, useFeatureFlag } from 'src/helpers/featureFlags';
import BusinessOwner from './business-owner';
import Commodities from './commodities';
import Coverage from './coverage';
import LossRuns from './loss-runs';
import OperatingArea from './operating-area';
import TerminalLocation from './terminal-location';
import UIIA from './uiia';

const Operations = () => {
  const getFeatureValue = useFeatureFlag();
  const { watch } = useFormContext();
  const addresses = watch([
    'operationsForm.businessOwner.address.state',
    'operationsForm.terminalLocation.sameAsPhysicalAddress',
    'operationsForm.terminalLocation.address.state',
  ]);
  const isTerminalStateTexas = useMemo(() => {
    if (addresses[1] && addresses[0] === 'Texas') {
      return true;
    }

    if (!addresses[1] && addresses[2] === 'Texas') {
      return true;
    }

    return false;
  }, [addresses]);
  const enabledCoverages = watch('operationsForm.coverages', [])
    .filter((record: CoverageDetails) => !!record.isRequired)
    .map((record: CoverageDetails) => record.coverageType);
  const isUIIAEnabled = getFeatureValue(Feature.NF_UIIA, false);

  return (
    <Grid container direction="column" spacing={4} pt={4}>
      <Grid item container spacing={3}>
        <Coverage />
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item container spacing={3}>
        <BusinessOwner />
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item container spacing={3}>
        <OperatingArea />

        <TerminalLocation />
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item container spacing={3}>
        <LossRuns />
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item container spacing={3}>
        <Commodities
          mtcEnabled={enabledCoverages.includes(
            CoverageType.CoverageMotorTruckCargo,
          )}
        />
      </Grid>

      {isUIIAEnabled && isTerminalStateTexas && (
        <>
          <Grid item>
            <Divider />
          </Grid>

          <Grid item container spacing={3}>
            <UIIA />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Operations;
