import * as React from 'react';
import {
  Box,
  Grid,
  Typography,
  styled,
  IconButton,
  Avatar,
  Button,
  InputAdornment,
} from '@material-ui/core';
import { Close, Visibility, VisibilityOff } from '@material-ui/icons';
import { Dialog, InputWithLabel } from '@nirvana/ui-kit';
import { useForm } from 'react-hook-form-v7';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ApolloError } from '@apollo/client';

import { ITheme } from 'src/assets/themes';
import { initSelector } from 'src/features/init/slices';
import { useChangePasswordMutation } from 'src/types/graphql-types';
import useSnackbar from 'src/hooks/useSnackbar';

const StyledAvatar = styled(Avatar)(({ theme }: { theme: ITheme }) => ({
  width: '65px',
  height: '65px',
  backgroundColor: theme.palette.primary.extraLight,
  color: theme.palette.primary.dark,
  ...theme.typography.h4,
}));

type FormData = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const Profile = () => {
  const [passwordFields, setPasswordFields] = React.useState({
    oldPassword: 'password',
    newPassword: 'password',
    confirmPassword: 'password',
  });
  const navigate = useNavigate();
  const enqueueSnackbar = useSnackbar();
  const init = useSelector(initSelector);
  const { user } = init;
  const {
    handleSubmit,
    formState: { errors },
    register,
    setError,
    watch,
  } = useForm({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  });
  const newPassword = watch('newPassword');
  const [changePasswordMutation, { loading }] = useChangePasswordMutation({
    onError: (error: ApolloError) => {
      const lastError: any =
        error.graphQLErrors?.[error.graphQLErrors.length - 1];

      if (
        lastError.includes("given password doesn't match persisted password")
      ) {
        setError('oldPassword', {
          type: 'manual',
          message: 'Wrong password entered',
        });
      } else {
        setError('newPassword', {
          type: 'manual',
          message: 'Unable to update password',
        });
      }
    },
    onCompleted: () => {
      // Navigate back to parent page
      navigate(-1);

      // Show success toast
      enqueueSnackbar(
        'Password Updated',
        'Your password has been successfully changed',
        { variant: 'success' },
      );
    },
  });

  const onSubmit = (data: FormData) => {
    changePasswordMutation({
      variables: {
        userId: user?.id || '',
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      },
    });
  };

  function handlePasswordTypeToggle(field: string) {
    setPasswordFields((prevFields) => ({
      ...prevFields,
      [field]: prevFields[field] === 'text' ? 'password' : 'text',
    }));
  }

  return (
    <Dialog
      title=""
      open={true}
      maxWidth="sm"
      onClose={() => {
        navigate(-1);
      }}
      primaryAction={
        <>
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={loading}
          >
            Save
          </Button>
        </>
      }
      disableBackdropClose
      PaperProps={{
        sx: {
          maxWidth: 592,
        },
      }}
      actionDivider
    >
      <form>
        <Box pt={2} pb={4} position="relative">
          <Grid container justifyContent="space-between" mb={5}>
            <Grid item>
              <Typography variant="h5" color="textPrimary" textAlign="center">
                Profile
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
                edge="end"
                size="small"
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            container
            flexWrap="nowrap"
            spacing={4}
            alignItems="center"
            mb={4}
          >
            <Grid item>
              <StyledAvatar alt="avatar">
                {user?.name.charAt(0).toUpperCase()}
              </StyledAvatar>
            </Grid>
            <Grid item container direction="column" spacing={1}>
              <Grid item container spacing={3} alignItems="center">
                <Grid item>
                  <Typography variant="body1" color="text.hint" width={40}>
                    Name
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    fontWeight="fontWeightRegular"
                    color="secondary"
                  >
                    {user?.name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container spacing={3} alignItems="center">
                <Grid item>
                  <Typography variant="body1" color="text.hint" width={40}>
                    Email
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    fontWeight="fontWeightRegular"
                    color="secondary"
                  >
                    {user?.email}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="body1" color="secondary" mb={3}>
                Change Password
              </Typography>
            </Grid>
            <Grid item container flexWrap="wrap" spacing={2}>
              <Grid item xs={6}>
                <InputWithLabel
                  label="Current Password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputLabelProps={{ color: 'primary' }}
                  placeholder="Enter Your Current Password"
                  type={passwordFields.oldPassword}
                  fullWidth
                  noMargin
                  {...register('oldPassword', {
                    required: 'Please enter your current password',
                  })}
                  error={!!errors.oldPassword}
                  helperText={(errors.oldPassword?.message as string) ?? ''}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => handlePasswordTypeToggle('oldPassword')}
                      >
                        {passwordFields.oldPassword === 'text' ? (
                          <Visibility fontSize="small" />
                        ) : (
                          <VisibilityOff fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <InputWithLabel
                  label="New Password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputLabelProps={{ color: 'primary' }}
                  placeholder="Enter Your New Password"
                  type={passwordFields.newPassword}
                  fullWidth
                  noMargin
                  {...register('newPassword', {
                    required: 'Please enter new password',
                    minLength: {
                      value: 8,
                      message: 'Password must be at least 8 characters long',
                    },
                    maxLength: {
                      value: 32,
                      message:
                        'Password should not contain more than 32 characters',
                    },
                    validate: {
                      noSpace: (value: string) => {
                        if (value.includes(' ')) {
                          return 'Password should not contain spaces';
                        }
                      },
                    },
                  })}
                  error={!!errors.newPassword}
                  helperText={
                    (errors.newPassword?.message as string) ?? undefined
                  }
                  autoComplete="new-password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => handlePasswordTypeToggle('newPassword')}
                      >
                        {passwordFields.newPassword === 'text' ? (
                          <Visibility fontSize="small" />
                        ) : (
                          <VisibilityOff fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <InputWithLabel
                  label="Confirm New Password"
                  type={passwordFields.confirmPassword}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputLabelProps={{ color: 'primary' }}
                  placeholder="Enter Your New Password"
                  fullWidth
                  noMargin
                  {...register('confirmPassword', {
                    required: 'Please confirm your new password',
                    validate: (value) =>
                      value === newPassword || 'Password repeat doesn’t match',
                  })}
                  error={!!errors.confirmPassword}
                  helperText={(errors.confirmPassword?.message as string) ?? ''}
                  autoComplete="new-password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() =>
                          handlePasswordTypeToggle('confirmPassword')
                        }
                      >
                        {passwordFields.confirmPassword === 'text' ? (
                          <Visibility fontSize="small" />
                        ) : (
                          <VisibilityOff fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Dialog>
  );
};

export default Profile;
