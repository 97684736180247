import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  makeStyles,
  Skeleton,
} from '@material-ui/core';

import { ITheme } from 'src/assets/themes';

const useStyles = makeStyles((theme: ITheme) => ({
  cardHeaderBar: {
    height: 4,
    width: 35,
    marginTop: 10,
    marginBottom: 8,
  },

  cardHeader: {
    padding: theme.typography.pxToRem(30),
    paddingBottom: theme.typography.pxToRem(14),
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: theme.palette.primary.light,
  },
  selectedTitle: {
    color: theme.palette.primary.main,
  },
  cardHeaderContent: {
    textAlign: 'center',
  },
  card: {
    position: 'relative',
    overflow: 'visible',
    borderWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 0px 9px rgba(128, 145, 196, 0.1)',
    borderRadius: theme.typography.pxToRem(10),
    borderColor: theme.palette.grey[50],
    paddingBottom: theme.spacing(3),
    width: '100%',
  },
}));

const PlanSkeleton = () => {
  const classes = useStyles();

  return (
    <>
      <Grid item container spacing={1} wrap="nowrap">
        {[1, 2, 3].map((_, b0index: number) => {
          return (
            <Grid
              key={`block0-${b0index}`}
              item
              flexGrow={1}
              style={{ display: 'flex' }}
            >
              <Card variant="outlined" className={clsx(classes.card)}>
                <CardHeader
                  title={
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Typography variant="h4">
                        <Skeleton animation="pulse" width={175} />
                      </Typography>

                      <div className={classes.cardHeaderBar} />
                    </Box>
                  }
                  subheader={
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Skeleton animation="pulse" width={150} height={60} />
                      </Grid>

                      <Grid item width="100%">
                        <Skeleton
                          animation="pulse"
                          width="100%"
                          height={67}
                          component="div"
                        />
                      </Grid>
                    </Grid>
                  }
                  subheaderTypographyProps={{
                    variant: 'h3',
                    color: 'secondary',
                  }}
                  classes={{
                    root: classes.cardHeader,
                    content: classes.cardHeaderContent,
                  }}
                />
                <CardContent>
                  <Grid
                    container
                    direction="column"
                    spacing={4}
                    alignItems="center"
                  >
                    {[1, 2].map((_, index: number) => {
                      return (
                        <Grid
                          key={`block1-${index}`}
                          item
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography
                            component="p"
                            mb={1}
                            textAlign="center"
                            variant="caption"
                          >
                            <Skeleton animation="pulse" width={175} />
                          </Typography>
                          <Typography
                            color="textPrimary"
                            component="p"
                            fontWeight="fontWeightBold"
                            mb={0.4}
                            textAlign="center"
                            variant="h6"
                            width="70%"
                          >
                            <Skeleton animation="pulse" />
                          </Typography>
                          <Typography
                            color="textSecondary"
                            fontWeight="fontWeightRegular"
                            textAlign="center"
                            width="80%"
                          >
                            <Skeleton animation="pulse" />
                          </Typography>
                        </Grid>
                      );
                    })}

                    {[1, 2].map((_, index: number) => {
                      return (
                        <Grid
                          key={`block2-${index}`}
                          item
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography
                            variant="body2"
                            color="primary"
                            component="p"
                            textAlign="center"
                            mb={1}
                            width="100%"
                          >
                            <Skeleton animation="pulse" />
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textPrimary"
                            textAlign="center"
                            width="90%"
                          >
                            <Skeleton animation="pulse" />
                          </Typography>
                          {index === 1 && (
                            <Typography
                              variant="body2"
                              color="textPrimary"
                              textAlign="center"
                              width="85%"
                            >
                              <Skeleton animation="pulse" />
                            </Typography>
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default PlanSkeleton;
