import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Box,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form-v7';
import {
  formatISO,
  isPast,
  isToday,
  isValid as isDateValid,
  isAfter as isDateAfter,
  isBefore as isDateBefore,
  startOfToday,
  addMonths,
  parseISO,
  sub,
} from 'date-fns';
import { Checkbox, DatePicker, Tooltip } from '@nirvana/ui-kit';
import { CoverageDetails, CoverageType } from '@nirvana/api/non-fleet';
import { useApplicationDetailsContext } from 'src/features/admitted/hooks/useApplicationDetails';
import { orderedPrimaryCoverages } from 'src/helpers/application';

const Coverage = () => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();
  const { producers } = useApplicationDetailsContext();

  const additionalCoverages = watch('operationsForm.coverages', [])
    .filter(
      (record: CoverageDetails) =>
        record.coverageType !== CoverageType.CoverageAutoLiability &&
        record.coverageType !== CoverageType.CoverageNonOwnedTrailer,
    )
    .sort((a: CoverageDetails, b: CoverageDetails) => {
      const aIndex = orderedPrimaryCoverages.indexOf(a.coverageType);
      const bIndex = orderedPrimaryCoverages.indexOf(b.coverageType);

      return aIndex - bIndex;
    });

  return (
    <>
      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={5}>
          <Box paddingTop="10px">
            <InputLabel htmlFor="effectiveDate">Effective Date</InputLabel>
            <FormHelperText style={{ width: 288 }}>
              The date when the policy becomes active
            </FormHelperText>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <Controller
              control={control}
              defaultValue={null}
              name="operationsForm.effectiveDate"
              render={({ field: { ref, value, onChange }, fieldState }) => {
                return (
                  <DatePicker
                    inputRef={ref}
                    inputFormat="MM/dd/yyyy"
                    shouldDisableDate={(date: unknown) =>
                      date instanceof Date
                        ? (isPast(date) && !isToday(date)) ||
                          isDateAfter(
                            date,
                            sub(addMonths(startOfToday(), 4), { days: 1 }),
                          )
                        : false
                    }
                    value={
                      value && typeof value === 'string'
                        ? parseISO(value)
                        : value
                    }
                    onChange={(value) => {
                      onChange(
                        formatISO(value, {
                          representation: 'date',
                        }),
                      );
                    }}
                    PopperProps={{
                      placement: 'bottom',
                      modifiers: [
                        {
                          name: 'flip',
                          options: {
                            fallbackPlacements: ['bottom', 'right', 'top'],
                          },
                        },
                      ],
                    }}
                    InputProps={{
                      placeholder: 'eg. 12/31/2021',
                      fullWidth: true,
                      error: !!fieldState.error,
                    }}
                  />
                );
              }}
              rules={{
                required: 'Please select effective date',
                validate: {
                  validateDate: (v) => {
                    // Make sure that v is of type Date
                    if (v && typeof v === 'string') {
                      v = parseISO(v);
                    }

                    if (!isDateValid(v)) {
                      return 'Please enter a valid date (mm/dd/yyyy)';
                    }

                    if (isDateAfter(v, addMonths(startOfToday(), 4))) {
                      return 'Effective date cannot be more than four months into the future';
                    }

                    if (isDateBefore(v, startOfToday())) {
                      return 'Effective date cannot be in the past';
                    }

                    return (
                      /^(2\d{3})-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])$/.test(
                        formatISO(v, {
                          representation: 'date',
                        }),
                      ) || 'Please enter a valid date (mm/dd/yyyy)'
                    );
                  },
                },
              }}
            />
            {(errors.operationsForm as any)?.effectiveDate?.message && (
              <FormHelperText error>
                {(errors.operationsForm as any)?.effectiveDate?.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={5}>
          <Box paddingTop="10px">
            <InputLabel htmlFor="start-date">Coverages</InputLabel>
            <FormHelperText style={{ width: 288 }}>
              Your limits and deductibles will be selected at the indication
              step
            </FormHelperText>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Box mb={2} style={{ opacity: '0.5' }}>
            <Controller
              name="operationsForm.coverages"
              render={() => (
                <Tooltip title="AL is a required coverage">
                  <span>
                    <Checkbox checked={true}>Auto Liability</Checkbox>
                  </span>
                </Tooltip>
              )}
            />
          </Box>

          {!!additionalCoverages.length && (
            <>
              <FormHelperText>
                Please select additional coverages
              </FormHelperText>
              <Box display="flex">
                {additionalCoverages.map((field: CoverageDetails) => (
                  <Controller
                    key={field.coverageType}
                    name="operationsForm.coverages"
                    render={({ field: { value, onChange } }) => {
                      const isChecked = value.find(
                        (record: CoverageDetails) =>
                          record.coverageType === field.coverageType,
                      )?.isRequired;

                      return (
                        <Box mb={2}>
                          <span>
                            <Checkbox
                              checked={isChecked}
                              onChange={(_, checked) => {
                                onChange(
                                  value.map((record: CoverageDetails) => {
                                    if (
                                      record.coverageType === field.coverageType
                                    ) {
                                      if (
                                        record.coverageType ===
                                          CoverageType.CoverageAutoPhysicalDamage &&
                                        !checked
                                      ) {
                                        setValue(
                                          'operationsForm.hasHiredAuto',
                                          false,
                                        );
                                      }

                                      return {
                                        ...record,
                                        isRequired: checked,
                                      };
                                    }
                                    return record;
                                  }),
                                );
                              }}
                            >
                              {field.label}
                            </Checkbox>
                          </span>
                        </Box>
                      );
                    }}
                  />
                ))}
              </Box>
            </>
          )}
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={5}>
          <Box paddingTop="10px">
            <InputLabel htmlFor="producer">Producer</InputLabel>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <Controller
              control={control}
              defaultValue=""
              name="operationsForm.producerID"
              render={({ field: { value, onChange }, fieldState }) => (
                <Select
                  displayEmpty
                  value={value}
                  variant="outlined"
                  onChange={onChange}
                  error={!!fieldState.error}
                >
                  <MenuItem value="">
                    <Typography color="text.hint">Select</Typography>
                  </MenuItem>
                  {producers?.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              )}
              rules={{ required: 'Please select a producer' }}
            />
            {(errors.operationsForm as any)?.producerID?.message && (
              <FormHelperText error>
                {(errors.operationsForm as any)?.producerID?.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default Coverage;
