import clsx from 'clsx';
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import NumberFormat from 'react-number-format';
import {
  AdmittedAppPackages,
  CoverageDetails,
  CoverageType,
} from '@nirvana/api/non-fleet';
import { Show } from '@nirvana/ui-kit';

import Button from 'src/components/button';
import { orderedPrimaryCoverages } from 'src/helpers/application';

import { getIndicationPackageName } from '../utils';
import { useStyles } from './styles';

const getPremiumSubtext = (
  coverageRecord: CoverageDetails,
  planDetails: AdmittedAppPackages,
) => {
  switch (coverageRecord.coverageType) {
    case CoverageType.CoverageAutoPhysicalDamage:
      return (
        <NumberFormat
          value={planDetails.tivPercentage}
          displayType="text"
          thousandSeparator={true}
          suffix="% per $100 TIV"
        />
      );

    case CoverageType.CoverageGeneralLiability:
      return (
        <NumberFormat
          value={coverageRecord.premium}
          displayType="text"
          thousandSeparator={true}
          prefix="$"
          suffix=" Premium"
        />
      );

    default:
      return (
        <NumberFormat
          value={coverageRecord.premiumPerUnit}
          displayType="text"
          thousandSeparator={true}
          prefix="$"
          suffix=" per Unit"
        />
      );
  }
};

interface IPlanSelector {
  value: string;
  planDetails: AdmittedAppPackages;
  onChange: (e: AdmittedAppPackages) => void;
  error?: boolean;
}

const PlanSelector = ({
  value,
  planDetails,
  onChange,
  error,
}: IPlanSelector) => {
  const classes = useStyles();
  const selected = planDetails.id === value;
  const recommended = planDetails.isRecommended;

  planDetails?.primaryCovs?.sort((a, b) => {
    const aIndex = orderedPrimaryCoverages.indexOf(a.coverageType);
    const bIndex = orderedPrimaryCoverages.indexOf(b.coverageType);

    return aIndex - bIndex;
  });

  return (
    <Grid item flexGrow={1} style={{ display: 'flex', flex: 1 }}>
      <Card
        variant="outlined"
        className={clsx(classes.card, 'cursor-pointer', {
          [classes.selected]: selected,
          [classes.recommended]: recommended,
          [classes.error]: error,
        })}
        onClick={() => {
          if (planDetails.isDisabled) {
            return;
          }

          onChange(planDetails);
        }}
      >
        <div
          className={clsx({
            [classes.disabled]: planDetails.isDisabled,
          })}
        >
          {recommended ? (
            <Box className={classes.cardChip}>
              <Typography variant="caption" fontWeight="600">
                Recommended
              </Typography>
            </Box>
          ) : null}

          <CardHeader
            title={
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography
                  variant="h6"
                  color="textPrimary"
                  fontWeight="fontWeightBold"
                >
                  {getIndicationPackageName(planDetails.packageName)}
                </Typography>
                <div className={classes.cardHeaderBar} />
              </Box>
            }
            subheader={
              <>
                <Typography
                  variant="h6"
                  component="span"
                  fontWeight="fontWeightRegular"
                  color="textPrimary"
                  className={classes.currencySymbol}
                >
                  $
                </Typography>
                <NumberFormat
                  value={planDetails.totalPremium}
                  displayType="text"
                  thousandSeparator={true}
                />
                <Grid item>
                  <Button
                    type="button"
                    color="primary"
                    variant="outlined"
                    sx={
                      selected
                        ? {
                            backgroundColor: () => '#D7DCFE !important',
                            marginTop: (theme: Theme) => theme.spacing(2),
                            '&:hover': {
                              backgroundColor: () => '#D7DCFE',
                            },
                          }
                        : {
                            marginTop: (theme: Theme) => theme.spacing(2),
                          }
                    }
                    fullWidth
                    size="large"
                  >
                    {selected ? 'Selected' : 'Select'}
                  </Button>
                </Grid>
              </>
            }
            titleTypographyProps={{
              className: selected ? classes.selectedTitle : classes.title,
              variant: 'h6',
              fontWeight: 'regular',
            }}
            subheaderTypographyProps={{
              variant: 'h3',
              color: 'secondary',
            }}
            classes={{
              root: classes.cardHeader,
              content: classes.cardHeaderContent,
            }}
          />
        </div>
        <CardContent>
          <div
            className={clsx({
              [classes.disabled]: planDetails.isDisabled,
            })}
          >
            <Grid container direction="column" spacing={4} alignItems="center">
              {planDetails?.primaryCovs?.map((coverage) => {
                return (
                  <Grid
                    key={coverage.coverageType}
                    item
                    container
                    direction="column"
                    justifyContent="center"
                  >
                    <Typography
                      color="primary"
                      component="p"
                      fontWeight="fontWeightRegular"
                      mb={1}
                      textAlign="center"
                      variant="caption"
                    >
                      {coverage.label}
                    </Typography>
                    <Typography
                      color="textPrimary"
                      component="p"
                      fontWeight="fontWeightBold"
                      mb={0.4}
                      textAlign="center"
                      variant="h6"
                    >
                      {getPremiumSubtext(coverage, planDetails)}
                    </Typography>
                    <Show
                      when={
                        coverage.coverageType !==
                        CoverageType.CoverageGeneralLiability
                      }
                    >
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        fontWeight="fontWeightRegular"
                        textAlign="center"
                      >
                        Premium -{' '}
                        <NumberFormat
                          value={coverage.premium}
                          displayType="text"
                          thousandSeparator={true}
                          prefix="$"
                        />
                      </Typography>
                    </Show>

                    <Show
                      when={
                        coverage.coverageType ===
                        CoverageType.CoverageGeneralLiability
                      }
                    >
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        fontWeight="fontWeightRegular"
                        textAlign="center"
                      >
                        Not Subject to Audit
                      </Typography>
                    </Show>
                  </Grid>
                );
              })}

              <Show
                when={
                  !!(
                    planDetails?.statutoryCovs &&
                    planDetails?.statutoryCovs?.length
                  )
                }
              >
                <Grid item container direction="column" justifyContent="center">
                  <Typography
                    color="primary"
                    component="p"
                    fontWeight="fontWeightRegular"
                    mb={1}
                    textAlign="center"
                    variant="caption"
                  >
                    Statutory Coverages
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textPrimary"
                    textAlign="center"
                  >
                    (
                    {planDetails?.statutoryCovs
                      ?.map((coverage) => coverage.label)
                      .join('/')}
                    )
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textPrimary"
                    textAlign="center"
                    sx={{ maxWidth: '85%', margin: '0 auto' }}
                  >
                    Offered at statutory minimum limits where available
                  </Typography>
                </Grid>
              </Show>

              <Show
                when={
                  !!(
                    planDetails?.additionalCovs &&
                    planDetails?.additionalCovs?.length
                  )
                }
              >
                <Grid item container direction="column" justifyContent="center">
                  <Typography
                    variant="caption"
                    color="primary"
                    component="p"
                    textAlign="center"
                    mb={1}
                  >
                    Additional coverages
                  </Typography>
                  <Show when={!planDetails?.isDisabled}>
                    <span className="flex justify-center items-center text-center">
                      UIIA{' '}
                      <Tooltip title="Be sure to review UIIA guidelines for any additional requirements.">
                        <span>
                          <HiOutlineInformationCircle className="ml-2 cursor-pointer text-primary-main" />
                        </span>
                      </Tooltip>
                    </span>
                  </Show>
                  {planDetails?.additionalCovs?.map((coverage) => (
                    <Typography
                      key={coverage.coverageType}
                      variant="caption"
                      color="textPrimary"
                      textAlign="center"
                    >
                      {coverage.label}

                      {coverage.limit && (
                        <NumberFormat
                          value={coverage.limit}
                          displayType="text"
                          thousandSeparator={true}
                          prefix=" - Limit $"
                        />
                      )}
                    </Typography>
                  ))}
                </Grid>
              </Show>
            </Grid>
          </div>
          <Show when={planDetails?.isDisabled}>
            <Alert severity="warning" sx={{ marginTop: 2, marginBottom: -4 }}>
              Not available. For this package, go back to the previous step and
              remove <strong>UIIA Coverage</strong>
            </Alert>
          </Show>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default PlanSelector;
