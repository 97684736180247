import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid } from '@material-ui/core';
import { AttachmentView } from '@nirvana/ui-kit';

import { FileUploadDialog } from 'src/components/dialog';
import { IFileUploadDialogProps } from 'src/components/dialog/fileUploadDialog';

import IconFileUpload from 'src/assets/icons/upload-primary.svg';
import Button from './default';

export interface IFileUploadProps
  extends Omit<IFileUploadDialogProps, 'dialogOpen' | 'onClose'> {}

/**
 * File upload component that renders a button to upload files.
 * Uses react-dropzone (Refer: https://react-dropzone.js.org)
 * @component
 *
 * @param {Object} props
 * @param {Object[]} props.value - List of files which are already uploaded.
 * @param {Function} props.onChange - Callback invoked with an array of files when one or more files are selected.
 * @param {Function} props.onRemove - Callback invoked with updated value that was passed in when a file is removed.
 * @param {Function} props.title - Title of the dialog.
 *
 * @example
 * const handleChange = (files: any) => {
 *   // Do something here to handle selected files
 * };
 * return <FileUpload onChange={handleChange} value={[]} />
 */
const FileUpload = (props: IFileUploadProps) => {
  const { value, onChange, onRemove, onDownload, title } = props;
  const [dialogVisibility, setDialogVisibility] =
    React.useState<boolean>(false);
  const handleDrop = React.useCallback(
    (acceptedFiles) => {
      onChange(acceptedFiles);
    },
    [onChange],
  );

  const { getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: handleDrop,
  });

  return (
    <>
      <Grid container flexDirection="column" spacing={3}>
        <Grid item>
          <input {...getInputProps()} />
          <Button
            variant="outlined"
            startIcon={<img src={IconFileUpload} alt="Upload" />}
            type="button"
            onClick={() => setDialogVisibility(true)}
          >
            Upload
          </Button>
        </Grid>
        {!!(value && value.length) && (
          <Grid item>
            <AttachmentView
              files={value.map((record) => ({ filename: record.name }))}
              onClick={() => setDialogVisibility(true)}
              onRemove={(index) => onRemove(value[index])}
              onDownload={(_, index) => onDownload(value[index], index)}
            />
          </Grid>
        )}
      </Grid>

      <FileUploadDialog
        {...props}
        dialogOpen={dialogVisibility}
        onClose={() => setDialogVisibility(false)}
        title={title}
      />
    </>
  );
};

export default FileUpload;
