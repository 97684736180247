import { FormHelperText } from '@material-ui/core';
import {
  FileDestinationGroup,
  FileMetadata,
  FileType,
} from '@nirvana/api/quoting';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  cancelApplicationFilesUpload,
  downloadApplicationFile,
  removeApplicationFileUpload,
  resetApplicationFileUpload,
  retryApplicationFilesUpload,
  uploadApplicationFiles,
} from 'src/features/application/actions';

import { getHelperText } from 'src/features/application/components/create/additionalInformation';
import {
  applicationSelector,
  FileUploadProgress,
} from 'src/features/application/slices';
import { FileUpload } from '../button';

const LossRunUploadWithSummary = ({
  control,
  errors,
  setValue,
  watch,
  fileDefaultValue,
}: any) => {
  const application = useSelector(applicationSelector);
  const { uploadedFiles } = application;
  const dispatch = useDispatch();

  const values = watch(['lossRunFiles']);

  const [isUploading, setUploading] = React.useState<boolean>(false);

  const handleLossRunFileUpload = (files: any) => {
    setUploading(true);

    files.forEach((file: any) => {
      dispatch(
        uploadApplicationFiles(file, {
          type: FileType.FileTypeLossRun,
          destinationGroup: FileDestinationGroup.FileDestinationGroupQuoting,
        }),
      );
    });
  };

  const handleLossRunFileUploadCancel = (progress: FileUploadProgress) => {
    dispatch(cancelApplicationFilesUpload(progress));
  };

  const handleLossRunFileUploadRetry = (progress: FileUploadProgress) => {
    dispatch(retryApplicationFilesUpload(progress));
  };

  const handleFileDownload = (handleId?: string) => {
    if (!handleId) {
      return;
    }

    dispatch(downloadApplicationFile(handleId));
  };

  React.useEffect(() => {
    if (!isUploading) {
      return;
    }

    const uploadedFilesValues = Object.values(uploadedFiles);
    const newFiles: any = [];
    uploadedFilesValues.forEach((record) => {
      if (record.status === 'succeeded') {
        newFiles.push(record);
        dispatch(removeApplicationFileUpload(record));
      }
    });
    setValue('lossRunFiles', [...newFiles, ...values.lossRunFiles]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isUploading, uploadedFiles, setValue]);

  return (
    <Controller
      name="lossRunFiles"
      defaultValue={fileDefaultValue || []}
      control={control}
      rules={{
        validate: {
          required: (value: FileMetadata[]) => {
            if (value.length === 0) {
              return 'Please upload at least one file';
            }
            return true;
          },
        },
      }}
      render={(props) => {
        return (
          <>
            <FileUpload
              key="loss-run-file-upload"
              value={Object.values(uploadedFiles).concat(props.value)}
              onChange={handleLossRunFileUpload}
              onRemove={(file) => {
                if (file && file.handle && uploadedFiles[file.handle]) {
                  // Remove by filename
                  dispatch(removeApplicationFileUpload(file));
                } else if (file && file.handle) {
                  const newValue = props.value.filter(
                    (record: FileMetadata) => record.handle !== file.handle,
                  );
                  props.onChange(newValue);
                } else if (!file) {
                  // Remove all
                  dispatch(resetApplicationFileUpload());
                  props.onChange([]);
                } else {
                  // Invalid case - do nothing
                }
              }}
              onCancel={handleLossRunFileUploadCancel}
              onRetry={handleLossRunFileUploadRetry}
              onDownload={(file) => handleFileDownload(file.handle)}
            />
            {!!errors.lossRunFiles && (
              <FormHelperText error>
                {getHelperText('lossRunFiles', errors)}
              </FormHelperText>
            )}
          </>
        );
      }}
    />
  );
};

export const LossRunUploadWithSummaryContainer = (props: any) => {
  const methods = useFormContext();

  return <LossRunUploadWithSummary {...methods} {...props} />;
};

export default LossRunUploadWithSummaryContainer;
export { LossRunUploadWithSummary };
