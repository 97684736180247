import { Box, Button, Grid, Typography, makeStyles } from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import { NavLink, useParams } from 'react-router-dom';

import { utils as UtilsHelper } from 'src/helpers';
import IconSuccess3D from 'src/assets/icons/success-3d.svg';

import { fetchBindableQuote } from '../../queries/quote';
import PdfDownload from './pdfDownload';

const useStyles = makeStyles({
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const Bound = () => {
  const classes = useStyles();
  const { applicationId = '' } = useParams();

  const { data } = useQuery(['bindable-quote', applicationId], () =>
    fetchBindableQuote(applicationId),
  );

  const handleQuoteDownload = () => {
    if (data?.quotePDF) {
      UtilsHelper.downloadFile(data.quotePDF);
    }
  };

  return (
    <Box flex={1} className={classes.center}>
      <Grid container direction="column" spacing={6} alignItems="center">
        <Grid item>
          <img src={IconSuccess3D} alt="Success" width={147} />
        </Grid>

        <Grid item>
          <Typography
            variant="h4"
            fontWeight="fontWeightBold"
            textAlign="center"
            maxWidth={700}
            mb={1}
          >
            Congratulations! You&apos;re almost there.
          </Typography>
          <Typography
            variant="body2"
            textAlign="center"
            maxWidth={700}
            color="textSecondary"
            mx="auto"
          >
            We just need to verify your signatures and we&apos;ll bind the
            quote. Check your email for policy docs!
          </Typography>
        </Grid>

        <Grid item>
          <PdfDownload
            filename={`Policy Binder_#${applicationId.substring(0, 6)}`}
            onClick={handleQuoteDownload}
          />
        </Grid>

        <Grid item>
          <NavLink to="/applications/list" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{
                textTransform: 'none',
              }}
            >
              Back to home
            </Button>
          </NavLink>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Bound;
