import * as React from 'react';
import { Outlet } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Container,
  makeStyles,
  Grid,
  Typography,
} from '@material-ui/core';

import BackgroundImage from 'src/assets/images/background.jpg';

import Branding from 'src/components/branding';
import { ITheme } from 'src/assets/themes';

const useStyles = makeStyles((theme: ITheme) => ({
  cardContainer: {
    position: 'relative',
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
  },
  cardContent: {
    padding: theme.spacing(5, 7),
    display: 'flex',
    flexDirection: 'column',
    minHeight: 400,
  },
  bgWrapper: {
    backgroundImage: `url(${BackgroundImage})`,
    flex: 1,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: theme.spacing(12),
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.overlay,
    opacity: 0.49,
  },
  text: {
    color: theme.palette.text.disabled,
  },
  logoOutline: {
    position: 'absolute',
    backgroundColor: theme.palette.gold.main,
    height: 2,
    width: theme.typography.pxToRem(83),
    left: theme.spacing(1.5),
    bottom: -4,
  },
  card: {
    width: theme.typography.pxToRem(495),
    maxWidth: theme.typography.pxToRem(495),
    border: '1px solid #E6E7EF',
    boxShadow: '0px 17px 18px rgba(128, 145, 196, 0.13)',
  },
}));

/**
 * HOC for unauthenticated pages. Includes basic skeleton including branding and content.
 * @component
 *
 * @param {Object} props
 * @param {Object} props.children - Text or any valid ReactNode.
 *
 * @example
 * return <AuthLayout>Login component goes here</AuthLayout>
 */
const AuthLayout = () => {
  const classes = useStyles();

  return (
    <Box className={classes.bgWrapper}>
      <Box className={classes.overlay} />
      <Container className={classes.cardContainer}>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item flexBasis="50%">
            <Grid container direction="column" spacing={7}>
              <Grid item>
                <Box display="flex" position="relative">
                  <Branding inverted />
                  <Box className={classes.logoOutline} />
                </Box>
              </Grid>
              <Grid item>
                <Typography variant="h3" pl={1} className={classes.text}>
                  Modern fleet insurance that rewards safety
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item flexBasis="50%" container justifyContent="flex-end">
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Outlet />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AuthLayout;
