import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';

import { ApplicationHeader } from '@nirvana/ui-kit';
import { ApplicationDetail } from '@nirvana/api/quoting';
import { CoverageType } from '@nirvana/api/non-fleet';

import useSnackbar from 'src/hooks/useSnackbar';
import IconCheck from 'src/assets/icons/check.svg';

import { bindQuote, fetchBindableQuote } from '../../queries/quote';
import QuoteSummary from './summary';
import QuoteDetails from './details';
import QuoteCoverages from './coverages';
import { AlertWrapper, useStyles } from './styles';
import QuoteSignature from './signature';

const Quote = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { applicationId = '' } = useParams();
  const enqueueSnackbar = useSnackbar();

  const { data, isLoading } = useQuery(['bindable-quote', applicationId], () =>
    fetchBindableQuote(applicationId),
  );

  const applicationDetails = data?.appDetails;

  const { mutate } = useMutation(() => bindQuote(applicationId), {
    onSuccess: () => {
      navigate(`/nrb/${applicationId}/bound`, { replace: true });
    },
    onError: () => {
      enqueueSnackbar(
        'Bind error',
        'Failed to bind quote. Please try again later.',
        { variant: 'error' },
      );
    },
  });

  const handleBind = () => {
    mutate();
  };

  if (isLoading) {
    return (
      <Box flex="1" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <ApplicationHeader
        details={
          {
            summary: {
              shortID: applicationDetails?.shortID,
              companyName:
                applicationDetails?.nrb?.operationsForm?.companyInfo?.name,
              dotNumber:
                applicationDetails?.nrb?.operationsForm?.companyInfo?.dotNumber,
              underwriterName: 'Taylor Gosiaco',

              underwriterEmail: 'taylor@nirvanatech.com',
            },
          } as ApplicationDetail
        }
        onBack={() => {
          navigate('/applications/list', { replace: true });
        }}
      />

      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Paper elevation={0} square sx={{ position: 'relative' }}>
            <AlertWrapper>
              <Container maxWidth="md">
                <Box display="flex">
                  <Box display="flex" flex="1">
                    <img src={IconCheck} width={14} />
                    <Typography variant="body1" marginLeft={1}>
                      Quote is ready for binding
                    </Typography>
                  </Box>
                  <Typography variant="caption">
                    Last Updated on: 15/03/2021
                  </Typography>
                </Box>
              </Container>
            </AlertWrapper>

            <QuoteSummary data={data?.quote} quotePDFLink={data?.quotePDF} />
          </Paper>
        </Grid>

        <Grid item>
          <QuoteDetails appDetails={data?.appDetails?.nrb} data={data?.quote} />
        </Grid>

        {!!data?.appDetails && (
          <Grid item>
            <QuoteCoverages
              appDetails={data?.appDetails?.nrb}
              mainCoverages={data?.quote.coverages.filter((record) => {
                return (
                  record.coverageType ===
                    CoverageType.CoverageAutoPhysicalDamage ||
                  record.coverageType === CoverageType.CoverageMotorTruckCargo
                );
              })}
              ancillaryCoverages={Object.values(
                data?.appDetails?.nrb?.coveragesForm?.ancillaryCovs || {},
              )}
            />
          </Grid>
        )}

        <Grid item>
          <QuoteSignature signaturePacketLink={data?.signaturePacketZip} />
        </Grid>
      </Grid>

      {/* Spacer to compensate for height of fixed bottom bar */}
      <Box py="64px" />

      <AppBar
        classes={{ root: classes.footerAppBar }}
        color="inherit"
        elevation={0}
        position="fixed"
        sx={{ top: 'auto', bottom: 0 }}
      >
        <Container>
          <Toolbar>
            <Grid container justifyContent="space-between">
              <Grid item />
              <Grid item>
                <Button variant="contained" onClick={handleBind}>
                  Bind Quote
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default Quote;
