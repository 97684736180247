import * as React from 'react';
import { Box } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { TableBasic } from '@nirvana/ui-kit';
import { CoverageRecord, CoverageType } from '@nirvana/api/quoting';

import { application as ApplicationHelper } from 'src/helpers';

interface IQuoteCoverages {
  approvedCoverages?: CoverageRecord[];
}

const QuoteCoverages = ({ approvedCoverages = [] }: IQuoteCoverages) => {
  const coverages = React.useMemo(() => {
    const possibleMainCoverages = [
      CoverageType.CoverageAutoLiability,
      CoverageType.CoverageAutoPhysicalDamage,
      CoverageType.CoverageMotorTruckCargo,
      CoverageType.CoverageGeneralLiability,
    ];

    // Separate main and other coverages
    const mainCoverages = approvedCoverages.filter((record) =>
      possibleMainCoverages.includes(record.coverageType),
    );
    const otherCoverages = approvedCoverages.filter(
      (record) =>
        !possibleMainCoverages.includes(record.coverageType) &&
        !ApplicationHelper.isEndorsementCoverage(record.coverageType),
    );

    // Sort main coverages
    const mainCoveragesMap = {};
    for (let i = 0, l = mainCoverages.length; i < l; i += 1) {
      if (possibleMainCoverages.includes(mainCoverages[i].coverageType)) {
        mainCoveragesMap[mainCoverages[i].coverageType] = mainCoverages[i];
      }
    }
    const orderedMainCoverages = ApplicationHelper.getOrderedCoverages(
      Object.keys(mainCoveragesMap) as CoverageType[],
    ).map((coverage) => mainCoveragesMap[coverage]);

    return orderedMainCoverages.concat(otherCoverages);
  }, [approvedCoverages]);

  return (
    <TableBasic
      headers={[
        {
          key: 'coverages',
          content: <>Coverages</>,
        },
        {
          key: 'limits',
          content: <>Limits</>,
        },
        {
          key: 'symbol',
          content: <>Symbol</>,
        },
        {
          key: 'definition',
          content: <>Definition</>,
        },
      ]}
      rows={
        coverages.map((record: CoverageRecord) => ({
          key: `coverage-${record.coverageType}`,
          columns: [
            {
              content: (
                <Box py={1}>
                  {ApplicationHelper.getIndicationOptionsFeatureLabel(
                    record?.coverageType,
                  )}
                </Box>
              ),
            },
            {
              content: (
                <Box py={1}>
                  <NumberFormat
                    value={record?.limit}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="$"
                  />
                </Box>
              ),
            },
            {
              content: (
                <Box py={1}>
                  {record?.symbolsAndDefinitions?.[0].symbol || '-'}
                </Box>
              ),
            },
            {
              content: (
                <Box py={1}>
                  {record?.symbolsAndDefinitions?.[0].definition || '-'}
                </Box>
              ),
            },
          ],
        })) ?? []
      }
    />
  );
};

export default QuoteCoverages;
