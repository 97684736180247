import { Box, Container, Typography } from '@material-ui/core';
import {
  AdmittedApp,
  CoverageDetails,
  CoverageType,
  QuoteOption,
} from '@nirvana/api/non-fleet';
import { Show, Switch, TableBasic, TableSpaced } from '@nirvana/ui-kit';
import NumberFormat from 'react-number-format';
import { orderedPrimaryCoverages } from 'src/helpers/application';
import { getMainCoverages } from './utils';

interface QuoteDetailsProps {
  appDetails: AdmittedApp | undefined;
  data: QuoteOption | undefined;
}

const QuoteDetails = ({ data, appDetails }: QuoteDetailsProps) => {
  const mainCoverages = getMainCoverages(data?.coverages ?? []).sort(
    (a: CoverageDetails, b: CoverageDetails) => {
      const aIndex = orderedPrimaryCoverages.indexOf(a.coverageType);
      const bIndex = orderedPrimaryCoverages.indexOf(b.coverageType);

      return aIndex - bIndex;
    },
  );

  const numberOfUnits = appDetails?.equipmentsForm?.vehicles?.length ?? 0;

  return (
    <Container maxWidth="md">
      <TableSpaced
        headers={[
          {
            key: 'coverage',
            content: <>Coverage</>,
            styles: { width: '22%' },
          },
          {
            key: 'exposure',
            content: <>Exposure</>,
            styles: { width: '18%' },
          },
          {
            key: 'rate',
            content: <>Rate</>,
            styles: { width: '18%' },
          },
          {
            key: 'deductible',
            content: <>Deductible</>,
            styles: { width: '10%' },
          },
          {
            key: 'limit',
            content: <>Limit</>,
            styles: { width: '22%' },
          },
          {
            key: 'premium',
            content: <>Premium</>,
            styles: {
              width: '10%',
              textAlign: 'right',
            },
          },
        ]}
        rows={
          mainCoverages?.map((record) => {
            return {
              columns: [
                {
                  content: (
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      fontWeight="fontWeightBold"
                    >
                      {record.label}
                    </Typography>
                  ),
                },
                {
                  content: (
                    <Switch
                      fallback={
                        <Typography variant="caption" color="textSecondary">
                          {numberOfUnits} {numberOfUnits > 1 ? 'Units' : 'Unit'}
                        </Typography>
                      }
                    >
                      <Switch.Match
                        when={
                          record.coverageType ===
                          CoverageType.CoverageAutoPhysicalDamage
                        }
                      >
                        <Typography variant="caption" color="textSecondary">
                          <NumberFormat
                            value={data?.TIV}
                            displayType="text"
                            thousandSeparator={true}
                            prefix="$"
                            suffix=" TIV"
                          />
                        </Typography>
                      </Switch.Match>

                      <Switch.Match
                        when={
                          record.coverageType ===
                          CoverageType.CoverageGeneralLiability
                        }
                      >
                        <Typography variant="caption" color="textSecondary">
                          Not Subject to Audit
                        </Typography>
                      </Switch.Match>
                    </Switch>
                  ),
                },
                {
                  content: (
                    <Switch
                      fallback={
                        <Typography variant="caption" color="textSecondary">
                          <NumberFormat
                            prefix="$"
                            suffix="/unit"
                            displayType="text"
                            value={record.premiumPerUnit}
                            thousandSeparator={true}
                          />
                        </Typography>
                      }
                    >
                      <Switch.Match
                        when={
                          record.coverageType ===
                          CoverageType.CoverageAutoPhysicalDamage
                        }
                      >
                        <Typography variant="caption" color="textSecondary">
                          <NumberFormat
                            value={data?.TIVPercentage}
                            displayType="text"
                            thousandSeparator={true}
                            suffix="% per TIV"
                          />
                        </Typography>
                      </Switch.Match>

                      <Switch.Match
                        when={
                          record.coverageType ===
                          CoverageType.CoverageGeneralLiability
                        }
                      >
                        <Typography variant="caption" color="textSecondary">
                          -
                        </Typography>
                      </Switch.Match>
                    </Switch>
                  ),
                },
                {
                  content: (
                    <Typography variant="caption" color="textSecondary">
                      {' '}
                      <NumberFormat
                        prefix="$"
                        suffix={
                          appDetails?.indicationForm?.coverages
                            ?.isAPDMTCDeductibleCombined &&
                          (record.coverageType ===
                            CoverageType.CoverageAutoPhysicalDamage ||
                            record.coverageType ===
                              CoverageType.CoverageMotorTruckCargo)
                            ? ' *'
                            : ''
                        }
                        value={record.deductible}
                        displayType="text"
                        thousandSeparator={true}
                      />
                    </Typography>
                  ),
                },
                {
                  content: (
                    <Show when={record.limit} fallback="N/A">
                      {(value) => (
                        <>
                          <Switch>
                            <Switch.Match
                              when={
                                record.coverageType ===
                                  CoverageType.CoverageAutoLiability ||
                                record.coverageType ===
                                  CoverageType.CoverageMotorTruckCargo
                              }
                            >
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                <NumberFormat
                                  prefix="$"
                                  suffix=" CSL"
                                  value={value}
                                  displayType="text"
                                  thousandSeparator={true}
                                />
                              </Typography>
                            </Switch.Match>
                            <Switch.Match
                              when={
                                record.coverageType ===
                                CoverageType.CoverageGeneralLiability
                              }
                            >
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                component="p"
                                mt={1}
                              >
                                <NumberFormat
                                  prefix="$"
                                  suffix=" Occurrence"
                                  value={1000000}
                                  displayType="text"
                                  thousandSeparator={true}
                                />
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                component="p"
                                mt={1}
                              >
                                <NumberFormat
                                  prefix="$"
                                  suffix=" Aggregate"
                                  value={2000000}
                                  displayType="text"
                                  thousandSeparator={true}
                                />
                              </Typography>
                            </Switch.Match>
                            <Switch.Match
                              when={
                                record.coverageType ===
                                CoverageType.CoverageAutoPhysicalDamage
                              }
                            >
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                component="p"
                                mt={1}
                              >
                                ACV
                              </Typography>

                              <Typography
                                variant="caption"
                                color="textSecondary"
                                component="p"
                                mt={1}
                              >
                                <NumberFormat
                                  prefix="$"
                                  suffix=" CSL"
                                  value={1000000}
                                  displayType="text"
                                  thousandSeparator={true}
                                />
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                component="p"
                                mt={1}
                              >
                                <NumberFormat
                                  prefix="$"
                                  suffix="&nbsp;Combined&nbsp;Tractor/Trailer"
                                  value={250000}
                                  displayType="text"
                                  thousandSeparator={true}
                                />
                              </Typography>
                            </Switch.Match>
                          </Switch>
                        </>
                      )}
                    </Show>
                  ),
                },
                {
                  content: (
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      fontWeight="fontWeightSemiBold"
                    >
                      <NumberFormat
                        prefix="$"
                        value={record.premium}
                        displayType="text"
                        thousandSeparator={true}
                      />
                    </Typography>
                  ),
                  styles: {
                    textAlign: 'right',
                  },
                },
              ],
            };
          }) ?? []
        }
      />

      <Box pt={1} mb={1}>
        <TableBasic
          headers={[]}
          rows={[
            {
              columns: [
                {
                  content: (
                    <Typography
                      variant="caption"
                      color="textPrimary"
                      fontWeight="fontWeightMedium"
                    >
                      Subtotal Premium
                    </Typography>
                  ),
                },
                {
                  content: (
                    <Typography
                      variant="caption"
                      color="textPrimary"
                      fontWeight="fontWeightMedium"
                    >
                      <NumberFormat
                        value={data?.subTotalPremium}
                        displayType="text"
                        thousandSeparator={true}
                        prefix="$"
                      />
                    </Typography>
                  ),
                  styles: {
                    textAlign: 'right',
                  },
                },
              ],
            },
            {
              columns: [
                {
                  content: (
                    <Typography
                      variant="caption"
                      color="textPrimary"
                      fontWeight="fontWeightMedium"
                    >
                      Flat Charges
                    </Typography>
                  ),
                },
                {
                  content: (
                    <Typography
                      variant="caption"
                      color="textPrimary"
                      fontWeight="fontWeightMedium"
                    >
                      <NumberFormat
                        value={data?.flatCharges}
                        displayType="text"
                        thousandSeparator={true}
                        prefix="$"
                      />
                    </Typography>
                  ),
                  styles: {
                    textAlign: 'right',
                  },
                },
              ],
            },
            ...(data?.stateSurcharge
              ? [
                  {
                    columns: [
                      {
                        content: (
                          <Typography
                            variant="caption"
                            color="textPrimary"
                            fontWeight="fontWeightMedium"
                          >
                            State Surcharge
                          </Typography>
                        ),
                      },
                      {
                        content: (
                          <Typography
                            variant="caption"
                            color="textPrimary"
                            fontWeight="fontWeightMedium"
                          >
                            <NumberFormat
                              value={data?.stateSurcharge}
                              displayType="text"
                              thousandSeparator={true}
                              prefix="$"
                            />
                          </Typography>
                        ),
                        styles: {
                          textAlign: 'right',
                        },
                      },
                    ],
                  } as any,
                ]
              : []),
            {
              columns: [
                {
                  content: (
                    <Typography variant="body1" fontWeight="fontWeightSemiBold">
                      Total Premium
                    </Typography>
                  ),
                },
                {
                  content: (
                    <Typography variant="body1" fontWeight="fontWeightSemiBold">
                      <NumberFormat
                        value={data?.totalPremium}
                        displayType="text"
                        thousandSeparator={true}
                        prefix="$"
                      />
                    </Typography>
                  ),
                  styles: {
                    textAlign: 'right',
                  },
                },
              ],
            },
          ]}
        />
      </Box>
      <Show
        when={appDetails?.indicationForm?.coverages?.isAPDMTCDeductibleCombined}
      >
        <Typography variant="caption" color="textSecondary" ml={3}>
          * Physical Damage and Motor Truck Cargo deductibles are combined
        </Typography>
      </Show>
    </Container>
  );
};

export default QuoteDetails;
