import * as React from 'react';
import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { InputNumeric } from '@nirvana/ui-kit';
import {
  AdditionalCommoditiyRecord,
  CommodityCategory,
  WeightedCommodityRecord,
} from '@nirvana/api/quoting';

import { useStyles } from './styles';
import {
  hasError,
  getFirstErrorMessage,
  getPrimaryCommodityDistribution,
  getAdditionalCommodityDistribution,
  isEmptyCommodityRecord,
} from './utils';

export interface ICommodityDistribution {
  list: CommodityCategory[];
}

const CommodityDistribution = ({ list }: ICommodityDistribution) => {
  const classes = useStyles();
  const { control, errors, watch } = useFormContext();
  const values = watch(['commodityDistribution']);

  const totalPrimaryCommodityPercentage = getPrimaryCommodityDistribution(
    values.commodityDistribution,
  );
  const totalCommodityPercentage =
    totalPrimaryCommodityPercentage +
    getAdditionalCommodityDistribution(values.commodityDistribution);

  return (
    <Grid item container direction="column" spacing={3}>
      <Grid item xs={4}>
        <Box paddingTop="24px">
          <InputLabel className={classes.inputLabel}>
            Commodities distribution
          </InputLabel>
          <FormHelperText>
            Please specify commodities hauled, with category, values and
            percentage of hauls
          </FormHelperText>
        </Box>
      </Grid>
      <Grid item xs={8} container flexDirection="column" spacing={0.4}>
        <Grid item container alignItems="center" spacing={2} flexWrap="nowrap">
          <Grid item xs={8}>
            <FormHelperText>Commodity</FormHelperText>
          </Grid>
          <Grid item xs={7}>
            <FormHelperText>Category</FormHelperText>
          </Grid>
          <Grid item xs={3}>
            <FormHelperText>Avg value</FormHelperText>
          </Grid>
          <Grid item xs={3}>
            <FormHelperText>Max value</FormHelperText>
          </Grid>
          <Grid item xs={3}>
            <FormHelperText>% of hauls</FormHelperText>
          </Grid>
        </Grid>
        <Grid item container flexDirection="column" spacing={2}>
          {[1, 2, 3, 4, 5].map((item, index: number) => (
            <Controller
              key={item}
              control={control}
              name={`commodityDistribution.commodities[${index}]`}
              defaultValue={{}}
              rules={{
                validate: {
                  commodity: (record: WeightedCommodityRecord) => {
                    if (!record) {
                      return true;
                    }

                    if (
                      !record?.commodity?.label &&
                      (record?.category?.type ||
                        record?.avgDollarValueHauled ||
                        record?.maxDollarValueHauled ||
                        record?.percentageOfHauls)
                    ) {
                      return 'Please enter commodity name';
                    }

                    return true;
                  },
                  category: (record: WeightedCommodityRecord) => {
                    if (!record) {
                      return true;
                    }

                    if (
                      !record?.category?.type &&
                      (record?.commodity?.label ||
                        record?.avgDollarValueHauled ||
                        record?.maxDollarValueHauled ||
                        record?.percentageOfHauls)
                    ) {
                      return 'Please select a commodity category';
                    }

                    return true;
                  },
                  avgDollarValueHauled: (record: WeightedCommodityRecord) => {
                    if (!record) {
                      return true;
                    }

                    if (
                      !record?.avgDollarValueHauled &&
                      (record?.commodity?.label ||
                        record?.category?.type ||
                        record?.maxDollarValueHauled ||
                        record?.percentageOfHauls)
                    ) {
                      return 'Please enter avg value';
                    }

                    return true;
                  },
                  maxDollarValueHauled: (record: WeightedCommodityRecord) => {
                    if (!record) {
                      return true;
                    }

                    if (
                      !record.maxDollarValueHauled &&
                      (record.commodity?.label ||
                        record?.category?.type ||
                        record.avgDollarValueHauled ||
                        record.percentageOfHauls)
                    ) {
                      return 'Please enter max value';
                    }

                    return true;
                  },
                  maxDollarValueLower: (record: WeightedCommodityRecord) => {
                    if (!record) {
                      return true;
                    }

                    if (
                      record.maxDollarValueHauled &&
                      record.avgDollarValueHauled &&
                      record.avgDollarValueHauled > record.maxDollarValueHauled
                    ) {
                      return 'Max value cannot be lower than avg value';
                    }

                    return true;
                  },
                  percentageOfHauls: (record: WeightedCommodityRecord) => {
                    if (!record) {
                      return true;
                    }

                    if (
                      !record.percentageOfHauls &&
                      (record.commodity?.label ||
                        record?.category?.type ||
                        record.avgDollarValueHauled ||
                        record.maxDollarValueHauled)
                    ) {
                      return 'Please enter % of Hauls';
                    }

                    return true;
                  },
                  minPrimaryCommodity: () => {
                    if (totalPrimaryCommodityPercentage === 0) {
                      return 'Please select at least 1 primary commodity';
                    }

                    return true;
                  },
                  total: () =>
                    totalCommodityPercentage === 100 || 'Total must equal 100%',
                },
              }}
              render={({ value, onChange }) => {
                return (
                  <Grid
                    item
                    container
                    alignItems="center"
                    spacing={2}
                    flexWrap="nowrap"
                  >
                    <Grid item xs={8}>
                      <FormControl fullWidth>
                        <OutlinedInput
                          placeholder="Commodity Name"
                          value={value?.commodity?.label}
                          onChange={(e) => {
                            let newValue = {
                              ...value,
                              commodity: { label: e?.target?.value },
                            };
                            if (isEmptyCommodityRecord(newValue)) {
                              newValue = {};
                            }

                            onChange(newValue);
                          }}
                          error={
                            errors?.commodityDistribution?.commodities?.[index]
                              ?.type === 'commodity' ||
                            (index === 0 &&
                              hasError(errors, 'minPrimaryCommodity'))
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={7}>
                      <FormControl
                        fullWidth
                        sx={{
                          maxWidth: 241,
                        }}
                      >
                        <Select
                          displayEmpty
                          variant="outlined"
                          value={value?.category?.type || ''}
                          onChange={(event) => {
                            let newValue = {
                              ...value,
                              category: { type: event.target.value },
                            };

                            if (isEmptyCommodityRecord(newValue)) {
                              newValue = {};
                            }

                            onChange(newValue);
                          }}
                          error={
                            errors?.commodityDistribution?.commodities?.[index]
                              ?.type === 'category' ||
                            (index === 0 &&
                              hasError(errors, 'minPrimaryCommodity'))
                          }
                        >
                          <MenuItem value="">Select</MenuItem>
                          {list?.map((option) => {
                            return (
                              <MenuItem value={option.type} key={option.type}>
                                {option.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputNumeric
                          placeholder="$0"
                          decimalScale={0}
                          value={value?.avgDollarValueHauled}
                          onChange={(e) => {
                            let newValue = {
                              ...value,
                              avgDollarValueHauled: e?.target?.value
                                ? +e?.target?.value
                                : undefined,
                            };
                            if (isEmptyCommodityRecord(newValue)) {
                              newValue = {};
                            }

                            onChange(newValue);
                          }}
                          error={
                            errors?.commodityDistribution?.commodities?.[index]
                              ?.type === 'avgDollarValueHauled' ||
                            (index === 0 &&
                              hasError(errors, 'minPrimaryCommodity'))
                          }
                          prefix="$"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputNumeric
                          placeholder="$0"
                          decimalScale={0}
                          value={value?.maxDollarValueHauled}
                          onChange={(e) => {
                            let newValue = {
                              ...value,
                              maxDollarValueHauled: e?.target?.value
                                ? +e?.target?.value
                                : undefined,
                            };
                            if (isEmptyCommodityRecord(newValue)) {
                              newValue = {};
                            }

                            onChange(newValue);
                          }}
                          error={
                            errors?.commodityDistribution?.commodities?.[index]
                              ?.type === 'maxDollarValueHauled' ||
                            errors?.commodityDistribution?.commodities?.[index]
                              ?.type === 'maxDollarValueLower' ||
                            (index === 0 &&
                              hasError(errors, 'minPrimaryCommodity'))
                          }
                          prefix="$"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputNumeric
                          placeholder="0%"
                          decimalScale={0}
                          value={value?.percentageOfHauls}
                          onChange={(e) => {
                            let newValue = {
                              ...value,
                              percentageOfHauls: e?.target?.value
                                ? +e?.target?.value
                                : undefined,
                            };
                            if (isEmptyCommodityRecord(newValue)) {
                              newValue = {};
                            }

                            onChange(newValue);
                          }}
                          error={
                            errors?.commodityDistribution?.commodities?.[index]
                              ?.type === 'percentageOfHauls' ||
                            (index === 0 &&
                              hasError(errors, 'minPrimaryCommodity'))
                          }
                          suffix="%"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                );
              }}
            />
          ))}
          <Grid item container flexDirection="column" spacing={0.5}>
            <Grid
              item
              container
              alignItems="center"
              spacing={2}
              flexWrap="nowrap"
            >
              <Grid item xs={21 as any}>
                <FormHelperText>Other commodities</FormHelperText>
              </Grid>

              <Grid item xs={3}>
                <FormHelperText />
              </Grid>
            </Grid>
            <Grid
              item
              container
              alignItems="flex-start"
              spacing={2}
              flexWrap="nowrap"
            >
              <Controller
                control={control}
                name="commodityDistribution.additionalCommodities"
                defaultValue={{}}
                rules={{
                  validate: {
                    commodities: (record: AdditionalCommoditiyRecord) => {
                      if (!record) {
                        return true;
                      }

                      if (!record.commodities && record.percentageOfHauls) {
                        return 'Please enter some commodity names';
                      }

                      return true;
                    },
                    percentageOfHauls: (record: AdditionalCommoditiyRecord) => {
                      if (!record) {
                        return true;
                      }

                      if (!record.percentageOfHauls && record.commodities) {
                        return 'Please enter % of Hauls';
                      }

                      return true;
                    },
                  },
                }}
                render={({ value, onChange }) => {
                  return (
                    <>
                      <Grid item xs={21 as any}>
                        <OutlinedInput
                          type="text"
                          placeholder="Commodity Names"
                          fullWidth
                          multiline
                          minRows={3}
                          maxRows={3}
                          value={value?.commodities}
                          onChange={(e) => {
                            onChange({ ...value, commodities: e.target.value });
                          }}
                          classes={{
                            root: classes.textareaRoot,
                            input: classes.textAreaInput,
                          }}
                          error={
                            errors?.commodityDistribution?.additionalCommodities
                              ?.type === 'commodities'
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <InputNumeric
                          placeholder="0%"
                          decimalScale={0}
                          value={value.percentageOfHauls}
                          error={
                            errors?.commodityDistribution?.additionalCommodities
                              ?.type === 'percentageOfHauls'
                          }
                          onChange={(e) => {
                            onChange({
                              ...value,
                              percentageOfHauls: e?.target?.value
                                ? +e?.target?.value
                                : undefined,
                            });
                          }}
                          suffix="%"
                        />
                      </Grid>
                    </>
                  );
                }}
              />
            </Grid>
          </Grid>

          <Grid item container direction="row" wrap="nowrap" spacing={0}>
            <Grid item>
              <Box className={clsx(classes.totalItem, classes.totalLabel)}>
                Total
              </Box>
            </Grid>
            <Grid item flexGrow={1}>
              <Box className={clsx(classes.totalItem, classes.totalValue)}>
                {totalCommodityPercentage}%
              </Box>
            </Grid>
          </Grid>

          <Grid item>
            <FormHelperText error>
              {getFirstErrorMessage(errors)}
            </FormHelperText>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CommodityDistribution;
