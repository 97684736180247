import * as React from 'react';
import { Box } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import {
  ApplicationDetail,
  QuoteData,
  WeightedCommodityRecord,
} from '@nirvana/api/quoting';

import { TableView } from 'src/components/table';
import { application as ApplicationHelper } from 'src/helpers';

import Section, {
  SectionDataRecord,
  SectionDataRenderParams,
} from '../section';

type IClassesAndCommoditiesProps = {
  hasMTC: boolean;
  onEdit?: (key: string) => void;
  applicationData: ApplicationDetail;
  isEditable: boolean;
  showChanges: boolean;
  quoteData?: QuoteData;
};

const ClassesAndCommoditiesView = ({
  hasMTC,
  onEdit,
  ...rest
}: IClassesAndCommoditiesProps) => {
  const { applicationData } = rest;

  const data: SectionDataRecord[] = [
    {
      key: 'operatingClassDistribution',
      label: 'Operating class distribution',
      getValue: (data: ApplicationDetail) => {
        return data.indicationForm?.classesAndCommoditiesForm
          ?.operatingClassDistribution;
      },
      renderValue: ({ value = [] }: SectionDataRenderParams) => {
        return (
          <TableView
            headers={[
              {
                key: 'columnOperatingClass',
                content: 'Operating class',
                width: '30%',
              },
              {
                key: 'columnPercentage',
                content: 'Percentage of operation',
              },
            ]}
            rows={value
              .filter((record: any) => !!record.percentageOfFleet)
              .map((record: any) => ({
                columns: [
                  {
                    content: ApplicationHelper.getOperatingClassLabel(
                      record.operatingClass,
                    ),
                    width: '30%',
                  },
                  { content: `${record.percentageOfFleet}%` },
                ],
              }))}
          />
        );
      },
    },
  ];

  if (hasMTC) {
    data.push({
      key: 'commodityDistribution',
      label: 'Commodities Hauled',
      getValue: (data: ApplicationDetail) => {
        return data.indicationForm?.classesAndCommoditiesForm
          ?.commodityDistribution;
      },
      renderValue: ({ value = {} }: SectionDataRenderParams) => {
        const { commodities = [], additionalCommodities } = value;
        return (
          <>
            <TableView
              headers={[
                {
                  key: 'commodityName',
                  content: 'Commodity',
                  width: '25%',
                },
                {
                  key: 'commodityCategory',
                  content: 'Category',
                  width: '25%',
                },
                {
                  key: 'avgDollarValueHauled',
                  content: 'Avg value',
                  width: '15%',
                },
                {
                  key: 'maxDollarValueHauled',
                  content: 'Max value',
                  width: '15%',
                },
                {
                  key: 'columnPercentage',
                  content: '% of hauls',
                  width: '20%',
                },
              ]}
              rows={commodities?.map((record: WeightedCommodityRecord) => ({
                columns: [
                  {
                    content: `${record.commodity.label}`,
                  },
                  {
                    content: `${record.category.label}`,
                  },
                  {
                    content: (
                      <NumberFormat
                        value={record.avgDollarValueHauled}
                        displayType="text"
                        thousandSeparator={true}
                        prefix="$"
                      />
                    ),
                  },
                  {
                    content: (
                      <NumberFormat
                        value={record.maxDollarValueHauled}
                        displayType="text"
                        thousandSeparator={true}
                        prefix="$"
                      />
                    ),
                  },
                  {
                    content: `${record.percentageOfHauls}%`,
                  },
                ],
              }))}
            />
            {additionalCommodities?.commodities ? (
              <Box mt={3}>
                <TableView
                  headers={[
                    {
                      key: 'commodities',
                      content: 'Other commodities',
                      width: '50%',
                    },
                    {
                      key: 'commodities-spacer',
                      content: '',
                      width: '30%',
                    },
                    {
                      key: '',
                      content: '',
                      width: '20%',
                    },
                  ]}
                  rows={[
                    {
                      columns: [
                        {
                          content: additionalCommodities?.commodities,
                          width: '50%',
                        },
                        {
                          content: '',
                          width: '30%',
                        },
                        {
                          content: `${additionalCommodities.percentageOfHauls}%`,
                          width: '20%',
                        },
                      ],
                    },
                  ]}
                />
              </Box>
            ) : null}
          </>
        );
      },
    });
  }

  if (
    applicationData.indicationForm?.classesAndCommoditiesForm?.primaryCommodity
  ) {
    data.push({
      key: 'primaryCommodity',
      label: 'Primary commodity',
      getValue: (data: ApplicationDetail) => {
        return data.indicationForm?.classesAndCommoditiesForm?.primaryCommodity
          ?.label;
      },
      renderValue: ({ value = '', hasChanged }) => {
        return hasChanged ? <mark>{value}</mark> : value;
      },
    });
  }

  if (
    applicationData.indicationForm?.classesAndCommoditiesForm
      ?.primaryCategory &&
    !hasMTC
  ) {
    data.push({
      key: 'primaryCategory',
      label: 'Primary commodity',
      getValue: (data: ApplicationDetail) => {
        return data.indicationForm?.classesAndCommoditiesForm?.primaryCategory
          ?.label;
      },
      renderValue: ({ value = '', hasChanged }) => {
        return hasChanged ? <mark>{value}</mark> : value;
      },
    });
  }

  return (
    <Section
      {...rest}
      title="Classes & Commodities "
      onEdit={() => onEdit?.('classesAndCommoditiesForm')}
      data={data}
    />
  );
};

export default ClassesAndCommoditiesView;
