import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { InputNumeric } from '@nirvana/ui-kit';
import { Controller, useFormContext } from 'react-hook-form-v7';

const UIIA = () => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();
  const uiiaErrors = (errors.operationsForm as any)?.companyInfo;
  const isUIIAEnabled = watch('operationsForm.hasHiredAuto');

  return (
    <>
      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={5}>
          <Box paddingTop="10px">
            <InputLabel htmlFor="uiiaCoverage">
              Do you want UIIA Coverage?
            </InputLabel>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <Controller
              control={control}
              defaultValue={undefined}
              name="operationsForm.hasHiredAuto"
              rules={{
                validate: {
                  required: (value?: string) => {
                    if (value === undefined) {
                      return 'Please select an option';
                    }

                    return true;
                  },
                },
              }}
              render={({ field: { value, onChange } }) => {
                return (
                  <FormControl>
                    <RadioGroup
                      row
                      aria-label="driver-on-policy"
                      value={`${value}`}
                      onChange={(e) => {
                        if (e.target.value === 'false') {
                          setValue(
                            'operationsForm.companyInfo.annualCostOfHire',
                            null,
                          );
                        }

                        onChange(e.target.value === 'true');
                      }}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio color="primary" size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio color="primary" size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                    {!!(errors.operationsForm as any)?.hasHiredAuto && (
                      <FormHelperText error>
                        {(errors.operationsForm as any)?.hasHiredAuto?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                );
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      {isUIIAEnabled && (
        <Grid
          item
          container
          direction="row"
          wrap="nowrap"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={5}>
            <Box paddingTop="10px">
              <InputLabel htmlFor="uiiaCoverage">
                Annual Cost of Hire
              </InputLabel>
              <FormHelperText style={{ width: 288 }}>
                Minimum allowable limit is $5,000
              </FormHelperText>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Controller
              control={control}
              name="operationsForm.companyInfo.annualCostOfHire"
              rules={{
                min: {
                  value: 5000,
                  message: 'Minimum allowable limit is $5,000',
                },
                required: isUIIAEnabled && 'Enter annual cost of hire',
              }}
              render={({ field: { value, onChange } }) => (
                <InputNumeric
                  value={value}
                  placeholder="$10,000"
                  prefix="$"
                  decimalScale={0}
                  onChange={(e) => {
                    onChange(+e.target.value);
                  }}
                  error={!!uiiaErrors?.annualCostOfHire}
                />
              )}
            />
            {uiiaErrors?.annualCostOfHire?.message && (
              <FormHelperText error>
                {uiiaErrors?.annualCostOfHire.message}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UIIA;
