import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';

import { ApplicationHeader, Tooltip } from '@nirvana/ui-kit';

import useSnackbar from 'src/hooks/useSnackbar';
import IconCheck from 'src/assets/icons/check.svg';

import { bindQuote, fetchBindableQuote } from '../../queries/quote';
import { useApplicationDetailsContext } from '../../hooks/useApplicationDetails';
import QuoteSummary from './summary';
import QuoteDetails from './breakdown';
import QuoteCoverages from './coverages';
import { AlertWrapper, useStyles } from './styles';
import QuoteSignature from './signature';
import QuoteForms from './forms';

const Quote = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const enqueueSnackbar = useSnackbar();
  const { applicationId = '' } = useParams();
  const { applitionHeaderInfo } = useApplicationDetailsContext();

  const { data, isLoading } = useQuery(['bindable-quote', applicationId], () =>
    fetchBindableQuote(applicationId),
  );

  const { mutate } = useMutation(() => bindQuote(applicationId), {
    onSuccess: () => {
      navigate(`/nrb/${applicationId}/bound`, { replace: true });
    },
    onError: () => {
      enqueueSnackbar(
        'Bind error',
        'Failed to bind quote. Please try again later.',
        { variant: 'error' },
      );
    },
  });

  const handleBind = () => {
    mutate();
  };

  if (isLoading) {
    return (
      <Box flex="1" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <ApplicationHeader
        details={applitionHeaderInfo}
        onBack={() => {
          navigate('/applications/list', { replace: true });
        }}
      />

      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Paper elevation={0} square sx={{ position: 'relative' }}>
            <AlertWrapper sx={{ mb: 3.5 }}>
              <Container maxWidth="md">
                <Box display="flex">
                  <Box display="flex" flex="1">
                    <img src={IconCheck} width={14} />
                    <Typography variant="body1" marginLeft={1}>
                      Quote is ready for binding
                    </Typography>
                  </Box>
                  <Typography variant="caption">
                    Last Updated on: {data?.lastUpdatedAt}
                  </Typography>
                </Box>
              </Container>
            </AlertWrapper>

            <QuoteSummary
              data={data?.quote}
              appDetails={data?.appDetails}
              quotePDFLink={data?.quotePDF}
            />
          </Paper>
        </Grid>

        <Grid item>
          <QuoteDetails
            appDetails={data?.appDetails?.admitted}
            data={data?.quote}
          />
        </Grid>

        {!!data?.appDetails && (
          <Grid item>
            <QuoteCoverages
              coverages={data?.quote.coverages}
              excludedDrivers={data.excludedDrivers}
            />

            {!!data.formDetails && (
              <QuoteForms formDetails={data.formDetails} />
            )}
          </Grid>
        )}

        <Grid item>
          <QuoteSignature signaturePacketLink={data?.signaturePacketZip} />
        </Grid>
      </Grid>

      <AppBar
        classes={{ root: classes.footerAppBar }}
        color="inherit"
        elevation={0}
        position="fixed"
        sx={{ top: 'auto', bottom: 0 }}
      >
        <Container>
          <Toolbar>
            <Grid container justifyContent="space-between">
              <Grid item />
              <Grid item>
                <Tooltip title="Coming soon">
                  <span>
                    <Button variant="contained" onClick={handleBind} disabled>
                      Bind Quote
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default Quote;
