import { useMemo, useState } from 'react';
import { HiUser } from 'react-icons/hi';
import NumberFormat from 'react-number-format';
import { Box, Container, Grid, Typography } from '@material-ui/core';

import { Show, Switch, TableBasic } from '@nirvana/ui-kit';
import { CoverageDetails, CoverageType } from '@nirvana/api/non-fleet';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TypographySemiBoldCaption,
  GroupHeader,
} from './styles';
import {
  getAncillaryCoverages,
  getCoverageAbbrevation,
  getMainCoverages,
  getSymbolDefinitionLabelByCoverage,
} from './utils';

interface IteratableCoverages extends CoverageDetails {
  ancillaryCoverages?: CoverageDetails[];
  coverageAbbreviation?: string;
}

interface IQuoteCoverages {
  excludedDrivers?: string[];
  coverages?: CoverageDetails[];
}

const coverageGroups = [
  {
    coverages: [CoverageType.CoverageAutoLiability],
    label: 'Auto Liability',
  },
  {
    coverages: [CoverageType.CoverageAutoPhysicalDamage],
    label: 'Physical Damage',
  },
  {
    coverages: [CoverageType.CoverageMotorTruckCargo],
    label: 'Motor Truck Cargo',
  },
  {
    coverages: [CoverageType.CoverageGeneralLiability],
    label: 'General Liability',
  },
];

const QuoteCoverages = ({
  excludedDrivers = [],
  coverages = [],
}: IQuoteCoverages) => {
  const [expandedPanel, setExpandedPanel] = useState(0);

  const mainCoverages = useMemo(() => getMainCoverages(coverages), [coverages]);

  const filteredCoverages = useMemo(() => {
    const allCoverages: IteratableCoverages[] = [];

    mainCoverages.forEach((coverage) => {
      allCoverages.push({
        ...coverage,
        coverageAbbreviation: getCoverageAbbrevation(coverage.label),
        ancillaryCoverages: getAncillaryCoverages(
          coverage.coverageType,
          coverages,
        ),
      });
    });

    return allCoverages;
  }, [mainCoverages, coverages]);

  const handleExpandChange =
    (panelIndex: number) => (e: any, expanded: boolean) => {
      if (expanded) {
        setExpandedPanel(panelIndex);
      } else {
        setExpandedPanel(-1);
      }
    };

  return (
    <Accordion expanded={expandedPanel === 1} onChange={handleExpandChange(1)}>
      <AccordionSummary
        id="coverage-header"
        expanded={expandedPanel === 1}
        aria-controls="coverage-content"
      >
        Coverages
      </AccordionSummary>
      <AccordionDetails>
        <Container maxWidth="md">
          <Grid container direction="column" spacing={3}>
            <Show when={excludedDrivers?.length > 0}>
              <Grid item>
                <div className="flex flex-col p-4 space-y-2 border rounded bg-error-extraLight/25 border-error-main">
                  <TypographySemiBoldCaption color="error">
                    Excluded Drivers
                  </TypographySemiBoldCaption>

                  <Typography variant="caption">
                    If bound, the following drivers will be excluded from the
                    underlying coverages due to their driving history records
                  </Typography>
                  <div>
                    {excludedDrivers?.map((name) => (
                      <div
                        key={name}
                        className="flex items-center space-x-1 text-xs text-error-main"
                      >
                        <HiUser />
                        <span>{name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </Grid>
            </Show>

            {coverageGroups.map(({ coverages, label }) => {
              const groupedCoverages = coverages.reduce((acc, coverageType) => {
                const matchedCoverages = filteredCoverages.filter(
                  (record) => record.coverageType === coverageType,
                );
                acc.push(...matchedCoverages);
                return acc;
              }, [] as IteratableCoverages[]);

              if (!groupedCoverages.length) {
                return null;
              }

              return (
                <Grid item key={label}>
                  <GroupHeader>
                    <Typography
                      variant="caption"
                      fontWeight="fontWeightBold"
                      sx={{ textTransform: 'uppercase' }}
                    >
                      {label}
                    </Typography>
                  </GroupHeader>
                  <TableBasic
                    styles={{ position: 'relative', zIndex: 2 }}
                    headers={[
                      { key: 'coverages', content: <>Coverages</> },
                      { key: 'limits', content: <>Limits</> },
                      { key: 'deductibles', content: <>Deductibles</> },
                      {
                        key: 'symbol-definition',
                        content: <>Symbol | Definition</>,
                      },
                    ]}
                    rows={groupedCoverages.map((record) => ({
                      key: `coverage-${record.coverageType}`,
                      columns: [
                        {
                          content: (
                            <Box py={1}>
                              <TypographySemiBoldCaption>
                                {record.label}
                              </TypographySemiBoldCaption>
                            </Box>
                          ),
                          styles: { width: '20%' },
                        },
                        {
                          content: (
                            <Box py={1}>
                              <TypographySemiBoldCaption>
                                <NumberFormat
                                  prefix="$"
                                  thousandSeparator
                                  displayType="text"
                                  value={record?.limit}
                                />
                              </TypographySemiBoldCaption>
                            </Box>
                          ),
                          styles: { width: '20%' },
                        },
                        {
                          content: (
                            <Box py={1}>
                              <TypographySemiBoldCaption>
                                <NumberFormat
                                  prefix="$"
                                  thousandSeparator
                                  displayType="text"
                                  value={record?.deductible}
                                />
                              </TypographySemiBoldCaption>
                            </Box>
                          ),
                          styles: { width: '20%' },
                        },
                        {
                          content: (
                            <Box py={1}>
                              <TypographySemiBoldCaption>
                                {getSymbolDefinitionLabelByCoverage(
                                  record.coverageType,
                                )}
                              </TypographySemiBoldCaption>
                            </Box>
                          ),
                          styles: { width: '40%' },
                        },
                      ],
                      subTable: {
                        rows:
                          record.ancillaryCoverages?.map((ancillaryRecord) => ({
                            key: `coverage-${ancillaryRecord.coverageType}`,
                            columns: [
                              {
                                content: (
                                  <Box py={1}>
                                    <Typography variant="caption">
                                      {ancillaryRecord.label}
                                    </Typography>
                                  </Box>
                                ),
                                styles: { width: '20%' },
                              },
                              {
                                content: (
                                  <Box py={1}>
                                    <Typography variant="caption">
                                      <Show
                                        when={ancillaryRecord.limit}
                                        fallback="INCLUDED"
                                      >
                                        <Switch
                                          fallback={
                                            <NumberFormat
                                              prefix="$"
                                              thousandSeparator
                                              displayType="text"
                                              value={ancillaryRecord.limit}
                                            />
                                          }
                                        >
                                          <Switch.Match
                                            when={
                                              ancillaryRecord.coverageType ===
                                              CoverageType.CoverageRentalReimbursement
                                            }
                                          >
                                            <NumberFormat
                                              prefix="$"
                                              thousandSeparator
                                              displayType="text"
                                              value={
                                                (ancillaryRecord?.limit ?? 0) /
                                                30
                                              }
                                              suffix="/day, 30 days max"
                                            />
                                          </Switch.Match>
                                        </Switch>
                                      </Show>
                                    </Typography>
                                  </Box>
                                ),
                                styles: { width: '20%' },
                              },
                              {
                                content: (
                                  <Box py={1}>
                                    <Typography variant="caption">
                                      <Show
                                        when={ancillaryRecord.deductible}
                                        fallback={
                                          record.coverageAbbreviation
                                            ? `${record.coverageAbbreviation} deductible`
                                            : ''
                                        }
                                      >
                                        <NumberFormat
                                          prefix="$"
                                          thousandSeparator
                                          displayType="text"
                                          value={ancillaryRecord.deductible}
                                        />
                                      </Show>
                                    </Typography>
                                  </Box>
                                ),
                                styles: { width: '20%' },
                              },
                              {
                                content: (
                                  <Box py={1}>
                                    <Typography variant="caption">
                                      {getSymbolDefinitionLabelByCoverage(
                                        ancillaryRecord.coverageType,
                                      )}
                                    </Typography>
                                  </Box>
                                ),
                                styles: { width: '40%' },
                              },
                            ],
                          })) ?? [],
                      },
                    }))}
                    dividerColor="primary.extraLight"
                  />
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </AccordionDetails>
    </Accordion>
  );
};

export default QuoteCoverages;
