import {
  Button,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import {
  HiCheck,
  HiOutlineDocumentDuplicate,
  HiX,
  HiXCircle,
} from 'react-icons/hi';
import { ReactMultiEmail } from 'react-multi-email';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useClipboard } from 'use-clipboard-copy';

import { Dialog, Show } from '@nirvana/ui-kit';
import 'react-multi-email/dist/style.css';

import { useSnackbar } from 'notistack';
import { sendTelematicsConsentRequestEmail } from 'src/features/application/actions';
import { applicationSelector } from 'src/features/application/slices';
import {
  COPY_TELEMATICS_LINK_SUCCESS,
  TELEMATICS_LINK_SENT,
} from 'src/features/telematics/events';
import { useAnalytics } from 'src/helpers/analytics';
import { useDispatch } from 'src/redux';
import EmailPreview from './email-preview';

type ConsentSuccessfulProps = {
  open: boolean;
  onClose: () => void;
  connectionURL: string | undefined;
};

export default function ConsentSuccessful({
  open,
  onClose,
  connectionURL,
}: ConsentSuccessfulProps) {
  const dispatch = useDispatch();
  const { capture } = useAnalytics();
  const { enqueueSnackbar } = useSnackbar();
  const { applicationId = '' } = useParams();
  const { activeApplication } = useSelector(applicationSelector);
  const [isEmailPreviewOpen, setIsEmailPreviewOpen] = useState(false);
  const [emailSendingStatus, setEmailSendingStatus] = useState<
    'pending' | 'progress' | 'completed'
  >('pending');
  const email = activeApplication?.telematicsInfo?.email;

  const [emails, setEmails] = useState<string[]>([email ?? '']);
  const clipboard = useClipboard({ copiedTimeout: 3000 });

  useEffect(() => {
    setEmails([email ?? '']);
  }, [email]);

  function handleSendEmail() {
    capture(TELEMATICS_LINK_SENT, {
      applicationId,
      emails,
    });

    setEmailSendingStatus('progress');

    dispatch(
      sendTelematicsConsentRequestEmail({
        applicationId,
        payload: {
          completed: true,
          email: { to: emails.map((email) => ({ email })) },
        },
      }),
    ).then((response) => {
      if (sendTelematicsConsentRequestEmail.fulfilled.match(response)) {
        setEmailSendingStatus('completed');
        enqueueSnackbar('Email sent successfully', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          TransitionProps: {
            timeout: 500,
            easing: 'ease-in',
          },
        });
        setTimeout(() => {
          setEmailSendingStatus('pending');
        }, 3000);
      }
    });
  }

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <div className="max-w-lg">
          <div className="flex items-start justify-between px-6 pb-2 mb-6 -mx-6 -my-2 border-b">
            <p className="font-bold">Request telematics consent</p>
            <IconButton size="small" onClick={onClose}>
              <HiX />
            </IconButton>
          </div>

          <div className="flex items-start justify-between mb-4">
            <p className="text-xl font-bold">Send this link to the Insured</p>
          </div>
          <p className="mb-4">
            The insured should use this secure link to authorize a connection to
            their telematics provider. This is required to obtain a quote.
          </p>
          <p className="mb-6">
            <a
              target="_blank"
              rel="noreferrer"
              className="underline text-primary-main hover:text-primary-dark"
              href="https://www.nirvanatech.com/why-telematics"
            >
              Read more
            </a>{' '}
            to understand how data usage and privacy are handled.
          </p>

          <p className="mb-3 font-bold">Send link in your own message</p>

          <OutlinedInput
            readOnly
            fullWidth
            value={connectionURL}
            className="mb-6 bg-primary-extraLight text-text-hint"
            endAdornment={
              <InputAdornment>
                <Link
                  underline="none"
                  className="flex items-center cursor-pointer"
                  onClick={() => {
                    clipboard.copy(connectionURL);
                    capture(COPY_TELEMATICS_LINK_SUCCESS, {
                      applicationId,
                    });
                  }}
                >
                  <Show
                    when={clipboard.copied}
                    fallback={
                      <>
                        <Typography variant="caption" color="primary" mr={0.5}>
                          Copy Link
                        </Typography>
                        <HiOutlineDocumentDuplicate />
                      </>
                    }
                  >
                    <Typography variant="caption" color="primary" mr={0.5}>
                      Link Copied
                    </Typography>
                    <HiCheck />
                  </Show>
                </Link>
              </InputAdornment>
            }
          />

          <div className="flex items-center mb-6 space-x-3">
            <hr className="flex-1" />
            <p className="text-text-hint">or</p>
            <hr className="flex-1" />
          </div>

          <p className="mb-3 font-bold">
            Send email through Nirvana{' '}
            <span
              onClick={() => setIsEmailPreviewOpen(true)}
              className="ml-1 text-xs font-normal cursor-pointer text-primary-main"
            >
              See Preview
            </span>
          </p>

          <div className="flex flex-wrap items-center gap-2 mb-0.5">
            <ReactMultiEmail
              emails={emails}
              onChange={setEmails}
              className="border-text-disabled focus:border-primary-main hover:border-text-primary"
              placeholder="Add emails separated by comma"
              getLabel={(
                email: string,
                index: number,
                removeEmail: (index: number) => void,
              ) => (
                <div
                  className="flex items-center px-2 py-1 mx-1 border rounded border-text-disabled"
                  key={index}
                >
                  <span className="mr-1 text-text-primary">{email}</span>
                  <HiXCircle
                    className="cursor-pointer text-text-hint"
                    onClick={() => removeEmail(index)}
                  />
                </div>
              )}
            />
            <Button
              variant="contained"
              onClick={handleSendEmail}
              disabled={
                emails.length === 0 || emailSendingStatus === 'progress'
              }
            >
              {emailSendingStatus === 'completed' ? 'Sent!' : 'Send'}
            </Button>
          </div>
          <p className="mb-6 text-xxs text-text-hint">
            Press enter to add multiple emails
          </p>
        </div>
      </Dialog>

      <EmailPreview
        open={isEmailPreviewOpen}
        onClose={() => setIsEmailPreviewOpen(false)}
      />
    </>
  );
}
