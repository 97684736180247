import {
  Box,
  Button,
  Grid,
  Typography,
  TypographyProps,
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { TerminalLocation } from '@nirvana/api/quoting';
import { ITheme } from '@nirvana/ui-kit';
import * as React from 'react';
import IconEdit from 'src/assets/icons/edit-info.svg';

import { TerminalLocations } from 'src/constants/terminals';
import { ZodError, ZodFormattedError } from 'zod';
import { LocationForm } from '.';

const CONTENT_MAX_WIDTH = 380;

const LocationCard = styled(Box)(({ theme }: { theme: ITheme }) => ({
  border: '1px solid #E6E7EF',
  borderRadius: '5px',
  padding: theme.spacing(0.75, 3),
  paddingRight: theme.spacing(2),
}));

const LocationErrorCard = styled(Box)(({ theme }: { theme: ITheme }) => ({
  border: '1px solid',
  borderColor: theme.palette.error.main,
  borderRadius: '5px',
  padding: theme.spacing(0.75, 3),
  paddingRight: theme.spacing(2),
  maxWidth: CONTENT_MAX_WIDTH,
}));

const CardTypography = styled((props: TypographyProps) => (
  <Typography
    variant="body2"
    color="textSecondary"
    style={{ textTransform: 'capitalize' }}
  >
    {props.children}
  </Typography>
))(() => ({}));

const EditButton = styled(Button)(({ theme }: { theme: ITheme }) => ({
  color: theme.palette.info.main,
}));

type LocationListProps = {
  list: TerminalLocation[];
  onChange: (list: TerminalLocation[]) => void;
  disableEdit?: boolean;
  errors?: ZodFormattedError<{
    [k: string]: ZodError[];
  }>;
};

const LocationList = ({
  list = [],
  onChange,
  disableEdit = false,
  errors,
}: LocationListProps) => {
  const [editIndex, setEditIndex] = React.useState<number | null>(null);

  if (errors) {
    return (
      <>
        <LocationErrorCard>
          <Grid container flexWrap="nowrap" spacing={2} alignItems="center">
            <Grid item container direction="column">
              <Grid item>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  gutterBottom
                  sx={{
                    wordBreak: 'break-word',
                  }}
                >
                  Business Address
                </Typography>
              </Grid>
              <div className="w-full">
                <div className="h-4 pr-0 bg-gray-100 rounded" />
                <div className="h-4 pr-0 m-2 ml-0 bg-gray-100 rounded w-72" />
              </div>
            </Grid>
          </Grid>
        </LocationErrorCard>
        <Typography
          variant="caption"
          color="error"
          sx={{
            marginTop: 2,
            wordBreak: 'break-word',
          }}
        >
          Please enter business addresses required for GL
        </Typography>
      </>
    );
  }

  return (
    <Grid container direction="column" spacing={2}>
      {list.map((record: TerminalLocation, index: number) => (
        <Grid item key={`terminal-${index}`}>
          {editIndex === index ? (
            <LocationForm
              index={index}
              terminal={record}
              onConfirm={(i: number, data: TerminalLocation) => {
                const newLocations = [...list];
                newLocations[i] = data;

                onChange(newLocations);
                setEditIndex(null);
              }}
              onRemove={(i: number) => {
                const newLocations = [...list];
                newLocations.splice(i, 1);

                onChange(newLocations);
                setEditIndex(null);
              }}
            />
          ) : (
            <LocationCard>
              <Grid container flexWrap="nowrap" spacing={2} alignItems="center">
                <Grid item container direction="column">
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      gutterBottom
                      sx={{
                        wordBreak: 'break-word',
                      }}
                    >
                      {[record.addressLineOne, record.addressLineTwo]
                        .filter((item) => !!item)
                        .join(', ')}
                      , {record.usState} {record.zipCode}
                    </Typography>
                  </Grid>
                  <Grid item container spacing={1} color="textSecondary">
                    <Grid item>
                      <CardTypography>
                        {
                          TerminalLocations.find(
                            (location) =>
                              location.code === record.typeOfTerminal,
                          )?.name
                        }
                      </CardTypography>
                    </Grid>

                    <Grid item>|</Grid>
                    <Grid item>
                      <CardTypography>
                        {record.isGuarded ? 'Guarded' : 'Non-guarded'}
                      </CardTypography>
                    </Grid>

                    <Grid item>|</Grid>
                    <Grid item>
                      <CardTypography>
                        {record.isGated ? 'Gated' : 'Non-gated'}
                      </CardTypography>
                    </Grid>
                  </Grid>
                </Grid>
                {!disableEdit && (
                  <Grid item>
                    <EditButton
                      startIcon={<img src={IconEdit} alt="Edit" width={12} />}
                      size="small"
                      onClick={() => setEditIndex(index)}
                    >
                      Edit
                    </EditButton>
                  </Grid>
                )}
              </Grid>
            </LocationCard>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default LocationList;
