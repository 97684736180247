import {
  AccordionProps,
  AccordionSummaryProps,
  AppBar,
  Box,
  Card,
  CircularProgress,
  Container,
  Divider,
  Grid,
  InputAdornment,
  Link,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  OutlinedInput,
  Paper,
  Radio,
  Toolbar,
  Typography,
  makeStyles,
  styled,
} from '@material-ui/core';
import { ExpandMore, InfoOutlined } from '@material-ui/icons';
import {
  ApplicationDetail,
  CoverageRecord,
  CoverageType,
  PaymentOptionRecord,
  RejectedCoverage,
  ApplicationSummaryTelematicsDataStatus,
} from '@nirvana/api/quoting';
import { ApplicationHeader, Show, TableBasic, Tooltip } from '@nirvana/ui-kit';
import { TableViewColumn } from '@nirvana/ui-kit/src/components/table/basic';
import clsx from 'clsx';
import * as React from 'react';
import { HiCheck, HiOutlineDocumentDuplicate } from 'react-icons/hi';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import IconCheck from 'src/assets/icons/check.svg';
import IconDiscount from 'src/assets/icons/discount-success.svg';
import IconDownload from 'src/assets/icons/download.svg';
import IconPlan from 'src/assets/icons/plan.svg';
import IconUpload from 'src/assets/icons/upload.svg';
import { ITheme } from 'src/assets/themes';
import Button from 'src/components/button';
import {
  application as ApplicationHelper,
  date as DateHelper,
  utils as UtilsHelper,
} from 'src/helpers';
import { useDispatch } from 'src/redux';

import { useClipboard } from 'use-clipboard-copy';
import { Feature, useFeatureFlag } from 'src/helpers/featureFlags';
import {
  downloadApplicationFile,
  downloadQuotePDFFile,
  fetchApplicationById,
} from '../../actions';
import { applicationSelector, getQuoteDetails } from '../../slices';
import ConnectionDrawer from '../create/connectionDrawer';
import QuoteCoverages from './coverages';
import FormsByCoverage from './formsByCoverage';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: 0,

  '&:before': {
    display: 'none',
  },
}));

interface IAccordionSummaryProps extends AccordionSummaryProps {
  expanded?: boolean;
}
const AccordionSummary = styled(
  ({ children, expanded, ...rest }: IAccordionSummaryProps) => {
    return (
      <MuiAccordionSummary {...rest}>
        <Container maxWidth="md">
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography
                variant="h6"
                fontWeight="fontWeightRegular"
                color="textPrimary"
              >
                {children}
              </Typography>
            </Grid>
            <Grid item>
              <span
                className={clsx('AccordionSummary-expandIconWrapper', {
                  'AccordionSummary-expandIconWrapper--expanded': expanded,
                })}
              >
                <ExpandMore color="primary" />
              </span>
            </Grid>
          </Grid>
        </Container>
      </MuiAccordionSummary>
    );
  },
)(({ theme }: { theme: ITheme }) => ({
  border: `1px solid ${theme.palette.primary.extraLight}`,
  boxShadow: '0px 3px 4px rgba(128, 145, 196, 0.08)',
  marginBottom: -1,

  '& .MuiAccordionSummary-content': {
    margin: 0,
  },

  '& .AccordionSummary-expandIconWrapper': {
    display: 'flex',
    color: theme.palette.action.active,
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),

    '&.AccordionSummary-expandIconWrapper--expanded': {
      transform: 'rotate(180deg)',
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(4, 0),
  backgroundColor: '#FCFCFF',
}));

const PaymentOption = styled(Card)(({ theme }: { theme: ITheme }) => ({
  border: `1px solid ${theme.palette.primary.extraLight}`,
  boxShadow: '0px 3px 9px 0px #8091C41A',
  padding: theme.spacing(1, 2, 2, 2),
  width: theme.typography.pxToRem(350),
  opacity: 0.55,
  pointerEvents: 'none',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

const TypographySemiBold = styled(Typography)(
  ({ theme }: { theme: ITheme }) => ({
    ...theme.typography.body2,
    fontWeight: theme.typography.fontWeightSemiBold,
    display: 'inline-block',
  }),
);

const TypographyMedium = styled(Typography)(({ theme }: { theme: ITheme }) => ({
  ...theme.typography.body2,
  fontWeight: theme.typography.fontWeightMedium,
}));

const AlertWrapper = styled(Box)(({ theme }: { theme: ITheme }) => ({
  backgroundColor: '#F0FEF4',
  height: theme.typography.pxToRem(50),
  display: 'flex',
  alignItems: 'center',
}));

const useStyles = makeStyles((theme: ITheme) => ({
  alertMessage: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  alertTitle: {
    ...theme.typography.subtitle1,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: 0,
    marginTop: '2px',
  },
  footerAppBar: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px -2px 4px rgba(128, 145, 196, 0.13)',
  },
  appBar: {
    boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.06)',
    padding: theme.spacing(1, 0),
    position: 'relative',
    top: 1,
    zIndex: 4,
  },
  toolbar: {
    minHeight: theme.spacing(3),
  },
  bindingDetails: {
    backgroundColor: theme.palette.primary.extraLight,
    border: '1px solid #E6E7EF',
    height: 42,
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  infoIcon: {
    color: theme.palette.info.main,
    fontSize: 14,
  },
}));

type RouteParams = {
  applicationId?: string;
};

/**
 * Quote binding page that enables the user to bind an application with a quote.
 * Corresponds to Quote Binding (https://www.figma.com/file/EEV3Aq0aYN8mZJu8pL5kc7/Nirvana-Tech-POC?node-id=16844%3A19452)
 * @component
 */
const ApplicationBinding = () => {
  const classes = useStyles();
  const params: RouteParams = useParams();
  const navigate = useNavigate();
  const getFeatureValue = useFeatureFlag();
  const isQuoteWithoutTelematicsEnabled = getFeatureValue(
    Feature.QUOTE_WITHOUT_TELEMATICS,
    false,
  );
  const [expandedPanel, setExpandedPanel] = React.useState(0);
  const [pdfDownloadLink, setPDFDownloadLink] = React.useState<
    string | undefined
  >();
  const isPDFDownloadLinkAvailable = !!pdfDownloadLink;
  const [openConsentDialog, setOpenConsentDialog] = React.useState(false);
  const dispatch = useDispatch();
  const application = useSelector(applicationSelector);
  const { applicationId = '' } = params;
  const clipboard = useClipboard({ copiedTimeout: 3000 });

  const { quoteDetails } = application;
  const {
    appSummary,
    coverageDetails,
    formsDetails,
    paymentOptions,
    premiumDetails,
    quoteSummary,
    documentSummary,
  } = quoteDetails || {};

  const mainCoverages: CoverageRecord[] = React.useMemo(() => {
    const possibleMainCoverages = [
      CoverageType.CoverageAutoLiability,
      CoverageType.CoverageAutoPhysicalDamage,
      CoverageType.CoverageMotorTruckCargo,
      CoverageType.CoverageGeneralLiability,
    ];
    const approvedCoveragesMap = {};

    const approvedCoverages = coverageDetails?.approvedCoverages || [];

    for (let i = 0, l = approvedCoverages.length; i < l; i += 1) {
      if (possibleMainCoverages.includes(approvedCoverages[i].coverageType)) {
        approvedCoveragesMap[approvedCoverages[i].coverageType] =
          approvedCoverages[i];
      }
    }

    const orderedCoverages = ApplicationHelper.getOrderedCoverages(
      Object.keys(approvedCoveragesMap) as CoverageType[],
    ).map((coverage) => approvedCoveragesMap[coverage]);

    return orderedCoverages;
  }, [coverageDetails]);

  const connectionURL = React.useMemo(() => {
    let link = application.activeApplication?.telematicsInfo?.link || '';
    const host = import.meta.env.VITE_WEB_URL;
    // Adding this validation in order to support older format of link
    // Removing it after we successfully migrate to link including Schema and host.
    if (!link.includes(host)) {
      link = `${host}/${link}`;
    }
    return link;
  }, [application.activeApplication?.telematicsInfo?.link]);

  const rejectedCoverages = React.useMemo(() => {
    return coverageDetails?.rejectedCoverages;
  }, [coverageDetails]);

  const isAPDMTCCombined = React.useMemo(() => {
    if (coverageDetails?.coveragesWithCombinedDeductibles) {
      return ApplicationHelper.isCombinedCoverage(
        CoverageType.CoverageAutoPhysicalDamage,
        coverageDetails.coveragesWithCombinedDeductibles,
      );
    }

    return false;
  }, [coverageDetails]);

  const formsByCoverage = React.useMemo(() => {
    const formsByCoverageMap = {
      [CoverageType.CoverageAutoLiability]: [],
      [CoverageType.CoverageAutoPhysicalDamage]: [],
      [CoverageType.CoverageMotorTruckCargo]: [],
      [CoverageType.CoverageGeneralLiability]: [],
    };

    for (let i = 0, l = formsDetails?.length || 0; i < l; i += 1) {
      if (formsDetails?.[i].coverage) {
        const { coverage } = formsDetails[i];
        if (formsByCoverageMap[coverage]) {
          formsByCoverageMap[coverage].push(formsDetails[i]);
        }
      }
    }

    return formsByCoverageMap;
  }, [formsDetails]);

  const handleExpandChange =
    (panelIndex: number) => (e: any, expanded: boolean) => {
      if (expanded) {
        setExpandedPanel(panelIndex);
      } else {
        setExpandedPanel(-1);
      }
    };

  const handleQuoteDownload = () => {
    if (!isPDFDownloadLinkAvailable) {
      return;
    }

    UtilsHelper.downloadFile(pdfDownloadLink);
  };

  const handleSignaturePacketDownload = () => {
    const handleId = documentSummary?.signaturePacketZipHandleId;

    if (!handleId) {
      return;
    }

    dispatch(downloadApplicationFile(handleId));
  };

  React.useEffect(() => {
    // Fetch application
    dispatch(fetchApplicationById(applicationId));

    // Fetch final quote data
    dispatch(getQuoteDetails({ applicationId }));

    // Send request to create a public download link
    dispatch(downloadQuotePDFFile({ applicationId })).then((response) => {
      if (downloadQuotePDFFile.fulfilled.match(response)) {
        setPDFDownloadLink(response.payload);
      } else {
        setPDFDownloadLink(undefined);
      }
    });
  }, [dispatch, applicationId]);

  if (!quoteDetails) {
    return (
      <Box flex="1" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <ApplicationHeader
        details={{ summary: appSummary } as ApplicationDetail}
        onBack={() => {
          navigate('/applications/list', { replace: true });
        }}
      />

      <Paper elevation={0} square sx={{ position: 'relative' }}>
        <Box>
          <AlertWrapper marginBottom={4}>
            <Container maxWidth="md">
              <Box display="flex">
                <Box display="flex" flex="1">
                  <img src={IconCheck} width={14} />
                  <Typography variant="body1" marginLeft={1}>
                    Quote is ready for binding
                  </Typography>
                </Box>
                <Typography variant="caption">
                  Last Updated on:{' '}
                  {DateHelper.formatDate(quoteSummary?.updatedAt ?? '')}
                </Typography>
              </Box>
            </Container>
          </AlertWrapper>

          <Grid container direction="column">
            <Grid item>
              <Container maxWidth="md">
                <Grid container spacing={2} mb={4}>
                  <Grid item>
                    <img src={IconPlan} width={52} />
                  </Grid>
                  <Grid item flexGrow={1}>
                    <Typography
                      variant="h4"
                      fontWeight="fontWeightBold"
                      color="textPrimary"
                    >
                      {ApplicationHelper.getIndicationOptionTagLabel(
                        quoteSummary?.optionTag,
                      )}{' '}
                      Plan
                    </Typography>
                    <Box display="flex">
                      <Typography
                        variant="body2"
                        fontWeight="fontWeightRegular"
                        color="text.hint"
                      >
                        Effective date of coverage:
                      </Typography>
                      <Typography
                        variant="caption"
                        fontWeight="fontWeightSemiBold"
                        color="text.primary"
                        mx={1}
                      >
                        {DateHelper.formatDate(
                          quoteSummary?.effectiveDate ?? '',
                        )}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h3"
                      color="common.black"
                      fontWeight="fontWeightSemiBold"
                      textAlign="right"
                      mb={1}
                    >
                      <Typography
                        variant="h5"
                        component="span"
                        display="inline-block"
                        fontWeight="fontWeightSemiBold"
                      >
                        $
                      </Typography>
                      <NumberFormat
                        value={quoteSummary?.totalPremium}
                        displayType="text"
                        thousandSeparator={true}
                        prefix=""
                      />
                    </Typography>
                    <Box display="flex" alignItems="center">
                      <Tooltip
                        title="PDF not available"
                        disableHoverListener={isPDFDownloadLinkAvailable}
                        disableFocusListener={isPDFDownloadLinkAvailable}
                        disableTouchListener={isPDFDownloadLinkAvailable}
                      >
                        <Link
                          href=""
                          onClick={(e) => {
                            e.preventDefault();

                            handleQuoteDownload();
                          }}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            opacity: isPDFDownloadLinkAvailable ? 1 : 0.6,
                            cursor: isPDFDownloadLinkAvailable
                              ? 'pointer'
                              : 'not-allowed',
                          }}
                        >
                          <Box
                            mr={1}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <img src={IconDownload} />
                          </Box>
                          Download Quote
                        </Link>
                      </Tooltip>
                      {!appSummary?.renewalMetadata && (
                        <>
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{
                              marginX: 2,
                            }}
                          />
                          <NavLink
                            to={`/applications/${applicationId}/submitted`}
                            style={{ textDecoration: 'none' }}
                          >
                            <Link href="">View Application</Link>
                          </NavLink>
                        </>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
            <Grid item>
              {rejectedCoverages && rejectedCoverages.length > 0 && (
                <Box className={classes.bindingDetails}>
                  <Container maxWidth="md">
                    <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                      {rejectedCoverages.map(
                        (record: RejectedCoverage, index: number) => {
                          return (
                            <>
                              <Typography fontWeight="fontWeightSemiBold">
                                {ApplicationHelper.getIndicationOptionsFeatureLabel(
                                  record.coverageType,
                                )}
                              </Typography>
                              <Tooltip title={record.reason}>
                                <InfoOutlined
                                  className={classes.infoIcon}
                                  fontSize="small"
                                />
                              </Tooltip>
                              {index < rejectedCoverages.length - 1 ? (
                                index === rejectedCoverages.length - 2 ? (
                                  <span>&nbsp;and&nbsp;</span>
                                ) : (
                                  <span>,&nbsp;</span>
                                )
                              ) : (
                                <span />
                              )}
                            </>
                          );
                        },
                      )}
                      &nbsp;{rejectedCoverages.length >= 2 ? 'are' : 'is'}
                      &nbsp;not included in the quote
                    </Typography>
                  </Container>
                </Box>
              )}
              <Box>
                <Container maxWidth="md">
                  <Grid container direction="column" spacing={4}>
                    <Grid item>
                      <Typography
                        variant="h6"
                        color="textPrimary"
                        fontWeight="fontWeightRegular"
                      >
                        Quote details
                      </Typography>
                    </Grid>
                    {mainCoverages.map((record: CoverageRecord) => {
                      return (
                        <Grid
                          item
                          key={`premium-coverage-${record.coverageType}`}
                        >
                          <Grid
                            container
                            alignItems="center"
                            mb={1}
                            spacing={3}
                          >
                            <Grid item>
                              <Typography
                                variant="h6"
                                color="seconday"
                                fontWeight="fontWeightSemiBold"
                              >
                                {ApplicationHelper.getIndicationOptionsFeatureLabel(
                                  record.coverageType,
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <TableBasic
                            headers={[
                              {
                                key: 'projectedExposure',
                                content: <>Projected Exposure</>,
                                styles: { width: '20%' },
                              },
                              {
                                key: 'rate',
                                content: <>Rate</>,
                                styles: { width: '20%' },
                              },
                              {
                                key: 'deductible',
                                content: <>Deductible</>,
                                styles: { width: '25%' },
                              },
                              {
                                key: 'limit',
                                content: <>Limit</>,
                                styles: { width: '15%' },
                              },
                              {
                                key: 'linePremium',
                                content: <>Line Premium</>,
                                styles: {
                                  width: '20%',
                                  textAlign: 'right',
                                },
                              },
                            ]}
                            rows={[
                              {
                                columns: [
                                  {
                                    content:
                                      record.coverageType ===
                                        CoverageType.CoverageAutoLiability ||
                                      record.coverageType ===
                                        CoverageType.CoverageMotorTruckCargo ? (
                                        <>
                                          <NumberFormat
                                            value={premiumDetails?.totalMiles}
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix=""
                                            suffix=" Miles"
                                          />
                                          <br />
                                          <NumberFormat
                                            value={
                                              premiumDetails?.totalPowerUnits
                                            }
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix=""
                                            suffix=" Units"
                                          />
                                        </>
                                      ) : record.coverageType ===
                                        CoverageType.CoverageGeneralLiability ? (
                                        'Not subject to audit'
                                      ) : (
                                        <>
                                          <NumberFormat
                                            value={premiumDetails?.tiv}
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix="$"
                                            suffix=" TIV"
                                          />
                                        </>
                                      ),
                                  },
                                  {
                                    content:
                                      record.coverageType ===
                                        CoverageType.CoverageAutoLiability ||
                                      record.coverageType ===
                                        CoverageType.CoverageMotorTruckCargo ? (
                                        <>
                                          <NumberFormat
                                            value={
                                              record.premiumPerHundredMiles
                                            }
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix="$"
                                            suffix=" / 100 miles"
                                          />
                                          <br />
                                          <NumberFormat
                                            value={record.premiumPerUnit}
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix="$"
                                            suffix=" / unit"
                                          />
                                        </>
                                      ) : record.coverageType ===
                                        CoverageType.CoverageGeneralLiability ? (
                                        ''
                                      ) : (
                                        <>
                                          <NumberFormat
                                            value={record.TIVPercentage}
                                            displayType="text"
                                            thousandSeparator={false}
                                            prefix=""
                                            suffix="% per TIV"
                                          />
                                        </>
                                      ),
                                  },
                                  {
                                    content: (
                                      <>
                                        <NumberFormat
                                          value={record.deductible}
                                          displayType="text"
                                          thousandSeparator={true}
                                          prefix="$"
                                        />
                                        {record.coverageType ===
                                          CoverageType.CoverageAutoPhysicalDamage &&
                                          isAPDMTCCombined && (
                                            <Typography
                                              variant="subtitle2"
                                              component="p"
                                            >
                                              Combined with MTC
                                            </Typography>
                                          )}
                                        {record.coverageType ===
                                          CoverageType.CoverageMotorTruckCargo &&
                                          isAPDMTCCombined && (
                                            <Typography
                                              variant="subtitle2"
                                              component="p"
                                            >
                                              Combined with APD
                                            </Typography>
                                          )}
                                      </>
                                    ),
                                  },
                                  {
                                    content: (
                                      <NumberFormat
                                        value={record.limit}
                                        displayType="text"
                                        thousandSeparator={true}
                                        prefix="$"
                                      />
                                    ),
                                  },
                                  {
                                    content: (
                                      <TypographySemiBold>
                                        <NumberFormat
                                          value={record.premium}
                                          displayType="text"
                                          thousandSeparator={true}
                                          prefix="$"
                                        />
                                      </TypographySemiBold>
                                    ),
                                    styles: {
                                      textAlign: 'right',
                                    },
                                  },
                                ],
                              },
                            ]}
                          />
                        </Grid>
                      );
                    })}
                    <Grid item>
                      <TableBasic
                        headers={[]}
                        rows={[
                          {
                            columns: [
                              {
                                content: (
                                  <TypographyMedium>
                                    Subtotal premium
                                  </TypographyMedium>
                                ),
                                styles: {
                                  width: '66%',
                                },
                              },
                              {
                                content: (
                                  <TypographySemiBold>
                                    <NumberFormat
                                      value={premiumDetails?.subtotalPremium}
                                      displayType="text"
                                      thousandSeparator={true}
                                      prefix="$"
                                    />
                                  </TypographySemiBold>
                                ),
                                styles: {
                                  width: '33%',
                                  textAlign: 'right',
                                },
                              },
                            ],
                          },
                          ...(premiumDetails?.totalSurchargePremium
                            ? [
                                {
                                  columns: [
                                    {
                                      content: (
                                        <TypographyMedium>
                                          State surcharges
                                        </TypographyMedium>
                                      ),
                                    } as TableViewColumn,
                                    {
                                      content: (
                                        <TypographySemiBold>
                                          <NumberFormat
                                            value={
                                              premiumDetails?.totalSurchargePremium
                                            }
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix="$"
                                          />
                                        </TypographySemiBold>
                                      ),
                                      styles: {
                                        textAlign: 'right',
                                      },
                                    } as TableViewColumn,
                                  ],
                                },
                              ]
                            : []),
                          ...(premiumDetails?.totalSurplusLinesTax
                            ? [
                                {
                                  columns: [
                                    {
                                      content: (
                                        <TypographyMedium>
                                          Surplus Line Tax
                                        </TypographyMedium>
                                      ),
                                    } as TableViewColumn,
                                    {
                                      content: (
                                        <TypographySemiBold>
                                          <NumberFormat
                                            value={
                                              premiumDetails?.totalSurplusLinesTax
                                            }
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix="$"
                                          />
                                        </TypographySemiBold>
                                      ),
                                      styles: {
                                        textAlign: 'right',
                                      },
                                    } as TableViewColumn,
                                  ],
                                },
                              ]
                            : []),
                          ...(premiumDetails?.totalStampingFee
                            ? [
                                {
                                  columns: [
                                    {
                                      content: (
                                        <TypographyMedium>
                                          Stamping Fee
                                        </TypographyMedium>
                                      ),
                                    } as TableViewColumn,
                                    {
                                      content: (
                                        <TypographySemiBold>
                                          <NumberFormat
                                            value={
                                              premiumDetails?.totalStampingFee
                                            }
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix="$"
                                          />
                                        </TypographySemiBold>
                                      ),
                                      styles: {
                                        textAlign: 'right',
                                      },
                                    } as TableViewColumn,
                                  ],
                                },
                              ]
                            : []),
                          {
                            columns: [
                              {
                                content: (
                                  <TypographyMedium>
                                    Flat Charges
                                  </TypographyMedium>
                                ),
                              },
                              {
                                content: (
                                  <TypographySemiBold>
                                    <NumberFormat
                                      value={premiumDetails?.flatCharges}
                                      displayType="text"
                                      thousandSeparator={true}
                                      prefix="$"
                                    />
                                  </TypographySemiBold>
                                ),
                                styles: {
                                  textAlign: 'right',
                                },
                              },
                            ],
                          },
                          {
                            columns: [
                              {
                                content: (
                                  <Typography
                                    variant="h6"
                                    fontWeight="fontWeightBold"
                                  >
                                    Total Premium
                                  </Typography>
                                ),
                              },
                              {
                                content: (
                                  <Typography
                                    variant="h5"
                                    fontWeight="fontWeightBold"
                                  >
                                    <NumberFormat
                                      value={premiumDetails?.totalPremium}
                                      displayType="text"
                                      thousandSeparator={true}
                                      prefix="$"
                                    />
                                  </Typography>
                                ),
                                styles: {
                                  textAlign: 'right',
                                },
                              },
                            ],
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Container>
                <div className="mt-8">
                  {!!premiumDetails?.safetyDiscountPercentage &&
                    premiumDetails?.safetyDiscountPercentage > 0 && (
                      <AlertWrapper>
                        <Container maxWidth="md">
                          <Box display="flex" flex="1" alignItems="center">
                            <img src={IconDiscount} width={23} />
                            <Typography
                              variant="body1"
                              marginLeft={1}
                              color="success.extraDark"
                            >
                              This quote includes a{' '}
                              {premiumDetails?.safetyDiscountPercentage}%
                              Nirvana Safety Discount, which means savings of{' '}
                              <NumberFormat
                                value={premiumDetails?.safetyDiscountPremium}
                                displayType="text"
                                thousandSeparator={true}
                                prefix="$"
                              />
                              !
                            </Typography>
                          </Box>
                        </Container>
                      </AlertWrapper>
                    )}
                </div>
              </Box>
            </Grid>
            <Grid item>
              <Accordion
                expanded={expandedPanel === 1}
                onChange={handleExpandChange(1)}
              >
                <AccordionSummary
                  aria-controls="coverage-content"
                  id="coverage-header"
                  expanded={expandedPanel === 1}
                >
                  Coverages
                </AccordionSummary>
                <AccordionDetails>
                  <Container maxWidth="md">
                    <QuoteCoverages
                      approvedCoverages={coverageDetails?.approvedCoverages}
                    />
                  </Container>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item>
              <Accordion
                expanded={expandedPanel === 2}
                onChange={handleExpandChange(2)}
              >
                <AccordionSummary
                  aria-controls="forms-content"
                  id="forms-header"
                  expanded={expandedPanel === 2}
                >
                  Forms
                </AccordionSummary>
                <AccordionDetails>
                  <Container maxWidth="md">
                    {(formsByCoverage[CoverageType.CoverageAutoLiability]
                      .length ||
                      formsByCoverage[CoverageType.CoverageAutoPhysicalDamage]
                        .length) && (
                      <FormsByCoverage
                        coverage={[
                          ...formsByCoverage?.[
                            CoverageType.CoverageAutoLiability
                          ],
                          ...formsByCoverage?.[
                            CoverageType.CoverageAutoPhysicalDamage
                          ],
                        ]}
                        title="Auto Liability + physical damage"
                      />
                    )}

                    {!!formsByCoverage[CoverageType.CoverageMotorTruckCargo]
                      .length && (
                      <FormsByCoverage
                        coverage={
                          formsByCoverage[CoverageType.CoverageMotorTruckCargo]
                        }
                        title="Motor Truck Cargo"
                      />
                    )}

                    {!!formsByCoverage[CoverageType.CoverageGeneralLiability]
                      .length && (
                      <FormsByCoverage
                        coverage={
                          formsByCoverage[CoverageType.CoverageGeneralLiability]
                        }
                        title="General Liability"
                      />
                    )}
                  </Container>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Show
              when={
                isQuoteWithoutTelematicsEnabled &&
                !!application.activeApplication?.telematicsInfo &&
                (appSummary?.telematicsDataStatus ===
                  ApplicationSummaryTelematicsDataStatus.TelematicsDataStatusFailed ||
                  (appSummary?.telematicsDataStatus as any) === '')
              }
            >
              <Grid item>
                <Container maxWidth="md">
                  <Box
                    py={4}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Typography
                      variant="h5"
                      color="secondary"
                      fontWeight="fontWeightSemiBold"
                      mb={2}
                    >
                      Telematics
                    </Typography>
                    <Typography>
                      <p className="mb-4">
                        The insured should use this secure link to authorize a
                        connection to their telematics provider. This is
                        required to obtain a quote.
                      </p>
                      <p className="mb-6">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className="underline text-primary-main hover:text-primary-dark"
                          href="https://www.nirvanatech.com/why-telematics"
                        >
                          Read more
                        </a>{' '}
                        to understand how data usage and privacy are handled.
                      </p>
                    </Typography>
                    <OutlinedInput
                      readOnly
                      fullWidth
                      value={connectionURL}
                      className="mb-6 bg-primary-extraLight text-text-hint"
                      endAdornment={
                        <InputAdornment>
                          <Link
                            underline="none"
                            sx={{
                              display: 'flex',
                              cursor: 'pointer',
                              alignItems: 'center',
                            }}
                            onClick={() => {
                              clipboard.copy(connectionURL);
                            }}
                          >
                            <Show
                              when={clipboard.copied}
                              fallback={
                                <>
                                  <Typography
                                    variant="caption"
                                    color="primary"
                                    mr={0.5}
                                  >
                                    Copy Link
                                  </Typography>
                                  <HiOutlineDocumentDuplicate />
                                </>
                              }
                            >
                              <Typography
                                variant="caption"
                                color="primary"
                                mr={0.5}
                              >
                                Link Copied
                              </Typography>
                              <HiCheck />
                            </Show>
                          </Link>
                        </InputAdornment>
                      }
                    />
                  </Box>
                </Container>
                <Divider
                  sx={{
                    borderColor: 'primary.extraLight',
                  }}
                />
              </Grid>
            </Show>

            <Show
              when={
                isQuoteWithoutTelematicsEnabled &&
                !application.activeApplication?.telematicsInfo
              }
            >
              <Grid item>
                <Container maxWidth="md">
                  <Box
                    py={4}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Typography
                      variant="h5"
                      color="secondary"
                      fontWeight="fontWeightSemiBold"
                      mb={2}
                    >
                      Telematics
                    </Typography>
                    <Typography
                      fontWeight="normal"
                      sx={{
                        marginBottom: 3,
                      }}
                    >
                      The insured must connect their telematics in order to bind
                      the quote. Please send them a connection request.
                    </Typography>

                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        setOpenConsentDialog(true);
                      }}
                    >
                      Share Request Link
                    </Button>
                  </Box>
                </Container>
                <Divider
                  sx={{
                    borderColor: 'primary.extraLight',
                  }}
                />
              </Grid>
            </Show>

            <Grid item>
              <Container maxWidth="md">
                <Box py={4}>
                  <Typography
                    variant="h5"
                    color="secondary"
                    fontWeight="fontWeightSemiBold"
                    mb={2}
                  >
                    Payment Options
                  </Typography>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    {paymentOptions?.map((record: PaymentOptionRecord) => {
                      return (
                        <Box key={`payment-option-${record.toBind}`}>
                          <Tooltip title="Coming soon">
                            <span>
                              <PaymentOption>
                                <Grid
                                  container
                                  spacing={2}
                                  flexWrap="nowrap"
                                  flexGrow={1}
                                >
                                  <Grid item>
                                    <Radio
                                      color="primary"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                      }}
                                      size="small"
                                      disableRipple
                                      sx={{
                                        padding: 0,
                                        '& .MuiSvgIcon-root': {
                                          fontSize: 16,
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid item container direction="column">
                                    <Grid item>
                                      {' '}
                                      <Typography
                                        variant="body1"
                                        color="textPrimary"
                                        fontWeight="fontWeightBold"
                                      >
                                        {ApplicationHelper.getPaymentOptionLabel(
                                          record.name,
                                        )}
                                      </Typography>
                                    </Grid>
                                    <Grid item flexGrow={1}>
                                      <Typography
                                        variant="caption"
                                        color="text.hint"
                                      >
                                        {record.description}
                                      </Typography>
                                    </Grid>
                                    <Grid item justifySelf="flex-end">
                                      <Grid item mt={2}>
                                        <Grid
                                          item
                                          container
                                          direction="row"
                                          justifyContent="space-between"
                                        >
                                          <Typography
                                            variant="caption"
                                            color="text.hint"
                                          >
                                            Deposit:
                                          </Typography>
                                          <Typography variant="subtitle2">
                                            <NumberFormat
                                              value={record.toBind}
                                              displayType="text"
                                              thousandSeparator={true}
                                              prefix="$"
                                            />
                                          </Typography>
                                        </Grid>
                                        {record.collateral !== undefined &&
                                          record.collateral > 0 && (
                                            <Grid
                                              item
                                              container
                                              direction="row"
                                              justifyContent="space-between"
                                            >
                                              <Typography
                                                variant="caption"
                                                color="text.hint"
                                              >
                                                Collateral:
                                              </Typography>
                                              <Typography variant="subtitle2">
                                                +{' '}
                                                <NumberFormat
                                                  value={record.collateral}
                                                  displayType="text"
                                                  thousandSeparator={true}
                                                  prefix="$"
                                                />
                                              </Typography>
                                            </Grid>
                                          )}
                                      </Grid>
                                      <Grid item mt={2}>
                                        <Grid
                                          item
                                          container
                                          direction="row"
                                          justifyContent="space-between"
                                        >
                                          <Typography
                                            variant="caption"
                                            color="text.hint"
                                          >
                                            Required to bind:
                                          </Typography>
                                          <Typography variant="subtitle2">
                                            <NumberFormat
                                              value={
                                                record.toBind +
                                                (record.collateral !== undefined
                                                  ? record.collateral
                                                  : 0)
                                              }
                                              displayType="text"
                                              thousandSeparator={true}
                                              prefix="$"
                                            />
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </PaymentOption>
                            </span>
                          </Tooltip>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Container>
              <Divider
                sx={{
                  borderColor: 'primary.extraLight',
                }}
              />
              <Container maxWidth="md">
                <Box
                  py={4}
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Typography
                    variant="h5"
                    color="secondary"
                    fontWeight="fontWeightSemiBold"
                    mb={2}
                  >
                    Signature
                  </Typography>
                  <Button
                    startIcon={
                      <img src={IconDownload} alt="Download Signature packet" />
                    }
                    sx={{
                      marginBottom: 3,
                    }}
                    disabled={!documentSummary?.signaturePacketZipHandleId}
                    onClick={(e) => {
                      e.preventDefault();

                      handleSignaturePacketDownload();
                    }}
                  >
                    Download signature packet
                  </Button>

                  <Tooltip title="Coming soon">
                    <span>
                      <Button
                        startIcon={<img src={IconUpload} alt="Upload" />}
                        variant="outlined"
                        size="small"
                        onClick={() => {}}
                        disabled
                      >
                        Upload
                      </Button>
                    </span>
                  </Tooltip>
                </Box>
              </Container>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      {/* Spacer to compensate for height of fixed bottom bar */}
      <Box py="64px" />

      <AppBar
        classes={{ root: classes.footerAppBar }}
        color="inherit"
        elevation={0}
        position="fixed"
        sx={{ top: 'auto', bottom: 0 }}
      >
        <Container>
          <Toolbar>
            <Grid container justifyContent="space-between">
              <Grid item />
              <Grid item>
                <Tooltip title="Coming soon">
                  <span>
                    <Button variant="contained" disabled>
                      Bind Quote
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>

      <ConnectionDrawer
        open={openConsentDialog}
        applicationId={applicationId}
        telematicsInfo={application.activeApplication?.telematicsInfo}
        onClose={() => setOpenConsentDialog(false)}
      />
    </>
  );
};

export default ApplicationBinding;
