import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Grid,
  Box,
  FormHelperText,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form-v7';
import { constants } from '@nirvana/ui-kit';

const { usStates } = constants;

const Company = () => {
  const { control, register } = useFormContext();

  return (
    <>
      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={5}>
          <Box paddingTop="10px">
            <InputLabel htmlFor="company-name">Company Name</InputLabel>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <FormControl>
            <OutlinedInput
              id="company-name"
              placeholder="eg. ‘ABC Trucking’"
              {...register('operationsForm.companyInfo.name')}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        item
        container
        spacing={3}
        wrap="nowrap"
        direction="row"
        alignItems="flex-start"
      >
        <Grid item xs={5}>
          <Box>
            <InputLabel htmlFor="company-name">
              Physical Business Address
            </InputLabel>
            <FormHelperText style={{ width: 288 }}>
              Please specify physical locations of your business
            </FormHelperText>
          </Box>
        </Grid>
        <Grid item xs={7} container flexDirection="column" spacing={2}>
          <Grid item>
            <FormControl style={{ width: 288 }}>
              <FormHelperText>Street</FormHelperText>
              <OutlinedInput
                id="street"
                placeholder="Enter street name"
                {...register('operationsForm.companyInfo.address.street')}
              />
            </FormControl>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <FormControl>
                <FormHelperText>City</FormHelperText>
                <OutlinedInput
                  id="city"
                  placeholder="eg. 'Austin'"
                  {...register('operationsForm.companyInfo.address.city')}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <FormHelperText>State</FormHelperText>
                <Controller
                  control={control}
                  defaultValue="state"
                  name="operationsForm.companyInfo.address.state"
                  render={({ field: { value, onChange } }) => (
                    <Select
                      size="small"
                      displayEmpty
                      value={value}
                      id="state-select"
                      variant="outlined"
                      onChange={onChange}
                      renderValue={(value) =>
                        value || <span className="text-text-hint">AA</span>
                      }
                    >
                      {usStates.map((state) => (
                        <MenuItem key={state.code} value={state.name}>
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <FormHelperText>Zip code</FormHelperText>
                <OutlinedInput
                  id="city"
                  placeholder="eg. '12345'"
                  {...register('operationsForm.companyInfo.address.zip')}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Company;
