import { useState } from 'react';
import { ISpace, initializeFlatfile } from '@flatfile/react-7';
import Button from 'src/components/button';

import workbook from './workbook';
import getListener from './listener';

type DriverListUploadFlatfile7Props = {
  isDisabled: boolean;
  applicationState?: string;
  onChange: (data: any) => void;
};

const DriverListUploadFlatfile7 = ({
  isDisabled,
  applicationState,
  onChange,
}: DriverListUploadFlatfile7Props) => {
  const [showSpace, setShowSpace] = useState(false);

  const onComplete = (data: any) => {
    onChange(data);
  };

  const spaceProps: ISpace = {
    name: 'Drivers upload',
    publishableKey: import.meta.env.VITE_FLATFILE_PUBLISHABLE_KEY,
    environmentId: import.meta.env.VITE_FLATFILE_ENVIRONMENT_ID,
    spaceBody: {
      namespace: 'portal',
    },
    workbook,
    listener: getListener({ onComplete }),
    sidebarConfig: {
      showSidebar: false,
    },
    themeConfig: {
      root: {
        primaryColor: '#3350A1',
        textColor: '#363D4D',
      },
      sidebar: {
        logo: 'https://www.nirvanatech.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo-main.1e8e276f.svg&w=384&q=75',
      },
    },
    closeSpace: {
      operation: 'submitActionFg',
      onClose: () => setShowSpace(false),
    },
    metadata: {
      applicationState,
    },
  };

  const { Space, OpenEmbed } = initializeFlatfile(spaceProps);

  const onOpenSpace = async (e: any) => {
    e.stopPropagation();

    if (isDisabled) {
      return;
    }

    setShowSpace(true);
    await OpenEmbed();
  };

  return (
    <div>
      <Button variant="contained" type="button" onClick={onOpenSpace}>
        Proceed
      </Button>
      {showSpace && <Space />}
    </div>
  );
};

export default DriverListUploadFlatfile7;
