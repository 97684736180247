import NumberFormat from 'react-number-format';
import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import { ApplicationDetails, QuoteOption } from '@nirvana/api/non-fleet';
import { Tooltip } from '@nirvana/ui-kit';
import { NavLink } from 'react-router-dom';

import IconPlan from 'src/assets/icons/plan.svg';
import { utils as UtilsHelper, date as DateHelper } from 'src/helpers';
import IconDownload from 'src/assets/icons/download.svg';
import IconEye from 'src/assets/icons/eye-primary.svg';

import { getIndicationPackageName } from '../create/indication/utils';
import { DiscountChip } from './styles';

interface SummaryProps {
  data?: QuoteOption;
  quotePDFLink?: string;
  appDetails?: ApplicationDetails;
}

const Summary = ({ data, appDetails, quotePDFLink }: SummaryProps) => {
  const isPDFDownloadLinkAvailable = !!quotePDFLink;
  const safetyDiscountPercentage = Math.round(
    ((data?.safetyDiscountAmount ?? 0) /
      ((data?.totalPremium ?? 0) + (data?.safetyDiscountAmount ?? 0))) *
      100,
  );

  const handleQuoteDownload = () => {
    if (quotePDFLink) {
      UtilsHelper.downloadFile(quotePDFLink);
    }
  };

  return (
    <Container maxWidth="md" sx={{ position: 'relative' }}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <img src={IconPlan} width={52} />
            </Grid>
            <Grid item flexGrow={1}>
              <Typography
                variant="h4"
                fontWeight="fontWeightBold"
                color="textPrimary"
              >
                {getIndicationPackageName(
                  appDetails?.admitted?.indicationForm?.selectedIndication
                    ?.packageName,
                )}{' '}
                Plan
              </Typography>
              <Box display="flex">
                <Typography
                  variant="body2"
                  fontWeight="fontWeightRegular"
                  color="text.hint"
                >
                  Effective date of coverage:
                </Typography>
                <Typography
                  variant="caption"
                  fontWeight="fontWeightSemiBold"
                  color="text.primary"
                  mx={1}
                >
                  {DateHelper.formatDate(
                    appDetails?.admitted?.operationsForm?.effectiveDate ?? '',
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                {safetyDiscountPercentage > 0 && (
                  <Typography
                    variant="h5"
                    fontWeight="fontWeightBold"
                    color="text.hint"
                    sx={{ textDecoration: 'line-through' }}
                    mb={1}
                  >
                    <NumberFormat
                      value={
                        (data?.totalPremium ?? 0) +
                        (data?.safetyDiscountAmount ?? 0)
                      }
                      displayType="text"
                      thousandSeparator={true}
                      prefix="$ "
                    />
                  </Typography>
                )}
                <Typography
                  variant="h3"
                  color="common.black"
                  fontWeight="fontWeightSemiBold"
                  textAlign="right"
                  mb={1}
                >
                  <Typography
                    variant="h5"
                    component="span"
                    display="inline-block"
                    fontWeight="fontWeightSemiBold"
                  >
                    $
                  </Typography>
                  <NumberFormat
                    value={data?.totalPremium}
                    displayType="text"
                    thousandSeparator={true}
                    prefix=""
                  />
                </Typography>
                {safetyDiscountPercentage > 0 && (
                  <DiscountChip
                    label={
                      <span>
                        Safety Discount ({safetyDiscountPercentage}%):{' '}
                        <NumberFormat
                          value={data?.safetyDiscountAmount}
                          displayType="text"
                          thousandSeparator={true}
                          prefix="$"
                        />
                      </span>
                    }
                    color="success"
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            mb={4}
          >
            <Tooltip
              title="PDF not available"
              disableHoverListener={isPDFDownloadLinkAvailable}
              disableFocusListener={isPDFDownloadLinkAvailable}
              disableTouchListener={isPDFDownloadLinkAvailable}
            >
              <Link
                href=""
                onClick={(e) => {
                  e.preventDefault();

                  handleQuoteDownload();
                }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  opacity: isPDFDownloadLinkAvailable ? 1 : 0.6,
                  cursor: isPDFDownloadLinkAvailable
                    ? 'pointer'
                    : 'not-allowed',
                }}
              >
                <Box
                  mr={1}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img src={IconDownload} />
                </Box>
                Download Quote
              </Link>
            </Tooltip>
            <>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  marginX: 2,
                }}
              />
              <NavLink
                to={`/non-fleet/application/${appDetails?.applicationID}/submitted`}
                style={{ textDecoration: 'none' }}
              >
                <Link
                  href=""
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    opacity: 1,
                    cursor: 'pointer',
                  }}
                >
                  <Box
                    mr={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img src={IconEye} />
                  </Box>
                  View Application
                </Link>
              </NavLink>
            </>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Summary;
