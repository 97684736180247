import { Box, Typography } from '@material-ui/core';
import {
  ApplicationDetail,
  CoverageRecord,
  CoverageType,
} from '@nirvana/api/quoting';
import NumberFormat from 'react-number-format';

import { TableView } from 'src/components/table';
import { application as ApplicationHelper } from 'src/helpers';
import { Feature, useFeatureFlag } from 'src/helpers/featureFlags';

import { Show } from '@nirvana/ui-kit';
import { INDICATION_RANGES_PERCENT } from '../../indicationOptions/indicationOptionWithPrice';
import { RangeIndication } from '../../indicationOptions/rangeIndication';
import Section, {
  SectionCommonProps,
  SectionDataRenderParams,
} from '../section';

export interface IIndication extends SectionCommonProps {
  onEdit: () => void;
}

const Indication = ({ onEdit, applicationData, ...rest }: IIndication) => {
  const getFeatureValue = useFeatureFlag();
  const isPricelessIndication = getFeatureValue(
    Feature.PRICELESS_INDICATION,
    false,
  );

  const isIndicationRangesEnabled = getFeatureValue(
    Feature.INDICATION_RANGES,
    false,
  );

  const hasLimits =
    applicationData.indicationForm?.operationsForm?.coveragesRequired?.some(
      (record) =>
        !!record.limit &&
        record.coverageType === CoverageType.CoverageMotorTruckCargo,
    );

  return (
    <Section
      {...rest}
      applicationData={applicationData}
      title={isPricelessIndication ? 'Packages' : 'Indication'}
      onEdit={onEdit}
      data={[
        {
          key: 'deductibles',
          label: 'Deductibles',
          getValue: (data: ApplicationDetail) => {
            return data.indicationForm?.operationsForm;
          },
          renderValue: ({ value }: SectionDataRenderParams) => {
            const { coveragesRequired = [], coveragesWithCombinedDeductibles } =
              value;
            const isAPDMTCCombined = ApplicationHelper.isCombinedCoverage(
              CoverageType.CoverageAutoPhysicalDamage,
              coveragesWithCombinedDeductibles,
            );
            const deductiblesMap = {};
            const possibleCoverages = [];
            for (let i = 0, l = coveragesRequired.length; i < l; i += 1) {
              deductiblesMap[coveragesRequired[i].coverageType] =
                coveragesRequired[i].deductible;

              if (
                !(
                  isAPDMTCCombined &&
                  coveragesRequired[i].coverageType ===
                    CoverageType.CoverageMotorTruckCargo
                )
              ) {
                possibleCoverages.push(coveragesRequired[i].coverageType);
              }
            }

            const orderedCoverages =
              ApplicationHelper.getOrderedCoverages(possibleCoverages);

            return (
              <TableView
                headers={orderedCoverages.map((coverage: CoverageType) => ({
                  key: coverage,
                  content: (
                    <>
                      {ApplicationHelper.getLabelByCoverage(
                        coverage,
                        coveragesWithCombinedDeductibles,
                      )}
                    </>
                  ),
                  width:
                    isAPDMTCCombined &&
                    coverage === CoverageType.CoverageAutoPhysicalDamage
                      ? '50%'
                      : '25%',
                }))}
                rows={[
                  {
                    columns: orderedCoverages?.map(
                      (coverage: CoverageType) => ({
                        content: (
                          <NumberFormat
                            value={deductiblesMap[coverage] || 0}
                            displayType="text"
                            thousandSeparator={true}
                            prefix="$"
                          />
                        ),
                        width:
                          isAPDMTCCombined &&
                          coverage === CoverageType.CoverageAutoPhysicalDamage
                            ? '50%'
                            : '25%',
                      }),
                    ),
                  },
                ]}
              />
            );
          },
        },
        ...(hasLimits
          ? [
              {
                key: 'limits',
                label: 'Limits',
                getValue: (data: ApplicationDetail) => {
                  return data.indicationForm?.operationsForm;
                },
                renderValue: ({ value }: SectionDataRenderParams) => {
                  const { coveragesRequired } = value;
                  const limitRecords =
                    coveragesRequired?.filter(
                      (record: CoverageRecord) =>
                        !!record.limit &&
                        // Show limits for MTC only
                        record.coverageType ===
                          CoverageType.CoverageMotorTruckCargo,
                    ) || [];

                  return (
                    <TableView
                      headers={limitRecords?.map((record: CoverageRecord) => ({
                        key: record.coverageType,
                        content: (
                          <>
                            {ApplicationHelper.getIndicationOptionsFeatureLabel(
                              record.coverageType,
                            )}
                          </>
                        ),
                        width: '25%',
                      }))}
                      rows={[
                        {
                          columns: limitRecords?.map(
                            (record: CoverageRecord) => ({
                              content: (
                                <NumberFormat
                                  value={record.limit || 0}
                                  displayType="text"
                                  thousandSeparator={true}
                                  prefix="$"
                                />
                              ),
                              width: '25%',
                            }),
                          ),
                        },
                      ]}
                    />
                  );
                },
              },
            ]
          : []),
        {
          key: 'packageSelected',
          label: 'Plan selected',

          getValue: (data: ApplicationDetail) => {
            return data.selectedIndication;
          },
          renderValue: ({ value, hasChanged }: SectionDataRenderParams) => {
            return (
              <Box display="flex" alignItems="center">
                <Typography variant="body2">
                  {ApplicationHelper.getIndicationOptionTagLabel(
                    value?.optionTag,
                  )}{' '}
                  plan
                </Typography>
                {!isPricelessIndication && (
                  <>
                    <Typography variant="body2">:&nbsp;</Typography>
                    <Typography variant="body1">
                      {hasChanged ? (
                        <>
                          <Show when={isIndicationRangesEnabled}>
                            <div className="px-0.5 py-1 bg-mark-default">
                              <RangeIndication
                                indicationNumber={value?.totalPremium}
                                rangePercentLow={INDICATION_RANGES_PERCENT.LOW}
                                rangePercentHigh={
                                  INDICATION_RANGES_PERCENT.HIGH
                                }
                              />
                            </div>
                          </Show>

                          <Show when={!isIndicationRangesEnabled}>
                            <mark>
                              <NumberFormat
                                value={value?.totalPremium}
                                displayType="text"
                                thousandSeparator={true}
                                prefix="$"
                              />
                            </mark>
                          </Show>
                        </>
                      ) : (
                        <>
                          <Show when={isIndicationRangesEnabled}>
                            <RangeIndication
                              indicationNumber={value?.totalPremium}
                              rangePercentLow={INDICATION_RANGES_PERCENT.LOW}
                              rangePercentHigh={INDICATION_RANGES_PERCENT.HIGH}
                            />
                          </Show>

                          <Show when={!isIndicationRangesEnabled}>
                            <NumberFormat
                              value={value?.totalPremium}
                              displayType="text"
                              thousandSeparator={true}
                              prefix="$"
                            />
                          </Show>
                        </>
                      )}
                    </Typography>
                  </>
                )}
              </Box>
            );
          },
        },
      ]}
    />
  );
};

export default Indication;
