import * as React from 'react';
import { useClipboard } from 'use-clipboard-copy';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { format, parseISO } from 'date-fns';
import {
  Box,
  CircularProgress,
  FormHelperText,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import {
  ApplicationDetail,
  CoverageRecord,
  CoverageType,
  DriverListRecord,
  LossRunSummaryRecord,
} from '@nirvana/api/quoting';
import { AttachmentView, Chip } from '@nirvana/ui-kit';

import { SummaryView, TableView } from 'src/components/table';
import { application as ApplicationHelper } from 'src/helpers';
import { Feature, useFeatureFlag } from 'src/helpers/featureFlags';

import { fetchAvailableProducers } from '../../../actions';
import { applicationSelector } from '../../../slices';

import { REVIEW_PAGE_VIEW } from '../../../../telematics/events';
import { useAnalytics } from '../../../../../helpers/analytics';
import { useStyles } from './styles';
import Section, { SectionDataRecord, SectionDataRenderParams } from './section';
import {
  ClassesAndCommoditiesView,
  IndicationView,
  OperationsView,
} from './sections';

export interface IReviewProps {
  coverages?: CoverageType[];
  onEdit: (key: string) => void;
  title: string;
  subtitle: React.ReactNode;
  action?: React.ReactNode;
  isEditable?: boolean;
  showUWChanges?: boolean;
}

/**
 * Review screen that displays the data filled up in each step of the application creation process.
 * The user is allowed to move to any previous step and edit the information that was previously submitted.
 * Corresponds to Review Page (https://www.figma.com/proto/OwouvIq33I1CCIjUXIlrcn/NIrvana_Dev-Handoff?node-id=98%3A13392&scaling=min-zoom&page-id=98%3A10867)
 * @component
 */
const Review = ({
  coverages = [],
  onEdit,
  title,
  subtitle,
  action,
  showUWChanges = false,
  isEditable = true,
}: IReviewProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const clipboard = useClipboard({
    copiedTimeout: 3000,
  });
  const application = useSelector(applicationSelector);
  const { activeApplication, availableProducers, quoteData } = application;
  const hasAPD = React.useMemo(() => {
    return coverages.includes(CoverageType.CoverageAutoPhysicalDamage);
  }, [coverages]);
  const hasMTC = React.useMemo(() => {
    return coverages.includes(CoverageType.CoverageMotorTruckCargo);
  }, [coverages]);
  const getFeatureValue = useFeatureFlag();
  const fleetType = getFeatureValue(Feature.FLEET_TYPE, 'fleet');
  const { capture } = useAnalytics();
  const applicationId = activeApplication?.summary?.applicationID;

  React.useEffect(() => {
    dispatch(fetchAvailableProducers());
  }, [dispatch]);

  React.useEffect(() => {
    capture(REVIEW_PAGE_VIEW, {
      applicationId,
    });
  }, []);

  if (!activeApplication) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  const commonSectionProps = {
    isEditable,
    showChanges: showUWChanges,
    applicationData: activeApplication,
    quoteData,
    hasMTC,
  };

  const getDriversSummaryRows = (driversList: Array<DriverListRecord>) => {
    const driversSummaryValues =
      ApplicationHelper.getDriversSummary(driversList);

    const driversSummaryRows = [
      {
        label: 'Total # of drivers',
        value: driversSummaryValues.total,
      },
      {
        label: 'Drivers aged 18-30 years',
        value: driversSummaryValues['18-30'],
      },
      {
        label: 'Drivers aged 31-50 years',
        value: driversSummaryValues['31-50'],
      },
      {
        label: 'Drivers aged 51+ years',
        value: driversSummaryValues['51+'],
      },
    ];

    return driversSummaryRows;
  };

  const handleCopyConnectionLink = (url: string) => {
    clipboard.copy(url);
  };

  return (
    <Grid container direction="column" wrap="nowrap" spacing={3}>
      <Grid item container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography
            variant="h4"
            fontWeight="fontWeightBold"
            color="textPrimary"
            gutterBottom
          >
            {title}
          </Typography>
          <FormHelperText sx={{ marginBottom: '12px' }}>
            {subtitle}
          </FormHelperText>
        </Grid>

        <Grid item>{action}</Grid>
      </Grid>
      <OperationsView
        {...commonSectionProps}
        onEdit={onEdit}
        availableProducers={availableProducers}
      />
      <ClassesAndCommoditiesView {...commonSectionProps} onEdit={onEdit} />
      {hasMTC ? (
        <Section
          {...commonSectionProps}
          title="Loss History"
          onEdit={() => onEdit('lossRunSummaryForm')}
          data={[
            {
              key: 'lossRunSummaryFormAuto&MotorTruck',
              label: 'Auto Liability & Motor Truck Cargo',
              getValue: (data: ApplicationDetail) => {
                return data.indicationForm?.lossRunSummaryForm;
              },
              renderValue: ({
                value = [],
                previousValue = { lossRunSummary: [] },
                hasChanged,
              }: SectionDataRenderParams) => {
                const previousLossRunSummaryByPeriod = {};
                const getKey = (record: LossRunSummaryRecord) =>
                  `${record.policyPeriodStartDate}-${record.policyPeriodEndDate}`;
                for (
                  let i = 0, l = previousValue.lossRunSummary.length;
                  i < l;
                  i += 1
                ) {
                  previousLossRunSummaryByPeriod[
                    getKey(previousValue.lossRunSummary[i])
                  ] = previousValue.lossRunSummary[i];
                }

                const AutoLiabilityCoverage = value.find(
                  (item: CoverageRecord) =>
                    item.coverageType === 'CoverageAutoLiability',
                ) || { lossRunSummary: [] };

                const MotorTruckCargoCoverage = value.find(
                  (item: CoverageRecord) =>
                    item.coverageType === 'CoverageMotorTruckCargo',
                ) || { lossRunSummary: [] };

                return (
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <Typography
                        variant="caption"
                        fontWeight="fontWeightBold"
                        mb={1}
                        component="p"
                      >
                        &nbsp;
                      </Typography>
                      <TableView
                        headers={[
                          {
                            key: 'columnPolicyPeriod',
                            content: 'Policy period',
                          },
                          {
                            key: 'columnPowerUnits',
                            content: 'Units',
                          },
                        ]}
                        rows={AutoLiabilityCoverage.lossRunSummary
                          .filter(
                            (record: LossRunSummaryRecord) =>
                              !!record.numberOfPowerUnits ||
                              !!record.lossIncurred ||
                              !!record.numberOfClaims,
                          )
                          .map((record: LossRunSummaryRecord) => ({
                            columns: [
                              {
                                content: (
                                  <>
                                    {format(
                                      parseISO(record.policyPeriodStartDate),
                                      'M/d/yy',
                                    )}
                                    &nbsp;-&nbsp;
                                    {format(
                                      parseISO(record.policyPeriodEndDate),
                                      'M/d/yy',
                                    )}
                                  </>
                                ),
                              },
                              {
                                content:
                                  hasChanged &&
                                  previousLossRunSummaryByPeriod[getKey(record)]
                                    .numberOfPowerUnits !==
                                    record.numberOfPowerUnits ? (
                                    <mark>{record.numberOfPowerUnits}</mark>
                                  ) : (
                                    `${record.numberOfPowerUnits}`
                                  ),
                              },
                            ],
                          }))}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant="caption"
                        fontWeight="fontWeightBold"
                        mb={1}
                        component="p"
                      >
                        Auto Liability
                      </Typography>
                      <TableView
                        headers={[
                          {
                            key: 'columnLossIncured',
                            content: 'Loss incurred',
                          },
                          {
                            key: 'columnClaims',
                            content: 'Claims',
                          },
                        ]}
                        rows={AutoLiabilityCoverage.lossRunSummary
                          .filter(
                            (record: LossRunSummaryRecord) =>
                              !!record.numberOfPowerUnits ||
                              !!record.lossIncurred ||
                              !!record.numberOfClaims,
                          )
                          .map((record: LossRunSummaryRecord) => ({
                            columns: [
                              {
                                content:
                                  hasChanged &&
                                  previousLossRunSummaryByPeriod[getKey(record)]
                                    .lossIncurred !== record.lossIncurred ? (
                                    <mark>
                                      <NumberFormat
                                        value={record.lossIncurred}
                                        displayType="text"
                                        thousandSeparator={true}
                                        prefix="$"
                                      />
                                    </mark>
                                  ) : (
                                    <NumberFormat
                                      value={record.lossIncurred}
                                      displayType="text"
                                      thousandSeparator={true}
                                      prefix="$"
                                    />
                                  ),
                              },
                              {
                                content:
                                  hasChanged &&
                                  previousLossRunSummaryByPeriod[getKey(record)]
                                    .numberOfClaims !==
                                    record.numberOfClaims ? (
                                    <mark>{record.numberOfClaims}</mark>
                                  ) : (
                                    `${record.numberOfClaims}`
                                  ),
                              },
                            ],
                          }))}
                      />
                    </Grid>
                    {!!MotorTruckCargoCoverage && (
                      <Grid item xs={4}>
                        <Typography
                          variant="caption"
                          fontWeight="fontWeightBold"
                          mb={1}
                          component="p"
                        >
                          Motor Truck Cargo
                        </Typography>
                        <TableView
                          headers={[
                            {
                              key: 'columnLossIncured',
                              content: 'Loss incurred',
                            },
                            {
                              key: 'columnClaims',
                              content: 'Claims',
                            },
                          ]}
                          rows={MotorTruckCargoCoverage?.lossRunSummary
                            ?.filter(
                              (record: LossRunSummaryRecord) =>
                                !!record.numberOfPowerUnits ||
                                !!record.lossIncurred ||
                                !!record.numberOfClaims,
                            )
                            ?.map((record: LossRunSummaryRecord) => ({
                              columns: [
                                {
                                  content:
                                    hasChanged &&
                                    previousLossRunSummaryByPeriod[
                                      getKey(record)
                                    ].lossIncurred !== record.lossIncurred ? (
                                      <mark>
                                        <NumberFormat
                                          value={record.lossIncurred}
                                          displayType="text"
                                          thousandSeparator={true}
                                          prefix="$"
                                        />
                                      </mark>
                                    ) : (
                                      <NumberFormat
                                        value={record.lossIncurred}
                                        displayType="text"
                                        thousandSeparator={true}
                                        prefix="$"
                                      />
                                    ),
                                },
                                {
                                  content:
                                    hasChanged &&
                                    previousLossRunSummaryByPeriod[
                                      getKey(record)
                                    ].numberOfClaims !==
                                      record.numberOfClaims ? (
                                      <mark>{record.numberOfClaims}</mark>
                                    ) : (
                                      `${record.numberOfClaims}`
                                    ),
                                },
                              ],
                            }))}
                        />
                      </Grid>
                    )}
                  </Grid>
                );
              },
            },
            ...(hasAPD
              ? [
                  {
                    key: 'lossRunSummaryFormPhysicalDamage',
                    label: 'Physical Damage',
                    getValue: (data: ApplicationDetail) => {
                      return data.indicationForm?.lossRunSummaryForm;
                    },
                    renderValue: ({
                      value = [],
                      previousValue = { lossRunSummary: [] },
                      hasChanged,
                    }: SectionDataRenderParams) => {
                      const previousLossRunSummaryByPeriod = {};
                      const getKey = (record: LossRunSummaryRecord) =>
                        `${record.policyPeriodStartDate}-${record.policyPeriodEndDate}`;
                      for (
                        let i = 0, l = previousValue.lossRunSummary.length;
                        i < l;
                        i += 1
                      ) {
                        previousLossRunSummaryByPeriod[
                          getKey(previousValue.lossRunSummary[i])
                        ] = previousValue.lossRunSummary[i];
                      }

                      const PhysicalDamageCoverage = value.find(
                        (item: CoverageRecord) =>
                          item.coverageType === 'CoverageAutoPhysicalDamage',
                      );

                      return (
                        <Grid container spacing={1}>
                          <Grid item xs={4}>
                            <Typography
                              variant="caption"
                              fontWeight="fontWeightBold"
                              mb={1}
                              component="p"
                            >
                              &nbsp;
                            </Typography>
                            <TableView
                              headers={[
                                {
                                  key: 'columnPolicyPeriod',
                                  content: 'Policy period',
                                },
                                {
                                  key: 'columnPowerUnits',
                                  content: 'Units',
                                },
                              ]}
                              rows={PhysicalDamageCoverage.lossRunSummary
                                .filter(
                                  (record: LossRunSummaryRecord) =>
                                    !!record.numberOfPowerUnits ||
                                    !!record.lossIncurred ||
                                    !!record.numberOfClaims,
                                )
                                .map((record: LossRunSummaryRecord) => ({
                                  columns: [
                                    {
                                      content: (
                                        <>
                                          {format(
                                            parseISO(
                                              record.policyPeriodStartDate,
                                            ),
                                            'M/d/yy',
                                          )}
                                          &nbsp;-&nbsp;
                                          {format(
                                            parseISO(
                                              record.policyPeriodEndDate,
                                            ),
                                            'M/d/yy',
                                          )}
                                        </>
                                      ),
                                    },
                                    {
                                      content:
                                        hasChanged &&
                                        previousLossRunSummaryByPeriod[
                                          getKey(record)
                                        ].numberOfPowerUnits !==
                                          record.numberOfPowerUnits ? (
                                          <mark>
                                            {record.numberOfPowerUnits}
                                          </mark>
                                        ) : (
                                          `${record.numberOfPowerUnits}`
                                        ),
                                    },
                                  ],
                                }))}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Typography
                              variant="caption"
                              fontWeight="fontWeightBold"
                              mb={1}
                              component="p"
                            >
                              Physical Damage
                            </Typography>
                            <TableView
                              headers={[
                                {
                                  key: 'columnLossIncured',
                                  content: 'Loss incurred',
                                },
                                {
                                  key: 'columnClaims',
                                  content: 'Claims',
                                },
                              ]}
                              rows={PhysicalDamageCoverage.lossRunSummary
                                .filter(
                                  (record: LossRunSummaryRecord) =>
                                    !!record.numberOfPowerUnits ||
                                    !!record.lossIncurred ||
                                    !!record.numberOfClaims,
                                )
                                .map((record: LossRunSummaryRecord) => ({
                                  columns: [
                                    {
                                      content:
                                        hasChanged &&
                                        previousLossRunSummaryByPeriod[
                                          getKey(record)
                                        ].lossIncurred !==
                                          record.lossIncurred ? (
                                          <mark>
                                            <NumberFormat
                                              value={record.lossIncurred}
                                              displayType="text"
                                              thousandSeparator={true}
                                              prefix="$"
                                            />
                                          </mark>
                                        ) : (
                                          <NumberFormat
                                            value={record.lossIncurred}
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix="$"
                                          />
                                        ),
                                    },
                                    {
                                      content:
                                        hasChanged &&
                                        previousLossRunSummaryByPeriod[
                                          getKey(record)
                                        ].numberOfClaims !==
                                          record.numberOfClaims ? (
                                          <mark>{record.numberOfClaims}</mark>
                                        ) : (
                                          `${record.numberOfClaims}`
                                        ),
                                    },
                                  ],
                                }))}
                            />
                          </Grid>
                        </Grid>
                      );
                    },
                  },
                ]
              : []),
          ]}
        />
      ) : (
        <Section
          {...commonSectionProps}
          title="Loss History"
          onEdit={() => onEdit('lossRunSummaryForm')}
          data={[
            ...(activeApplication.indicationForm?.lossRunSummaryForm || []).map(
              (formItem, index: number) => ({
                key: `lossRunSummaryForm.${formItem.coverageType}`,
                label: ApplicationHelper.getIndicationOptionsFeatureLabel(
                  formItem.coverageType,
                ),
                getValue: (data: ApplicationDetail) => {
                  return data.indicationForm?.lossRunSummaryForm?.[index];
                },
                renderValue: ({
                  value = { lossRunSummary: [] },
                  previousValue = { lossRunSummary: [] },
                  hasChanged,
                }: SectionDataRenderParams) => {
                  const previousLossRunSummaryByPeriod = {};
                  const getKey = (record: LossRunSummaryRecord) =>
                    `${record.policyPeriodStartDate}-${record.policyPeriodEndDate}`;
                  for (
                    let i = 0, l = previousValue.lossRunSummary.length;
                    i < l;
                    i += 1
                  ) {
                    previousLossRunSummaryByPeriod[
                      getKey(previousValue.lossRunSummary[i])
                    ] = previousValue.lossRunSummary[i];
                  }

                  return (
                    <TableView
                      headers={[
                        {
                          key: 'columnPolicyPeriod',
                          content: 'Policy period',
                        },
                        {
                          key: 'columnPowerUnits',
                          content: '# of Power Units',
                        },
                        {
                          key: 'columnLossIncured',
                          content: 'Loss incurred',
                        },
                        {
                          key: 'columnClaims',
                          content: '# of claims',
                        },
                      ]}
                      rows={value.lossRunSummary
                        .filter(
                          (record: LossRunSummaryRecord) =>
                            !!record.numberOfPowerUnits ||
                            !!record.lossIncurred ||
                            !!record.numberOfClaims,
                        )
                        .map((record: LossRunSummaryRecord) => ({
                          columns: [
                            {
                              content: (
                                <>
                                  {format(
                                    parseISO(record.policyPeriodStartDate),
                                    'M/d/yy',
                                  )}
                                  &nbsp;-&nbsp;
                                  {format(
                                    parseISO(record.policyPeriodEndDate),
                                    'M/d/yy',
                                  )}
                                </>
                              ),
                            },
                            {
                              content:
                                hasChanged &&
                                previousLossRunSummaryByPeriod[getKey(record)]
                                  .numberOfPowerUnits !==
                                  record.numberOfPowerUnits ? (
                                  <mark>{record.numberOfPowerUnits}</mark>
                                ) : (
                                  `${record.numberOfPowerUnits}`
                                ),
                            },
                            {
                              content:
                                hasChanged &&
                                previousLossRunSummaryByPeriod[getKey(record)]
                                  .lossIncurred !== record.lossIncurred ? (
                                  <mark>
                                    <NumberFormat
                                      value={record.lossIncurred}
                                      displayType="text"
                                      thousandSeparator={true}
                                      prefix="$"
                                    />
                                  </mark>
                                ) : (
                                  <NumberFormat
                                    value={record.lossIncurred}
                                    displayType="text"
                                    thousandSeparator={true}
                                    prefix="$"
                                  />
                                ),
                            },
                            {
                              content:
                                hasChanged &&
                                previousLossRunSummaryByPeriod[getKey(record)]
                                  .numberOfClaims !== record.numberOfClaims ? (
                                  <mark>{record.numberOfClaims}</mark>
                                ) : (
                                  `${record.numberOfClaims}`
                                ),
                            },
                          ],
                        }))}
                    />
                  );
                },
              }),
            ),
          ]}
        />
      )}

      {[
        <IndicationView
          key="section-indication"
          {...commonSectionProps}
          onEdit={() => onEdit('selectedIndication')}
        />,
        <Section
          key="section-additional-info"
          {...commonSectionProps}
          title="Additional information"
          onEdit={() => onEdit('additionalInfoForm')}
          data={[
            ...(fleetType !== 'wholesale-non-fleet'
              ? [
                  {
                    key: 'telematicsConnection',
                    label: 'ELD/Telematics connection',
                    getValue: (data: ApplicationDetail) => {
                      return data.telematicsInfo;
                    },
                    renderValue: ({ value }: SectionDataRenderParams) => {
                      if (!value?.link) {
                        return null;
                      }

                      return (
                        <Box
                          flexDirection="column"
                          display="flex"
                          alignItems="flex-start"
                        >
                          <Box mb={1}>
                            <Typography variant="body1" color="secondary">
                              {value?.name}
                            </Typography>
                            <Typography
                              variant="caption"
                              component="p"
                              color="textSecondary"
                            >
                              {value?.email}
                            </Typography>

                            <Link
                              style={{ cursor: 'pointer' }}
                              underline={clipboard.copied ? 'none' : 'hover'}
                              onClick={() => {
                                let link = value?.link;
                                const host = import.meta.env.VITE_WEB_URL;
                                // Adding this validation in order to support older format of link
                                // Removing it after we successfully migrate to link including Schema and host.
                                if (!link.includes(host)) {
                                  link = `${host}/${link}`;
                                }
                                handleCopyConnectionLink(link);
                              }}
                            >
                              {clipboard.copied ? (
                                <Typography
                                  variant="caption"
                                  color="success.main"
                                >
                                  Copied!
                                </Typography>
                              ) : (
                                <Typography variant="caption" color="primary">
                                  Copy Connection Link
                                </Typography>
                              )}
                            </Link>
                          </Box>
                          <Box>
                            <Chip label="Link Generated" color="warning" />
                          </Box>
                        </Box>
                      );
                    },
                  },
                  {
                    key: 'telematicsConnection-divider',
                    type: 'divider',
                  } as SectionDataRecord,
                ]
              : []),
            {
              key: 'driverList',
              label: 'Drivers List',
              getValue: (data: ApplicationDetail) => {
                return data.additionalInfoForm?.driverList;
              },
              renderValue: ({
                value,
                previousValue,
                hasChanged,
              }: SectionDataRenderParams) => {
                const previousDriversSummary = getDriversSummaryRows(
                  previousValue?.drivers,
                );

                return (
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <AttachmentView
                        files={[
                          {
                            filename: (
                              value?.flatfileMetadata || value?.implerMetadata
                            )?.fileMetadata.name,
                          },
                        ]}
                      />
                    </Grid>
                    {value?.drivers ? (
                      <Grid item>
                        <div className={classes.summaryContainer}>
                          <SummaryView
                            title="Document Summary"
                            rows={getDriversSummaryRows(value?.drivers).map(
                              (record, index) => {
                                return {
                                  ...record,
                                  value:
                                    hasChanged &&
                                    previousDriversSummary[index].value !==
                                      record.value ? (
                                      <mark>{record.value}</mark>
                                    ) : (
                                      record.value
                                    ),
                                };
                              },
                            )}
                          />
                        </div>
                      </Grid>
                    ) : null}
                  </Grid>
                );
              },
            },
            {
              key: 'driverList-divider',
              type: 'divider',
            },
            {
              key: 'ownerOperators',
              label: 'How many units in the fleet are owner-operators?',
              getValue: (data: ApplicationDetail) => {
                return data.additionalInfoForm?.numOwnerOperatorUnits;
              },
              renderValue: ({ value, hasChanged }: SectionDataRenderParams) => {
                return hasChanged ? <mark>{value}</mark> : value;
              },
            },
            ...(activeApplication?.additionalInfoForm?.percentageOfSubhaul
              ? [
                  {
                    key: 'percentageOfSubhaul',
                    label: 'What % of your operations is subhaul?',
                    getValue: (data: ApplicationDetail) => {
                      return data.additionalInfoForm?.percentageOfSubhaul;
                    },
                    renderValue: ({
                      value,
                      hasChanged,
                    }: SectionDataRenderParams) => {
                      return hasChanged ? <mark>{value}</mark> : `${value}%`;
                    },
                  },
                ]
              : []),
            {
              key: 'customerCommodity',
              label:
                'Select if any of these operations or commodities apply to the customer',

              getValue: (data: ApplicationDetail) => {
                return data.additionalInfoForm?.commodities;
              },
              renderValue: ({
                value = [],
                hasChanged,
              }: SectionDataRenderParams) => {
                if (!value.length) {
                  return 'N/A';
                }

                return hasChanged ? (
                  <mark>
                    {value.map((commodity: string) => (
                      <p key={commodity}>
                        {ApplicationHelper.getAdditionInformationCommodityLabel(
                          commodity,
                        )}
                      </p>
                    ))}
                  </mark>
                ) : (
                  value.map((commodity: string) => (
                    <p key={commodity}>
                      {ApplicationHelper.getAdditionInformationCommodityLabel(
                        commodity,
                      )}
                    </p>
                  ))
                );
              },
            },
            ...((activeApplication?.additionalInfoForm?.commodities || [])
              .length
              ? [
                  {
                    key: 'additionalInfo',
                    label: 'Additional information',
                    getValue: (data: ApplicationDetail) => {
                      return data.additionalInfoForm?.commoditiesComment;
                    },
                    renderValue: ({
                      value,
                      hasChanged,
                    }: SectionDataRenderParams) => {
                      return hasChanged ? (
                        <mark>{value || 'N/A'}</mark>
                      ) : (
                        value || 'N/A'
                      );
                    },
                  },
                ]
              : []),
            {
              key: 'additionalInfo-divider',
              type: 'divider',
            },
            {
              key: 'lossRuns',
              label: 'Loss Runs',

              getValue: (data: ApplicationDetail) => {
                return data.additionalInfoForm?.lossRunFiles;
              },
              renderValue: ({ value }: SectionDataRenderParams) => {
                return (
                  <AttachmentView
                    files={
                      value?.map((item: any) => {
                        return {
                          filename: item.name || item.handle,
                        };
                      }) || []
                    }
                  />
                );
              },
            },
            {
              key: 'lossRunNarrative',
              label: 'Large losses',
              getValue: (data: ApplicationDetail) => {
                return data.additionalInfoForm?.largeLossComment;
              },
              renderValue: ({ value, hasChanged }: SectionDataRenderParams) => {
                return hasChanged ? (
                  <mark>{value || 'N/A'}</mark>
                ) : (
                  value || 'N/A'
                );
              },
            },
            {
              key: 'isALIncumbent',
              label: 'Do you currently control AL for this account?',
              getValue: (data: ApplicationDetail) => {
                return data.additionalInfoForm?.coverageMetadata
                  ?.additionalIncumbentInfo?.isALIncumbent;
              },
              renderValue: ({ value, hasChanged }: SectionDataRenderParams) => {
                return hasChanged ? (
                  <mark>{value ? 'Yes' : 'No'}</mark>
                ) : value ? (
                  'Yes'
                ) : (
                  'No'
                );
              },
            },
            {
              key: 'targetALPrice',
              label: 'Total Target AL Price',
              getValue: (data: ApplicationDetail) => {
                return data.additionalInfoForm?.targetPrices?.find(
                  (tp) =>
                    tp.coverageType === CoverageType.CoverageAutoLiability,
                );
              },
              renderValue: ({ value, hasChanged }: SectionDataRenderParams) => {
                return hasChanged ? (
                  <mark>
                    {value?.totalPremium ? (
                      <NumberFormat
                        value={value.totalPremium}
                        displayType="text"
                        thousandSeparator={true}
                        prefix="$"
                      />
                    ) : (
                      'N/A'
                    )}
                  </mark>
                ) : value?.totalPremium ? (
                  <NumberFormat
                    value={value.totalPremium}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="$"
                  />
                ) : (
                  'N/A'
                );
              },
            },
            {
              key: 'comments',
              label: 'Additional information',
              getValue: (data: ApplicationDetail) => {
                return data.additionalInfoForm?.overallComment;
              },
              renderValue: ({ value, hasChanged }: SectionDataRenderParams) => {
                return hasChanged ? (
                  <mark>{value || 'N/A'}</mark>
                ) : (
                  value || 'N/A'
                );
              },
            },
            {
              key: 'additionalAgentFiles',
              label: 'Additional Agent Files',

              getValue: (data: ApplicationDetail) => {
                return data.additionalInfoForm?.additionalAgentFiles;
              },
              renderValue: ({ value }: SectionDataRenderParams) => {
                return (
                  <AttachmentView
                    files={
                      value?.map((item: any) => {
                        return {
                          filename: item.name || item.handle,
                        };
                      }) || []
                    }
                  />
                );
              },
            },
          ]}
        />,
      ][fleetType !== 'fleet' ? 'reverse' : 'slice']()}
    </Grid>
  );
};

export default Review;
