import * as React from 'react';
import { InputAdornment, IconButton } from '@material-ui/core';
import IconEye from '../../assets/icons/eye.svg';
import InputWithLabel, { InputWithLabelProps } from './withLabel';

export const InputPassword = React.forwardRef<
  HTMLInputElement,
  InputWithLabelProps
>(({ label, ...rest }, ref) => {
  const [passwordFieldType, setPasswordFieldType] = React.useState<
    'text' | 'password'
  >('password');

  function handlePasswordTypeToggle() {
    if (passwordFieldType === 'text') {
      setPasswordFieldType('password');
    } else {
      setPasswordFieldType('text');
    }
  }

  return (
    <InputWithLabel
      label={label}
      type={passwordFieldType}
      endAdornment={
        <InputAdornment position="end">
          <IconButton size="small" onClick={handlePasswordTypeToggle}>
            <img src={IconEye} alt="Toggle Password Visibility" />
          </IconButton>
        </InputAdornment>
      }
      {...rest}
      ref={ref}
    />
  );
});

export default InputPassword;
