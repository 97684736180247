import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { AdditionalInsuredChangeChangeTypeEnum } from '@nirvana/api/endorsementapp';
import { useFieldArray, useFormContext } from 'react-hook-form-v7';
import AdditionalInsuredForm from './additional-insured-form';

const AdditionalInsured = ({
  hasBlanketWaiverOfSubrogation,
}: {
  hasBlanketWaiverOfSubrogation: boolean;
}) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'additionalInsureds',
    shouldUnregister: true,
  });

  return (
    <div className="flex flex-row items-start">
      <h6 className="font-bold basis-1/3">Additional Insured</h6>
      <div className="flex flex-col gap-6 basis-4/6">
        {fields.map((record: any, index) => {
          return (
            <AdditionalInsuredForm
              hasBlanketWaiverOfSubrogation={hasBlanketWaiverOfSubrogation}
              key={`ai-${record.additionalInsured.name}-${index}`}
              index={index}
              disabled={
                record.changeType ===
                AdditionalInsuredChangeChangeTypeEnum.Unchanged
              }
              onRemove={() => {
                remove(index);
              }}
            />
          );
        })}
        <div>
          <Button
            startIcon={<Add />}
            variant="outlined"
            onClick={() =>
              append({
                changeType: AdditionalInsuredChangeChangeTypeEnum.Added,
                additionalInsured: {
                  name: '',
                  address: {
                    street: '',
                    city: '',
                    state: '',
                    zip: '',
                  },
                },
                hasWoS: false,
              })
            }
          >
            Add Additional Insured
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AdditionalInsured;
