import {
  Box,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
} from '@material-ui/core';
import { ApplicationDetail } from '@nirvana/api/quoting';
import { CheckboxRow } from '@nirvana/ui-kit';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useStyles } from 'src/features/application/components/create/additionalInformation';
import { application as ApplicationHelper } from 'src/helpers';

interface CommodityLabelFormViewProps {
  application: ApplicationDetail;
}

const CommodityLabelList = Object.keys(
  ApplicationHelper.AdditionalInformationCommodityLabel,
);

const CommodityLabelFormView = ({
  application,
}: CommodityLabelFormViewProps) => {
  const classes = useStyles();

  const { control, setValue } = useFormContext();

  React.useEffect(() => {
    const commodities = application?.additionalInfoForm?.commodities;

    if (commodities) {
      setValue('commodities', commodities);
    }
  }, [application?.additionalInfoForm?.commodities, setValue]);

  return (
    <Grid item container direction="column" spacing={3}>
      <Grid item>
        <Box paddingTop="10px">
          <InputLabel htmlFor="coverage-input" className={classes.inputLabel}>
            Select if any of these operations or commodities apply
          </InputLabel>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <FormGroup aria-label="commodities">
          <Controller
            name="commodities"
            defaultValue={
              application?.additionalInfoForm?.commodities || CommodityLabelList
            }
            control={control}
            render={(props) => (
              <>
                {CommodityLabelList.map((commodityItem: string) => (
                  <FormControl fullWidth key={commodityItem}>
                    <CheckboxRow
                      className={classes.checkbox}
                      label={ApplicationHelper.getAdditionInformationCommodityLabel(
                        commodityItem,
                      )}
                      checked={props.value.includes(commodityItem)}
                      onChange={(event) => {
                        if (event.target.checked) {
                          props.onChange([...props.value, commodityItem]);
                        } else {
                          props.onChange(
                            props.value.filter(
                              (record: string) => record !== commodityItem,
                            ),
                          );
                        }
                      }}
                    />
                  </FormControl>
                ))}
              </>
            )}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
};

export default CommodityLabelFormView;
