import { Fragment, useEffect } from 'react';
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
} from '@material-ui/core';

import Clock from 'src/assets/icons/permissions/clock.svg';
import LocationMarker from 'src/assets/icons/permissions/location-marker.svg';
import ShieldCheck from 'src/assets/icons/permissions/shield-check.svg';
import Truck from 'src/assets/icons/permissions/truck.svg';
import User from 'src/assets/icons/permissions/user.svg';
import { useAnalytics } from 'src/helpers/analytics';
import { TSPRecord } from '@nirvana/api/quoting';
import { hooks } from 'src/helpers';
import { PERMISSION_VIEW_TELEMATICS } from 'src/features/telematics/events';

const permissions = [
  {
    name: 'Vehicles',
    access: 'Read access only',
    icon: Truck,
  },
  {
    name: 'Drivers',
    access: 'Read access only',
    icon: User,
  },
  {
    name: 'Hours of Service',
    access: 'Read access only',
    icon: Clock,
  },
  {
    name: 'Safety Events',
    access: 'Read access only',
    icon: ShieldCheck,
  },
  {
    name: 'Location',
    access: 'Read access only',
    icon: LocationMarker,
  },
];

const Permissions = ({ selectedProvider }: { selectedProvider: TSPRecord }) => {
  const query = hooks.useQuery();
  const { capture } = useAnalytics();
  const applicationId = query.get('token') || '';

  useEffect(() => {
    if (applicationId && selectedProvider.tsp) {
      capture(PERMISSION_VIEW_TELEMATICS, {
        provider: selectedProvider.tsp,
        applicationId,
      });
    }
  }, [applicationId, capture, selectedProvider.tsp]);

  return (
    <Grid container direction="column" spacing={2} mb={1}>
      <Grid item alignSelf="center">
        <Typography
          textAlign="center"
          variant="caption"
          component="p"
          maxWidth={320}
          lineHeight={1.25}
        >
          <strong>Nirvana</strong> is requesting permission to access your
          <br /> <strong>{selectedProvider.name}</strong> data
        </Typography>
      </Grid>
      <Grid item>
        <List>
          {permissions.map((permission, index) => {
            return (
              <Fragment key={`${permission.name}`}>
                <ListItem alignItems="center">
                  <ListItemAvatar
                    sx={{
                      minWidth: 40,
                    }}
                  >
                    <img src={permission.icon} alt={permission.name || ''} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={permission.name}
                    secondary={permission.access}
                    primaryTypographyProps={{
                      variant: 'caption',
                      fontWeight: 'fontWeightBold',
                      lineHeight: 1,
                      marginBottom: 0.5,
                    }}
                    secondaryTypographyProps={{
                      variant: 'caption',
                      lineHeight: 1,
                      fontWeight: 'fontWeightMedium',
                      color: 'text.hint',
                    }}
                  />
                </ListItem>
                {index !== permissions.length - 1 && <Divider component="li" />}
              </Fragment>
            );
          })}
        </List>
      </Grid>
    </Grid>
  );
};

export default Permissions;
