import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { AttachmentView, Dialog } from '@nirvana/ui-kit';
import { ITheme } from 'src/assets/themes';

import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import {
  downloadApplicationFile,
  uploadFlatFile,
} from 'src/features/application/actions';
import { application as ApplicationHelper } from 'src/helpers';

import { Close } from '@material-ui/icons';
import { FileDestinationGroup, FileType } from '@nirvana/api/quoting';
import { useDispatch } from 'react-redux';

import IconFileUpload from 'src/assets/icons/upload-primary.svg';
import { getHelperText } from 'src/features/application/components/create/additionalInformation';
import { FlatFileUpload } from '../button';
import { SummaryView } from '../table';

const useStyles = makeStyles((theme: ITheme) => ({
  formControl: {
    width: 220,
  },

  summaryContainer: {
    width: 380,
  },
  dialogPaper: {
    width: theme.typography.pxToRem(600),
  },
  dialogContent: {
    paddingBottom: theme.spacing(3),
    position: 'relative',
    overflow: 'hidden',
    borderBottom: '1px solid #E6E7EF',
  },
  dialogCloseButton: {
    position: 'absolute',
    top: theme.spacing(0),
    right: theme.spacing(0),
  },
}));

const EquipmentUploadWithSummary = ({
  control,
  errors,
  isUploading,
  setIsUploading,
  openUploadDialog,
  isDialogOpen,
  fileDefaultValue,
}: any) => {
  const dispatch = useDispatch();

  const handleFileDownload = (handleId?: string) => {
    if (!handleId) {
      return;
    }

    dispatch(downloadApplicationFile(handleId));
  };

  const classes = useStyles();

  // TODO: Change this to read from the API
  const isApdCoverage = true;

  return (
    <Box>
      <Controller
        control={control}
        name="equipmentList"
        // Default value is set to undefined so that the required validation works
        defaultValue={fileDefaultValue || undefined}
        rules={{ required: 'Please upload a list of vehicles' }}
        render={(props) => {
          const summaryValues = ApplicationHelper.getEquipmentSummary(
            props.value?.info,
          );
          const summaryRows = [
            {
              label: 'Total # of trucks and trailers',
              value: summaryValues.totalUnits,
            },
            {
              label: 'Total value',
              value: (
                <NumberFormat
                  value={summaryValues.totalValue}
                  displayType="text"
                  thousandSeparator={true}
                  prefix="$"
                />
              ),
            },
          ];

          return (
            <Grid container direction="column" spacing={1}>
              <Grid item>
                {props.value?.flatfileMetadata?.fileMetadata?.name ? (
                  <AttachmentView
                    files={[
                      {
                        filename:
                          props.value?.flatfileMetadata?.fileMetadata?.name ||
                          'Equipment List.xlsx',
                      },
                    ]}
                    onRemove={() => {
                      props.onChange(null);
                    }}
                    onDownload={() => {
                      handleFileDownload(
                        props.value.flatfileMetadata.fileMetadata.handle,
                      );
                    }}
                  />
                ) : (
                  <Button
                    variant="outlined"
                    onClick={() => openUploadDialog(true)}
                    startIcon={
                      isUploading ? (
                        <CircularProgress size="16px" />
                      ) : (
                        <img src={IconFileUpload} alt="File Upload" />
                      )
                    }
                    disabled={isUploading}
                  >
                    Upload
                  </Button>
                )}
                <Dialog
                  title=""
                  open={isDialogOpen}
                  maxWidth="md"
                  onClose={() => openUploadDialog(false)}
                  primaryAction={
                    <Box onClick={() => openUploadDialog(false)}>
                      <FlatFileUpload
                        key={
                          isApdCoverage
                            ? 'flatfile-with-apd'
                            : 'flatfile-with-al'
                        }
                        variant="contained"
                        type="Equipment"
                        title="Upload Equipment List"
                        fields={[
                          {
                            label: 'VIN',
                            alternates: [
                              'Chassis',
                              'VIN#',
                              'VIN Number',
                              '17 Digit VIN',
                              '17 Digit VIN#',
                              'Trailer#',
                              'Serial',
                              'Serial#',
                              'Full VIN#',
                            ],
                            key: 'vin',
                            description: 'Uniquely identifies your vehicle',
                            validators: [
                              {
                                validate: 'required',
                                error:
                                  'VIN is required for power units. For trailers without VINs, use "Trailer#" (example "Trailer1", "Trailer2", etc...)',
                              },
                              {
                                validate: 'regex_matches',
                                error:
                                  'A valid VIN is required for power units. For trailers without VINs, use "Trailer#" (no spaces, example "Trailer1", "Trailer2", etc...)',
                                regex:
                                  '^(?!(T|t)otal|TOTAL|(V|v)in|VIN|(V|v)alue|VALUE)([a-zA-Z0-9])[a-zA-Z0-9]*([a-zA-Z0-9])$',
                              },
                              {
                                validate: 'unique',
                                error:
                                  'Duplicate VINs are not allowed. For trailers, use "Trailer#" (example "Trailer1", "Trailer2", etc...)',
                              },
                            ],
                          },
                          {
                            label: 'Stated Value',
                            alternates: [
                              'Amount',
                              'Cost',
                              'Renewal Value',
                              'Stated Value',
                              'Stated Amount',
                              'Stated Amt',
                              'Value',
                              'Renewal',
                              'Renewal Physical Damage',
                              'Physical Damage Value',
                              'Physical Damage Amount',
                            ],
                            key: 'statedValue',
                            description: 'Stated value for your vehicle',
                            validators: [
                              {
                                validate: 'required',
                                error: isApdCoverage
                                  ? 'Stated value is required for Physical Damage. For no Physical Damage, use "0".'
                                  : 'Stated value is required for Auto Liability. For trailers without stated value, use "0"',
                              },
                              {
                                validate: 'regex_matches',
                                error: isApdCoverage
                                  ? 'Stated value should be a valid amount.'
                                  : 'Stated value should be a valid amount.',
                                regex: '^(\\d*\\.?\\d+)(,\\d*\\.?\\d*)*$',
                              },
                              {
                                validate: 'regex_matches',
                                error: isApdCoverage
                                  ? 'Valid stated value is required for Physical Damage. For no Physical Damage, use "0".'
                                  : 'Valid stated value is required for Auto Liability. For trailers without stated value, use "0"',
                                regex:
                                  '^(?!(V|v)alue|VALUE|(R|r)enewal|RENEWAL|(S|s)tated|STATED|(C|c)ost|COST|(A|a)mount)|AMOUNT',
                              },
                            ],
                          },
                        ]}
                        fieldHooks={{
                          vin: (values) => {
                            return values.map(([item, index]) => {
                              const newItem =
                                typeof item === 'string'
                                  ? item.replace(/[$,.\s]/g, '')
                                  : item;

                              if (newItem === item) {
                                return [item, index] as any;
                              } else {
                                return [
                                  {
                                    value: newItem,
                                    info: [
                                      {
                                        message:
                                          'Removed invalid characters and whitespaces',
                                        level: 'info',
                                      },
                                    ],
                                  },
                                  index,
                                ];
                              }
                            });
                          },
                          statedValue: (values) => {
                            return values.map(([item, index]) => {
                              const newItem =
                                typeof item === 'string'
                                  ? ~~item.replace(/[$,]/g, '')
                                  : item;

                              if (newItem === item) {
                                return [item, index] as any;
                              } else if (
                                newItem &&
                                !/^(\d*\.?\d+)(,\d*\.?\d*)*$/.test(
                                  newItem.toString(),
                                )
                              ) {
                                return [
                                  {
                                    value: newItem,
                                    info: [
                                      {
                                        message:
                                          'Stated value should be a valid number.',
                                        level: 'error',
                                      },
                                    ],
                                  },
                                ];
                              } else {
                                return [
                                  {
                                    value: newItem,
                                    info: [
                                      {
                                        message:
                                          'Removed invalid characters and decimals',
                                        level: 'info',
                                      },
                                    ],
                                  },
                                  index,
                                ];
                              }
                            });
                          },
                        }}
                        value={props.value}
                        onChange={(results: any) => {
                          if (results) {
                            setIsUploading(true);
                            const equipmentList: any = {
                              info: results.validData.map(
                                ({ vin, statedValue }: any) => ({
                                  vin: vin,
                                  statedValue:
                                    typeof statedValue === 'number'
                                      ? statedValue
                                      : ~~statedValue.replace(/[$,]/g, ''),
                                }),
                              ),
                              flatfileMetadata: {
                                flatfileHandle: results.$meta.batchID,
                                fileMetadata: {
                                  name: results.$meta.filename,
                                },
                              },
                            };
                            const uploadPromise: any = equipmentList
                              .flatfileMetadata?.fileMetadata?.name
                              ? dispatch(
                                  uploadFlatFile({
                                    flatfileMetadata:
                                      equipmentList.flatfileMetadata,
                                    fileType: FileType.FileTypeEquipmentList,
                                    fileDestinationGroup:
                                      FileDestinationGroup.FileDestinationGroupQuoting,
                                  }),
                                )
                              : Promise.reject(new Error('skip_file_upload'));
                            uploadPromise
                              .then((response: any) => {
                                equipmentList.flatfileMetadata.fileMetadata.handle =
                                  response.payload.handle;

                                props.onChange(equipmentList);

                                setIsUploading(false);
                              })
                              .catch(() => {
                                if (
                                  !equipmentList.flatfileMetadata?.fileMetadata
                                    ?.name
                                ) {
                                  equipmentList.flatfileMetadata.fileMetadata.name =
                                    'EquipmentList.csv';
                                }
                                // Save the data anyways
                                props.onChange(equipmentList);

                                setIsUploading(false);
                              });
                          } else {
                            props.onChange(undefined);
                          }
                        }}
                        render={(_, launch) => {
                          return (
                            <Button variant="contained" onClick={launch}>
                              Proceed
                            </Button>
                          );
                        }}
                      />
                    </Box>
                  }
                  classes={{
                    paper: classes.dialogPaper,
                  }}
                >
                  <Box className={classes.dialogContent}>
                    <IconButton
                      className={classes.dialogCloseButton}
                      onClick={() => openUploadDialog(false)}
                      edge="end"
                      size="small"
                    >
                      <Close />
                    </IconButton>
                    <Grid container direction="column" spacing={3}>
                      <Grid
                        item
                        container
                        alignItems="center"
                        flexWrap="nowrap"
                      >
                        <Typography variant="h5" color="textPrimary">
                          Upload equipment list
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={3}>
                          <Grid item>
                            <Typography variant="body2" mb={2} component="p">
                              Please upload the equipment list in{' '}
                              <strong>CSV</strong> or <strong>Excel</strong>{' '}
                              format.
                            </Typography>

                            <Typography variant="body2" gutterBottom>
                              <strong>
                                <u>NOTE:</u>{' '}
                              </strong>
                              To ensure the upload works properly, please make
                              sure that:
                              <ol>
                                <li>
                                  - Tractors and trailers are on the same tab or
                                  sheet in the file.
                                </li>
                                <li>
                                  - There are NO merged cells in your file. For
                                  instructions on how to remove merged cells,
                                  please{' '}
                                  <Link
                                    href="https://www.officetooltips.com/excel_2016/tips/unmerging_all_merged_cells.html"
                                    target="_blank"
                                  >
                                    check here
                                  </Link>
                                  .
                                </li>
                              </ol>
                            </Typography>
                            <Typography variant="body2">
                              If you prefer, you can download and use our{' '}
                              <Link
                                href="https://nirvana-public-assets.s3.us-east-2.amazonaws.com/Nirvana_upload_template.xlsx"
                                target="_blank"
                              >
                                <strong>template here</strong>
                              </Link>{' '}
                              to make sure the upload works.
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Dialog>
              </Grid>
              {props.value?.info && props.value?.info?.length ? (
                <Grid item>
                  <div className={classes.summaryContainer}>
                    <SummaryView title="Document Summary" rows={summaryRows} />
                  </div>
                </Grid>
              ) : null}
            </Grid>
          );
        }}
      />

      {!!errors.equipmentList && (
        <FormHelperText error>
          {getHelperText('equipmentList', errors)}
        </FormHelperText>
      )}
    </Box>
  );
};

export const EquipmentUploadWithSummaryContainer = (props: any) => {
  const methods = useFormContext();

  return <EquipmentUploadWithSummary {...methods} {...props} />;
};

export default EquipmentUploadWithSummaryContainer;
export { EquipmentUploadWithSummary };
