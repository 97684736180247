import range from 'lodash/range';
import DatePicker from 'react-datepicker';
import { getMonth, getYear } from 'date-fns';
import { OutlinedInput, makeStyles } from '@material-ui/core';
import 'react-datepicker/dist/react-datepicker.css';

const NIRVANA_START_YEAR = 2021;
const YEARS = range(NIRVANA_START_YEAR, getYear(new Date()) + 1, 1);
const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

type DateTimePickerProps = {
  value: Date;
  onChange: (date: Date) => void;
};

type DateTimeCustomHeaderProps = {
  date: Date;
  changeYear: (year: string) => void;
  changeMonth: (month: number) => void;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
};

export const DateTimePicker = ({ value, onChange }: DateTimePickerProps) => {
  const useStyles = makeStyles({
    root: {
      '& > .react-datepicker-wrapper': {
        width: '100%',
      },

      '& > .react-datepicker__tab-loop .react-datepicker': {
        fontFamily: 'inherit',
        color: 'inherit',
      },

      '& > .react-datepicker__tab-loop .react-datepicker__time-container .react-datepicker-time__header':
        {
          fontFamily: 'inherit',
          color: 'inherit',
          fontWeight: 'normal',
        },

      '& > .react-datepicker__tab-loop .react-datepicker__day': {
        borderRadius: '0.3rem',
        width: '28px',
        height: '28px',
      },

      '& > .react-datepicker__tab-loop .react-datepicker__day--selected': {
        backgroundColor: '#3350A1',
        borderColor: '#757575',
      },

      '& > .react-datepicker__tab-loop .react-datepicker__day--today': {
        borderRadius: '0.3rem',
        border: '1px solid #757575',
      },

      '& > .react-datepicker__tab-loop .react-datepicker__day--keyboard-selected':
        {
          backgroundColor: 'transparent',
          border: 'none',
        },

      '& > .react-datepicker__tab-loop ul.react-datepicker__time-list > li.react-datepicker__time-list-item.react-datepicker__time-list-item--selected':
        {
          backgroundColor: '#3350A1',
        },
    },
    header: {
      margin: 12,
      display: 'flex',
      justifyContent: 'space-between',
    },
  });

  const styles = useStyles();

  return (
    <div className={styles.root}>
      <DatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }: DateTimeCustomHeaderProps) => (
          <div className={styles.header}>
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {'<'}
            </button>
            <select
              data-testid="date-picker-year"
              className="bg-transparent text-sm"
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {YEARS.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              data-testid="date-picker-month"
              className="bg-transparent text-sm"
              value={MONTHS[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(MONTHS.indexOf(value))
              }
            >
              {MONTHS.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {'>'}
            </button>
          </div>
        )}
        selected={value}
        onChange={(date) => date && onChange(date)}
        maxDate={new Date()}
        showTimeSelect
        showIcon
        toggleCalendarOnIconClick
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="MM/dd/yyyy h:mm aa"
        customInput={<OutlinedInput className="w-full" />}
      />
    </div>
  );
};
