import {
  Box,
  Button,
  FormHelperText,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { AttachmentView } from '@nirvana/ui-kit';

import { Controller, useFormContext } from 'react-hook-form';
import { application as ApplicationHelper } from 'src/helpers';

import { useDispatch } from 'react-redux';
import IconFileUpload from 'src/assets/icons/upload-primary.svg';
import { downloadApplicationFile } from 'src/features/application/actions';
import { getHelperText } from 'src/features/application/components/create/additionalInformation';
import DriversListDialog from 'src/features/application/components/create/driversListUpload';
import { SummaryView } from '../table';

const ContentMaxWidth = 380;

const useStyles = makeStyles(() => ({
  summaryContainer: {
    width: ContentMaxWidth,
  },
}));

const DriverUploadWithSummary = ({
  openUploadDialog,
  isDialogOpen,
  fileDefaultValue,
}: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { control, errors } = useFormContext();

  const handleFileDownload = (handleId?: string) => {
    if (!handleId) {
      return;
    }

    dispatch(downloadApplicationFile(handleId));
  };

  return (
    <Box>
      <Controller
        control={control}
        name="driverList"
        defaultValue={fileDefaultValue || undefined}
        rules={{ required: 'Please upload a list of drivers' }}
        render={(props) => {
          const summaryValues = ApplicationHelper.getDriversSummary(
            props.value?.drivers,
          );
          const summaryRows = [
            {
              label: 'Drivers aged 18-30 years',
              value: summaryValues['18-30'],
            },
            {
              label: 'Drivers aged 31-50 years',
              value: summaryValues['31-50'],
            },
            {
              label: 'Drivers aged 51+ years',
              value: summaryValues['51+'],
            },
            {
              label: 'Total # of drivers',
              value: summaryValues.total,
            },
          ];

          return (
            <Grid container direction="column" spacing={1}>
              <Grid item>
                {props.value?.flatfileMetadata?.fileMetadata?.name ? (
                  <AttachmentView
                    files={[
                      {
                        filename:
                          props.value?.flatfileMetadata?.fileMetadata?.name ||
                          'Drivers List.xlsx',
                      },
                    ]}
                    onRemove={() => {
                      props.onChange(null);
                    }}
                    onDownload={() => {
                      handleFileDownload(
                        props.value.flatfileMetadata.fileMetadata.handle,
                      );
                    }}
                  />
                ) : (
                  <>
                    <Button
                      variant="outlined"
                      onClick={() => openUploadDialog(true)}
                      startIcon={<img src={IconFileUpload} alt="File Upload" />}
                    >
                      Upload
                    </Button>
                  </>
                )}
                <DriversListDialog
                  {...props}
                  value={props.value}
                  onChange={(data) => {
                    props.onChange(data);
                  }}
                  open={isDialogOpen}
                  onClose={() => openUploadDialog(false)}
                  onUploadComplete={() => {
                    openUploadDialog(false);
                  }}
                  onStart={() => {
                    openUploadDialog(false);
                  }}
                />
              </Grid>
              {props.value?.drivers ? (
                <Grid item>
                  <div className={classes.summaryContainer}>
                    <SummaryView title="Document Summary" rows={summaryRows} />
                  </div>
                </Grid>
              ) : null}
            </Grid>
          );
        }}
      />
      {!!errors.driverList && (
        <FormHelperText error>
          {getHelperText('driverList', errors)}
        </FormHelperText>
      )}
    </Box>
  );
};

export const DriverUploadWithSummaryContainer = (props: any) => {
  const methods = useFormContext();

  return <DriverUploadWithSummary {...methods} {...props} />;
};

export default DriverUploadWithSummaryContainer;
export { DriverUploadWithSummary };
